import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartA from "../../../../models/fsa2211Form/FSA2211PartA"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility"
import "./FSA2211Form.css"

type formDataPartACallback = (formDataPartA: FSA2211PartA) => void;

interface FSA2211FormPartAProps {
  partAData?: FSA2211PartA;
  formDataChange: formDataPartACallback;
}

export default class FSA2211FormPartA extends React.Component<FSA2211FormPartAProps, FSA2211PartA> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partAData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartAProps) {
    if (previousProps.partAData !== this.props.partAData) {
      this.setState(this.props.partAData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartA {
    return {
      individual: false,
      jointOperation: false,
      trust: false,
      partnership: false,
      corporation: false,
      cooperative: false,
      llc: false,
      otherCheckbox: false,
      otherExplainTextbox: ""
    } as FSA2211PartA
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART A &nbsp;&nbsp; TYPE OF OPERATION <em>(LOAN APPLICANT)</em></Col>
                </Row>
                <Row>
                  <Col><span className="standard-text">1. Type of Operation</span></Col>
                </Row>
                <Row noGutters className="standard-text">
                  <Form.Check className="mr-3" type="checkbox" name="individual" label="Individual" onChange={this.handleInputChange.bind(this)} checked={this.state.individual}></Form.Check>
                  <Form.Check className="mr-3" type="checkbox" >
                    <Form.Check.Input name="jointOperation" onChange={this.handleInputChange.bind(this)} type="checkbox" checked={this.state.jointOperation}></Form.Check.Input><Form.Check.Label>Joint Operation <em className="instruction-text">(includes Spouses Applying jointly)</em></Form.Check.Label>
                  </Form.Check>

                  <Form.Check className="mr-3" type="checkbox" label="Trust" name="trust" onChange={this.handleInputChange.bind(this)} checked={this.state.trust}></Form.Check>
                  <Form.Check className="mr-3" type="checkbox" label="Partnership" name="partnership" onChange={this.handleInputChange.bind(this)} checked={this.state.partnership}></Form.Check>
                  <Form.Check className="mr-3" type="checkbox" label="Corporation" name="corporation" onChange={this.handleInputChange.bind(this)} checked={this.state.corporation}></Form.Check>
                  <Form.Check className="mr-3" type="checkbox" label="Cooperative" name="cooperative" onChange={this.handleInputChange.bind(this)} checked={this.state.cooperative}></Form.Check>
                  <Form.Check className="mr-3" type="checkbox" label="LLC" name="llc" onChange={this.handleInputChange.bind(this)} checked={this.state.llc}></Form.Check>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={2} className="align-left">
                    <Form.Check type="checkbox">
                      <Form.Check.Input type="checkbox" name="otherCheckbox" onChange={this.handleInputChange.bind(this)} checked={this.state.otherCheckbox}></Form.Check.Input><Form.Check.Label>Other <em>(Explain):</em></Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col md={5} className="align-left"><Form.Control onChange={this.handleInputChange.bind(this)} name="otherExplainTextbox" type="text" value={this.state.otherExplainTextbox} /></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}