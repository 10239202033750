import { EquipmentCondition, NotesPayableLoanPurpose } from "../shared/Enums";

class BalanceSheetUtility {
    public GetEquipmentConditionText(condition?: EquipmentCondition): string {
        switch (condition) {
            case EquipmentCondition.Excellent:
                return 'Excellent';
            case EquipmentCondition.Good:
                return 'Good';
            case EquipmentCondition.Average:
                return 'Average';
            case EquipmentCondition.Fair:
                return 'Fair';
            case EquipmentCondition.Poor:
                return 'Poor';
            default:
                return ''
        }
    }

    public GetNotesPayableLoanPurposeText(purpose?: NotesPayableLoanPurpose): string {
        switch (purpose) {
            case NotesPayableLoanPurpose.Equipment:
                return 'Equipment';
            case NotesPayableLoanPurpose.Operating:
                return 'Operating';
            default:
                return ''
        }
    }
}

const balanceSheetUtility = new BalanceSheetUtility();
export default balanceSheetUtility;