import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import SecondaryButton from "shared/components/SecondaryButton";
import PrimaryButton from "shared/components/PrimaryButton";
import DangerButton from "shared/components/DangerButton";
import "./ConfirmModal.css"

type callback = () => void;

interface ConfirmModalProps {
  showModal: boolean;
  modalTitle: string;
  modalMessage: string;
  confirmButtonText?: string;
  isDanger?: boolean;
  onModalConfirm: callback;
  onModalCancel: callback;
}

export default class ConfirmModal extends React.Component<ConfirmModalProps> {
  getConfirmButtonText(): string {
    return this.props.confirmButtonText ?? 'Confirm';
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.onModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>{this.props.modalMessage}</p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton buttonText={'Cancel'} onClick={this.props.onModalCancel} />
          {this.props.isDanger ? 
            <DangerButton 
              buttonText={this.getConfirmButtonText()}
              onClick={this.props.onModalConfirm}
            />
          :
            <PrimaryButton 
              buttonText={this.getConfirmButtonText()}
              onClick={this.props.onModalConfirm}
            />
          }
        </Modal.Footer>
      </Modal>
    );
  }
}