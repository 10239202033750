
import { ADD_DEALER, ADD_DEALER_SALESMAN, DealerActionTypes, DealerState, GET_DEALERS, UPDATE_DEALER, UPDATE_DEALER_SALESMAN } from "redux/store/dealer/types";

const initialState: DealerState = {
    dealers: []
};

export default function dealer(
    state = initialState,
    action?: DealerActionTypes
): DealerState {
    switch (action?.type) {
        case GET_DEALERS: {
            return {
                ...state,
                dealers: action.payload.dealers
            };
        }

        case ADD_DEALER: {
            return {
                ...state,
                dealers: [...state.dealers ?? [], action.payload.dealer]
            };
        }

        case UPDATE_DEALER: {
            let dealers = [...state.dealers ?? []];
            let updatedIndex = dealers.findIndex(d => d.id === action.payload.dealer.id);
            dealers[updatedIndex] = action.payload.dealer;

            return {
                ...state,
                dealers: dealers
            };
        }

        case ADD_DEALER_SALESMAN: {
            let dealers = [...state.dealers ?? []];
            let dealerIndex = dealers.findIndex(d => d.id === action.payload.dealerId);
            let dealer = {...dealers[dealerIndex]};
            dealer.dealerSalesmen = [...dealer.dealerSalesmen, action.payload.dealerSalesman];
            dealers[dealerIndex] = {...dealer};
            return {
                ...state,
                dealers: dealers
            };
        }

        case UPDATE_DEALER_SALESMAN: {
            let dealers = [...state.dealers ?? []];
            let dealerIndex = dealers.findIndex(d => d.id === action.payload.dealerId);
            let dealer = {...dealers[dealerIndex]};
            let updatedIndex = dealer.dealerSalesmen.findIndex(ds => ds.id === action.payload.dealerSalesman.id);
            dealer.dealerSalesmen[updatedIndex] = {...action.payload.dealerSalesman};
            dealers[dealerIndex] = {...dealer};
            return {
                ...state,
                dealers: dealers
            };
        }

        default:
            return state;
    }
}
