import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../../../utilities/AppInsights";
import { AppState } from '../../../redux/store/store';
import { toggleLoading } from '../../../redux/actions/system/actions';
import { getEmployeeBank, employeeUpdateBank } from '../../../redux/actions/bank/actions';
import { connect, ConnectedProps } from 'react-redux';
import Container from "react-bootstrap/esm/Container";
import "react-datepicker/dist/react-datepicker.css";
import "./EditBankPage.css"
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Bank from "../../../models/bank/bank";
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import Form from "react-bootstrap/esm/Form";
import PrimaryButton from "shared/components/PrimaryButton";
import InputGroup from "react-bootstrap/esm/InputGroup";

const mapState = (state: AppState, route: any) => {
  const { system, bank } = state;
  const { isLoading } = system;
  const { employeeBank } = bank;
  return {
    isLoading,
    employeeBank,
    route
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  getEmployeeBank: () => getEmployeeBank(),
  updateBank: (bank: Bank) => employeeUpdateBank(bank)
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

interface AddBankPagePageState {
  employeeBank: Bank;
}

class AddBankPage extends React.Component<Props, AddBankPagePageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      employeeBank: {} as Bank
    }
  }

  componentDidMount() {
    this.loadActiveBanks();
  }

  componentDidUpdate(previousProps: Props) {
    if (previousProps.employeeBank !== this.props.employeeBank) {
      this.setState({ employeeBank: this.props.employeeBank });
    }
  }

  loadActiveBanks(): void {
    this.props.toggleLoading(true);
    this.props.getEmployeeBank();
  }

  // State Setters
  setBankNameState(event: any): void {
    let bank: Bank = { ...this.state.employeeBank };
    bank.name = event.target.value;
    this.setState({ employeeBank: bank });
  }

  setBankAddressState(event: any): void {
    let bank: Bank = { ...this.state.employeeBank };
    bank.streetAddress = event.target.value;
    this.setState({ employeeBank: bank });
  }

  setBankAddress2State(event: any): void {
    let bank: Bank = { ...this.state.employeeBank };
    bank.streetAddress2 = event.target.value.length > 0 ? event.target.value : undefined;
    this.setState({ employeeBank: bank });
  }

  setBankCityState(event: any): void {
    let bank: Bank = { ...this.state.employeeBank };
    bank.city = event.target.value;
    this.setState({ employeeBank: bank });
  }

  setBankStateValueState(event: any): void {
    let bank: Bank = { ...this.state.employeeBank };
    bank.state = event.target.value;
    this.setState({ employeeBank: bank });
  }

  setBankZipCodeState(event: any): void {
    let bank: Bank = { ...this.state.employeeBank };
    bank.zipCode = event.target.value;
    this.setState({ employeeBank: bank });
  }

  setBankBaseRateState(event: any): void {
    let bank: Bank = { ...this.state.employeeBank };
    bank.baseRate = event.target.value.length > 0 ? +event.target.value : undefined;
    this.setState({ employeeBank: bank });
  }

  updateBank() {
    if (this.state.employeeBank !== undefined && this.validateData()) {
      this.props.toggleLoading(true);
      this.props.updateBank(this.state.employeeBank);
    }
  }

  validateData(): boolean {
    let validBankName, validBankAddress, validBankAddress2, validBankCity, validBankState, validBankZip, validBaseRate: boolean;

    if (this.state.employeeBank !== undefined) {
      validBankName = (this.state.employeeBank.name !== undefined && this.state.employeeBank.name.length > 0);

      validBankAddress = (this.state.employeeBank.streetAddress !== undefined && this.state.employeeBank.streetAddress.length > 0);

      validBankAddress2 = this.state.employeeBank.streetAddress2 === undefined || (this.state.employeeBank.streetAddress2 !== undefined && this.state.employeeBank.streetAddress2.length > 0);

      validBankCity = (this.state.employeeBank.city !== undefined && this.state.employeeBank.city.length > 0);

      validBankState = (this.state.employeeBank.state !== undefined && this.state.employeeBank.state.length > 0);

      validBankZip = (this.state.employeeBank.zipCode !== undefined && this.state.employeeBank.zipCode.length > 0);

      validBaseRate = this.state.employeeBank.baseRate !== undefined && !isNaN(+this.state.employeeBank.baseRate);

      return validBankName
        && validBankAddress
        && validBankAddress2
        && validBankCity
        && validBankState
        && validBankZip
        && validBaseRate;
    } else {
      return false;
    }
  }

  render() {
    return (
      <>
        <Container>
          <div>
            <Row className="edit-bank-title-row align-items-center">
              <Col>
                <h1>Edit Bank Information</h1>
              </Col>
              <Col className="d-flex justify-content-end">
                <PrimaryButton buttonText={'Update'} disabled={!this.validateData()} onClick={this.updateBank.bind(this, 'create')} />
              </Col>
            </Row>
            <div>
              <Form>
                <Form.Group>
                  <Form.Label>Bank Name*</Form.Label>
                  <Form.Control value={this.state.employeeBank.name} onChange={this.setBankNameState.bind(this)}></Form.Control>

                  <Form.Label>Address Line 1*</Form.Label>
                  <Form.Control value={this.state.employeeBank.streetAddress} onChange={this.setBankAddressState.bind(this)}></Form.Control>

                  <Form.Label>Address Line 2</Form.Label>
                  <Form.Control value={this.state.employeeBank.streetAddress2} onChange={this.setBankAddress2State.bind(this)}></Form.Control>

                  <Form.Label>City*</Form.Label>
                  <Form.Control value={this.state.employeeBank.city} onChange={this.setBankCityState.bind(this)}></Form.Control>

                  <Form.Label>State*</Form.Label>
                  <Form.Control value={this.state.employeeBank.state} onChange={this.setBankStateValueState.bind(this)}></Form.Control>

                  <Form.Label>Zip*</Form.Label>
                  <Form.Control value={this.state.employeeBank.zipCode} onChange={this.setBankZipCodeState.bind(this)}></Form.Control>

                  <Form.Label>Interest Rate*</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Interest Rate"
                      aria-label="Interest Rate"
                      type="number"
                      value={this.state.employeeBank.baseRate}
                      onChange={this.setBankBaseRateState.bind(this)}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Container>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'dark'}
            animation={'border'}
          />
        }
      </>
    );
  }
}

export default withAITracking(reactPlugin, connector(AddBankPage));
