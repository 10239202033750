import { LOGGED_IN_ACCOUNT, SET_SUBDOMAIN, SystemActionTypes, SystemState, TOGGLE_API_REQUEST_SUCCESS, TOGGLE_LOADING, TOGGLE_MODAL_LOADING } from "../../store/system/types";
import { Subdomain } from "shared/Enums";
import LoggedInAccount from "models/account/loggedInAccount";

const initialState: SystemState = {
    isLoading: false,
    isModalLoading: false,
    apiRequestSuccess: false,
    loggedInAccount: {} as LoggedInAccount,
    subdomain: 0 as Subdomain
};

export default function system(state = initialState, action?: SystemActionTypes): SystemState {
    switch (action?.type) {
        case TOGGLE_LOADING: {
            return {
                ...state,
                isLoading: action.payload.isLoading
            };
        }
        case TOGGLE_MODAL_LOADING: {
            return {
                ...state,
                isModalLoading: action.payload.isModalLoading
            };
        }
        case TOGGLE_API_REQUEST_SUCCESS: {
            return {
                ...state,
                apiRequestSuccess: action.payload.apiRequestSuccess
            };
        }

        case LOGGED_IN_ACCOUNT: {
            return {
                ...state,
                loggedInAccount: {...action.payload.account}
            };
        }

        case SET_SUBDOMAIN: {
            return {
                ...state,
                subdomain: action.payload.subdomain
            };
        }

        default:
            return state;
    }
}