import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartD from "../../../../models/fsa2211Form/FSA2211PartD"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartDCallback = (formDataPartD: FSA2211PartD) => void;

interface FSA2211FormPartDProps {
  partDData?: FSA2211PartD;
  formDataChange: formDataPartDCallback;
}

export default class FSA2211FormPartD extends React.Component<FSA2211FormPartDProps, FSA2211PartD> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partDData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartDProps) {
    if (previousProps.partDData !== this.props.partDData) {
      this.setState(this.props.partDData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartD {
    return {
      businessUnderAnyOtherNameYes: false,
      businessUnderAnyOtherNameNo: false,
      businessUnderAnyOtherNameWhatNames: "",
      ethnicityHispanicOrLatino: false,
      ethnicityNonHispanicOrLatino: false,
      raceAmericanIndianOrAlaskanNative: false,
      raceBlackOrAfricanAmerican: false,
      raceNativeHawaiianOrOtherPacificIslander: false,
      raceAsian: false,
      raceWhite: false,
      genderMale: false,
      genderFemale: false,
      veteranStatusVeteran: false,
      veteranStatusNonVeteran: false,
    } as FSA2211PartD
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART D &nbsp;&nbsp; OTHER INFORMATION <em>(LOAN APPLICANT)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-left">1. Have you or any entity members ever conducted business under any other name(s)?</Col>
                </Row>
                <Row className="standard-text">
                  <Col md={1} className="align-left"><Form.Check name="businessUnderAnyOtherNameYes" type="checkbox" label="Yes" onChange={this.handleInputChange.bind(this)} checked={this.state.businessUnderAnyOtherNameYes} /></Col>
                  <Col md={3} className="align-left"><Form.Check name="businessUnderAnyOtherNameNo" type="checkbox" label='No  If "YES", what name(s)?' onChange={this.handleInputChange.bind(this)} checked={this.state.businessUnderAnyOtherNameNo} /></Col>
                  <Col md={5} className="align-left"><Form.Control name="businessUnderAnyOtherNameWhatNames" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.businessUnderAnyOtherNameWhatNames} /></Col>
                </Row>
                <Row className="standard-text mt-2 subsection-border-top">
                  <Col className="align-center"><em className="standard-bold">Voluntary Information for Monitoring Purposes</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-left">
                    Ethnicity, race, and gender information is requested in order to monitor FSA's compliance with Federal laws prohibiting discrimination against loan applicants and to
                    determine if you qualify for targeted funds. You are not required to furnish this information but are encouraged to do so. Failure to complete this information may result in
                    you not receiving access to targeted funds for which you may be eligible. Entity applicants should base their answers on the ethnicity, race, and gender of the owners of a
                    majority interest in the entity.
                  </Col>
                </Row>
                <Row className="standard-text mt-3 subsection-border-top">
                  <Col md={2} className="align-left mt-2">
                    <Row><Col>2A. Ethnicity</Col></Row>
                    <Row className="mt-2"><Col><Form.Check name="ethnicityHispanicOrLatino" type="checkbox" label="Hispanic or Latino" onChange={this.handleInputChange.bind(this)} checked={this.state.ethnicityHispanicOrLatino} /></Col></Row>
                    <Row><Col><Form.Check name="ethnicityNonHispanicOrLatino" type="checkbox" label="Not Hispanic or Latino" onChange={this.handleInputChange.bind(this)} checked={this.state.ethnicityNonHispanicOrLatino} /></Col></Row>
                  </Col>
                  <Col md={5} className="align-left mt-2">
                    <Row><Col>2B. Race</Col></Row>
                    <Row className="mt-2">
                      <Col md={8}><Form.Check name="raceAmericanIndianOrAlaskanNative" type="checkbox" label="American Indian or Alaskan Native" onChange={this.handleInputChange.bind(this)} checked={this.state.raceAmericanIndianOrAlaskanNative} /></Col>
                      <Col><Form.Check name="raceAsian" type="checkbox" label="Asian" onChange={this.handleInputChange.bind(this)} checked={this.state.raceAsian} /></Col>
                    </Row>
                    <Row>
                      <Col md={8}><Form.Check name="raceBlackOrAfricanAmerican" type="checkbox" label="Black or African American" onChange={this.handleInputChange.bind(this)} checked={this.state.raceBlackOrAfricanAmerican} /></Col>
                      <Col><Form.Check name="raceWhite" type="checkbox" label="White" onChange={this.handleInputChange.bind(this)} checked={this.state.raceWhite} /></Col>
                    </Row>
                    <Row><Col md={8}><Form.Check name="raceNativeHawaiianOrOtherPacificIslander" type="checkbox" label="Native Hawaiian or Other Pacific Islander" onChange={this.handleInputChange.bind(this)} checked={this.state.raceNativeHawaiianOrOtherPacificIslander} /></Col><Col></Col></Row>
                  </Col>
                  <Col md={2} className="align-left mt-2">
                    <Row><Col>2C. Gender</Col></Row>
                    <Row className="mt-2"><Col><Form.Check name="genderMale" type="checkbox" label="Male" onChange={this.handleInputChange.bind(this)} checked={this.state.genderMale} /></Col></Row>
                    <Row><Col><Form.Check name="genderFemale" type="checkbox" label="Female" onChange={this.handleInputChange.bind(this)} checked={this.state.genderFemale} /></Col></Row>
                  </Col>
                  <Col className="align-left mt-2">
                    <Row><Col>2D. Veteran Status</Col></Row>
                    <Row className="mt-2"><Col><Form.Check name="veteranStatusVeteran" type="checkbox" label="Veteran" onChange={this.handleInputChange.bind(this)} checked={this.state.veteranStatusVeteran} /></Col></Row>
                    <Row><Col><Form.Check name="veteranStatusNonVeteran" type="checkbox" label="Non-Veteran" onChange={this.handleInputChange.bind(this)} checked={this.state.veteranStatusNonVeteran} /></Col></Row>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}