import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartH from "../../../../models/fsa2211Form/FSA2211PartH"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartHCallback = (formDataPartH: FSA2211PartH) => void;

interface FSA2211FormPartHProps {
  partHData?: FSA2211PartH;
  formDataChange: formDataPartHCallback;
}

export default class FSA2211FormPartH extends React.Component<FSA2211FormPartHProps, FSA2211PartH> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partHData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartHProps) {
    if (previousProps.partHData !== this.props.partHData) {
      this.setState(this.props.partHData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartH {
    return {
      purpose1: "",
      purpose2: "",
      purpose3: "",
      purpose4: "",
      amount1: "",
      amount2: "",
      amount3: "",
      amount4: "",
    } as FSA2211PartH
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART H &nbsp;&nbsp; FUNDS PURPOSE <em>(ALL LENDER TYPES)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>1. Purposes for which funds will be used</Col>
                  <Col>2. Amount</Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={10}><Form.Control name="purpose1" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.purpose1} /></Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amount1" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amount1} /></Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={10}><Form.Control name="purpose2" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.purpose2} /></Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amount2" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amount2} /></Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={10}><Form.Control name="purpose3" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.purpose3} /></Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amount3" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amount3} /></Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={10}><Form.Control name="purpose4" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.purpose4} /></Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amount4" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amount4} /></Col></Row>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}