import BankEmployee from '../../../models/bank/bankEmployee';
import bankService from '../../../accessors/bankService';
import { AppThunk } from '../../../redux/store/store';
import { TOGGLE_API_REQUEST_SUCCESS, TOGGLE_LOADING } from '../../store/system/types';
import { ACTIVE_BANKS, ADD_BANK, EMPLOYEE_BANK, LOAN_OFFICERS, ADMIN_UPDATE_BANK, EMPLOYEE_UPDATE_BANK, BANK_SECURITY_ROLES, CREATE_BANK_EMPLOYEE } from '../../store/bank/types';
import Bank from '../../../models/bank/bank';
import bankEmployeeService from 'accessors/bankEmployeeService';
import SecurityRole from 'models/account/securityRole';

export const getLoanOfficers = (): AppThunk => async (dispatch) => {
    bankService.GetLoanOfficers()
        .then((officers: BankEmployee[]) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            dispatch({
                type: LOAN_OFFICERS,
                payload: {
                    loanOfficers: officers
                },
            });
        })
};

export const getActiveBanks = (): AppThunk => async (dispatch) => {
    bankService.GetActiveBanks()
        .then((banks: Bank[]) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            dispatch({
                type: ACTIVE_BANKS,
                payload: {
                    activeBanks: banks
                },
            });
        })
};

export const addNewBank = (
    bank: Bank
): AppThunk => async (dispatch) => {
    bankService.AddNewBank(bank)
        .then((newBank: Bank) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (Object.keys(newBank).length > 0) {
                dispatch({
                    type: ADD_BANK,
                    payload: {
                        bank: newBank
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const adminUpdateBank = (
    bank: Bank
): AppThunk => async (dispatch) => {
    bankService.UpdateBank(bank)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            if (success) {
                dispatch({
                    type: ADMIN_UPDATE_BANK,
                    payload: {
                        bank: bank
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const getEmployeeBank = (): AppThunk => async (dispatch) => {
    bankService.GetCurrentBank()
        .then((bank: Bank) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            dispatch({
                type: EMPLOYEE_BANK,
                payload: {
                    bank: bank
                },
            });
        })
};

export const employeeUpdateBank = (
    bank: Bank
): AppThunk => async (dispatch) => {
    bankService.UpdateBank(bank)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            if (success) {
                dispatch({
                    type: EMPLOYEE_UPDATE_BANK,
                    payload: {
                        bank: bank
                    },
                });
            }
        })
};

export const getBankSecurityRoles = (): AppThunk => async (dispatch) => {
    bankEmployeeService.GetBankSecurityRoles()
        .then((bankSecurityRoles: SecurityRole[]) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (bankSecurityRoles.length > 0) {
                dispatch({
                    type: BANK_SECURITY_ROLES,
                    payload: {
                        bankSecurityRoles: bankSecurityRoles
                    },
                });
            }
        })
};

export const updateBankEmployee = (
    bankEmployee: BankEmployee
): AppThunk => async (dispatch) => {
    bankEmployeeService.UpdateBankEmployee(bankEmployee)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (success) {
                dispatch({
                    type: CREATE_BANK_EMPLOYEE,
                    payload: {
                        updatedBankEmployee: bankEmployee
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const createBankEmployee = (
    bankEmployee: BankEmployee
): AppThunk => async (dispatch) => {
    bankEmployeeService.CreateBankEmployee(bankEmployee)
        .then((newBankEmployee: BankEmployee) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (Object.keys(newBankEmployee).length > 0) {
                dispatch({
                    type: CREATE_BANK_EMPLOYEE,
                    payload: {
                        newBankEmployee: newBankEmployee
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};