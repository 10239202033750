import SecurityRole from "models/account/securityRole";
import Bank from "../../../models/bank/bank";
import BankEmployee from "../../../models/bank/bankEmployee";

export const LOAN_OFFICERS = 'GET_LOAN_OFFICERS';
export const ACTIVE_BANKS = 'GET_ACTIVE_BANKS';
export const ADD_BANK = 'ADD_NEW_BANK';
export const ADMIN_UPDATE_BANK = 'ADMIN_UPDATE_BANK';
export const EMPLOYEE_BANK = 'GET_EMPLOYEE_BANK';
export const EMPLOYEE_UPDATE_BANK = 'EMPLOYEE_UPDATE_BANK';
export const BANK_SECURITY_ROLES = 'BANK_SECURITY_ROLES';
export const UPDATE_BANK_EMPLOYEE = 'UPDATE_BANK_EMPLOYEE';
export const CREATE_BANK_EMPLOYEE = 'CREATE_BANK_EMPLOYEE';

export interface BankState {
  loanOfficers: BankEmployee[];
  activeBanks: Bank[];
  employeeBank: Bank;
  bankSecurityRoles: SecurityRole[];
}

interface GetLoanOfficersActions {
  type: typeof LOAN_OFFICERS;
  payload: {
    loanOfficers: BankEmployee[];
  }
}

interface GetActiveBanksAction {
  type: typeof ACTIVE_BANKS;
  payload: {
    activeBanks: Bank[];
  }
}

interface AddNewBankAction {
  type: typeof ADD_BANK;
  payload: {
    bank: Bank;
  }
}

interface AdminUpdateBankAction {
  type: typeof ADMIN_UPDATE_BANK;
  payload: {
    bank: Bank;
  }
}

interface EmployeeBankAction {
  type: typeof EMPLOYEE_BANK;
  payload: {
    bank: Bank;
  }
}

interface EmployeeUpdateBankAction {
  type: typeof EMPLOYEE_UPDATE_BANK;
  payload: {
    bank: Bank;
  }
}

interface GetBankSecurityRoles {
    type: typeof BANK_SECURITY_ROLES;
    payload: {
        bankSecurityRoles: SecurityRole[];
    }
}

interface UpdateBankEmployee {
    type: typeof UPDATE_BANK_EMPLOYEE;
    payload: {
        updatedBankEmployee: BankEmployee;
    }
}

interface CreateBankEmployee {
  type: typeof CREATE_BANK_EMPLOYEE;
  payload: {
      newBankEmployee: BankEmployee;
  }
}

export type BankActionTypes = GetLoanOfficersActions
  | GetActiveBanksAction
  | AddNewBankAction
  | AdminUpdateBankAction
  | EmployeeBankAction
  | EmployeeUpdateBankAction
  | GetBankSecurityRoles
  | UpdateBankEmployee
  | CreateBankEmployee;