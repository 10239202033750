import React from "react";
import "./App.css";
import { User, withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "auth/ProtectedRoute";
import OffersPage from "pages/loan/offers/OffersPage";
import UsersPage from "pages/users/usersList/UsersPage";
import NotFoundPage from "pages/core/notFound/NotFoundPage";
import auth from "utilities/AuthUtility";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActiveLoansListPage from "pages/loan/loansList/active/ActiveLoansListPage";
import ClosedLoansListPage from "pages/loan/loansList/closed/ClosedLoansListPage";
import LoanDetailsPage from "pages/loan/loanDetails/LoanDetailsPage";
import ErrorPage from "pages/core/error/ErrorPage";
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import AddBankPage from "pages/bank/add/AddBankPage";
import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import EditBankPage from "pages/bank/edit/EditBankPage";
import Header from "shared/components/header/Header";
import AddDealerPage from "pages/dealer/add/AddDealerPage";
import DealerLandingPage from "pages/dealerAccount/landingPage/DealerLandingPage";
import CreateAccountPage from "pages/dealerAccount/createAccount/CreateAccountPage";
import { Subdomain } from "shared/Enums";
import globalUtility from "utilities/GlobalUtility";
import CreateLoanPage from "pages/loan/createLoan/CreateLoanPage";

interface RouteState {
  subdomain: Subdomain;
}

class App extends React.Component<WithAuth0Props, RouteState> {
  constructor(props: WithAuth0Props) {
    super(props);

    // This isn't ideal, but due to react-auth0 SDK the "recommended" way
    // to expose auth0 methods externally from components is via below.
    // https://github.com/auth0/auth0-react/issues/67#issuecomment-661874041
    auth.setAccessTokenSilently(this.props.auth0.getIdTokenClaims);
    auth.setLogout(this.props.auth0.logout);

    library.add(fas as IconPack);

    let subdomain = globalUtility.GetSubdomain();
    this.state = {
      subdomain: subdomain
    }
  }

  componentDidMount(): void {
    this.checkForRedirect(this.props.auth0.user);
  }

  componentDidUpdate(prevProps: Readonly<WithAuth0Props>, prevState: Readonly<RouteState>, snapshot?: any): void {
    this.checkForRedirect(this.props.auth0.user);
  }

  checkForRedirect(user: User | undefined): void {
    if (user != undefined) {
      if (this.state.subdomain === Subdomain.Admin && user["https://agcreditbank.com/role"] === 'Dealer Salesman') {
        let hrefToReplace = window.location.href;
        let newHref = hrefToReplace.replace('admin', 'dealer')
        window.location.replace(newHref);
      }

      if (this.state.subdomain === Subdomain.Dealer && user["https://agcreditbank.com/role"] !== 'Dealer Salesman') {
        let hrefToReplace = window.location.href;
        let newHref = hrefToReplace.replace('dealer', 'admin')
        window.location.replace(newHref);
      }
    }
  }

  render() {
    const { error, isAuthenticated, isLoading } = this.props.auth0;
    
    return (
      <div className="Container">
        {isLoading ?
          <LoadingSpinner
            variant={'dark'}
            animation={'border'}
          />
          :
          <>
            <Header />
            {error && ErrorPage}

            {(isAuthenticated || this.state.subdomain === Subdomain.Admin) &&

              <div className="Content parent-switch-selector">
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                  <ProtectedRoute path="/offers" component={OffersPage} />
                  <ProtectedRoute path="/users" component={UsersPage} />
                  <ProtectedRoute path="/active" component={ActiveLoansListPage} />
                  <ProtectedRoute path="/closed" component={ClosedLoansListPage} />
                  <ProtectedRoute path="/loan/create" component={CreateLoanPage} />
                  <ProtectedRoute path="/loan/:id" component={LoanDetailsPage} />
                  <ProtectedRoute path="/bank/add" component={AddBankPage} />
                  <ProtectedRoute path="/bank/edit" component={EditBankPage} />
                  <ProtectedRoute path="/dealer/add" component={AddDealerPage} />

                  {/* <Route path="/callback">
                  <LoadingSpinner
                    variant={'dark'}
                    animation={'border'}
                  />
                </Route> */}

                  <Route exact path={["/", "/callback"]}>
                    <Redirect to="/active" />
                  </Route>

                  <Route component={NotFoundPage} />
                </Switch>
                <ToastContainer />
              </div>
            }

            {this.state.subdomain === Subdomain.Dealer && !isAuthenticated &&
              <>
                <Switch>
                  <Route path="/create-account" component={CreateAccountPage} />

                  <Route component={DealerLandingPage} />
                </Switch>
              </>
            }

          </>
        }


      </div>

    );
  }
}

export default withAuth0(App);
