import React from "react";
import "./BalanceSheetNAICSSection.css"
import loanApplicationUtility from "utilities/LoanApplicationUtility";
import BalanceSheetNAICS from "models/financialDocuments/balanceSheet/assets/naics";
import AssetCategory from "models/financialDocuments/balanceSheet/assets/assetCategory";
import { NAICSGroup } from "shared/Enums";

interface NAICSSectionProps {
  assetCategory: AssetCategory
  assetData: BalanceSheetNAICS
}

interface NAICSSectionState {

}

export default class BalanceSheetNAICSSection extends React.Component<NAICSSectionProps, NAICSSectionState> {
  render() {
    return (
      <>
        <div>
          <div><span style={{fontStyle: 'italic'}}>Measurement:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{loanApplicationUtility.GetAgriculturalProductMeasurers(this.props.assetData.measurement ?? 0)}</span>
          </div>
        </div>

        {this.props.assetCategory.naicsType != undefined && this.props.assetCategory.naicsType === NAICSGroup.BreedingStock &&
          <div>
            <div><span style={{fontStyle: 'italic'}}>Livestock Units Purchased:</span></div>
            <div>
              <span style={{paddingLeft: '10px'}}>{this.props.assetData.purchasedLivestockOrPoultry ? 'Yes' : 'No'}</span>
            </div>
            <div><span style={{fontStyle: 'italic'}}>Livestock Units Raised:</span></div>
            <div>
              <span style={{paddingLeft: '10px'}}>{this.props.assetData.raisedLivestockOrPoultry ? 'Yes' : 'No'}</span>
            </div>
          </div>
        }

        {this.props.assetCategory.naicsType != undefined && this.props.assetCategory.naicsType === NAICSGroup.MarketLivestockOrPoultry &&
          <div>
            <div><span style={{fontStyle: 'italic'}}>Average Weight:</span></div>
            <div>
              <span style={{paddingLeft: '10px'}}>
                {this.props.assetData.averageWeight}
              </span>
            </div>
          </div>
        }
        <div>
          <div><span style={{fontStyle: 'italic'}}>Number of Units:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{loanApplicationUtility.BuildAmount(this.props.assetData.numberOfUnits)}</span>
          </div>
        </div>
        <div>
          <div><span style={{fontStyle: 'italic'}}>Price per Unit:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{loanApplicationUtility.BuildCurrencyAmount(this.props.assetData.pricePerUnit)}</span>
          </div>
        </div>
      </>
    );
  }
}