import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartI from "../../../../models/fsa2211Form/FSA2211PartI"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartICallback = (formDataPartI: FSA2211PartI) => void;

interface FSA2211FormPartIProps {
  partIData?: FSA2211PartI;
  formDataChange: formDataPartICallback;
}

export default class FSA2211FormPartI extends React.Component<FSA2211FormPartIProps, FSA2211PartI> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partIData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartIProps) {
    if (previousProps.partIData !== this.props.partIData) {
      this.setState(this.props.partIData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartI {
    return {
      itemDescription1: "",
      itemDescription2: "",
      itemDescription3: "",
      itemDescription4: "",
      lienPosition1: "",
      lienPosition2: "",
      lienPosition3: "",
      lienPosition4: "",
      estimatedValue1: "",
      estimatedValue2: "",
      estimatedValue3: "",
      estimatedValue4: "",
      amountOfPriorLien1: "",
      amountOfPriorLien2: "",
      amountOfPriorLien3: "",
      amountOfPriorLien4: "",
      collateralValue1: "",
      collateralValue2: "",
      collateralValue3: "",
      collateralValue4: "",
      estimatedValueTotal: "",
      amountOfPriorLienTotal: "",
      collateralValueTotal: "",
    } as FSA2211PartI
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART I &nbsp;&nbsp; PROPOSED SECURITY <em>(ALL LENDER TYPES)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={4}>1. Item Description</Col>
                  <Col>2. Lien Position</Col>
                  <Col>3. Estimated Value</Col>
                  <Col>4. Amount of Prior Lien</Col>
                  <Col>5. Collateral Value</Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={4}><Form.Control name="itemDescription1" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.itemDescription1} /></Col>
                  <Col>
                    <Form.Control name="lienPosition1" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.lienPosition1} />
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="estimatedValue1" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.estimatedValue1} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amountOfPriorLien1" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amountOfPriorLien1} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="collateralValue1" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.collateralValue1} /></Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={4}><Form.Control name="itemDescription2" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.itemDescription2} /></Col>
                  <Col>
                    <Form.Control name="lienPosition2" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.lienPosition2} />
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="estimatedValue2" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.estimatedValue2} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amountOfPriorLien2" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amountOfPriorLien2} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="collateralValue2" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.collateralValue2} /></Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={4}><Form.Control name="itemDescription3" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.itemDescription3} /></Col>
                  <Col>
                    <Form.Control name="lienPosition3" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.lienPosition3} />
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="estimatedValue3" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.estimatedValue3} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amountOfPriorLien3" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amountOfPriorLien3} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="collateralValue3" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.collateralValue3} /></Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={4}><Form.Control name="itemDescription4" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.itemDescription4} /></Col>
                  <Col>
                    <Form.Control name="lienPosition4" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.lienPosition4} />
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="estimatedValue4" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.estimatedValue4} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amountOfPriorLien4" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amountOfPriorLien4} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="collateralValue4" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.collateralValue4} /></Col></Row>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={4}></Col>
                  <Col className="standard-bold align-right align-self-center">
                    6. TOTALS:
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="estimatedValueTotal" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.estimatedValueTotal} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="amountOfPriorLienTotal" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.amountOfPriorLienTotal} /></Col></Row>
                  </Col>
                  <Col>
                    <Row noGutters><div className="align-self-center">$</div><Col><Form.Control name="collateralValueTotal" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.collateralValueTotal} /></Col></Row>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}