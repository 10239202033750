import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./FSA2211Form.css"

interface FSA2211LegalStatementSectionProps {
}

interface FSA2211LegalStatementSectionState {
}

export default class FSA2211LegalStatementSection extends React.Component<FSA2211LegalStatementSectionProps, FSA2211LegalStatementSectionState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border mt-3">
            <Form>
              <Form.Group>
                <Row className="standard-text mt-2">
                  <Col md={1} className="standard-bold">NOTE:</Col>
                  <Col><em>The following statement is made in accordance with the Privacy Act of 1974 (5 USC 552a – as amended). The authority for requesting the 
                    information identified on this form is 7 CFR Part 762, the Consolidated Farm and Rural Development Act (7 U.S.C. 1921 et seq.), and the 
                    Agricultural Act of 2014 (Pub. L. 113-79). The information will be used to determine applicant/borrower ability to participate in and receive benefits 
                    under an FSA Loan Program through Lender certification that all applicable FSA Loan Program requirements have been or will be met. The 
                    information collected on this form may be disclosed to other Federal, State, Local government agencies, Tribal agencies, and nongovernmental 
                    entities that have been authorized access to the information by statute or regulation and/or as described in applicable Routine Uses identified in 
                    the System of Records Notice for USDA/FSA-14, Applicant/Borrower. Providing the requested information is voluntary. However, failure to furnish 
                    the requested information will result in a determination that the applicant/borrower is unable to participate in and receive benefits under an FSA 
                    Loan Program.</em>
                  </Col>
                </Row>
                <Row className="standard-text mt-3">
                  <Col md={1} className="standard-bold"></Col>
                  <Col><em><span className="standard-bold">Public Burden Statement (Paperwork Reduction Act):</span> According to the Paperwork Reduction Act of 1995, an agency may not conduct or 
                    sponsor, and a person is not required to respond to, a collection of information unless it displays a valid OMB control number. The valid OMB 
                    control number for this information collection is 0560-0155. The time required to complete this information collection is estimated to average 2.5
                    hours per response, including the time for reviewing instructions, searching existing data sources, gathering and maintaining the data needed, and 
                    completing and reviewing the collection of information. <span className="standard-bold">RETURN THIS COMPLETED FORM TO YOUR COUNTY FSA OFFICE</span></em>
                  </Col>
                </Row>               
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className="standard-text mt-2">
            <Col><em>In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, the USDA, its Agencies, offices, 
              and employees, and institutions participating in or administering USDA programs are prohibited from discriminating based on race, color, national origin, 
              religion, sex, gender identity (including gender expression), sexual orientation, disability, age, marital status, family/parental status, income derived from a 
              public assistance program, political beliefs, or reprisal or retaliation for prior civil rights activity, in any program or activity conducted or funded by USDA (not 
              all bases apply to all programs). Remedies and complaint filing deadlines vary by program or incident. </em>
          </Col>
        </Row>
        <Row className="standard-text mt-2">
          <Col>
            <em>
              Persons with disabilities who require alternative means of communication for program information (e.g., Braille, large print, audiotape, American Sign 
              Language, etc.) should contact the responsible Agency or USDA’s TARGET Center at (202) 720-2600 (voice and TTY) or contact USDA through the Federal 
              Relay Service at (800) 877-8339. Additionally, program information may be made available in languages other than English. 
            </em>
          </Col>
        </Row>
        <Row className="standard-text mt-2">
            <Col>
              <em>
                To file a program discrimination complaint, complete the USDA Program Discrimination Complaint Form, AD-3027, found online at 
                <a href="http://www.ascr.usda.gov/complaint_filing_cust.html"> http://www.ascr.usda.gov/complaint_filing_cust.html</a> and at any USDA office or write a letter addressed to USDA and provide in the letter all of the 
                information requested in the form. To request a copy of the complaint form, call (866) 632-9992. Submit your completed form or letter to USDA by: (1) mail: 
                U.S. Department of Agriculture Office of the Assistant Secretary for Civil Rights 1400 Independence Avenue, SW Washington, D.C. 20250-9410; (2) fax: 
                (202) 690-7442; or (3) email: <a href = "mailto:program.intake@usda.gov">program.intake@usda.gov</a>. USDA is an equal opportunity provider, employer, and lender.
              </em>
          </Col>
        </Row>
      </>
    );
  }
}