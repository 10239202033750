import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartE from "../../../../models/fsa2211Form/FSA2211PartE"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartECallback = (formDataPartE: FSA2211PartE) => void;

interface FSA2211FormPartEProps {
  partEData?: FSA2211PartE;
  formDataChange: formDataPartECallback;
}

export default class FSA2211FormPartE extends React.Component<FSA2211FormPartEProps, FSA2211PartE> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partEData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartEProps) {
    if (previousProps.partEData !== this.props.partEData) {
      this.setState(this.props.partEData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartE {
    return {
      descriptionOfOperation: "",
      operatorOfAFamilyFarmYes: false,
      operatorOfAFamilyFarmNo: false,
      yearsOperatingAFarm: 0,
      acresOwned: 0,
      acresRented: 0,
      notCausedFarmServiceAgencyALossYes: false,
      notCausedFarmServiceAgencyALossNo: false,
      notDelinquentOnDebtToUSGovernmentYes: false,
      notDelinquentOnDebtToUSGovernmentNo: false,
      notHaveOutstandingRecordedJudgmentYes: false,
      notHaveOutstandingRecordedJudgmentNo: false,
      citizenOfUSYes: false,
      citizenOfUSNo: false,
      capacityToIncurObligationsOfLoanYes: false,
      capacityToIncurObligationsOfLoanNo: false,
      convictedOfControlledSubstanceYes: false,
      convictedOfControlledSubstanceNo: false,
      notEmployeeOfLenderOrAgencyYes: false,
      notEmployeeOfLenderOrAgencyNo: false,
      unableToObtainCreditWithoutGuaranteeYes: false,
      unableToObtainCreditWithoutGuaranteeNo: false,
      notProvidedFalseDocumentsOrStatementsInPastYes: false,
      notProvidedFalseDocumentsOrStatementsInPastNo: false,
    } as FSA2211PartE
  }


  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART E &nbsp;&nbsp; APPLICANT ELIGIBILITY INFORMATION <em>(LOAN APPLICANT)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-left">1. Description of Operation, Including Commodity(s) Produced or To Be Produced</Col>
                </Row>
                <Row className="standard-text">
                  <Col className="align-left"><Form.Control name="descriptionOfOperation" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.descriptionOfOperation} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={4} className="align-left">2. I am or will be the operator of a family farm</Col>
                  <Col md={3} className="align-left">3. Number of Years Operating a Farm</Col>
                  <Col md={3} className="align-left">4. Acres Owned</Col>
                  <Col md={2} className="align-left">5. Acres Rented</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={4} className="align-left">
                    <Row>
                      <Col><Form.Check name="operatorOfAFamilyFarmYes" type="checkbox" label="YES" onChange={this.handleInputChange.bind(this)} checked={this.state.operatorOfAFamilyFarmYes} /></Col>
                      <Col><Form.Check name="operatorOfAFamilyFarmNo" type="checkbox" label="NO" onChange={this.handleInputChange.bind(this)} checked={this.state.operatorOfAFamilyFarmNo} /></Col>
                    </Row>
                  </Col>
                  <Col md={3} className="align-left"><Form.Control name="yearsOperatingAFarm" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.yearsOperatingAFarm} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="acresOwned" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.acresOwned} /></Col>
                  <Col md={2} className="align-left"><Form.Control name="acresRented" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.acresRented} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}></Col>
                  <Col md={1} className="align-center">YES</Col>
                  <Col md={1} className="align-center">NO</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>6. I <em>(including all members, if an entity applicant)</em> have not caused the Farm Service Agency a loss by receiving debt forgiveness
                    through write-down, write-off, compromise, adjustment, reduction, charge-off, payment of a guaranteed loss claim, or bankruptcy.</Col>
                  <Col md={1} className="align-center"><Form.Check name="notCausedFarmServiceAgencyALossYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notCausedFarmServiceAgencyALossYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="notCausedFarmServiceAgencyALossNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notCausedFarmServiceAgencyALossNo} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>7. I <em>(including all members, if an entity applicant)</em> am not delinquent on any debt to the United States Government.</Col>
                  <Col md={1} className="align-center"><Form.Check name="notDelinquentOnDebtToUSGovernmentYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notDelinquentOnDebtToUSGovernmentYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="notDelinquentOnDebtToUSGovernmentNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notDelinquentOnDebtToUSGovernmentNo} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>8. I <em>(including all members, if an entity applicant)</em> do not have any outstanding recorded judgments obtained by the United States in
                    a Federal Court.</Col>
                  <Col md={1} className="align-center"><Form.Check name="notHaveOutstandingRecordedJudgmentYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notHaveOutstandingRecordedJudgmentYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="notHaveOutstandingRecordedJudgmentNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notHaveOutstandingRecordedJudgmentNo} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>9. I <em>(or members holding a majority interest if an entity applicant)</em> am a citizen of the United States, a U.S. non-citizen national, or a
                    qualified alien under applicable Federal immigration laws. <em>(United States non-citizen nationals and qualified aliens must provide
                      the appropriate documentation as to their immigration status)</em></Col>
                  <Col md={1} className="align-center"><Form.Check name="citizenOfUSYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenOfUSYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="citizenOfUSNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenOfUSNo} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>10. I <em>(including all members, if an entity applicant)</em> have the legal capacity to incur the obligations of the loan.</Col>
                  <Col md={1} className="align-center"><Form.Check name="capacityToIncurObligationsOfLoanYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.capacityToIncurObligationsOfLoanYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="capacityToIncurObligationsOfLoanNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.capacityToIncurObligationsOfLoanNo} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>11. I <em>(including all members, if an entity applicant)</em> have not been convicted of planting, cultivating, growing, producing, harvesting,
                    storing, trafficking, or possessing a controlled substance within the last 5 crop years.</Col>
                  <Col md={1} className="align-center"><Form.Check name="convictedOfControlledSubstanceYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.convictedOfControlledSubstanceYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="convictedOfControlledSubstanceNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.convictedOfControlledSubstanceNo} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>12. I <em>(including all members, if an entity applicant)</em> am not an employee, related to an employee, or an associate of an employee of
                    the Lender or Farm Service Agency.</Col>
                  <Col md={1} className="align-center"><Form.Check name="notEmployeeOfLenderOrAgencyYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notEmployeeOfLenderOrAgencyYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="notEmployeeOfLenderOrAgencyNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notEmployeeOfLenderOrAgencyNo} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>13. I <em>(including all members, if an entity applicant)</em> am unable to obtain sufficient credit without a guarantee.</Col>
                  <Col md={1} className="align-center"><Form.Check name="unableToObtainCreditWithoutGuaranteeYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.unableToObtainCreditWithoutGuaranteeYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="unableToObtainCreditWithoutGuaranteeNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.unableToObtainCreditWithoutGuaranteeNo} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>14. I <em>(including all members, if an entity applicant)</em> have not provided the Farm Service Agency with false or misleading documents or
                    statements in the past.</Col>
                  <Col md={1} className="align-center"><Form.Check name="notProvidedFalseDocumentsOrStatementsInPastYes" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notProvidedFalseDocumentsOrStatementsInPastYes} /></Col>
                  <Col md={1} className="align-center"><Form.Check name="notProvidedFalseDocumentsOrStatementsInPastNo" type="checkbox" onChange={this.handleInputChange.bind(this)} checked={this.state.notProvidedFalseDocumentsOrStatementsInPastNo} /></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}