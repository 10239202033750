import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import { IdDocumentSde } from "../../../shared/Enums";
import Button from "react-bootstrap/esm/Button";
import "./IdDocumentsSection.css"
import CustomerAccountVerificationDocument from "models/account/customer/customerAccountVerificationDocument";

type IdDocumentModalAction = 'view' | 'upload';
type IdDocumentCallback = (action: IdDocumentModalAction, documentId?: number, documentSide?: IdDocumentSde) => void;

interface IdDocumentsSectionProps {
  loanDetails: LoanApplication,
  setIdDocumentModalState: IdDocumentCallback
}

export default class IdDocumentsSectionSection extends React.Component<IdDocumentsSectionProps> {

  render() {
    return (
      <>
        <div>
          <h3>ID Verification Documents</h3>

          <Table key='applicant-verification-docs' className="table-border" striped={false} size="sm">
            <thead>
              <tr>
                <th className="document-type-heading document-type-title">Verification Documents</th>
                <th className="align-right document-type-heading" >
                  <Button
                    className="download-link-text"
                    variant="link"
                    onClick={this.props.setIdDocumentModalState.bind(this, 'upload', undefined, undefined)}>Upload</Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.loanDetails.customerAccount.customerAccountVerificationDocuments != undefined && this.props.loanDetails.customerAccount.customerAccountVerificationDocuments.map(
                (document: CustomerAccountVerificationDocument, i: number) => {
                  return (
                    <>
                      {document.frontPictureLocation?.length > 0 &&
                        <tr key={`front-id-document-${i}`}>
                          <td><Button className="download-link-text" variant="link" onClick={() => {this.props.setIdDocumentModalState('view', document.id, IdDocumentSde.Front)}}>{`Front Of Document`}</Button></td>
                        </tr>
                      }

                      {(document.frontPictureLocation == undefined || document.frontPictureLocation.length === 0) &&
                        <tr>
                          <td><span className="no-file-text">No front photo uploaded.</span></td>
                        </tr>
                      }

                      {document.backPictureLocation?.length > 0 &&
                        <tr key={`back-id-document-${i}`}>
                          <td><Button className="download-link-text" variant="link" onClick={this.props.setIdDocumentModalState.bind(this, 'view', document.id, IdDocumentSde.Back)}>{`Back Of Document`}</Button></td>
                        </tr>
                      }

                      {(document.backPictureLocation == undefined || document.backPictureLocation.length === 0) &&
                        <tr key={`back-id-document-${i}`}>
                          <td>No photo of back</td>
                        </tr >
                      }
                    </>
                  );
                }
              )}

              {(this.props.loanDetails.customerAccount.customerAccountVerificationDocuments == undefined || this.props.loanDetails.customerAccount.customerAccountVerificationDocuments.length === 0) &&
                <tr>
                  <td>
                    No verification documents uploaded.
                  </td>
                </tr>
              }
            </tbody>
          </Table>
        </div >
      </>
    );
  }
}