import { IdToken } from "@auth0/auth0-react";

class AuthUtility {
  private _getAccessTokenSilently: () => Promise<IdToken | undefined> = () =>
    Promise.resolve(undefined);
  private _logout: () => void | null = () => null;

  getToken = (): Promise<string> => {
    return new Promise((resolve) => {
      this._getAccessTokenSilently().then((idToken) => {
        if (idToken === undefined) {
          // Reloading will redirect to the login page, while retaining a redirect url
          window.location.reload();
        } else {
          return resolve(idToken.__raw);
        }
      });
    });
  };

  setAccessTokenSilently = (func: () => Promise<IdToken | undefined>) =>
    (this._getAccessTokenSilently = func);

  logout = (): void => {
    this._logout();
  };

  setLogout = (func: () => void) => {
    this._logout = func;
  };
}

const auth = new AuthUtility();
export default auth;
