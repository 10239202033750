import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Button, Form } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { reactPlugin } from "../../../utilities/AppInsights";
import "./CreateLoanPage.css"
import { AppState } from "redux/store/store";
import { toggleAPIRequestSuccess, toggleLoading } from "redux/actions/system/actions";
import { addNewDealer, getDealers, updateDealer } from "redux/actions/dealer/actions";
import Dealer from "models/dealerPurchase/dealer";
import { ConnectedProps, connect } from "react-redux";
import dealerService from "accessors/dealerService";
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import LoanApplication from "models/loan/loanApplication";
import { LoanType } from "shared/Enums";
import DealerPurchase from "models/dealerPurchase/dealerPurchase";
import CurrencyInput from "react-currency-input-field";
import PrimaryButton from "shared/components/PrimaryButton";
import EquipmentInformation from "models/dealerPurchase/equipmentInformation";
import PhoneInput from "react-phone-input-2";
import EquipmentInsuranceInformation from "models/dealerPurchase/equipmentInsuranceInformation";
import { isValidPhoneNumber } from "react-phone-number-input";
import Applicant from "models/applicantForm/applicant";
import DatePicker from "react-datepicker";
import globalUtility from "utilities/GlobalUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EntityApplicant from "models/applicantForm/entityApplicant";
import SelectOption from "shared/models/selectOption";
import AsyncSelect from 'react-select/async';
import accountService from "accessors/accountService";
import CustomerAccount from "models/account/customer/customerAccount";
import loanApplicationUtility from "utilities/LoanApplicationUtility";
import SecondaryButton from "shared/components/SecondaryButton";
import loanApplicationService from "accessors/loanApplicationService";
import isDate from "date-fns/isDate";
import LendingReference from "models/dealerPurchase/lendingReference";
import CollateralEquityCashDown from "models/collateralEquity/cashDown";
import GrossRevenue from "models/grossRevenue/grossRevenue";


const mapState = (state: AppState, route: any) => {
  const { system, dealer } = state;
  const { isLoading, apiRequestSuccess, loggedInAccount } = system;
  const { dealers } = dealer;
  return {
    isLoading,
    apiRequestSuccess,
    dealers,
    route,
    loggedInAccount
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  toggleAPIRequestSuccess: (apiRequestSuccess: boolean) => toggleAPIRequestSuccess(apiRequestSuccess),
  getDealers: () => getDealers(),
  addNewDealer: (dealer: Dealer) => addNewDealer(dealer),
  updateDealer: (dealer: Dealer) => updateDealer(dealer),
}

const STATES = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

interface CreateLoanPageState {
  loanApplication: LoanApplication;
  validInsurancePhoneNumber: boolean;
  validLendingReferencePhoneNumber: boolean;
  validCollateral: boolean;
  loadingAccountOptions: boolean;
  accountSelectOptions: Array<Array<SelectOption>>;
  accountOptions: Array<Array<CustomerAccount>>;
}

class CreateAccountPage extends React.Component<Props, CreateLoanPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loanApplication: {
        loanType: LoanType.DealerPurchase,
        loanDealerPurchase: {} as DealerPurchase,
        loanEquipmentInformation: [{ index: 0, new: false } as EquipmentInformation] as EquipmentInformation[],
        loanEquipmentInsuranceInformation: {} as EquipmentInsuranceInformation,
        applicants: [{} as Applicant]
      } as LoanApplication,
      validInsurancePhoneNumber: false,
      validLendingReferencePhoneNumber: false,
      validCollateral: false,
      loadingAccountOptions: false,
      accountSelectOptions: [[], []],
      accountOptions: [[], []]
    };
  }

  componentDidMount() {
    this.setValidFormClass('loan-type', true);
    this.loadSalesmanInformation();
  }

  loadSalesmanInformation(): void {
    this.props.toggleLoading(true);

    dealerService.GetSalesmanInformation(this.props.loggedInAccount.id)
      .then(salesman => {
        this.props.toggleLoading(false);

        if (Object.keys(salesman).length > 0) {
          let loanApplication: LoanApplication = { ...this.state.loanApplication };

          loanApplication.loanDealerPurchase = {
            dealerId: salesman.dealerId,
            dealer: { ...salesman.dealer },
            dealerSalesmanId: salesman.id,
            dealerSalesman: { ...salesman }
          } as DealerPurchase

          this.setValidFormClass('dealership-select', true);
          this.setValidFormClass('salesman-select', true);

          this.setState({ loanApplication: loanApplication });
        }
      })
  }

  buildDealerText(): string {
    if (this.state.loanApplication.loanDealerPurchase?.dealer != undefined) {
      let dealerInfo = { ...this.state.loanApplication.loanDealerPurchase.dealer };

      return `${dealerInfo.name} - ${dealerInfo.city}, ${dealerInfo.state}`;
    } else {
      return '';
    }
  }

  buildSalesmanText(): string {
    if (this.state.loanApplication.loanDealerPurchase?.dealerSalesman != undefined) {
      let salesmanInfo = { ...this.state.loanApplication.loanDealerPurchase.dealerSalesman };

      return `${salesmanInfo.firstName} ${salesmanInfo.lastName}`;
    } else {
      return '';
    }
  }

  setValidFormClass(formId: string, valid: boolean) {
    document.getElementById(formId)?.classList.remove('global-form-valid', 'global-form-invalid');

    document.getElementById(formId)?.classList.add(valid ? 'global-form-valid' : 'global-form-invalid');
  }

  setPurchaseAmountState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };
    let dealerPurchase = { ...loanApplication.loanDealerPurchase };
    dealerPurchase.purchaseAmount = amount;
    loanApplication.loanDealerPurchase = { ...dealerPurchase };

    this.setValidFormClass('total-purchase-amount', amount > 0);

    this.setState({ loanApplication: loanApplication }, this.setLoanAmountState);
  }

  setLoanTypeState(loanType: LoanType, checked: boolean): void {
    let loanApplication = { ...this.state.loanApplication };

    if (checked) {
      loanApplication.loanType = loanType;
    } else {
      loanApplication.loanType = 0;
    }

    this.setValidFormClass(`loan-type`, loanApplication.loanType > 0);

    this.setState({ loanApplication: loanApplication });
  }

  addEquipmentClicked(): void {
    let loanApplication = { ...this.state.loanApplication };

    loanApplication.loanEquipmentInformation?.push({
      index: loanApplication.loanEquipmentInformation.length,
      price: undefined,
      vin: undefined,
      make: undefined,
      model: undefined,
      year: undefined,
      new: false
    } as EquipmentInformation);

    this.setState({ loanApplication: loanApplication });
  }

  removeEquipmentClicked(index: number) {
    let loanApplication = { ...this.state.loanApplication };

    console.log(loanApplication.loanEquipmentInformation)
    if (loanApplication.loanEquipmentInformation != undefined) {
      loanApplication.loanEquipmentInformation.splice(index, 1);
    }

    this.setState({ loanApplication: loanApplication });
  }


  setEquipmentConditionValue(isNew: boolean, index: number): void {
    let loanApplication = { ...this.state.loanApplication };

    let allEquipment = [...loanApplication.loanEquipmentInformation ?? []];
    let equipment = allEquipment[index];

    equipment.new = isNew;

    loanApplication.loanEquipmentInformation = [...allEquipment];

    this.setState({ loanApplication: loanApplication });

  }

  setEquipmentPurchasePriceAmount(amount: number, index: number): void {
    let loanApplication = { ...this.state.loanApplication };

    let allEquipment = [...loanApplication.loanEquipmentInformation ?? []];
    let equipment = allEquipment[index];

    equipment.price = amount;

    loanApplication.loanEquipmentInformation = [...allEquipment];

    this.setValidFormClass(`equipment-purchase-price-${index}`, amount > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setEquipmentVinSerialValue(value: string, index: number): void {
    let loanApplication = { ...this.state.loanApplication };

    let allEquipment = [...loanApplication.loanEquipmentInformation ?? []];
    let equipment = allEquipment[index];

    equipment.vin = value;

    loanApplication.loanEquipmentInformation = [...allEquipment];

    this.setValidFormClass(`equipment-vin-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setEquipmentYearSerialValue(value: string, index: number): void {
    let loanApplication = { ...this.state.loanApplication };

    let allEquipment = [...loanApplication.loanEquipmentInformation ?? []];
    let equipment = allEquipment[index];

    equipment.year = value.substring(0, 4);

    loanApplication.loanEquipmentInformation = [...allEquipment];

    this.setValidFormClass(`equipment-year-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setEquipmentMakeSerialValue(value: string, index: number): void {
    let loanApplication = { ...this.state.loanApplication };

    let allEquipment = [...loanApplication.loanEquipmentInformation ?? []];
    let equipment = allEquipment[index];

    equipment.make = value;

    loanApplication.loanEquipmentInformation = [...allEquipment];

    this.setValidFormClass(`equipment-make-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setEquipmentModelSerialValue(value: string, index: number): void {
    let loanApplication = { ...this.state.loanApplication };

    let allEquipment = [...loanApplication.loanEquipmentInformation ?? []];
    let equipment = allEquipment[index];

    equipment.model = value;

    loanApplication.loanEquipmentInformation = [...allEquipment];

    this.setValidFormClass(`equipment-model-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setInsuranceAgencyState(value: string): void {
    let loanApplication = { ...this.state.loanApplication };
    let insuranceInfo: EquipmentInsuranceInformation = { ...loanApplication.loanEquipmentInsuranceInformation };

    insuranceInfo.companyName = value;
    loanApplication.loanEquipmentInsuranceInformation = { ...insuranceInfo };

    this.setValidFormClass('insurance-agency', value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setAgentNameState(value: string): void {
    let loanApplication = { ...this.state.loanApplication };
    let insuranceInfo: EquipmentInsuranceInformation = { ...loanApplication.loanEquipmentInsuranceInformation };

    insuranceInfo.agentName = value;
    loanApplication.loanEquipmentInsuranceInformation = { ...insuranceInfo };

    this.setValidFormClass('insurance-agent-name', value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setAgentPhoneNumberState(value: string, data: any, event: any, formattedValue: string): void {
    let loanApplication = { ...this.state.loanApplication };
    let insuranceInfo: EquipmentInsuranceInformation = { ...loanApplication.loanEquipmentInsuranceInformation };
    let validNumber = false;

    validNumber = isValidPhoneNumber(formattedValue);
    insuranceInfo.displayPhoneNumber = formattedValue;
    insuranceInfo.phoneNumber = value.substring(1, 11);
    loanApplication.loanEquipmentInsuranceInformation = { ...insuranceInfo };

    this.setValidFormClass('insurance-phone-number', validNumber);

    this.setState({ loanApplication: loanApplication, validInsurancePhoneNumber: validNumber });
  }

  setCustomerHireUseState(isCustomHire: boolean) {
    let loanApplication = { ...this.state.loanApplication };
    let dealerPurchase = { ...loanApplication.loanDealerPurchase };

    dealerPurchase.customHire = isCustomHire;
    loanApplication.loanDealerPurchase = { ...dealerPurchase };

    this.setState({ loanApplication: loanApplication });
  }

  setAssetsState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };
    let dealerPurchase = { ...loanApplication.loanDealerPurchase };

    dealerPurchase.totalAssets = amount;
    loanApplication.loanDealerPurchase = { ...dealerPurchase };

    this.setValidFormClass('total-assets', amount > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setLiabilitiesState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };
    let dealerPurchase = { ...loanApplication.loanDealerPurchase };

    dealerPurchase.totalLiabilities = amount;
    loanApplication.loanDealerPurchase = { ...dealerPurchase };

    this.setValidFormClass('total-liabilities', amount > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setCashDownAmountState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };
    let cashDown = { ...loanApplication.collateralEquityCashDown };

    if (amount > 0) {
      cashDown.amount = amount;
      loanApplication.collateralEquityCashDown = { ...cashDown };
    } else {
      // This is to satisfy validation
      loanApplication.collateralEquityCashDown = undefined;
    }

    let validCollateral = this.validateCollateral(loanApplication);
    this.setValidFormClass('collateral-section', validCollateral);

    this.setState({ loanApplication: loanApplication, validCollateral: validCollateral }, this.setLoanAmountState);
  }

  setTradeDescriptionState(value: string): void {
    let loanApplication = { ...this.state.loanApplication };
    let trade = { ...loanApplication.collateralEquityTrade };

    if (value.length === 0 && (trade.value ?? 0) === 0 && (trade.stillOwe ?? 0) === 0) {
      // This is to satisfy validation
      loanApplication.collateralEquityTrade = undefined;
    } else {
      trade.description = value;
      loanApplication.collateralEquityTrade = { ...trade };
    }

    let validCollateral = this.validateCollateral(loanApplication);
    this.setValidFormClass('collateral-section', validCollateral);

    this.setState({ loanApplication: loanApplication, validCollateral: validCollateral });
  }

  setTradeAmountState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };
    let trade = { ...loanApplication.collateralEquityTrade };

    if (amount === 0 && (trade.description ?? '').length === 0 && (trade.stillOwe ?? 0) === 0) {
      // This is to satisfy validation
      loanApplication.collateralEquityTrade = undefined;
    } else {
      trade.value = amount;
      loanApplication.collateralEquityTrade = { ...trade };
    }

    let validCollateral = this.validateCollateral(loanApplication);
    this.setValidFormClass('collateral-section', validCollateral);

    this.setState({ loanApplication: loanApplication, validCollateral: validCollateral }, this.setLoanAmountState);
  }

  setTradeAmountOwedState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };
    let trade = { ...loanApplication.collateralEquityTrade };

    if (amount === 0 && (trade.description ?? '').length === 0 && (trade.value ?? 0) === 0) {
      // This is to satisfy validation
      loanApplication.collateralEquityTrade = undefined;
    } else {
      trade.stillOwe = amount;
      loanApplication.collateralEquityTrade = { ...trade };
    }

    let validCollateral = this.validateCollateral(loanApplication);
    this.setValidFormClass('collateral-section', validCollateral);

    this.setState({ loanApplication: loanApplication, validCollateral: validCollateral }, this.setLoanAmountState);
  }

  setLoanAmountState(): void {
    let loanApplication = { ...this.state.loanApplication };
    let loanAmount = loanApplication.loanDealerPurchase?.purchaseAmount ?? 0;
    let equityAmount: number = 0;

    if (loanApplication.collateralEquityCashDown != undefined) {
      equityAmount += (loanApplication.collateralEquityCashDown.amount ?? 0);
    }

    if (loanApplication.collateralEquityTrade != undefined) {
      equityAmount += (loanApplication.collateralEquityTrade.value ?? 0) - (loanApplication.collateralEquityTrade.stillOwe ?? 0);
    }

    loanApplication.loanAmount = loanAmount - equityAmount;
    this.setState({ loanApplication: loanApplication });
  }

  setOtherDescriptionState(value: string): void {
    let loanApplication = { ...this.state.loanApplication };
    let other = { ...loanApplication.collateralEquityOtherCollateral };

    if (value.length === 0 && (other.value ?? 0) === 0) {
      // This is to satisfy validation
      loanApplication.collateralEquityOtherCollateral = undefined;
    } else {
      other.description = value;
      loanApplication.collateralEquityOtherCollateral = { ...other };
    }

    let validCollateral = this.validateCollateral(loanApplication);
    this.setValidFormClass('collateral-section', validCollateral);

    this.setState({ loanApplication: loanApplication, validCollateral: validCollateral });
  }

  setOtherAmountState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };
    let other = { ...loanApplication.collateralEquityOtherCollateral };

    if (amount === 0 && (other.description ?? '').length === 0) {
      // This is to satisfy validation
      loanApplication.collateralEquityOtherCollateral = undefined;
    } else {
      other.value = amount;
      loanApplication.collateralEquityOtherCollateral = { ...other };
    }

    let validCollateral = this.validateCollateral(loanApplication);
    this.setValidFormClass('collateral-section', validCollateral);

    this.setState({ loanApplication: loanApplication, validCollateral: validCollateral });
  }

  setLendingReferenceBankState(value: string): void {
    let loanApplication = { ...this.state.loanApplication };
    let lendingReference = { ...loanApplication.loanLendingReference };

    lendingReference.bankName = value;
    loanApplication.loanLendingReference = { ...lendingReference };

    this.setValidFormClass('lending-bank-name', value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setLendingReferenceNameState(value: string): void {
    let loanApplication = { ...this.state.loanApplication };
    let lendingReference = { ...loanApplication.loanLendingReference };

    lendingReference.contactName = value;
    loanApplication.loanLendingReference = { ...lendingReference };

    this.setValidFormClass('lending-contact-name', value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setLendingReferencePhoneNumberState(value: string, data: any, event: any, formattedValue: string): void {
    let loanApplication = { ...this.state.loanApplication };
    let lendingReference = { ...loanApplication.loanLendingReference };
    let validNumber = false;

    validNumber = isValidPhoneNumber(formattedValue);
    lendingReference.displayPhoneNumber = formattedValue;
    lendingReference.phoneNumber = value.substring(1, 11);
    loanApplication.loanLendingReference = { ...lendingReference };

    this.setValidFormClass('lending-phone-number', validNumber);

    this.setState({ loanApplication: loanApplication, validLendingReferencePhoneNumber: validNumber });
  }

  setProjectedFarmRevenuesAmountState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.projectedGrossRevenues == undefined) {
      loanApplication.projectedGrossRevenues = {};
    }

    loanApplication.projectedGrossRevenues.farmRevenues = amount;

    this.setValidFormClass('projected-gross-revenues', amount > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setProjectedOffFarmRevenuesAmountState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.projectedGrossRevenues == undefined) {
      loanApplication.projectedGrossRevenues = {};
    }

    loanApplication.projectedGrossRevenues.offFarmRevenues = amount;

    this.setValidFormClass('projected-off-farm-revenues', amount > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setPriorFarmRevenuesAmountState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.priorGrossRevenues == undefined) {
      loanApplication.priorGrossRevenues = {};
    }

    loanApplication.priorGrossRevenues.farmRevenues = amount;

    this.setState({ loanApplication: loanApplication });
  }

  setPriorOffFarmRevenuesAmountState(amount: number): void {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.priorGrossRevenues == undefined) {
      loanApplication.priorGrossRevenues = {};
    }

    loanApplication.priorGrossRevenues.offFarmRevenues = amount;

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantEmailState(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.emailAddress = value;
    applicant.validEmail = globalUtility.ValidateEmailAddress(value);
    applicant.validationAccountExists = false;
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-email-${index}`, applicant.validEmail);

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantPhoneNumberState(value: string, data: any, event: any, formattedValue: string, index: number): void {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];
    let validNumber = false;

    validNumber = isValidPhoneNumber(formattedValue);
    applicant.displayPhoneNumber = formattedValue;
    applicant.phoneNumber = value.substring(1, 11);
    applicant.validPhoneNumber = validNumber;
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-phone-number-${index}`, validNumber);

    this.setState({ loanApplication: loanApplication, validLendingReferencePhoneNumber: validNumber });
  }

  setApplicantFirstNameState(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.firstName = value;
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-first-name-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantLastNameState(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.lastName = value;
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-last-name-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantSuffixState(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.suffix = value;
    loanApplication.applicants = [...loanApplication.applicants]

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantAddressLine1State(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.address = value;
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-address-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantAddressLine2State(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.addressLine2 = value;
    loanApplication.applicants = [...loanApplication.applicants]

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantCityState(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.city = value;
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-city-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantStateValueState(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.state = value;
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-state-${index}`, value?.length > 0 && value !== "-1");

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantZipCodeState(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.zipCode = value.substring(0, 5); // max 5 digit code
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-zip-${index}`, value?.length > 0);

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantDOBState(value: Date, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.dateOfBirth = value;
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-dob-${index}`, isDate(value));

    this.setState({ loanApplication: loanApplication });
  }

  setApplicantSSNState(value: string, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.ssn = value;
    applicant.validSSN = globalUtility.ValidateSSN(value);
    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-ssn-${index}`, applicant.validSSN);

    this.setState({ loanApplication: loanApplication });
  }

  setCreateAccountAgreedState(agreed: boolean, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.createAccountAgreed = agreed;
    loanApplication.applicants = [...loanApplication.applicants]

    this.setState({ loanApplication: loanApplication });
  }

  setExperianAgreedState(agreed: boolean, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.experianAgreed = agreed;
    loanApplication.applicants = [...loanApplication.applicants]

    this.setState({ loanApplication: loanApplication });
  }

  addApplicantButtonClicked() {
    let loanApplication = { ...this.state.loanApplication };
    loanApplication.applicants.push({} as Applicant)
    this.setState({ loanApplication: loanApplication });
  }

  removeApplicantClicked(index: number) {
    let loanApplication = { ...this.state.loanApplication };
    loanApplication.applicants.splice(index, 1);

    let accountSelectOptions = [...this.state.accountSelectOptions];
    let accountOptions = [...this.state.accountOptions];

    accountSelectOptions[index] = [];
    accountOptions[index] = [];

    this.setState({ loanApplication: loanApplication, accountSelectOptions: accountSelectOptions, accountOptions: accountOptions });
  }

  setHasAccountState(agreed: boolean, index: number) {
    let loanApplication = { ...this.state.loanApplication };
    let applicant = loanApplication.applicants[index];

    applicant.hasAccount = agreed;
    applicant.customerAccountId = undefined;
    applicant.emailAddress = '';
    applicant.validEmail = false;
    applicant.firstName = '';
    applicant.lastName = '';
    applicant.suffix = '';
    applicant.address = '';
    applicant.addressLine2 = '';
    applicant.city = '';
    applicant.state = '';
    applicant.zipCode = '';
    applicant.dateOfBirth = new Date();
    applicant.phoneNumber = '';
    applicant.displayPhoneNumber = '';
    applicant.validPhoneNumber = false;
    applicant.experianAgreed = false;
    applicant.createAccountAgreed = false;
    applicant.validationAccountExists = false;

    loanApplication.applicants = [...loanApplication.applicants];

    this.setValidFormClass(`customer-email-${index}`, false);
    this.setValidFormClass(`customer-phone-number-${index}`, false);
    this.setValidFormClass(`customer-first-name-${index}`, false);
    this.setValidFormClass(`customer-last-name-${index}`, false);
    this.setValidFormClass(`customer-address-${index}`, false);
    this.setValidFormClass(`customer-city-${index}`, false);
    this.setValidFormClass(`customer-state-${index}`, false);
    this.setValidFormClass(`customer-zip-${index}`, false);
    this.setValidFormClass(`customer-dob-${index}`, false);

    this.setState({ loanApplication: loanApplication });
  }

  asyncLoadAccountOptions(searchText: string, index: number): Promise<SelectOption[]> {
    return accountService.LookupCustomerAccounts(searchText)
      .then(results => {
        let accountSelectOptions = [...this.state.accountSelectOptions];
        let accountOptions = [...this.state.accountOptions];

        let newOptions = results.map((ca: CustomerAccount) => {
          return { label: `${ca.firstName} ${ca.lastName}, ${loanApplicationUtility.FormatPhoneNumer(ca.phoneNumber)}, ${ca.email}`, value: ca.id } as SelectOption;
        });

        accountSelectOptions[index] = newOptions;
        accountOptions[index] = results;

        this.setState({ accountSelectOptions: accountSelectOptions, accountOptions: accountOptions });
        return Promise.resolve(newOptions);
      });
  }

  accountSelected(selectedId: number, index: number) {
    let accountOptions = [...this.state.accountOptions];
    let selectedAccount = accountOptions[index].find(ca => ca.id === selectedId);

    if (selectedAccount != undefined) {
      let loanApplication = { ...this.state.loanApplication };
      let applicant = loanApplication.applicants[index];

      applicant.customerAccountId = selectedAccount.id;
      applicant.emailAddress = selectedAccount.email;
      applicant.validEmail = true;
      applicant.firstName = selectedAccount.firstName;
      applicant.lastName = selectedAccount.lastName;
      applicant.suffix = selectedAccount.suffix;

      applicant.address = selectedAccount.streetAddress;
      applicant.addressLine2 = selectedAccount.streetAddress2;
      applicant.address = selectedAccount.streetAddress;
      applicant.city = selectedAccount.city;
      applicant.state = selectedAccount.state;
      applicant.zipCode = selectedAccount.zipCode;
      applicant.dateOfBirth = new Date(selectedAccount.dateOfBirth);
      applicant.phoneNumber = selectedAccount.phoneNumber.toString();
      applicant.displayPhoneNumber = `+1${selectedAccount.phoneNumber}`;
      applicant.validPhoneNumber = true;

      loanApplication.applicants = [...loanApplication.applicants];

      this.setValidFormClass(`customer-email-${index}`, true);
      this.setValidFormClass(`customer-phone-number-${index}`, true);
      this.setValidFormClass(`customer-first-name-${index}`, true);
      this.setValidFormClass(`customer-last-name-${index}`, true);
      this.setValidFormClass(`customer-address-${index}`, true);
      this.setValidFormClass(`customer-city-${index}`, true);
      this.setValidFormClass(`customer-state-${index}`, true);
      this.setValidFormClass(`customer-zip-${index}`, true);
      this.setValidFormClass(`customer-dob-${index}`, true);

      this.setState({ loanApplication: loanApplication });
    }
  }

  addEntityButtonClicked() {
    let loanApplication = { ...this.state.loanApplication };
    loanApplication.entityApplicant = {} as EntityApplicant;
    this.setState({ loanApplication: loanApplication });
  }

  removeEntityClicked() {
    let loanApplication = { ...this.state.loanApplication };
    loanApplication.entityApplicant = undefined;
    this.setState({ loanApplication: loanApplication });
  }

  setEntityNameState(value: string) {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.entityApplicant != undefined) {
      loanApplication.entityApplicant.organizationName = value;

      this.setValidFormClass('entity-name', value?.length > 0);

      this.setState({ loanApplication: loanApplication });
    }
  }

  setEntityDateOrganizedState(value: Date) {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.entityApplicant != undefined) {
      loanApplication.entityApplicant.dateOrganized = value;

      this.setValidFormClass('entity-date', isDate(value));
      this.setState({ loanApplication: loanApplication });
    }
  }

  setEntityEINState(value: string) {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.entityApplicant != undefined) {
      loanApplication.entityApplicant.ein = value;

      let validEIN = globalUtility.ValidateEIN(value);
      loanApplication.entityApplicant.validEIN = validEIN;
      this.setValidFormClass('entity-ein', validEIN);
      this.setState({ loanApplication: loanApplication });
    }
  }

  setEntityAddressState(value: string) {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.entityApplicant != undefined) {
      loanApplication.entityApplicant.address = value;
      this.setValidFormClass('entity-address', value?.length > 0);

      this.setState({ loanApplication: loanApplication });
    }
  }

  setEntityCityState(value: string) {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.entityApplicant != undefined) {
      loanApplication.entityApplicant.city = value;
      this.setValidFormClass('entity-city', value?.length > 0);

      this.setState({ loanApplication: loanApplication });
    }
  }

  setEntityStateValueState(value: string) {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.entityApplicant != undefined) {
      loanApplication.entityApplicant.state = value;
      this.setValidFormClass('entity-state', value?.length > 0 && value !== "-1");

      this.setState({ loanApplication: loanApplication });
    }
  }

  setEntityZipCodeState(value: string) {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.entityApplicant != undefined) {
      loanApplication.entityApplicant.zipCode = value.substring(0, 5);
      this.setValidFormClass('entity-zip', value?.length > 0);

      this.setState({ loanApplication: loanApplication });
    }
  }

  setEntityExperianAgreedState(agreed: boolean) {
    let loanApplication = { ...this.state.loanApplication };

    if (loanApplication.entityApplicant != undefined) {
      loanApplication.entityApplicant.experianAgreed = agreed;

      this.setState({ loanApplication: loanApplication });
    }
  }

  validateCollateral(loanApplication: LoanApplication) {
    return (!(loanApplication.collateralEquityCashDown == undefined && loanApplication.collateralEquityTrade == undefined && loanApplication.collateralEquityOtherCollateral == undefined) &&
      (loanApplication.collateralEquityCashDown == undefined || (loanApplication.collateralEquityCashDown.amount != undefined && loanApplication.collateralEquityCashDown.amount > 0)) &&
      (loanApplication.collateralEquityTrade == undefined || (loanApplication.collateralEquityTrade.description != undefined && loanApplication.collateralEquityTrade.description.length > 0 && loanApplication.collateralEquityTrade.value != undefined && loanApplication.collateralEquityTrade.value > 0)) &&
      (loanApplication.collateralEquityOtherCollateral == undefined || (loanApplication.collateralEquityOtherCollateral.description != undefined && loanApplication.collateralEquityOtherCollateral.description.length > 0 && loanApplication.collateralEquityOtherCollateral.value != undefined && loanApplication.collateralEquityOtherCollateral.value > 0)));
  }

  validateData(): boolean {
    if (this.state.loanApplication.loanAmount > 0 && this.state.loanApplication != undefined) {
      let loanApplication = { ...this.state.loanApplication };

      if (loanApplication.loanDealerPurchase != undefined) {
        let validLoanType: boolean, validDealer: boolean, validPurchaseAmount: boolean, validEquipmentInfo: boolean,
          validInsuranceInformation: boolean, validAssets: boolean, validLiabilities: boolean, validLendingReference: boolean, validCollateral: boolean,
          validRevenues: boolean, validApplicants: boolean, validEntity: boolean;

        validLoanType = loanApplication.loanType != undefined && loanApplication.loanType > 0;

        validDealer = loanApplication.loanDealerPurchase.dealerSalesmanId != undefined && loanApplication.loanDealerPurchase.dealerSalesmanId > 0
          && loanApplication.loanDealerPurchase.dealerId != undefined && loanApplication.loanDealerPurchase.dealerId > 0;

        validPurchaseAmount = loanApplication.loanDealerPurchase.purchaseAmount != undefined && loanApplication.loanDealerPurchase.purchaseAmount > 0;

        validEquipmentInfo = loanApplication.loanEquipmentInformation != undefined && loanApplication.loanEquipmentInformation.length > 0;


        if (validEquipmentInfo) {
          loanApplication.loanEquipmentInformation?.some(e => {
            validEquipmentInfo = e.make != undefined && e.make.length > 0
              && e.model != undefined && e.model.length > 0
              && e.year != undefined && e.year.length > 0
              && e.new != undefined
              && e.price != undefined && e.price > 0
              && e.vin != undefined && e.vin.length > 0;

            return !validEquipmentInfo;
          })
        }

        validInsuranceInformation = loanApplication.loanEquipmentInsuranceInformation != undefined && this.state.validInsurancePhoneNumber
          && loanApplication.loanEquipmentInsuranceInformation.agentName != undefined && loanApplication.loanEquipmentInsuranceInformation.agentName.length > 0
          && loanApplication.loanEquipmentInsuranceInformation.companyName != undefined && loanApplication.loanEquipmentInsuranceInformation.companyName.length > 0;

        validAssets = (loanApplication.loanDealerPurchase?.totalAssets ?? 0) > 0;
        validLiabilities = (loanApplication.loanDealerPurchase?.totalLiabilities ?? 0) > 0;

        validLendingReference = loanApplication.loanLendingReference != undefined && this.state.validLendingReferencePhoneNumber
          && loanApplication.loanLendingReference.bankName != undefined && loanApplication.loanLendingReference.bankName.length > 0
          && loanApplication.loanLendingReference.contactName != undefined && loanApplication.loanLendingReference.contactName.length > 0;

        validCollateral = this.state.validCollateral;

        validRevenues = loanApplication.projectedGrossRevenues?.farmRevenues != undefined && loanApplication.projectedGrossRevenues.farmRevenues > 0 && loanApplication.projectedGrossRevenues?.offFarmRevenues != undefined && loanApplication.projectedGrossRevenues.offFarmRevenues > 0;

        validApplicants = loanApplication.applicants != undefined && loanApplication.applicants.length > 0;

        if (validApplicants) {
          loanApplication.applicants?.some(a => {
            validApplicants = a.emailAddress != undefined && a.emailAddress.length > 0
              && a.firstName != undefined && a.firstName.length > 0
              && a.lastName != undefined && a.lastName.length > 0
              && a.address != undefined && a.address.length > 0
              && a.city != undefined && a.city.length > 0
              && a.state != undefined && a.state.length > 0 && a.state !== '-1'
              && a.zipCode != undefined && a.zipCode.length > 0
              && a.dateOfBirth != undefined && a.dateOfBirth.toDateString().length > 0
              && a.validSSN && a.ssn.length > 0
              && a.validPhoneNumber && a.phoneNumber.length != undefined && a.phoneNumber.length > 0
              && a.experianAgreed
              && (a.createAccountAgreed || (a.customerAccountId != undefined && a.customerAccountId > 0))

            return !validApplicants;
          })
        }

        validEntity = loanApplication.entityApplicant == undefined ||
          (loanApplication.entityApplicant.organizationName != undefined && loanApplication.entityApplicant.organizationName.length > 0
            && loanApplication.entityApplicant.dateOrganized != undefined && loanApplication.entityApplicant.dateOrganized.toDateString().length > 0
            && loanApplication.entityApplicant.ein != undefined && loanApplication.entityApplicant.ein.length > 0 && (loanApplication.entityApplicant.validEIN ?? false)
            && loanApplication.entityApplicant.address != undefined && loanApplication.entityApplicant.address.length > 0
            && loanApplication.entityApplicant.city != undefined && loanApplication.entityApplicant.city.length > 0
            && loanApplication.entityApplicant.state != undefined && loanApplication.entityApplicant.state.length > 0 && loanApplication.entityApplicant.state !== '-1'
            && loanApplication.entityApplicant.zipCode != undefined && loanApplication.entityApplicant.zipCode.length > 0
            && (loanApplication.entityApplicant.experianAgreed ?? false));


        return validLoanType && validDealer && validPurchaseAmount && validEquipmentInfo &&
          validInsuranceInformation && validAssets && validLiabilities && validLendingReference && validCollateral &&
          validRevenues && validApplicants && validEntity;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async submitLoanApplication(): Promise<void> {
    this.props.toggleLoading(true);

    if (this.state.loanApplication.loanDealerPurchase != undefined) {
      this.state.loanApplication.loanDealerPurchase.description = Array.prototype.map.call(this.state.loanApplication.loanEquipmentInformation, e => `${e.make} ${e.model}`).toString();

      let accountIssue: boolean = false;
      let loanApplication = { ...this.state.loanApplication };

      let index = 0;
      for (let a of loanApplication.applicants) {
        if (!a.hasAccount) {
          await accountService.CheckIfAccountExists(a.emailAddress)
            .then(accountExists => {
              if (accountExists) {
                accountIssue = true;

                a.validationAccountExists = true;
                loanApplication.applicants[index] = { ...a }
                loanApplication.applicants = [...loanApplication.applicants];

                this.setValidFormClass(`customer-email-${index}`, false);

                this.setState({ loanApplication: loanApplication });
              }
            })
        }
        index++;
      }

      if (!accountIssue) {
        loanApplicationService.SubmitLoanApplication(this.state.loanApplication)
          .then(loanId => {
            this.props.toggleLoading(false);

            if (loanId > 0) {
              this.props.route.history.push(`/loan/${loanId}`);
            }
          })
      } else {
        this.props.toggleLoading(false);
      }
    }
  }

  checkForDev(): boolean {
    return window.location.host.includes('localhost') || window.location.host.includes('qa');
  }

  devPrefillApplication(): void {
    let loanApplication = { ...this.state.loanApplication };

    loanApplication.loanType = LoanType.DealerPurchase;
    loanApplication.loanAmount = 50000;
    loanApplication.projectedGrossRevenues = {
      farmRevenues: 62000,
      offFarmRevenues: 36000
    } as GrossRevenue;

    if (loanApplication.loanDealerPurchase != undefined) {
      loanApplication.loanDealerPurchase.purchaseAmount = 75000;
      loanApplication.loanDealerPurchase.customHire = false;
      loanApplication.loanDealerPurchase.totalAssets = 650000
      loanApplication.loanDealerPurchase.totalLiabilities = 450000
    }

    loanApplication.loanEquipmentInformation = [
      {
        index: 0,
        price: 50000,
        vin: '1Z0346EBAGJ278775',
        make: 'John Deere',
        model: '6120E',
        year: '2024',
        new: true
      } as EquipmentInformation,
      {
        index: 1,
        price: 25000,
        vin: '1T0326EBAGJ296618',
        make: 'John Deere',
        model: '326E',
        year: '2015',
        new: false
      } as EquipmentInformation
    ];

    loanApplication.loanEquipmentInsuranceInformation = {
      companyName: 'State Farm',
      agentName: 'James Smith',
      phoneNumber: '3085643298',
      displayPhoneNumber: '+1 (308)-564-3298'
    } as EquipmentInsuranceInformation;

    loanApplication.loanLendingReference = {
      bankName: 'State Bank',
      contactName: 'James Smith',
      phoneNumber: '3085644523',
      displayPhoneNumber: '+1 (308)-564-4523'
    } as LendingReference;

    loanApplication.collateralEquityCashDown = {
      amount: 25000
    } as CollateralEquityCashDown;

    this.setState({
      loanApplication: loanApplication, validInsurancePhoneNumber: true,
      validLendingReferencePhoneNumber: true,
      validCollateral: true
    })
  }

  render() {
    return (
      <div className="d-flex justify-content-center fill-and-center" style={{ marginTop: 15, marginBottom: 15 }}>

        <Row className="justify-content-center">
          <Form>
            <h3>Sale Information</h3>
            {this.checkForDev() &&
              <div>
                <SecondaryButton
                  buttonText="DEV: Pre-Fill"
                  onClick={this.devPrefillApplication.bind(this)}
                />
              </div>
            }
            <Form.Group className="mb-3">
              <Form.Label>Dealer Information*</Form.Label>
              <Form.Control id="dealership-select" type="text" placeholder="Dealer Information" defaultValue={''} value={this.buildDealerText()} onChange={() => { }} disabled={true} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Salesman Information*</Form.Label>
              <Form.Control id="salesman-select" type="text" placeholder="Salesman Information" defaultValue={''} value={this.buildSalesmanText()} onChange={() => { }} disabled={true} />
            </Form.Group>


            <Form.Group className="mb-3">
              <Form.Label>Total Purchase Amount*</Form.Label>
              <CurrencyInput
                id="total-purchase-amount"
                prefix="$"
                placeholder="Purchase Amount"
                defaultValue={0}
                decimalsLimit={2}
                value={this.state.loanApplication.loanDealerPurchase?.purchaseAmount}
                onValueChange={(value, name, values) => this.setPurchaseAmountState(values?.float ?? 0)}
                className="form-control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="mb-3" id={`loan-type`}>Loan Type*</Form.Label>
              <Form.Group className="mb-3" >
                <Form.Check type="checkbox" label={loanApplicationUtility.GetLoanTypeText(LoanType.DealerPurchase)} checked={this.state.loanApplication.loanType === LoanType.DealerPurchase} onChange={(event) => this.setLoanTypeState(LoanType.DealerPurchase, event.target.checked)} />
                <Form.Check type="checkbox" label={loanApplicationUtility.GetLoanTypeText(LoanType.EquipmentRefinance)} checked={this.state.loanApplication.loanType === LoanType.EquipmentRefinance} onChange={(event) => this.setLoanTypeState(LoanType.EquipmentRefinance, event.target.checked)} />
              </Form.Group>
            </Form.Group>

            <div>
              <h3>Equipment Information</h3>
              {this.state.loanApplication.loanEquipmentInformation != undefined ?
                <>
                  {this.state.loanApplication.loanEquipmentInformation.map((le, index) => {
                    return (
                      <>
                        <h5>{`Equipment Item #${index + 1}`}
                          {index > 0 &&
                            <span>
                              <Button variant="link" onClick={() => this.removeEquipmentClicked(index)} >
                                <FontAwesomeIcon icon="minus" color="red" />
                              </Button>
                            </span>
                          }
                        </h5>
                        <Form.Group className="ml-3">
                          <Form.Label className="mb-3">New/Used*</Form.Label>
                          <Form.Group className="mb-3" >
                            <Form.Check type="checkbox" label="New" checked={le.new} onChange={(event) => this.setEquipmentConditionValue(event.target.checked ? true : false, index)} />
                            <Form.Check type="checkbox" label="Used" checked={!le.new} onChange={(event) => this.setEquipmentConditionValue(event.target.checked ? false : true, index)} />
                          </Form.Group>

                          <Row className="mb-3">
                            <Form.Group as={Col}>
                              <Form.Label>{`Item #${index + 1} Purchase Amount*`}</Form.Label>
                              <CurrencyInput
                                id={`equipment-purchase-price-${index}`}
                                prefix="$"
                                placeholder="Purchase Amount"
                                defaultValue={0}
                                decimalsLimit={2}
                                value={le.price}
                                onValueChange={(value, name, values) => this.setEquipmentPurchasePriceAmount(values?.float ?? 0, index)}
                                className="form-control"
                              />
                            </Form.Group>

                            <Form.Group as={Col}>
                              <Form.Label>Vin/Serial #*</Form.Label>
                              <Form.Control id={`equipment-vin-${index}`} type="text" placeholder="Enter vin/serial#" value={le.vin} defaultValue={''} onChange={(event) => this.setEquipmentVinSerialValue(event.target.value, index)} />
                            </Form.Group>
                          </Row>

                          <Row className="mb-3">
                            <Form.Group as={Col}>
                              <Form.Label>Year*</Form.Label>
                              <Form.Control
                                id={`equipment-year-${index}`}
                                type="text"
                                placeholder="Enter year"
                                value={le.year} defaultValue={''}
                                onChange={(event) => this.setEquipmentYearSerialValue(event.target.value, index)}
                              />
                            </Form.Group>

                            <Form.Group as={Col}>
                              <Form.Label>Make*</Form.Label>
                              <Form.Control id={`equipment-make-${index}`} type="text" placeholder="Enter make" value={le.make} defaultValue={''} onChange={(event) => this.setEquipmentMakeSerialValue(event.target.value, index)} />
                            </Form.Group>

                            <Form.Group as={Col}>
                              <Form.Label>Model*</Form.Label>
                              <Form.Control id={`equipment-model-${index}`} type="text" placeholder="Enter model" value={le.model} defaultValue={''} onChange={(event) => this.setEquipmentModelSerialValue(event.target.value, index)} />
                            </Form.Group>
                          </Row>
                        </Form.Group>
                      </>
                    )
                  })
                  }
                </>
                :
                <div className="mb-3">Please add equipment information.</div>
              }
              <div className="mb-3">
                <SecondaryButton
                  buttonText="Add Equipment"
                  onClick={this.addEquipmentClicked.bind(this)}
                />
              </div>
            </div>

            <div>
              <h3>Equipment Insurance Information</h3>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Insurance Agency*</Form.Label>
                  <Form.Control id="insurance-agency" type="text" placeholder="Enter agency name" value={this.state.loanApplication.loanEquipmentInsuranceInformation?.companyName} defaultValue={''} onChange={(event) => this.setInsuranceAgencyState(event.target.value)} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Agent*</Form.Label>
                  <Form.Control id="insurance-agent-name" type="text" placeholder="Enter agent name" value={this.state.loanApplication.loanEquipmentInsuranceInformation?.agentName} defaultValue={''} onChange={(event) => this.setAgentNameState(event.target.value)} />
                </Form.Group>

                <Form.Group as={Col}>
                  <PhoneInput
                    specialLabel="Agent Phone Number*"
                    inputClass="form-control"
                    country={'us'}
                    onlyCountries={['us']}
                    value={this.state.loanApplication.loanEquipmentInsuranceInformation?.displayPhoneNumber}
                    onChange={this.setAgentPhoneNumberState.bind(this)}
                    inputProps={{
                      id: 'insurance-phone-number'
                    }}
                  />
                </Form.Group>
              </Row>
            </div>

            <div>
              <h3>General Questions</h3>
              <Form.Label className="mb-3">Is This Purchase For Custom Hire Use?*</Form.Label>
              <Form.Group className="mb-3" >
                <Form.Check type="checkbox" label="Yes" checked={this.state.loanApplication.loanDealerPurchase?.customHire} onChange={(event) => this.setCustomerHireUseState(event.target.checked ? true : false)} />
                <Form.Check type="checkbox" label="No" checked={!this.state.loanApplication.loanDealerPurchase?.customHire} onChange={(event) => this.setCustomerHireUseState(event.target.checked ? false : true)} />
              </Form.Group>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Customer's Total Assets*</Form.Label>
                  <CurrencyInput
                    id="total-assets"
                    prefix="$"
                    placeholder="Enter total assets"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={this.state.loanApplication.loanDealerPurchase?.totalAssets ?? 0}
                    onValueChange={(value, name, values) => this.setAssetsState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Customer's Total Liabilities*</Form.Label>
                  <CurrencyInput
                    id="total-liabilities"
                    prefix="$"
                    placeholder="Enter total liabilities"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={this.state.loanApplication.loanDealerPurchase?.totalLiabilities ?? 0}
                    onValueChange={(value, name, values) => this.setLiabilitiesState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>
              </Row>
            </div>

            <div>
              <h3>Main Lending Reference Information</h3>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Bank Name*</Form.Label>
                  <Form.Control id="lending-bank-name" type="text" placeholder="Enter bank name" value={this.state.loanApplication.loanLendingReference?.bankName} defaultValue={''} onChange={(event) => this.setLendingReferenceBankState(event.target.value)} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Contact Name*</Form.Label>
                  <Form.Control id="lending-contact-name" type="text" placeholder="Enter contact name" value={this.state.loanApplication.loanLendingReference?.contactName} defaultValue={''} onChange={(event) => this.setLendingReferenceNameState(event.target.value)} />
                </Form.Group>

                <Form.Group as={Col}>
                  <PhoneInput
                    specialLabel="Contact Phone Number*"
                    inputClass="form-control"
                    country={'us'}
                    onlyCountries={['us']}
                    value={this.state.loanApplication.loanLendingReference?.displayPhoneNumber}
                    onChange={this.setLendingReferencePhoneNumberState.bind(this)}
                    inputProps={{
                      id: "lending-phone-number"
                    }}
                  />
                </Form.Group>
              </Row>
            </div>

            <div>
              <h3>Collateral Information</h3>
              <div className="mb-3" id="collateral-section">
                <div style={{ fontStyle: 'italic' }}>At least one section below is required*</div>
                <div style={{ fontStyle: 'italic' }}>Final Asking Loan Amount: <span style={{ color: 'green' }}>{loanApplicationUtility.BuildCurrencyAmount(this.state.loanApplication.loanAmount)}</span></div>
              </div>

              <h5>Cash</h5>
              <Form.Group>
                <Form.Label>How much cash down is being provided?</Form.Label>
                <CurrencyInput
                  id="cash-down"
                  prefix="$"
                  placeholder="Enter cash down amount"
                  defaultValue={0}
                  decimalsLimit={2}
                  value={this.state.loanApplication.collateralEquityCashDown?.amount ?? 0}
                  onValueChange={(value, name, values) => this.setCashDownAmountState(values?.float ?? 0)}
                  className="form-control"
                />
              </Form.Group>

              <h5>Trade Information</h5>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Describe the trade" value={this.state.loanApplication.collateralEquityTrade?.description} defaultValue={''} onChange={(event) => this.setTradeDescriptionState(event.target.value)} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Trade Value</Form.Label>
                  <CurrencyInput
                    id="cash-down"
                    prefix="$"
                    placeholder="Enter the value of the trade"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={this.state.loanApplication.collateralEquityTrade?.value ?? 0}
                    onValueChange={(value, name, values) => this.setTradeAmountState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Any Amount Owed?</Form.Label>
                  <CurrencyInput
                    id="cash-down"
                    prefix="$"
                    placeholder="Enter any amount still owed"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={this.state.loanApplication.collateralEquityTrade?.stillOwe ?? 0}
                    onValueChange={(value, name, values) => this.setTradeAmountOwedState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>
              </Row>

              <h5>Other Collateral Information</h5>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Describe the other collateral" value={this.state.loanApplication.collateralEquityOtherCollateral?.description} defaultValue={''} onChange={(event) => this.setOtherDescriptionState(event.target.value)} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Value</Form.Label>
                  <CurrencyInput
                    id="cash-down"
                    prefix="$"
                    placeholder="Enter the value of the other collateral"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={this.state.loanApplication.collateralEquityOtherCollateral?.value ?? 0}
                    onValueChange={(value, name, values) => this.setOtherAmountState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>
              </Row>
            </div>

            <div>
              <h3>Customer Information</h3>
              <h5>Projected Annual Gross Revenues (approximate)*</h5>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Farm/Ranch Revenues</Form.Label>
                  <CurrencyInput
                    id="projected-gross-revenues"
                    prefix="$"
                    placeholder="Enter the gross revenues from their farm"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={this.state.loanApplication.projectedGrossRevenues?.farmRevenues ?? 0}
                    onValueChange={(value, name, values) => this.setProjectedFarmRevenuesAmountState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Off-Farm Revenues*</Form.Label>
                  <CurrencyInput
                    id="projected-off-farm-revenues"
                    prefix="$"
                    placeholder="Enter their off-farm gross revenues"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={this.state.loanApplication.projectedGrossRevenues?.offFarmRevenues ?? 0}
                    onValueChange={(value, name, values) => this.setProjectedOffFarmRevenuesAmountState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>
              </Row>

              <h5>Prior Year Revenues From Schedule "F" (approximate)</h5>
              <div style={{ fontStyle: "italic" }}>(Optional)</div>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Farm/Ranch Revenues</Form.Label>
                  <CurrencyInput
                    id="cash-down"
                    prefix="$"
                    placeholder="Enter the gross revenues from their farm"
                    defaultValue={0}
                    decimalsLimit={2}
                    onValueChange={(value, name, values) => this.setPriorFarmRevenuesAmountState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Off-Farm Revenues</Form.Label>
                  <CurrencyInput
                    id="cash-down"
                    prefix="$"
                    placeholder="Enter their off-farm gross revenues"
                    defaultValue={0}
                    decimalsLimit={2}
                    onValueChange={(value, name, values) => this.setPriorOffFarmRevenuesAmountState(values?.float ?? 0)}
                    className="form-control"
                  />
                </Form.Group>
              </Row>

              <h5>Personal Information</h5>
              {this.state.loanApplication.applicants.map((a, index) => {
                return (
                  <>
                    <h6>{`Applicant #${index + 1}`}
                      {index === 1 &&
                        <span>
                          <Button variant="link" onClick={() => this.removeApplicantClicked(index)} >
                            <FontAwesomeIcon icon="minus" color="red" />
                          </Button>
                        </span>
                      }
                    </h6>

                    <Form.Group className="mb-3" style={{ maxWidth: 500 }}>
                      <Form.Check type="checkbox"
                        label="Does this applicant already have an account?"
                        checked={a.hasAccount}
                        onChange={(event) => this.setHasAccountState(event.target.checked, index)}
                      />
                    </Form.Group>

                    {a.hasAccount &&
                      <div className="mb-3">
                        <AsyncSelect
                          classNamePrefix="select"
                          placeholder="Search by name, email, or phone number"
                          loadOptions={(inputValue) => this.asyncLoadAccountOptions(inputValue, index)}
                          defaultOptions={this.state.accountSelectOptions[index]}
                          onChange={(selectedItem) => this.accountSelected(+(selectedItem?.value ?? -1), index)}
                        />
                      </div>
                    }

                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>Email Address*</Form.Label>
                        <Form.Control
                          id={`customer-email-${index}`}
                          type="text"
                          placeholder="Enter customer's email address"
                          value={a?.emailAddress}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantEmailState(event.target.value, index)}
                          disabled={a.hasAccount}
                        />
                        {a.validationAccountExists &&
                          <div style={{ color: 'red', fontStyle: 'italic' }}>Account already exists-</div>
                        }
                      </Form.Group>

                      <Form.Group as={Col}>
                        <PhoneInput
                          specialLabel="Phone Number*"
                          inputClass="form-control"
                          country={'us'}
                          onlyCountries={['us']}
                          value={a.displayPhoneNumber}
                          onChange={(value, data, event, formattedValue) => this.setApplicantPhoneNumberState(value, data, event, formattedValue, index)}
                          disabled={a.hasAccount}
                          inputProps={{
                            id: `customer-phone-number-${index}`
                          }}
                        />
                      </Form.Group>
                    </Row >

                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          id={`customer-first-name-${index}`}
                          type="text"
                          placeholder="Enter customer's first name"
                          value={a?.firstName}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantFirstNameState(event.target.value, index)}
                          disabled={a.hasAccount}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          id={`customer-last-name-${index}`}
                          type="text"
                          placeholder="Enter customer's last name"
                          value={a?.lastName}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantLastNameState(event.target.value, index)}
                          disabled={a.hasAccount}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Suffix (Jr, Sr, III)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter customer's suffix"
                          value={a?.suffix}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantSuffixState(event.target.value, index)}
                          disabled={a.hasAccount}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>Address Line 1*</Form.Label>
                        <Form.Control
                          id={`customer-address-${index}`}
                          type="text"
                          placeholder="Enter customer's address (line 1)"
                          value={a?.address}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantAddressLine1State(event.target.value, index)}
                          disabled={a.hasAccount}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Address Line 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter customer's address (line 2)"
                          value={a?.addressLine2}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantAddressLine2State(event.target.value, index)}
                          disabled={a.hasAccount}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>City*</Form.Label>
                        <Form.Control
                          id={`customer-city-${index}`}
                          type="text"
                          placeholder="Enter customer's city"
                          value={a?.city}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantCityState(event.target.value, index)}
                          disabled={a.hasAccount}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>State*</Form.Label>
                        <Form.Control as="select"
                          id={`customer-state-${index}`}
                          onChange={(event) => this.setApplicantStateValueState(event.target.value, index)}
                          value={a.state}
                          disabled={a.hasAccount}
                        >
                          <option value={-1}>Please select a state...</option>
                          {STATES.map(s => {
                            return (
                              <option value={s}>{s}</option>
                            )
                          })}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Zip Code*</Form.Label>
                        <Form.Control
                          id={`customer-zip-${index}`}
                          type="text"
                          placeholder="Enter customer's 5 digit zip code"
                          value={a?.zipCode}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantZipCodeState(event.target.value, index)}
                          disabled={a.hasAccount}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label
                          id={`customer-dob-${index}`}>Date of Birth*</Form.Label>
                        <div style={{ fontSize: 12 }}>&nbsp;</div>
                        <DatePicker
                          selected={a.dateOfBirth}
                          //onSelect={(value: Date) => this.setApplicantDOBState(value, index)}
                          onChange={(value: Date) => this.setApplicantDOBState(value, index)}
                          disabled={a.hasAccount}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Social Security Number (###-##-####)*</Form.Label>
                        <div style={{ fontSize: 12, fontStyle: 'italic' }}>Input in format shown above</div>
                        <Form.Control
                          id={`customer-ssn-${index}`}
                          type="text"
                          placeholder="123-45-6789"
                          value={a?.ssn}
                          defaultValue={''}
                          onChange={(event) => this.setApplicantSSNState(event.target.value, index)} />
                      </Form.Group>
                    </Row>

                    {
                      !a.hasAccount &&
                      <Form.Group className="mb-3" style={{ maxWidth: 500 }}>
                        <Form.Check type="checkbox"
                          label="I Agree"
                          checked={a.createAccountAgreed}
                          onChange={(event) => this.setCreateAccountAgreedState(event.target.checked, index)}
                        />
                        <Form.Label style={{ fontSize: 12, fontStyle: 'italic' }}>In order for a loan application to be processed, an account is required for the customer seeking financing. By clicking 'I Agree' on behalf of the customer, you are allowing agGRO to create an account automatically using the email address provided.</Form.Label>
                      </Form.Group>
                    }

                    <Form.Group className="mb-3" style={{ maxWidth: 500 }}>
                      <Form.Check type="checkbox"
                        label="I Agree"
                        checked={a.experianAgreed}
                        onChange={(event) => this.setExperianAgreedState(event.target.checked, index)}
                      />
                      <Form.Label style={{ fontSize: 12, fontStyle: 'italic' }}>On behalf of the customer, you understand that by checking 'I Agree', immediately following this notice they are providing ‘written instructions’ to agCredit, Inc under the Fair Credit Reporting Act authorizing agCredit, Inc to obtain information from their personal credit profile or other information from Experian. They authorize agCredit, Inc to obtain such information solely to pre-qualify them for credit options.</Form.Label>
                    </Form.Group>
                  </>
                )
              })}

              {this.state.loanApplication.applicants.length < 2 &&
                <div className="mb-3">
                  <SecondaryButton
                    buttonText="Add Joint Applicant"
                    onClick={this.addApplicantButtonClicked.bind(this)}
                  />
                </div>
              }
            </div>

            <div>
              <h5>Entity Information
                {this.state.loanApplication.entityApplicant != undefined &&
                  <span>
                    <Button variant="link" onClick={this.removeEntityClicked.bind(this)} >
                      <FontAwesomeIcon icon="minus" color="red" />
                    </Button>
                  </span>
                }

              </h5>
              {this.state.loanApplication.entityApplicant == undefined &&
                <div className="mb-3">
                  <SecondaryButton
                    buttonText="Add Entity Information"
                    onClick={this.addEntityButtonClicked.bind(this)}
                  />
                </div>
              }

              {this.state.loanApplication.entityApplicant != undefined &&
                <>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>Organization Name*</Form.Label>
                      <Form.Control
                        id="entity-name"
                        type="text"
                        placeholder="Enter organization's legal name"
                        value={this.state.loanApplication.entityApplicant.organizationName}
                        defaultValue={''}
                        onChange={(event) => this.setEntityNameState(event.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label
                        id="entity-date">Date Organized*</Form.Label>
                      <DatePicker
                        selected={this.state.loanApplication.entityApplicant.dateOrganized}
                        onChange={(value: Date) => this.setEntityDateOrganizedState(value)}
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group>
                    <Form.Label>EIN (###-#######)*</Form.Label>
                    <div style={{ fontSize: 12, fontStyle: 'italic' }}>Input in format shown above</div>
                    <Form.Control
                      id="entity-ein"
                      type="text"
                      placeholder="12-3456789"
                      value={this.state.loanApplication.entityApplicant.ein}
                      defaultValue={''}
                      onChange={(event) => this.setEntityEINState(event.target.value)} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Address*</Form.Label>
                    <Form.Control
                      id="entity-address"
                      type="text"
                      placeholder="Enter organization's address"
                      value={this.state.loanApplication.entityApplicant.address}
                      defaultValue={''}
                      onChange={(event) => this.setEntityAddressState(event.target.value)} />
                  </Form.Group>

                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>City*</Form.Label>
                      <Form.Control
                        id="entity-city"
                        type="text"
                        placeholder="Enter organization's city"
                        value={this.state.loanApplication.entityApplicant.city}
                        defaultValue={''}
                        onChange={(event) => this.setEntityCityState(event.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>State*</Form.Label>
                      <Form.Control as="select"
                        id="entity-state"
                        onChange={(event) => this.setEntityStateValueState(event.target.value)}
                        value={this.state.loanApplication.entityApplicant.state}>
                        <option value={-1}>Please select a state...</option>
                        {STATES.map(s => {
                          return (
                            <option value={s}>{s}</option>
                          )
                        })}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Zip Code*</Form.Label>
                      <Form.Control
                        id="entity-zip"
                        type="text"
                        placeholder="Enter organizations's 5 digit zip code"
                        value={this.state.loanApplication.entityApplicant.zipCode}
                        defaultValue={''}
                        onChange={(event) => this.setEntityZipCodeState(event.target.value)} />
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-3" style={{ maxWidth: 500 }}>
                    <Form.Check type="checkbox"
                      label="I Agree"
                      checked={this.state.loanApplication.entityApplicant.experianAgreed}
                      onChange={(event) => this.setEntityExperianAgreedState(event.target.checked)}
                    />
                    <Form.Label style={{ fontSize: 12, fontStyle: 'italic' }}>On behalf of the customer, you understand that by checking 'I Agree', immediately following this notice they are providing ‘written instructions’ to agCredit, Inc under the Fair Credit Reporting Act authorizing agCredit, Inc to obtain information from their personal credit profile or other information from Experian. They authorize agCredit, Inc to obtain such information solely to pre-qualify them for credit options.</Form.Label>
                  </Form.Group>
                </>
              }
            </div>

            <div style={{ textAlign: "center" }}>
              <PrimaryButton
                buttonText="Submit Application"
                onClick={this.submitLoanApplication.bind(this)}
                disabled={!this.validateData()}
              />
            </div>
          </Form>
        </Row>
        {
          this.props.isLoading &&
          <LoadingSpinner
            variant={'dark'}
            animation={'border'}
          />
        }
      </div >
    );
  }
}

export default withAITracking(reactPlugin, connector(CreateAccountPage));
