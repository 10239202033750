import React from "react";
import "./RealEstateLandSection.css"
import loanApplicationUtility from "utilities/LoanApplicationUtility";
import AssetCategory from "models/financialDocuments/balanceSheet/assets/assetCategory";
import RealEstateLand from "models/financialDocuments/balanceSheet/assets/realEstateLand";

interface RealEstateSectionProps {
  assetCategory: AssetCategory
  assetData: RealEstateLand
}

interface RealEstateSectionState {

}

export default class RealEstateLandSection extends React.Component<RealEstateSectionProps, RealEstateSectionState> {
  render() {
    return (
      <>
        <div>
          <div><span style={{fontStyle: 'italic'}}>Total Number of Acres:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{loanApplicationUtility.BuildAmount(this.props.assetData.totalAcres ?? 0)}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Total Number of Crop Acres:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{loanApplicationUtility.BuildAmount(this.props.assetData.totalCropAcres ?? 0)}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Percent Owned:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{`${this.props.assetData.percentOwned ?? 0}%`}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Price Per Land Acre:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{loanApplicationUtility.BuildCurrencyAmount(this.props.assetData.pricePerAcre)}</span>
          </div>
        </div>
      </>
    );
  }
}