import PagedList from "models/accessors/pagedList";
import LoanApplication from "../models/loan/loanApplication";
import axiosRequestUtility from "../utilities/AxiosRequestUtility";
import { CollateralEquityTypes, DocumentTypeMapper, FixedAssetType, IdDocumentSde } from "../shared/Enums";
import LoanNote from "models/note/loanNote";
import BankEmployee from "models/bank/bankEmployee";
import LoanOffer from "models/loanOffer/loanOffer";
import LoanDocument from "models/loanDocument/loanDocument";
import AddDocumentsRequest from "models/addDocumentsRequest/AddDocumentsRequest";
import DownloadDocumentResponse from "models/downloadDocumentResponse/DownloadDocumentResponse"
import DocumentRequest from "models/documentData/DocumentRequest";
import FSA2211 from "models/fsa2211Form/FSA2211";
import LoanApplicationFilterCriteria from "models/accessors/LoanApplicationFilterCriteria";
import globalUtility from "utilities/GlobalUtility";
import IdDocumentUpload from "models/account/customer/idDocumentUpload/idDocumentUpload";
import CustomerAccountVerificationDocument from "models/account/customer/customerAccountVerificationDocument";

class LoanApplicationService {
  public GetLoanApplications(
    filterCriteria: LoanApplicationFilterCriteria
  ): Promise<PagedList<LoanApplication>> {
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `LoanApplications`,
      params:
      {
        'pageNumber': filterCriteria.pageNumber,
        'listType': filterCriteria.loanListType,
        'loanType': filterCriteria.loanTypeFilter.join(','),
        'scorecardLevel': filterCriteria.scorecardLevelFilter.join(','),
        'loanStatus': filterCriteria.loanStatusFilter.join(','),
        'loanOfficer': filterCriteria.loanOfficerFilter.join(','),
        'referenceNumber': filterCriteria.submittedReferenceNumberFilter,
        'submittedStartDate': filterCriteria.submittedStartDateFilter,
        'submittedEndDate': filterCriteria.submittedEndDateFilter,
      }
    })
      .then(response => {
        let pagedLoanApplications = response.data;

        pagedLoanApplications.items.forEach((la: LoanApplication) => {
          la = this.MapLoanApplicationParts(la);
        })

        return pagedLoanApplications;
      })
      .catch(error => {
        console.log(error);
        alert('Error loading loan applications. Please try again.')
        return [];
      })
  }

  public ExportLoanApplications(
    listType: 'active' | 'closed',
    loanTypeFilter: number[],
    scorecardLevelFilter: number[],
    loanStatusFilter: number[],
    loanOfficerFilter: number[],
    submittedStartDateFilter?: Date,
    submittedEndDateFilter?: Date
  ): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `ExportLoanApplications`,
      params:
      {
        'listType': listType,
        'loanType': loanTypeFilter.join(','),
        'scorecardLevel': scorecardLevelFilter.join(','),
        'loanStatus': loanStatusFilter.join(','),
        'loanOfficer': loanOfficerFilter.join(','),
        'submittedStartDate': submittedStartDateFilter,
        'submittedEndDate': submittedEndDateFilter,
      }
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${listType}-loan-list-${new Date().getTime()}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();

        return true;
      })
      .catch(error => {
        console.log(error);
        alert('Error exporting loan applications. Please try again.')
        return false;
      })
  }

  public SubmitLoanApplication(loanApplication: LoanApplication): Promise<number> {
    return axiosRequestUtility.Request({
      method: 'POST',
      url: 'SubmitApplication',
      data: loanApplication
    })
      .then(response => {
        return +response.data;
      })
      .catch(error => {
        console.log(error);
        alert('Error submitting loan application. Please try again.')
        return 0;
      })
  }

  public GetLoanApplicationDetails(loanId: number): Promise<LoanApplication> {
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `LoanApplicationDetails`,
      params:
      {
        'loanId': loanId
      }
    })
      .then(response => {
        return this.MapLoanApplicationParts(response.data as LoanApplication);
      })
      .catch(error => {
        console.log(error);
        alert('Error loading loan application details. Please try again.')
        return {} as LoanApplication;
      })
  }

  public AssignLoanOfficerToLoan(loanId: number, employeeId: number): Promise<BankEmployee> {
    return axiosRequestUtility.Request({
      method: 'PUT',
      url: `AssignLoanOfficer`,
      params:
      {
        'loanId': loanId,
        'bankEmployeeId': employeeId
      }
    })
      .then(response => {
        return response.data as BankEmployee
      })
      .catch(error => {
        console.log(error);
        alert('Error loading loan application details. Please try again.')
        return {} as BankEmployee;
      })
  }

  public AddLoanNote(loanNote: LoanNote): Promise<LoanNote> {
    return axiosRequestUtility.Request({
      method: 'POST',
      url: `AddLoanNote`,
      data: loanNote
    })
      .then(response => {
        return response.data as LoanNote;
      })
      .catch(error => {
        console.log(error);
        alert('Error adding note to loan application. Please try again.')
        return {} as LoanNote;
      })
  }

  public UpdateLoanNote(loanNote: LoanNote): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'PUT',
      url: `UpdateLoanNote`,
      data: loanNote
    })
      .then(response => {
        return true
      })
      .catch(error => {
        console.log(error);
        alert('Error adding note to loan application. Please try again.')
        return false;
      })
  }

  public DeleteLoanNote(loanNoteId: number): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'DELETE',
      url: `DeleteLoanNote`,
      params:
      {
        'loanNoteId': loanNoteId
      }
    })
      .then(response => {
        return true;
      })
      .catch(error => {
        console.log(error);
        alert('Error deleting note from loan application. Please try again.')
        return false;
      })
  }

  public UpdateLoanStatus(loanId: number, status: number): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'PUT',
      url: `UpdateLoanStatus`,
      params:
      {
        'loanId': loanId,
        'status': status
      }
    })
      .then(response => {
        return true
      })
      .catch(error => {
        console.log(error);
        alert('Error setting loan status. Please try again.')
        return false;
      })
  }

  public AddLoanOffer(loanOffer: LoanOffer): Promise<LoanOffer> {
    return axiosRequestUtility.Request({
      method: 'POST',
      url: `AddLoanOffer`,
      data: loanOffer
    })
      .then(response => {
        return response.data as LoanOffer;
      })
      .catch(error => {
        console.log(error);
        alert('Error adding loan offer. Please try again.')
        return {} as LoanOffer;
      })
  }

  private MapLoanApplicationParts(loanApplication: LoanApplication) {
    loanApplication.fixedAssetTypes = [];
    loanApplication.collateralEquityTypes = [];

    // Fixed Asset Types
    if (loanApplication.fixedAssetEquipment !== undefined) {
      loanApplication.fixedAssetTypes.push(FixedAssetType.Equipment);
    }

    if (loanApplication.fixedAssetLivestock !== undefined && loanApplication.fixedAssetLivestock.length > 0) {
      loanApplication.fixedAssetTypes.push(FixedAssetType.Livestock);
    }

    if (loanApplication.fixedAssetRealEstate !== undefined) {
      loanApplication.fixedAssetTypes.push(FixedAssetType.RealEstate);
    }

    // Collateral Equity Types
    if (loanApplication.collateralEquityCashDown !== undefined) {
      loanApplication.collateralEquityTypes.push(CollateralEquityTypes.CashDown);
    }

    if (loanApplication.collateralEquityTrade !== undefined) {
      loanApplication.collateralEquityTypes.push(CollateralEquityTypes.Trade);
    }

    if (loanApplication.collateralEquityOtherCollateral !== undefined) {
      loanApplication.collateralEquityTypes.push(CollateralEquityTypes.OtherCollateral);
    }

    return loanApplication;
  }

  public AddLoanDocument(loanDocuments: AddDocumentsRequest): Promise<LoanDocument[]> {

    var data = new FormData();
    var filecount = 1;
    var date = new Date();
    loanDocuments.data.note = "";

    var datetag = (date.getMonth() + 1).toString() + date.getDate().toString() + date.getFullYear().toString() + date.getHours().toString() + date.getMinutes().toString();
    loanDocuments.documents.forEach(file => {
      var newFile = new File([file], `${DocumentTypeMapper[loanDocuments.data.type ?? 0].replace(" ", "_")}_${(loanDocuments.data.year ?? '').toString()}_${datetag}_${filecount.toString()}${file.name.slice(file.name.lastIndexOf('.'))}`)
      filecount++;
      data.append("files[]", newFile);
    })

    data.append("documentdata", JSON.stringify(loanDocuments.data));

    return axiosRequestUtility.Request({
      method: 'POST',
      url: `AddLoanDocuments`,
      data: data
    })
      .then(response => {
        return response.data as LoanDocument[];
      })
      .catch(error => {
        alert('Error adding document to loan application. Please try again.')
        return {} as LoanDocument[];
      })
  }

  public DeleteLoanDocument(loanDocumentId: number): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'DELETE',
      url: `DeleteLoanDocument`,
      params:
      {
        'loanDocumentId': loanDocumentId
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        alert('Error deleting document from loan application. Please try again.')
        return false;
      })
  }

  public DownloadLoanDocument(loanDocumentId: number): Promise<DownloadDocumentResponse> {
    return axiosRequestUtility.Request({
      method: 'POST',
      responseType: 'arraybuffer',
      url: `DownloadLoanDocument`,
      params:
      {
        'loanDocumentId': loanDocumentId
      }
    })
      .then(response => {
        return globalUtility.CreateFileDownloadResponse(response);
      })
      .catch(error => {
        alert('Error downloading document from loan application. Please try again.')
        return {} as DownloadDocumentResponse;
      })
  }

  public FindOpenDocumentRequests(customerAccountId: number): Promise<DocumentRequest[]> {
    return axiosRequestUtility.Request({
      method: 'POST',
      responseType: 'array',
      url: 'FindOpenDocumentRequests',
      params:
      {
        'customerAccountId': customerAccountId
      }
    })
      .then(response => {
        return response.data as DocumentRequest[];
      })
      .catch(error => {
        alert('Error getting open Document Requests from loan application. Please try again.')
        return [] as DocumentRequest[];
      })
  }

  public DeleteDocumentRequest(documentRequestId: number): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'DELETE',
      url: `DeleteDocumentRequest`,
      params:
      {
        'documentRequestId': documentRequestId
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        alert('Error deleting document request. Please try again.')
        return false;
      })
  }

  public AddDocumentRequests(requests: DocumentRequest[]): Promise<LoanDocument[]> {

    var data = new FormData();
    data.append("documentdatas", JSON.stringify(requests));

    return axiosRequestUtility.Request({
      method: 'POST',
      url: 'AddDocumentRequests',
      data: data
    })
      .then(response => {
        return response.data as LoanDocument[];
      })
      .catch(error => {
        alert('Error adding document requests. Please try again.')
        return {} as LoanDocument[];
      })
  }

  public AddIdDocument(idDocumentUpload: IdDocumentUpload): Promise<CustomerAccountVerificationDocument> {
    var data = new FormData();

    idDocumentUpload.documentData.forEach(d => {
      var newFile = new File([d.document], (d.documentSide === IdDocumentSde.Front ? `front${d.document.name.slice(d.document.name.lastIndexOf('.'))}` : `back${d.document.name.slice(d.document.name.lastIndexOf('.'))}`));
      data.append("files[]", newFile);
    })

    data.append('idDocumentUpload', JSON.stringify(idDocumentUpload));

    return axiosRequestUtility.Request({
      method: 'POST',
      url: `AddIdDocuments`,
      data: data
    })
      .then(response => {
        return response.data as CustomerAccountVerificationDocument;
      })
      .catch(error => {
        alert('Error adding ID documents. Please try again.')
        return {} as CustomerAccountVerificationDocument;
      })
  }

  public SaveFSA2211(FormData: FSA2211): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'POST',
      url: `SaveFSA2211`,
      data: FormData
    })
      .then(response => {
        return true;
      })
      .catch(error => {
        alert('Error saving FSA2211. Please try again.')
        return false;
      })
  }

  public GetCurrentFSA2211RevisionVersion(): Promise<string> {
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `GetCurrentFSA2211RevisionVersion`
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        alert('Error getting current revision version. Please try again.')
        return "";
      })
  }

  public GenerateFSA2211Pdf(FormData: FSA2211): Promise<DownloadDocumentResponse> {
    return axiosRequestUtility.Request({
      method: 'POST',
      responseType: 'arraybuffer',
      url: `GenerateFSA2211Pdf`,
      data: FormData
    })
      .then(response => {
        return globalUtility.CreateFileDownloadResponse(response);
      })
      .catch(error => {
        alert('Error exporting FSA2211. Please try again.')
        return {} as DownloadDocumentResponse;
      })
  }

  public DownloadFSA2211Pdf(loanId: number): Promise<DownloadDocumentResponse> {
    return axiosRequestUtility.Request({
      method: 'POST',
      responseType: 'arraybuffer',
      url: `DownloadFSA2211`,
      data: loanId
    })
      .then(response => {
        return globalUtility.CreateFileDownloadResponse(response);
      })
      .catch(error => {
        alert('Error downloading FSA2211. Please try again.')
        return {} as DownloadDocumentResponse;
      })
  }
}

const loanApplicationService = new LoanApplicationService();
export default loanApplicationService;