import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./CollateralEquitySection.css";
import { CollateralEquityTypes } from "../../../shared/Enums";
import loanApplicationUtility from "utilities/LoanApplicationUtility";

interface CollateralEquitySectionProps {
  loanDetails: LoanApplication
}

export default class CollateralEquitySection extends React.Component<CollateralEquitySectionProps> {
  render() {
    return (
      <>
        {this.props.loanDetails.collateralEquityTypes !== undefined && this.props.loanDetails.collateralEquityTypes.length > 0 &&
          (<>
            <div>
              <h3>Collateral Details</h3>
              <Table bordered striped={false} size="sm">
                <tbody>
                  {this.props.loanDetails.collateralEquityTypes.includes(CollateralEquityTypes.CashDown) &&
                    (<>
                      <tr>
                        <td width="250px" className="app-info"><strong>Cash Down Provided</strong></td>
                        <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.collateralEquityCashDown?.amount)}</td>
                      </tr>
                    </>)
                  }

                  {this.props.loanDetails.collateralEquityTypes.includes(CollateralEquityTypes.Trade) &&
                    (<>
                      <tr>
                        <td width="250px" className="app-info"><strong>Trade-In Description</strong></td>
                        <td className="app-info">{this.props.loanDetails.collateralEquityTrade?.description}</td>
                      </tr>
                      <tr>
                        <td width="250px" className="app-info"><strong>Trade-In Value</strong></td>
                        <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.collateralEquityTrade?.value)}</td>
                      </tr>
                    </>)
                  }

                  {this.props.loanDetails.collateralEquityTypes.includes(CollateralEquityTypes.OtherCollateral) &&
                    (<>
                      <tr>
                        <td width="250px" className="app-info"><strong>Other Collateral Description</strong></td>
                        <td className="app-info">{this.props.loanDetails.collateralEquityOtherCollateral?.description}</td>
                      </tr>
                      <tr>
                        <td width="250px" className="app-info"><strong>Other Collateral Value</strong></td>
                        <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.collateralEquityOtherCollateral?.value)}</td>
                      </tr>
                    </>)
                  }
                </tbody>
              </Table>
            </div>
          </>)
        }
      </>
    );
  }
}