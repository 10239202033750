import {
    createStore,
    applyMiddleware,
    compose,
    Action,
    combineReducers,
  } from 'redux';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import system from '../reducers/system/reducers';
import loans from '../reducers/loan/reducers';
import bank from '../reducers/bank/reducers';
import dealer from '../reducers/dealer/reducers';
  
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
  >;
  
  declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION__?: any;
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
      devToolsExtension: any;
    }
  }
  
  export const composeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  
  const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
  
  const rootReducer = combineReducers({
    system,
    loans,
    bank,
    dealer
  });
  
  export type AppState = ReturnType<typeof rootReducer>;
  const store = createStore(rootReducer, {}, enhancer);
  export default store;
  