import React from "react";
import { Button, Table } from "react-bootstrap";
import "./FSAApplicationsSection.css"

interface FSAApplicationsSectionProps {
  openModal: callback;
}

type FSAApplicationForm = 'FSA2211' | 'FSA2037' | 'FSA2038';
type callback = () => void;

interface FSAApplicationsSectionState {
  selectedForm?: FSAApplicationForm
}

export default class FSAApplicationsSection extends React.Component<FSAApplicationsSectionProps, FSAApplicationsSectionState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }  

  render() {
    return (
      <>
        <div className="loan-application-details-section">
          <h3>FSA Applications</h3>
          <Table className="table-border" striped={false} size="sm">
            <thead>
              <tr>
                <th className="form-type-heading form-type-title">Application For Guarantee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Button className="download-link-text" variant="link" onClick={() => {this.props.openModal()}} >View</Button></td>
              </tr>
            </tbody>
          </Table>
        </div>              
      </>
    );
  }
}