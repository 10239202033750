import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartM from "../../../../models/fsa2211Form/FSA2211PartM"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartMCallback = (formDataPartM: FSA2211PartM) => void;

interface FSA2211FormPartMProps {
  partMData?: FSA2211PartM;
  formDataChange: formDataPartMCallback;
}

export default class FSA2211FormPartM extends React.Component<FSA2211FormPartMProps, FSA2211PartM> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partMData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartMProps) {
    if (previousProps.partMData !== this.props.partMData) {
      this.setState(this.props.partMData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartM {
    return {
      lenderStatusPLP: false,
      lenderStatusCLP: false,
      lenderStatusSEL: false,
      lenderStatusMLP: false,
      lenderCertifiesDate: "",
      lendingInstitutionNameAndAddress: "",
      telephoneNumber: "",
      lenderTaxIDNumber: "",
      regulatoryCertifyingAgency: "",
      emailAddress: "",
      nameOfLendersRepresentative: "",
      titleOfLenderRepresentative: "",
      date: "",
    } as FSA2211PartM
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART M &nbsp;&nbsp;    ENVIRONMENTAL INFORMATION <em>(ALL LENDER TYPES)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>1. Lender Status </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={3}><Form.Check name="lenderStatusPLP" inline label="Preferred Lender (PLP)" onChange={this.handleInputChange.bind(this)} checked={this.state.lenderStatusPLP} /></Col>
                  <Col md={3}><Form.Check name="lenderStatusCLP" inline label="Certified Lender (CLP)" onChange={this.handleInputChange.bind(this)} checked={this.state.lenderStatusCLP} /></Col>
                  <Col md={3}><Form.Check name="lenderStatusSEL" inline label="Standard Eligible (SEL)" onChange={this.handleInputChange.bind(this)} checked={this.state.lenderStatusSEL} /></Col>
                  <Col md={3}><Form.Check name="lenderStatusMLP" inline label="Micro Lender (MLP)" onChange={this.handleInputChange.bind(this)} checked={this.state.lenderStatusMLP} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>2. Lender Certifies that: </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>
                    <Row><Col>a. All applicable requirements in 7 C.F.R. Part 762, and FSA-2201 have been or will be met.</Col></Row>
                    <Row><Col>b. The Lender would not make the loan without an FSA guarantee.</Col></Row>
                    <Row><Col>c. The loan applicant shows the ability to repay requested loan.</Col></Row>
                    <Row><Col>d. The proposed collateral securing the loan is considered adequate.</Col></Row>
                    <Row><Col>e. All documentation required by 7 C.F.R. Part 762, but not required to be submitted with the loan application, has been obtained and supports the
                      data presented in this application.</Col></Row>
                    <Row><Col>f. If applicable for PLP lenders, loans will be made according to the current Credit Management System (CMS).</Col></Row>
                    <Row><Col>g. Application will be governed by Lender’s Agreement (FSA-2201) dated: </Col></Row>
                    <Row className="mt-2"><Col className="standard-bold">Date</Col></Row>
                    <Row><Col><Form.Control name="lenderCertifiesDate" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.lenderCertifiesDate} /></Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>3A. Lending Institution Name and Address <Form.Control name="lendingInstitutionNameAndAddress" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.lendingInstitutionNameAndAddress} /></Col>
                  <Col>3B. Telephone Number (Including Area Code)<Form.Control name="telephoneNumber" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.telephoneNumber} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>4A. Lender 9 Digit Tax ID Number<Form.Control name="lenderTaxIDNumber" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.lenderTaxIDNumber} /></Col>
                  <Col>4B. Regulatory or Certifying Agency<Form.Control name="regulatoryCertifyingAgency" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.regulatoryCertifyingAgency} /></Col>
                  <Col>5. Email Address <Form.Control name="emailAddress" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.emailAddress} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>6A. Name of Lender’s Representative<Form.Control name="nameOfLendersRepresentative" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.nameOfLendersRepresentative} /></Col>
                  <Col>6B. Title of Lender’s Representative<Form.Control name="titleOfLenderRepresentative" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.titleOfLenderRepresentative} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>7A. Authorized Lender Representative’s Signature<Form.Control type="text" disabled /></Col>
                  <Col>7B. Date (MM-DD-YYYY)<Form.Control name="date" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.date} /></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}