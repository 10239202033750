import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Can from "../../../auth/Can";
import { Link, Redirect } from "react-router-dom";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import EditProfileModal from "components/modals/profileModal/EditProfileModal";
import ProfileTag from "../header/ProfileTag";
import { Image } from "react-bootstrap";
import Logo from '../../../assets/full_logo.png'
import './Header.css'

interface HeaderState {
  showEditProfileModal: boolean;
}

class Header extends React.Component<WithAuth0Props, HeaderState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showEditProfileModal: false
    }
  }

  logout = () => {
    this.props.auth0.logout({ returnTo: window.location.origin });
  };

  resetEditProfileModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showEditProfileModal: false });
  }

  showEditProfileModal(): void {
    this.setState({ showEditProfileModal: true });
  }

  render() {
    let { user } = this.props.auth0;

    // User is undefined initially
    if (!user) {
      return null;
    }

    return (
      <>
        <Navbar expand="lg" variant="dark">
          <Navbar.Brand as={Link} to="/active">
            <Image width={150} src={Logo} fluid />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* Bank/Bank Employee Items */}
              <Can
                perform="Loan Officer"
                yes={() => (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/active">
                      Active Loans
                    </Nav.Link>
                  </Nav.Item>
                )}
                no={() => <Redirect to={{ pathname: "/" }} />}
              />

              <Can
                perform="Loan Officer"
                yes={() => (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/closed">
                      Closed Loans
                    </Nav.Link>
                  </Nav.Item>
                )}
                no={() => <Redirect to={{ pathname: "/" }} />}
              />

              <Can
                perform="Admin"
                yes={() => (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/bank/add">
                      Banks
                    </Nav.Link>
                  </Nav.Item>
                )}
                no={() => <Redirect to={{ pathname: "/" }} />}
              />

              <Can
                perform="Manager"
                yes={() => (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/users">
                      Users
                    </Nav.Link>
                  </Nav.Item>
                )}
                no={() => <Redirect to={{ pathname: "/" }} />}
              />

              <Can
                perform="Admin"
                yes={() => (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/dealer/add">
                      Dealers
                    </Nav.Link>
                  </Nav.Item>
                )}
                no={() => <Redirect to={{ pathname: "/" }} />}
              />

              {/* Dealer Salesman Items */}
              <Can
                perform="Dealer Salesman"
                yes={() => (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/active">
                      My Open Loans
                    </Nav.Link>
                  </Nav.Item>
                )}
                no={() => <Redirect to={{ pathname: "/" }} />}
              />

              <Can
                perform="Dealer Salesman"
                yes={() => (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/closed">
                      My Closed Loans
                    </Nav.Link>
                  </Nav.Item>
                )}
                no={() => <Redirect to={{ pathname: "/" }} />}
              />

              <Can
                perform="Dealer Salesman"
                yes={() => (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/loan/create">
                      Create Loan
                    </Nav.Link>
                  </Nav.Item>
                )}
                no={() => <Redirect to={{ pathname: "/" }} />}
              />
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <NavDropdown title={<ProfileTag />} id="user-actions-dropdown" alignRight={true}>
              <NavDropdown.Item onClick={this.showEditProfileModal.bind(this)}>Edit Profile</NavDropdown.Item>
              <Can
                perform="Manager"
                yes={() => (
                  <NavDropdown.Item as={Link} to="/users">Edit Users</NavDropdown.Item>
                )}
                no={() => null}
              />
              <Can
                perform="Manager"
                yes={() => (
                  <NavDropdown.Item as={Link} to="/bank/edit">
                    Edit Bank Information
                  </NavDropdown.Item>
                )}
                no={() => null}
              />
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={this.logout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar>
        <EditProfileModal
          showModal={this.state.showEditProfileModal}
          sourcePage={'header'}
          action={'edit'}
          onModalCancel={() => this.setState({ showEditProfileModal: false })}
        />
      </>
    );
  }
}

export default withAuth0(Header)
