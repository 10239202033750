import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartG from "../../../../models/fsa2211Form/FSA2211PartG"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartGCallback = (formDataPartG: FSA2211PartG) => void;

interface FSA2211FormPartGProps {
  partGData?: FSA2211PartG;
  formDataChange: formDataPartGCallback;
}

export default class FSA2211FormPartG extends React.Component<FSA2211FormPartGProps, FSA2211PartG> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partGData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartGProps) {
    if (previousProps.partGData !== this.props.partGData) {
      this.setState(this.props.partGData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartG {
    return {
      requestNumber: 0,
      requestNumberOf: 0,
      loanTypeFO: false,
      loanTypeOL: false,
      loanTypeOLLOC: false,
      loanTypeCL: false,
      eZGuaranteeYes: false,
      eZGuaranteeNo: false,
      loanAmount: 0,
      interestRate: 0,
      variable: false,
      fixed: false,
      maximum: false,
      other: false,
      otherEntry: "",
      repaymentPeriod: "",
      principalRepaymentTerms: "",
      interestRepaymentTerms: "",
    } as FSA2211PartG
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART G &nbsp;&nbsp; TYPE OF ASSISTANCE REQUESTED <em>(ALL LENDER TYPES)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={2}>1. Request Number</Col>
                  <Col md={2}>2. Loan Type</Col>
                  <Col md={2}>3. EZ Guarantee</Col>
                  <Col md={3}>4. Loan Amount or LOC Ceiling</Col>
                  <Col md={3}>5. Interest Rate</Col>
                </Row>
                <Row className="standard-text">
                  <Col md={2}>
                    <Row>
                      <Col><Form.Control name="requestNumber" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.requestNumber} /></Col> of
                      <Col><Form.Control name="requestNumberOf" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.requestNumberOf} /></Col>
                    </Row>
                  </Col>
                  <Col md={2}>
                    <Row>
                      <Col>
                        <Form.Check name="loanTypeFO" type="checkbox" label="FO" onChange={this.handleInputChange.bind(this)} checked={this.state.loanTypeFO} />
                        <Form.Check name="loanTypeOLLOC" type="checkbox" label="OL/LOC" onChange={this.handleInputChange.bind(this)} checked={this.state.loanTypeOLLOC} /></Col>
                      <Col>
                        <Form.Check name="loanTypeOL" type="checkbox" label="OL" onChange={this.handleInputChange.bind(this)} checked={this.state.loanTypeOL} />
                        <Form.Check name="loanTypeCL" type="checkbox" label="CL" onChange={this.handleInputChange.bind(this)} checked={this.state.loanTypeCL} />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={2}>
                    <Form.Check name="eZGuaranteeYes" inline type="checkbox" label="YES" onChange={this.handleInputChange.bind(this)} checked={this.state.eZGuaranteeYes} />
                    <Form.Check name="eZGuaranteeNo" inline type="checkbox" label="NO" onChange={this.handleInputChange.bind(this)} checked={this.state.eZGuaranteeNo} /></Col>
                  <Col md={3}>
                    <Row noGutters>
                      <div className="align-self-center">$</div><Col><Form.Control name="loanAmount" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.loanAmount} /></Col>
                    </Row>
                  </Col>
                  <Col md={3}>
                    <Row noGutters>
                      <Col><Form.Control name="interestRate" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.interestRate} /></Col><Col className="d-flex align-items-center">%</Col>
                      <Col>
                        <Form.Check name="variable" label="Variable" onChange={this.handleInputChange.bind(this)} checked={this.state.variable} />
                        <Form.Check name="fixed" label="Fixed" onChange={this.handleInputChange.bind(this)} checked={this.state.fixed} /></Col>
                    </Row>
                  </Col>

                </Row>
                <Row className="standard-text mt-2">
                  <Col>6. Requested Guarantee Percentage</Col>
                  <Col>7. Repayment Period <em>(Years)</em></Col>
                  <Col>8. Principal Repayment Terms</Col>
                  <Col>9. Interest Repayment Terms</Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col md={4}>
                        <Form.Check name="maximum" inline type="checkbox" label="Maximum" onChange={this.handleInputChange.bind(this)} checked={this.state.maximum} />
                      </Col>
                      <Col md={3}>
                        <Form.Check name="other" inline type="checkbox" label="Other" onChange={this.handleInputChange.bind(this)} checked={this.state.other} />
                      </Col>
                      <Col md={5}><Form.Control name="otherEntry" type="text" onChange={this.handleInputChange.bind(this)} checked={this.state.fixed} /></Col>
                    </Row>
                  </Col>
                  <Col><Form.Control name="repaymentPeriod" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.repaymentPeriod} /></Col>
                  <Col><Form.Control name="principalRepaymentTerms" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.principalRepaymentTerms} /></Col>
                  <Col><Form.Control name="interestRepaymentTerms" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.interestRepaymentTerms} /></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}