import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartN from "../../../../models/fsa2211Form/FSA2211PartN"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartNCallback = (formDataPartN: FSA2211PartN) => void;

interface FSA2211FormPartNProps {
  partNData?: FSA2211PartN;
  formDataChange: formDataPartNCallback;
}

export default class FSA2211FormPartN extends React.Component<FSA2211FormPartNProps, FSA2211PartN> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partNData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartNProps) {
    if (previousProps.partNData !== this.props.partNData) {
      this.setState(this.props.partNData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartN {
    return {
      dateRecieved: "",
      dateCompleted: "",
    } as FSA2211PartN
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART N &nbsp;&nbsp; FSA USE ONLY</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>1A. Date Recieved <em>(MM-DD-YYYY)</em></Col>
                  <Col>1B. Date Complete <em>(MM-DD-YYYY)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col><Form.Control name="dateRecieved" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.dateRecieved} /></Col>
                  <Col><Form.Control name="dateCompleted" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.dateCompleted} /></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}