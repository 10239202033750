import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartQSel from "../../../../models/fsa2211Form/FSA2211PartQSel"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartQSelCallback = (formDataPartQSel: FSA2211PartQSel) => void;

interface FSA2211FormPartQSelProps {
  partQSelData?: FSA2211PartQSel;
  formDataChange: formDataPartQSelCallback;
}

export default class FSA2211FormPartQSel extends React.Component<FSA2211FormPartQSelProps, FSA2211PartQSel> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partQSelData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartQSelProps) {
    if (previousProps.partQSelData !== this.props.partQSelData) {
      this.setState(this.props.partQSelData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartQSel {
    return {
      selLoanNarrativeLess: false,
      selLoanNarrativeMore: false,
      selBalanceSheetLess: false,
      selBalanceSheetMore: false,
      selCashFlowBudgetLess: false,
      selCashFlowBudgetMore: false,
      selLocationOfFarmedLandLess: false,
      selLocationOfFarmedLandMore: false,
      selCreditReportLess: false,
      selCreditReportMore: false,
      selProposedLoanAgreementMore: false,
      selVerificationOfDebtsOver5000More: false,
      selVerificationOfNonFarmAndOtherIncomeMore: false,
      selThreeYearsOfFinancialHistoryMore: false,
      selThreeYearsOfProductionHistoryMore: false,
      selEntityInformationLess: false,
      selEntityInformationMore: false,
      selEnvironmentalInformationLess: false,
      selEnvironmentalInformationMore: false,
      selConstructionDevelopmentPlansMore: false,
      selTransitionPlanLess: false,
      selTransitionPlanMore: false,
      selConservationForestStewardshipManagementPlanLess: false,
      selConservationForestStewardshipManagementPlanMore: false,
    } as FSA2211PartQSel
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART Q &nbsp;&nbsp; SUPPORTING INFORMATION <em>(FOR SEL/CLP LENDERS COMPLETING NON-EZ GUARANTEED LOANS</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>1. Please attach the following:</Col>
                </Row>
                <Row className="align-center subsection-heading standard-text mt-2">
                  <Col className="white-border" md={8}>
                    <Row className="mt-2"><Col>SEL</Col></Row>
                    <Row><Col><em>(Standard Eligible Lender)</em></Col></Row>
                    <Row className="mb-2"><Col>Submission Requirements</Col></Row>
                  </Col>
                  <Col md={2}>
                    <Row><Col>&nbsp;</Col></Row>
                    <Row><Col>For Loans</Col></Row>
                    <Row><Col>$125,000 or Less</Col></Row>
                  </Col>
                  <Col className="white-border" md={2}>
                    <Row><Col>&nbsp;</Col></Row>
                    <Row><Col>For Loans</Col></Row>
                    <Row><Col>More Than $125,000</Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Loan Narrative</Col>
                  <Col md={2} className="align-center"><Form.Check name="selLoanNarrativeLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selLoanNarrativeLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selLoanNarrativeMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selLoanNarrativeMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Balance Sheet</Col>
                  <Col md={2} className="align-center"><Form.Check name="selBalanceSheetLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selBalanceSheetLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selBalanceSheetMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selBalanceSheetMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Cash Flow Budge</Col>
                  <Col md={2} className="align-center"><Form.Check name="selCashFlowBudgetLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selCashFlowBudgetLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selCashFlowBudgetMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selCashFlowBudgetMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Location of Farmed Land</Col>
                  <Col md={2} className="align-center"><Form.Check name="selLocationOfFarmedLandLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selLocationOfFarmedLandLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selLocationOfFarmedLandMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selLocationOfFarmedLandMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Credit Report</Col>
                  <Col md={2} className="align-center"><Form.Check name="selCreditReportLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selCreditReportLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selCreditReportMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selCreditReportMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Proposed Loan Agreement</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center"><Form.Check name="selProposedLoanAgreementMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selProposedLoanAgreementMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Verification of Debts over $5,000</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center"><Form.Check name="selVerificationOfDebtsOver5000More" onChange={this.handleInputChange.bind(this)} checked={this.state.selVerificationOfDebtsOver5000More} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Verification of Non-Farm & Other Income</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center"><Form.Check name="selVerificationOfNonFarmAndOtherIncomeMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selVerificationOfNonFarmAndOtherIncomeMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>3 Years of Financial History</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center"><Form.Check name="selThreeYearsOfFinancialHistoryMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selThreeYearsOfFinancialHistoryMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>3 Years of Production History</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center"><Form.Check name="selThreeYearsOfProductionHistoryMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selThreeYearsOfProductionHistoryMore} /></Col>
                </Row>
                <Row><Col className="standard-text instruction-heading">If Applicable, include the information below:</Col></Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Entity Information (including a balance sheet for <span className="standard-bold">each</span> member)</Col>
                  <Col md={2} className="align-center"><Form.Check name="selEntityInformationLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selEntityInformationLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selEntityInformationMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selEntityInformationMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Environmental Information</Col>
                  <Col md={2} className="align-center"><Form.Check name="selEnvironmentalInformationLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selEnvironmentalInformationLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selEnvironmentalInformationMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selEnvironmentalInformationMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Construction/Development Plans</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center"><Form.Check name="selConstructionDevelopmentPlansMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selConstructionDevelopmentPlansMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}><span className="standard-bold">FOR CL Loans:</span> Transition Plan</Col>
                  <Col md={2} className="align-center"><Form.Check name="selTransitionPlanLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selTransitionPlanLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selTransitionPlanMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selTransitionPlanMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}><span className="standard-bold">FOR CL Loans:</span> Conservation or Forest Stewardship Management Plan</Col>
                  <Col md={2} className="align-center"><Form.Check name="selConservationForestStewardshipManagementPlanLess" onChange={this.handleInputChange.bind(this)} checked={this.state.selConservationForestStewardshipManagementPlanLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="selConservationForestStewardshipManagementPlanMore" onChange={this.handleInputChange.bind(this)} checked={this.state.selConservationForestStewardshipManagementPlanMore} /></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}