import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import SecondaryButton from "shared/components/SecondaryButton";
import PrimaryButton from "shared/components/PrimaryButton";
import DangerButton from "shared/components/DangerButton";
import "./AddDocumentModal.css"
import 'react-calendar/dist/Calendar.css';
import DropZone from "../../../components/dropZone/DropZone";
import AddDocumentsRequest from "models/addDocumentsRequest/AddDocumentsRequest";
import DocumentData from "models/documentData/DocumentData";
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import DocumentRequestSection from "shared/components/documentRequestSection";
import numberUtility from "utilities/NumberUtility";
import { DocumentType } from "shared/Enums";

type callback = () => void;
type documentsCallBack = (documentsRequest: AddDocumentsRequest) => void;

interface AddDocumentModalProps {
  showModal: boolean;
  modalAction?: string;
  documentName?: string;
  customerId: number;
  loanId: number;
  defaultType?: DocumentType;
  defaultYear?: string;
  isLoading: boolean;
  documentListChange: documentsCallBack;
  onModalCancel: callback;
  onModalConfirm: callback;
}

interface AddDocumentModalState {
  documentsRequest: AddDocumentsRequest;
  isValid: boolean;
}

export default class AddDocumentModal extends React.Component<AddDocumentModalProps, AddDocumentModalState> {

  constructor(props: any) {
    super(props);

    this.state = {
      documentsRequest: this.createDefaultDocumentData(),
      isValid: false
    }
  }

  componentDidUpdate(previousProps: AddDocumentModalProps): void {
    if (previousProps.defaultType !== this.props.defaultType || previousProps.defaultYear !== this.props.defaultYear) {
      let documentsRequest: AddDocumentsRequest = { ...this.state.documentsRequest };
      documentsRequest.data.type = this.props.defaultType;
      documentsRequest.data.year = this.props.defaultYear;
      this.updateRequest(documentsRequest.data);
    }
  }

  createDefaultDocumentData(): AddDocumentsRequest {
    let defaultDocumentData = {
      documents: [],
      data: {
        note: "",
        customerAccountId: this.props.customerId,
        loanId: this.props.loanId,
        type: this.props.defaultType,
        year: this.props.defaultYear
      } as DocumentData
    };

    return defaultDocumentData;
  }

  selectedFilesChange(files: File[]): void {
    var updatedRequest = this.state.documentsRequest;
    updatedRequest.documents = files;
    this.setState({ documentsRequest: updatedRequest }, () => {
      this.updateValidation();
    });
    this.props.documentListChange(updatedRequest);
  }

  updateValidation(): void {
    let isValid = false;
    isValid = (
      this.state.documentsRequest !== undefined && this.state.documentsRequest.documents.length > 0 &&
      this.state.documentsRequest.data.type !== undefined && numberUtility.isNumber(Number(this.state.documentsRequest.data.type)) &&
      this.state.documentsRequest.data.year !== undefined && numberUtility.isNumber(Number(this.state.documentsRequest.data.year))
    )
    this.setState({ isValid: isValid })
  }

  getDocumentModalTitle(): string {
    switch (this.props.modalAction) {
      case 'create':
      case 'deny loan':
        return 'Add Documents';

      case 'delete':
        return 'Remove Document';

      case 'download':
        return 'Download Document';

      default:
        return '';
    }
  }

  getModalActionButton(): JSX.Element {
    switch (this.props.modalAction) {
      case 'create':
      case 'deny loan':
        return <PrimaryButton
          disabled={this.disableButton()}
          buttonText={'Add Document'}
          onClick={this.onModalConfirmAction.bind(this)} />;
      case 'delete':
        return <DangerButton buttonText={'Remove Document'} onClick={this.props.onModalConfirm.bind(this)} />;

      case 'download':
        return <PrimaryButton buttonText={'Download Document'} onClick={this.props.onModalConfirm.bind(this)} />;

      default:
        return <></>;
    }
  }

  disableButton(): boolean {
    return !this.state.isValid;
  }

  resetModalState(): void {
    var resetRequest = this.createDefaultDocumentData();
    this.setState({ documentsRequest: resetRequest }, () => {
      this.updateValidation();
    });
  }

  onModalConfirmAction(): void {
    this.props.onModalConfirm();
    this.resetModalState()
  }

  onModalCancelAction(): void {
    this.resetModalState();
    this.props.onModalCancel();
  }

  updateRequest(data: DocumentData): void {
    this.setState({
      documentsRequest: {
        ...this.state.documentsRequest,
        data: data
      }
    }, () => {
      this.updateValidation();
      this.props.documentListChange(this.state.documentsRequest);
    });
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.onModalCancelAction.bind(this)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.getDocumentModalTitle()} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              {this.props.modalAction === 'delete' &&
                (<>
                  <Form.Text>Are you sure you would like to delete the following?</Form.Text>
                  <Form.Text>{this.props.documentName}</Form.Text>
                </>)
              }
              {this.props.modalAction === 'download' &&
                (<>
                  <Form.Text>Would like to download the following?</Form.Text>
                  <Form.Text>{this.props.documentName}</Form.Text>
                </>)
              }
              {(this.props.modalAction === 'create' || this.props.modalAction === 'deny loan') &&
                (<>
                  <DocumentRequestSection
                    documentsRequestData={this.state.documentsRequest.data}
                    hideNotes={true}
                    updateRequest={this.updateRequest.bind(this)} />
                  {this.props.modalAction === 'deny loan' &&
                    <Form.Text>Please upload a Regulation B form to continue with the loan denial.</Form.Text>
                  }
                  <DropZone
                    fileListChange={this.selectedFilesChange.bind(this)} />
                </>)
              }
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton buttonText={'Close'} onClick={this.onModalCancelAction.bind(this)} />
          {this.getModalActionButton()}
        </Modal.Footer>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'light'}
            animation={'border'}
          />
        }
      </Modal>
    );
  }
}