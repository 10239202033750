import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../../utilities/AppInsights";
import { AppState } from '../../redux/store/store';
import { toggleLoading } from '../../redux/actions/system/actions';
import { getLoanApplications } from '../../redux/actions/loan/actions';
import { getLoanOfficers } from '../../redux/actions/bank/actions';
import { connect, ConnectedProps } from 'react-redux';
import LoadingSpinner from "../../shared/components/spinner/LoadingSpinner";
import PagedList from "../../models/accessors/pagedList";
import LoanApplication from "../../models/loan/loanApplication";
import loanApplicationUtility from "../../utilities/LoanApplicationUtility";
import Container from "react-bootstrap/esm/Container";
import SelectOption from "../../shared/models/selectOption";
import "react-datepicker/dist/react-datepicker.css";
import "./LoanListSection.css"
import BankEmployee from "../../models/bank/bankEmployee";
import LoansListFilters from "./filters/LoansListFilters";
import LoansListResults from "./results/LoansListResults";
import PaginationComponent from "../../shared/components/pagination/PaginationComponent";
import bankEmployeeUtility from "../../utilities/BankEmployeeUtility";
import LoanApplicationFilterCriteria from "models/accessors/LoanApplicationFilterCriteria";

type listType = 'active' | 'closed'

const mapState = (state: AppState) => {
  const { system, loans, bank } = state;
  const { isLoading } = system;
  const { pagedLoanApplications } = loans;
  const { loanOfficers } = bank;
  return {
    isLoading,
    pagedLoanApplications,
    loanOfficers 
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  getLoanApplications: (filterCriteria: LoanApplicationFilterCriteria) => getLoanApplications(filterCriteria),
  getLoanOfficers: () => getLoanOfficers()
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {loanListType: listType, route: any};

interface LoanListSectionState {
  pagedLoanApplications: PagedList<LoanApplication>;
  loanTypeFilter: SelectOption[];
  scorecardLevelFilter: SelectOption[];
  loanStatusFilter: SelectOption[];
  loanOfficerOptions: SelectOption[];
  loanOfficerFilter: SelectOption[];
  submittedStartDateFilter?: Date;
  submittedEndDateFilter?: Date;
  referenceNumberFilter: string;
  noResultText: string
}

class LoanListSection extends React.Component<Props, LoanListSectionState> {
  constructor(props: any) {
    super(props);

    let noResultText = "";
    if(this.props.loanListType === 'active'){
      noResultText = "Active"
    } else {
      noResultText = "Closed";
    }

    this.state = {
      pagedLoanApplications: {} as PagedList<LoanApplication>,
      loanTypeFilter: [],
      scorecardLevelFilter: [],
      loanStatusFilter: [],
      loanOfficerOptions: [],
      loanOfficerFilter: [],
      referenceNumberFilter: "",
      noResultText: noResultText
    }

  }

  componentDidMount() {
    this.getLoanList(1);
    this.props.getLoanOfficers();
  }

  componentDidUpdate(previousProps: Props) {
    if (previousProps.pagedLoanApplications !== this.props.pagedLoanApplications) {
      this.setState({ pagedLoanApplications: this.props.pagedLoanApplications });
    }

    if (previousProps.loanOfficers !== this.props.loanOfficers) {
      this.mapLoanOfficerOptions();
      
    }
  }

  getLoanList(pageNumber: number) {
    if (pageNumber === 0) pageNumber = 1;
    this.props.toggleLoading(true);

    this.props.getLoanApplications({
      loanListType: this.props.loanListType,
      pageNumber: pageNumber,
      loanTypeFilter: this.state.loanTypeFilter.map(f => +f.value),
      scorecardLevelFilter: this.state.scorecardLevelFilter.map(f => +f.value),
      loanStatusFilter: this.state.loanStatusFilter.map(f => +f.value),
      loanOfficerFilter: this.state.loanOfficerFilter.map(f => +f.value),
      submittedReferenceNumberFilter: this.state.referenceNumberFilter,
      submittedStartDateFilter: this.state.submittedStartDateFilter, 
      submittedEndDateFilter: this.state.submittedEndDateFilter
    });

  }

  mapLoanOfficerOptions(): void {
    var options: SelectOption[] = this.props.loanOfficers.map((o: BankEmployee) => {
      return {
        value: o.id,
        label: bankEmployeeUtility.GetBankEmployeeName(o)
      } as SelectOption;
    });

    this.setState({ loanOfficerOptions: options });
  }

  // State Setters
  // When we modify a filter, we need to reset the page filter to 1
  setLoanTypeFilter(selectedOptions: any): void {
    let pagedLoanApplications: PagedList<LoanApplication> = {...this.state.pagedLoanApplications};
    pagedLoanApplications.currentPage = 1;
    this.setState({loanTypeFilter: selectedOptions as SelectOption[], pagedLoanApplications: pagedLoanApplications},() => this.getLoanList(1));
  }

  setScorecardLevelFilter(selectedOptions: any): void {
    let pagedLoanApplications: PagedList<LoanApplication> = {...this.state.pagedLoanApplications};
    pagedLoanApplications.currentPage = 1;
    this.setState({scorecardLevelFilter: selectedOptions as SelectOption[], pagedLoanApplications: pagedLoanApplications},() => this.getLoanList(1));
  }

  setLoanStatusFilter(selectedOptions: any): void {
    let pagedLoanApplications: PagedList<LoanApplication> = {...this.state.pagedLoanApplications};
    pagedLoanApplications.currentPage = 1;
    this.setState({loanStatusFilter: selectedOptions as SelectOption[], pagedLoanApplications: pagedLoanApplications},() => this.getLoanList(1));
  }

  setLoanOfficerFilter(selectedOptions: any): void {
    let pagedLoanApplications: PagedList<LoanApplication> = {...this.state.pagedLoanApplications};
    pagedLoanApplications.currentPage = 1;
    this.setState({loanOfficerFilter: selectedOptions as SelectOption[], pagedLoanApplications: pagedLoanApplications},() => this.getLoanList(1));
  }

  setSubmittedStartDateFilter(selectedDate: Date): void {
    let pagedLoanApplications: PagedList<LoanApplication> = {...this.state.pagedLoanApplications};
    pagedLoanApplications.currentPage = 1;
    this.setState({submittedStartDateFilter: selectedDate, pagedLoanApplications: pagedLoanApplications},() => this.getLoanList(1))
  }

  setSubmittedEndDateFilter(selectedDate: Date): void {
    let pagedLoanApplications: PagedList<LoanApplication> = {...this.state.pagedLoanApplications};
    pagedLoanApplications.currentPage = 1;
    this.setState({submittedEndDateFilter: selectedDate, pagedLoanApplications: pagedLoanApplications},() => this.getLoanList(1))
  }

  setReferenceNumberFilter(referenceNumber: React.ChangeEvent<HTMLInputElement>): void {
    const value = referenceNumber.target.value;
    this.setState({referenceNumberFilter: value});
    if(value.length > 1 || value.length === 0){
    let pagedLoanApplications: PagedList<LoanApplication> = {...this.state.pagedLoanApplications};
    pagedLoanApplications.currentPage = 1;  
    this.setState({pagedLoanApplications: pagedLoanApplications},() => this.getLoanList(1))
    }
  }

  getLoanStatusOptions(): SelectOption[]{
    if(this.props.loanListType === 'active'){
      return loanApplicationUtility.GetActiveLoanStatusOptions();
    }else{
      return loanApplicationUtility.GetClosedLoanStatusOptions();
    }
  }

  render() {
    return (
      <>
        <Container fluid>
          <LoansListFilters 
            loanTypeFilter={this.state.loanTypeFilter}
            loanTypeFilterCallback={this.setLoanTypeFilter.bind(this)}
            scorecardLevelFilter={this.state.scorecardLevelFilter}
            scorecardLevelFilterCallback={this.setScorecardLevelFilter.bind(this)}
            loanStatusOptions={this.getLoanStatusOptions()}
            loanStatusFilter={this.state.loanStatusFilter}
            loanStatusFilterCallback={this.setLoanStatusFilter.bind(this)}
            loanOfficerOptions={this.state.loanOfficerOptions}
            loanOfficerFilter={this.state.loanOfficerFilter}
            loanOfficerFilterCallback={this.setLoanOfficerFilter.bind(this)}
            submittedStartDateFilter={this.state.submittedStartDateFilter}
            submittedStartDateFilterCallback={this.setSubmittedStartDateFilter.bind(this)}
            submittedEndDateFilter={this.state.submittedEndDateFilter}
            submittedEndDateFilterCallback={this.setSubmittedEndDateFilter.bind(this)}
            referenceNumber={this.state.referenceNumberFilter}
            referenceNumberFilterCallback={this.setReferenceNumberFilter.bind(this)}
            resetFilterCallback={this.getLoanList.bind(this, this.state.pagedLoanApplications.currentPage)}
          />
          <div className="pb-30">
            <LoansListResults 
              pagedLoanApplications={this.state.pagedLoanApplications}
              route={this.props.route}
              noResultsText={'No ' + this.props.loanListType +' loans'}
            />
          </div>
          <div className="fixed-bottom">
            <PaginationComponent 
              items={this.state.pagedLoanApplications}
              changePage={this.getLoanList.bind(this)}
            />
          </div>
          {this.props.isLoading &&
            <LoadingSpinner
              variant={'dark'}
              animation={'border'}
            />
          }
        </Container>
      </>
    );
  }
}

export default withAITracking(reactPlugin, connector(LoanListSection));
