import React from "react";
import Form from "react-bootstrap/esm/Form";
import 'react-calendar/dist/Calendar.css';
import Col from "react-bootstrap/esm/Col";
import { DocumentTypeMapper } from "../Enums";
import DocumentData from "../../models/documentData/DocumentData";
import categoryUtility from "utilities/CategoryUtility";
import DocumentRequestWithGuid from "models/documentData/DocumentRequestWithGuid";


interface DocumentRequestSectionProps {
  documentsRequestData: DocumentData | DocumentRequestWithGuid;
  index?: number;
  hideNotes?: boolean;
  updateRequest(request: DocumentData | DocumentRequestWithGuid, index?: number): void;
}

interface DocumentRequestSectionState {
}

export default class DocumentRequestSection extends React.Component<DocumentRequestSectionProps, DocumentRequestSectionState> {
  years: number[] = [];
  yearsPast = 25;
  categoryNumbers = categoryUtility.categoryNumbers();

  constructor(props: any) {
    super(props);

    this.state = {
      documentsRequest: {
        documents: [],
        data: this.props.documentsRequestData as DocumentData
      },
    }

    let currentYear = new Date().getFullYear();
    for (let i = 0; i < this.yearsPast; i++) {
      this.years.push(currentYear - i)
    }
  }

  setDocumentType(type: number): void {
    const data = { ...this.props.documentsRequestData, type };
    this.props.updateRequest(data, this.props.index);
  }

  setDocumentYear(year: number): void {
    const data = { ...this.props.documentsRequestData, year: year.toString() };
    this.props.updateRequest(data, this.props.index);
  }

  setDocumentNote(note: string): void {
    const data = { ...this.props.documentsRequestData, note };
    this.props.updateRequest(data, this.props.index);
  }

  render() {
    return (
      <>
        <Form.Row>
          <Col>
            <Form.Control as="select" aria-label="Default select example" onChange={(event) => this.setDocumentType(+event.target.value)}>
              <option>Document Type</option>
              {this.categoryNumbers.map(
                (type: number, i: number) => {
                  return (<option key={`cat-number-${i}`} value={type} selected={this.props.documentsRequestData.type === type}>{DocumentTypeMapper[type]}</option>);
                })
              }
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              as="select"
              aria-label="Default select"
              onChange={(event) => this.setDocumentYear(+event.target.value)}>
              <option>Document Year</option>
              {this.years.map((year: number, i: number) => {
                return (<option key={`doc-year-${i}`} value={year} selected={this.props.documentsRequestData.year === year.toString()}>{year.toString()}</option>)
              }
              )}
            </Form.Control>
          </Col>
        </Form.Row>
        {!this.props.hideNotes && (
          <>
            <Form.Row>
              <Col>
                Note:
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Control as="textarea" onChange={(event) => this.setDocumentNote(event.target.value)}></Form.Control>
              </Col>
            </Form.Row>
          </>
        )}
      </>
    );
  }

}
