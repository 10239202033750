import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import SecondaryButton from "shared/components/SecondaryButton";
import PrimaryButton from "shared/components/PrimaryButton";
import "./EditProfileModal.css"
import FormLabel from "react-bootstrap/esm/FormLabel";
import BankEmployee from "../../../models/bank/bankEmployee";
import { AppState } from '../../../redux/store/store';
import { connect, ConnectedProps } from 'react-redux';
import { toggleAPIRequestSuccess, toggleLoading, getLoggedInAccountInfo, updateLoggedInEmployeeInfo } from '../../../redux/actions/system/actions';
import { SecurityRoleEnum, Subdomain } from "shared/Enums";
import Can from "auth/Can";
import SecurityRole from "models/account/securityRole";
import { createBankEmployee, getBankSecurityRoles, updateBankEmployee } from "redux/actions/bank/actions";
import LoggedInAccount from "models/account/loggedInAccount";

const mapState = (state: AppState) => {
  const { system, bank } = state;
  const { isLoading, apiRequestSuccess, loggedInAccount, subdomain } = system;
  const { bankSecurityRoles } = bank;
  return {
    isLoading,
    apiRequestSuccess,
    loggedInAccount,
    bankSecurityRoles,
    subdomain
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  toggleAPIRequestSuccess: (apiRequestSuccess: boolean) => toggleAPIRequestSuccess(apiRequestSuccess),
  getLoggedInEmployeeInfo: () => getLoggedInAccountInfo(),
  updateLoggedInEmployeeInfo: (bankEmployee: BankEmployee) => updateLoggedInEmployeeInfo(bankEmployee),
  getBankSecurityRoles: () => getBankSecurityRoles(),
  updateBankEmployee: (bankEmployee: BankEmployee) => updateBankEmployee(bankEmployee),
  createBankEmployee: (bankEmployee: BankEmployee) => createBankEmployee(bankEmployee)
}

type callback = () => void;
type newUserCallback = (bankEmployee: BankEmployee) => void;
type SourcePage = 'header' | 'users' | 'bank';
type UserAction = 'create' | 'edit';

interface EditProfileModalProps {
  showModal: boolean;
  sourcePage: SourcePage;
  action: UserAction;
  showSecurityRole?: boolean;
  selectedBankEmployee?: BankEmployee;
  onModalCancel: callback;
  newUserCallback?: newUserCallback;

}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & EditProfileModalProps;

interface EditProfileModalState {
  bankEmployee: BankEmployee | LoggedInAccount;
  bankSecurityRoles: SecurityRole[];
}

class EditProfileModal extends React.Component<Props, EditProfileModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      bankEmployee: {} as BankEmployee | LoggedInAccount,
      bankSecurityRoles: []
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<EditProfileModalState>, snapshot?: any): void {
    if (prevProps.loggedInAccount !== this.props.loggedInAccount) {
      if (this.props.sourcePage === 'header') {
        this.setState({ bankEmployee: this.props.loggedInAccount });
      }
    }

    if (prevProps.bankSecurityRoles !== this.props.bankSecurityRoles) {
      this.setState({ bankSecurityRoles: this.props.bankSecurityRoles });
    }

    if (prevProps.apiRequestSuccess !== this.props.apiRequestSuccess && this.props.apiRequestSuccess) {
      this.props.onModalCancel();
      this.props.toggleAPIRequestSuccess(false);
    }
  }

  loadProfile(): void {
    switch (this.props.sourcePage) {
      case 'header':
        this.props.toggleLoading(true);
        this.props.getLoggedInEmployeeInfo();
        break;
      case 'users':
      case 'bank':
        this.props.toggleLoading(true);
        this.props.getBankSecurityRoles();
        this.setState({ bankEmployee: { ...this.props.selectedBankEmployee ?? {} as BankEmployee } })
        break;
      default:
        this.props.toggleLoading(true);
        this.props.getLoggedInEmployeeInfo();
        break;
    }
  }

  resetForm(): void {
    this.setState({ bankEmployee: {} as BankEmployee });
    this.props.onModalCancel();
  }

  setEmailState(event: any): void {
    let employeeInfo: BankEmployee = { ...this.state.bankEmployee as BankEmployee };
    employeeInfo.email = event.target.value;
    this.setState({ bankEmployee: employeeInfo });
  }

  setFirstNameState(event: any): void {
    let employeeInfo: BankEmployee = { ...this.state.bankEmployee as BankEmployee };
    employeeInfo.firstName = event.target.value;
    this.setState({ bankEmployee: employeeInfo });
  }

  setLastNameState(event: any): void {
    let employeeInfo: BankEmployee = { ...this.state.bankEmployee as BankEmployee };
    employeeInfo.lastName = event.target.value;
    this.setState({ bankEmployee: employeeInfo });
  }

  setPhoneNumberState(event: any): void {
    let employeeInfo: LoggedInAccount = { ...this.state.bankEmployee as LoggedInAccount };
    employeeInfo.phoneNumber = event.target.value;
    this.setState({ bankEmployee: employeeInfo });
  }

  setSecurityRoleState(event: any): void {
    let employeeInfo: BankEmployee = { ...this.state.bankEmployee as BankEmployee };
    let selectedSecurityRole: SecurityRole = this.props.bankSecurityRoles.find((bsr: SecurityRole) => bsr.id === +event.target.value)
    employeeInfo.securityRole = selectedSecurityRole;
    this.setState({ bankEmployee: employeeInfo });
  }

  updateProfile(): void {
    if (this.validateData()) {
      this.props.toggleLoading(true);

      if (this.props.sourcePage === 'header') this.props.updateLoggedInEmployeeInfo(this.state.bankEmployee as BankEmployee);

      if (this.props.sourcePage === 'users') {
        if (this.props.action == 'create') {
          this.props.createBankEmployee(this.state.bankEmployee as BankEmployee);
        } else {
          this.props.updateBankEmployee(this.state.bankEmployee as BankEmployee);
        }
      }

      if (this.props.sourcePage === 'bank' && this.props.newUserCallback != undefined) {
        this.props.toggleLoading(false);
        this.props.newUserCallback(this.state.bankEmployee as BankEmployee);
      }
    }
  }

  validateData(): boolean {
    let validEmail, validFirstName, validLastName, validSecurityRole: boolean;

    validEmail = this.state.bankEmployee.email != undefined && this.state.bankEmployee.email.length > 0;

    validFirstName = this.state.bankEmployee.firstName !== undefined && this.state.bankEmployee.firstName.length > 0;

    validLastName = this.state.bankEmployee.lastName !== undefined && this.state.bankEmployee.lastName.length > 0;

    validSecurityRole = this.state.bankEmployee.securityRole != undefined && this.state.bankEmployee.securityRole.id > 0
      && (this.props.sourcePage !== 'bank' || this.state.bankEmployee.securityRole.id === SecurityRoleEnum.Manager);

    return validEmail && validFirstName && validLastName && validSecurityRole;
  }

  render() {
    return (
      <Modal show={this.props.showModal} onShow={this.loadProfile.bind(this)} onHide={this.resetForm.bind(this)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.props.action === 'create' ? 'Create Profile' : 'Update Profile'}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormLabel>Email</FormLabel>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Email"
                aria-label="Email"
                type="text"
                readOnly={this.props.action !== 'create'}
                value={this.state.bankEmployee.email}
                onChange={this.setEmailState.bind(this)}
              />
            </InputGroup>

            <FormLabel>First Name</FormLabel>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="First Name"
                aria-label="First Name"
                value={this.state.bankEmployee.firstName}
                onChange={this.setFirstNameState.bind(this)}
              />
            </InputGroup>

            <FormLabel>Last Name</FormLabel>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Last Name"
                aria-label="Last Name"
                value={this.state.bankEmployee.lastName}
                onChange={this.setLastNameState.bind(this)}
              />
            </InputGroup>

            {this.props.subdomain === Subdomain.Dealer &&
              <>
                <FormLabel>Phone Number</FormLabel>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                    value={this.state.bankEmployee.phoneNumber}
                    onChange={this.setPhoneNumberState.bind(this)}
                  />
                </InputGroup>
              </>
            }

            {this.props.showSecurityRole &&
              <>
                <FormLabel>User Role</FormLabel>
                <InputGroup className="mb-3">
                  <FormControl
                    as="select"
                    className="mr-sm-2"
                    value={this.state.bankEmployee.securityRole?.id}
                    onChange={this.setSecurityRoleState.bind(this)}
                  >
                    {this.props.action === 'create' && (
                      <option value={-1}>Select a role...</option>
                    )}
                    {this.state.bankSecurityRoles.map((bsr: SecurityRole, index: number) => {
                      return (
                        <>
                          {(bsr.id === SecurityRoleEnum.Admin) ?
                            <Can
                              perform="Admin"
                              yes={() => (
                                <>
                                  {this.props.sourcePage === 'bank' ?
                                    null
                                    :
                                    <option value={bsr.id}>{bsr.name}</option>
                                  }
                                </>
                              )}
                              no={() => null} />
                            :
                            <option value={bsr.id}>{bsr.name}</option>
                          }
                        </>
                      )
                    })}
                  </FormControl>
                </InputGroup>
              </>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton buttonText={'Cancel'} onClick={this.resetForm.bind(this)} />
          <PrimaryButton
            buttonText={`${this.props.action === 'create' ? 'Create Profile' : 'Update Profile'}`}
            onClick={this.updateProfile.bind(this)}
            disabled={!this.validateData()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connector(EditProfileModal);