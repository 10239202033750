import Dealer from "models/dealerPurchase/dealer";
import axiosRequestUtility from "../utilities/AxiosRequestUtility";
import DealerSalesman from "models/dealerPurchase/dealerSalesman";
import publicAxiosRequestUtility from "utilities/PublicAxiosRequestUtility";
import CreateDealerSalesmanAccount from "models/account/dealer/createDealerAccount";

class DealerService {
  public GetDealersPublic(): Promise<Dealer[]> {
    return publicAxiosRequestUtility.Request({
      method: 'GET',
      url: `GetDealersPublic`,
    })
      .then(response => {
        return response.data as Dealer[]
      })
      .catch(error => {
        console.log(error);
        alert('Error loading dealers. Please try again.')
        return [];
      })
  }

  public GetDealers(): Promise<Dealer[]> {
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `GetDealers`,
    })
      .then(response => {
        return response.data as Dealer[]
      })
      .catch(error => {
        console.log(error);
        alert('Error loading dealers. Please try again.')
        return [];
      })
  }

  public GetSalesmanInformation(salesmanId: number): Promise<DealerSalesman> {
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `GetSalesmanInformation`,
      params: {
        id: salesmanId
      }
    })
      .then(response => {
        return response.data as DealerSalesman
      })
      .catch(error => {
        console.log(error);
        alert('Error loading salesman information. Please try again.')
        return {} as DealerSalesman;
      })
  }

  public CreateDealerSalesmanAccount(dealerSalesman: CreateDealerSalesmanAccount): Promise<number> {
    return publicAxiosRequestUtility.Request({
      method: 'POST',
      url: `CreateDealerSalesmanAccount`,
      data: dealerSalesman
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return -1;
      })
  }

  public AddNewDealer(dealer: Dealer): Promise<Dealer> {
    return axiosRequestUtility.Request({
      method: 'POST',
      url: `AddDealer`,
      data: dealer
    })
      .then(response => {
        return response.data as Dealer;
      })
      .catch(error => {
        console.log(error);
        alert('Error adding a new dealer. Please try again.')
        return {} as Dealer;
      })
  }

  public UpdateDealer(dealer: Dealer): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'PUT',
      url: `UpdateDealer`,
      data: dealer
    })
      .then(response => {
        return true;
      })
      .catch(error => {
        console.log(error);
        alert('Error updating dealer information. Please try again.')
        return false;
      })
  }

  public AddNewDealerSalesman(dealerSalesman: DealerSalesman): Promise<DealerSalesman> {
    return axiosRequestUtility.Request({
      method: 'POST',
      url: `AddDealerSalesman`,
      data: dealerSalesman
    })
      .then(response => {
        return response.data as DealerSalesman;
      })
      .catch(error => {
        console.log(error);
        alert('Error adding a new dealer salesman. Please try again.')
        return {} as DealerSalesman;
      })
  }

  public UpdateDealerSalesman(dealerSalesman: DealerSalesman): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'PUT',
      url: `UpdateDealerSalesman`,
      data: dealerSalesman
    })
      .then(response => {
        return true;
      })
      .catch(error => {
        console.log(error);
        alert('Error updating dealer salesman information. Please try again.')
        return false;
      })
  }
}

const dealerService = new DealerService();
export default dealerService;