class HandleInputChangeUtility {
    public GetNameAndValue(event: any): NameAndValue {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var result: NameAndValue = {
            name: name,
            value: value,
        }
        return result;
    }
}

const handleInputChangeUtility = new HandleInputChangeUtility();
export default handleInputChangeUtility;

export interface NameAndValue {
    name: string;
    value: string;
}
