import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import SecondaryButton from "shared/components/SecondaryButton";
import "./IdDocumentModal.css"
import 'react-calendar/dist/Calendar.css';
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import DropZone from "components/dropZone/DropZone";
import IdDocumentUpload from "models/account/customer/idDocumentUpload/idDocumentUpload";
import { IdDocumentSde } from "shared/Enums";
import PrimaryButton from "shared/components/PrimaryButton";

type voidCallback = () => void;
type documentCallback = (documentUpload: IdDocumentUpload) => void;
type modalAction = 'view' | 'upload';

interface IdDocumentModalProps {
  showModal: boolean;
  image: string;
  isLoading: boolean;
  modalAction: modalAction;
  customerAccountId: number;
  onModalCancel: voidCallback;
  onModalConfirm: documentCallback;
}

interface IdDocumentModalState {
  documentUpload: IdDocumentUpload;
  frontPreviewBlob: string;
  backPreviewBlob: string;
}

const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/x-icon'];

export default class IdDocumentModal extends React.Component<IdDocumentModalProps, IdDocumentModalState> {

  constructor(props: any) {
    super(props);

    this.state = {
      documentUpload: {
        customerAccountId: this.props.customerAccountId,
        documentData: []
      } as IdDocumentUpload,
      frontPreviewBlob: '',
      backPreviewBlob: ''
    }
  }

  componentDidUpdate(previousProps: IdDocumentModalProps): void {

  }

  resetModalState(): void {

  }

  getModalTitle() {
    switch (this.props.modalAction) {
      case 'view':
        return 'Verification Document Preview'

      case 'upload':
        return 'Verification Document Upload'

      default:
        break;
    }
  }

  selectedFilesChange(documentSide: IdDocumentSde, files: File[]): void {
    let currentDocumentUpload = { ...this.state.documentUpload };
    let frontPreviewBlob = this.state.frontPreviewBlob;
    let backPreviewBlob = this.state.backPreviewBlob;
    let index = currentDocumentUpload.documentData.findIndex(d => d.documentSide === documentSide);

    if (index >= 0) {
      currentDocumentUpload.documentData.splice(index, 1);
    }

    if (files.length > 0) {
      currentDocumentUpload.documentData.push({
        documentSide: documentSide,
        document: files[0]
      });

      if (documentSide === IdDocumentSde.Front) {
        frontPreviewBlob = URL.createObjectURL(files[0]);
      } else {
        backPreviewBlob = URL.createObjectURL(files[0]);
      }
    } else {
      if (documentSide === IdDocumentSde.Front) {
        frontPreviewBlob = '';
      } else {
        backPreviewBlob = '';
      }
    }

    this.setState({ documentUpload: currentDocumentUpload, frontPreviewBlob: frontPreviewBlob, backPreviewBlob: backPreviewBlob });
  }

  onModalConfirmAction(): void {
    this.props.onModalConfirm(this.state.documentUpload);
  }

  onModalCancelAction(): void {
    this.setState({
      documentUpload: {
        customerAccountId: this.props.customerAccountId,
        documentData: []
      } as IdDocumentUpload,
      frontPreviewBlob: '',
      backPreviewBlob: ''
    }, this.props.onModalCancel);
  }

  validateData(): boolean {
    let currentDocumentUpload = { ...this.state.documentUpload };

    let validCustomerAccountId: boolean = currentDocumentUpload.customerAccountId != undefined && currentDocumentUpload.customerAccountId > 0;

    let validFrontPhoto: boolean = currentDocumentUpload.documentData != undefined
      && currentDocumentUpload.documentData.findIndex(d => d.document != undefined && d.documentSide === IdDocumentSde.Front) >= 0;

    let validBackPhoto: boolean = currentDocumentUpload.documentData != undefined
      && currentDocumentUpload.documentData.findIndex(d => d.document != undefined && d.documentSide === IdDocumentSde.Back) >= 0;

    return validCustomerAccountId && validFrontPhoto && validBackPhoto;
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.onModalCancelAction.bind(this)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.getModalTitle()} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.modalAction === 'view' &&
            <div className='center-img'>
              <img alt="" src={this.props.image} width="400" />
            </div>
          }

          {this.props.modalAction === 'upload' &&
            <>
              <div>
                <h3>Front Photo*</h3>
                <div className='center-img'>
                  <img alt="" src={this.state.frontPreviewBlob} width="100" />
                </div>
                <DropZone
                  fileListChange={(files: any) => this.selectedFilesChange(IdDocumentSde.Front, files)}
                  multiple={false}
                  allowedTypes={allowedTypes}
                />
              </div>
              <div style={{ paddingTop: 20 }}>
                <h3>Back Photo*</h3>
                <div className='center-img'>
                  <img alt="" src={this.state.backPreviewBlob} width="100" />
                </div>
                <DropZone
                  fileListChange={(files: any) => this.selectedFilesChange(IdDocumentSde.Back, files)}
                  multiple={false}
                  allowedTypes={allowedTypes}
                />
              </div>
              <div style={{ fontSize: 12, fontStyle: 'italic', paddingTop: 20 }}>
                * Required
              </div>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          {this.props.modalAction === 'upload' &&
            <PrimaryButton
              disabled={!this.validateData()}
              buttonText="Upload"
              onClick={this.onModalConfirmAction.bind(this)}
            />
          }
          <SecondaryButton buttonText={'Close'} onClick={this.onModalCancelAction.bind(this)} />
        </Modal.Footer>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'light'}
            animation={'border'}
          />
        }
      </Modal>
    );
  }
}