import React from "react";
import Pagination from "react-bootstrap/esm/Pagination";
import PagedList from "../../../models/accessors/pagedList";
import "./PaginationComponent.css"

type changePageCallback = (pageNumber: number) => void;

interface PaginationComponentProps {
  items: PagedList<any>;
  changePage: changePageCallback;
}

export default class PaginationComponent extends React.Component<PaginationComponentProps> {
  paginationItems() {
    if (this.props.items.items && this.props.items.items.length > 0) {
      // Array of elements that will be returned to render
      let items = [];

      // Page variables to use throughout
      let currentPage = this.props.items.currentPage;
      let totalPages = this.props.items.totalPages;

      // We will show segments of 5 pages at a time, so we need to figure out which segment we are in based on current page number
      let segmentStart = Math.floor(currentPage / 5) * 5;

      // If we are on the last segment, we only want to show the highest page number
      let paginationMax = (segmentStart + 5) <= totalPages ? (segmentStart + 5) : totalPages;

      // Booleans to show ellipsis
      let showPreviousEllipsis = segmentStart > 1;
      let showEndEllipsis = (segmentStart + 5) < totalPages;


      if (showPreviousEllipsis) {
        items.push(
          <Pagination.Item key={'pagination-first'} onClick={() => this.props.changePage(1)}>
            1
          </Pagination.Item>,
          <Pagination.Ellipsis onClick={() => this.props.changePage(segmentStart - 5)} />
        );
      }

      for (let number = segmentStart === 0 ? 1 : segmentStart; number <= paginationMax; number++) {
        items.push(
          <Pagination.Item key={`pagination-${number}`}
            active={number === currentPage}
            onClick={() => (number !== currentPage) ? this.props.changePage(number) : null}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (showEndEllipsis) {
        items.push(
          <Pagination.Ellipsis onClick={() => this.props.changePage(segmentStart + 5)} />,
          <Pagination.Item key={'pagination-last'} onClick={() => this.props.changePage(totalPages)}>
            {totalPages}
          </Pagination.Item>
        );
      }

      return items;
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="fixed-bottom">
        <div className="pagination justify-content-center">
          <Pagination>
            <Pagination.First disabled={this.props.items.currentPage === 1} onClick={() => this.props.changePage(1)} />
            <Pagination.Prev disabled={this.props.items.currentPage === 1} onClick={() => this.props.changePage(this.props.items.currentPage - 1)} />
            {this.paginationItems()}
            <Pagination.Next disabled={this.props.items.currentPage === this.props.items.totalPages} onClick={() => this.props.changePage(this.props.items.currentPage + 1)} />
            <Pagination.Last disabled={this.props.items.currentPage === this.props.items.totalPages} onClick={() => this.props.changePage(this.props.items.totalPages)} />
          </Pagination>
        </div>
      </div>
    );
  }
}