import React from "react";
import Button from "react-bootstrap/esm/Button";

type callback = () => void;

interface PrimaryButtonProps {
  buttonText: string;
  disabled?: boolean;
  onClick: callback;
}

export default class PrimaryButton extends React.Component<PrimaryButtonProps> {
  render() {
    const disabledPointer = {
      cursor: 'not-allowed'
    }

    const normalPointer = {
      cursor: 'pointer'
    }

    return (
      <Button variant="primary" 
              className="mr-1" 
              onClick={this.props.onClick} 
              disabled={this.props.disabled} style={this.props.disabled ? disabledPointer : normalPointer}
      >
        {this.props.buttonText}
      </Button>
    );
  }
}

