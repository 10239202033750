import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartOCoApplicant from "../../../../models/fsa2211Form/FSA2211PartOCoApplicant";
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartOSection2Callback = (formDataPartPartOSection2: FSA2211PartOCoApplicant) => void;

interface FSA2211FormPartOSection2Props {
  partOSectionTwoData?: FSA2211PartOCoApplicant;
  formDataChange: formDataPartOSection2Callback;
}

export default class FSA2211FormPartOSection2 extends React.Component<FSA2211FormPartOSection2Props, FSA2211PartOCoApplicant> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partOSectionTwoData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartOSection2Props) {
    if (previousProps.partOSectionTwoData !== this.props.partOSectionTwoData) {
      this.setState(this.props.partOSectionTwoData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartOCoApplicant {
    return {
      coApplicantFullName: "",
      coApplicantSSNOrTaxId: "",
      coApplicantBirthDate: "",
      coApplicantAddress: "",
      coApplicantCounty: "",
      coApplicantTelephoneNumber: "",
      coApplicantTelephoneTypeHome: false,
      coApplicantTelephoneTypeCell: false,
      coApplicantTelephoneTypeWork: false,
      percentOwnership: 0,
      coSignerOnly: false,
      maritalStatusMarried: false,
      maritalStatusUnmarried: false,
      maritalStatusDivorced: false,
      maritalStatusLegallySeparated: false,
      maritalStatusWidowed: false,
      citizenshipStatusUSCitizen: false,
      citizenshipStatusNonCitizenNational: false,
      citizenshipStatusResidentAlien: false,
      citizenshipStatusRefugeeOther: false,
      ethnicityHispanicOrLatino: false,
      ethnicityNonHispanicOrLatino: false,
      raceAmericanIndianOrAlaskanNative: false,
      raceBlackOrAfricanAmerican: false,
      raceNativeHawaiianOrOtherPacificIslander: false,
      raceAsian: false,
      raceWhite: false,
      genderMale: false,
      genderFemale: false,
      veteranStatusVeteran: false,
      veteranStatusNonVeteran: false,
    } as FSA2211PartOCoApplicant
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row className="">
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row className="standard-text mt-2">
                  <Col>2A. Co-Applicant’s, Entity Member’s, or Co-Signer’s Full Legal Name</Col>
                  <Col>2B. Co-Applicant’s, Entity Member’s, or Co-Signer’s 9 Digit Social Security or Tax ID Number</Col>
                  <Col>2C. Co-Applicant’s, Entity Member’s, or Co-Signer’s Birthdate (MM-DD-YYYY)</Col>
                </Row>
                <Row className="standard-text">
                  <Col><Form.Control name="coApplicantFullName" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.coApplicantFullName} /></Col>
                  <Col><Form.Control name="coApplicantSSNOrTaxId" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.coApplicantSSNOrTaxId} /></Col>
                  <Col><Form.Control name="coApplicantBirthDate" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.coApplicantBirthDate} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>2D. Co-Applicant’s, Entity Member’s, or Co-Signer’s Address:</Col>
                  <Col>2E. Residence or Headquarters County</Col>
                  <Col>2F. Co-Applicant’s, Entity Member’s, or Co-Signer’s Telephone Number <em>(Including Area Code)</em></Col>
                </Row>
                <Row className="standard-text">
                  <Col><Form.Control name="coApplicantAddress" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.coApplicantAddress} /></Col>
                  <Col><Form.Control name="coApplicantCounty" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.coApplicantCounty} /></Col>
                  <Col><Form.Control name="coApplicantTelephoneNumber" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.coApplicantTelephoneNumber} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>2G. Co-Applicant’s, Entity Member’s, or Co-Signer’s Telephone Type</Col>
                  <Col>2H. % Ownership <em>(If entity member):</em></Col>
                  <Col>
                    <Row>
                      <Col md={8}>2I. Check Box if Co-Signer Only:</Col>
                      <Col><Form.Check name="coSignerOnly" onChange={this.handleInputChange.bind(this)} checked={this.state.coSignerOnly} /></Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="standard-text">
                  <Col>
                    <Row>
                      <Col className="align-left"><Form.Check name="coApplicantTelephoneTypeHome" type="checkbox" label="Home" onChange={this.handleInputChange.bind(this)} checked={this.state.coApplicantTelephoneTypeHome} /></Col>
                      <Col className="align-left"><Form.Check name="coApplicantTelephoneTypeCell" type="checkbox" label="Cell" onChange={this.handleInputChange.bind(this)} checked={this.state.coApplicantTelephoneTypeCell} /></Col>
                      <Col className="align-left"><Form.Check name="coApplicantTelephoneTypeWork" type="checkbox" label="Work" onChange={this.handleInputChange.bind(this)} checked={this.state.coApplicantTelephoneTypeWork} /></Col>
                    </Row>
                  </Col>
                  <Col><Form.Control name="percentOwnership" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.percentOwnership} /></Col>
                  <Col></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col>2J. Marital Status:</Col>
                  <Col><Form.Check name="maritalStatusMarried" label="Married" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusMarried} /></Col>
                  <Col><Form.Check name="maritalStatusUnmarried" label="Unmarried" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusUnmarried} /></Col>
                  <Col><Form.Check name="maritalStatusDivorced" label="Divorced" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusDivorced} /></Col>
                  <Col><Form.Check name="maritalStatusLegallySeparated" label="Legally Separated" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusLegallySeparated} /></Col>
                  <Col><Form.Check name="maritalStatusWidowed" label="Widowed" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusWidowed} /></Col>
                </Row>
                <Row className="standard-text mt-3">
                  <Col>2K. Citizenship Status:</Col>
                  <Col><Form.Check name="citizenshipStatusUSCitizen" label="U.S. Citizen" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenshipStatusUSCitizen} /></Col>
                  <Col><Form.Check name="citizenshipStatusNonCitizenNational" label="*Non-Citizen National" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenshipStatusNonCitizenNational} /></Col>
                  <Col><Form.Check name="citizenshipStatusResidentAlien" label="*Resident Alien" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenshipStatusResidentAlien} /></Col>
                  <Col><Form.Check name="citizenshipStatusRefugeeOther" label="*Refugee or Other" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenshipStatusRefugeeOther} /></Col>
                </Row>
                <Row className="standard-text mt-3">
                  <Col><span className="standard-bold">*NOTE:</span> Applicant will be asked to provide I-551 and/ or other proper documentation of immigration status as found under PRWORA (8 U.S.C. 1641)</Col>
                </Row>
                <Row className="subsection-border-top standard-text mt-2">
                  <Col>
                    <Row className="mt-2"><Col className="align-center"><em><strong>Voluntary Information for Monitoring Purposes</strong></em></Col></Row>
                    <Row className="mt-2"><Col>Ethnicity, race, and gender information is requested in order to monitor FSA's compliance with Federal laws prohibiting discrimination against loan
                      applicants and to determine if you qualify for targeted funds. You are not required to furnish this information but are encouraged to do so. Failure to
                      complete this information may result in you not receiving access to targeted funds for which you may be eligible. Entity applicants should base their
                      answers on the ethnicity, race, and gender of the owners of a majority interest in the entity.</Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-2 subsection-border-top">
                  <Col md={2} className="align-left mt-2">
                    <Row><Col>2L. Ethnicity</Col></Row>
                    <Row className="mt-2"><Col><Form.Check name="ethnicityHispanicOrLatino" type="checkbox" label="Hispanic or Latino" onChange={this.handleInputChange.bind(this)} checked={this.state.ethnicityHispanicOrLatino} /></Col></Row>
                    <Row><Col><Form.Check name="ethnicityNonHispanicOrLatino" type="checkbox" label="Not Hispanic or Latino" onChange={this.handleInputChange.bind(this)} checked={this.state.ethnicityNonHispanicOrLatino} /></Col></Row>
                  </Col>
                  <Col md={5} className="align-left mt-2">
                    <Row><Col>2M. Race</Col></Row>
                    <Row className="mt-2"><Col md={8}><Form.Check name="raceAmericanIndianOrAlaskanNative" type="checkbox" label="American Indian or Alaskan Native" onChange={this.handleInputChange.bind(this)} checked={this.state.raceAmericanIndianOrAlaskanNative} /></Col>
                      <Col><Form.Check name="raceAsian" type="checkbox" label="Asian" onChange={this.handleInputChange.bind(this)} checked={this.state.raceAsian} /></Col></Row>
                    <Row><Col md={8}><Form.Check name="raceBlackOrAfricanAmerican" type="checkbox" label="Black or African American" onChange={this.handleInputChange.bind(this)} checked={this.state.raceBlackOrAfricanAmerican} /></Col>
                      <Col><Form.Check name="raceWhite" type="checkbox" label="White" onChange={this.handleInputChange.bind(this)} checked={this.state.raceWhite} /></Col></Row>
                    <Row><Col md={8}><Form.Check name="raceNativeHawaiianOrOtherPacificIslander" type="checkbox" label="Native Hawaiian or Other Pacific Islander" onChange={this.handleInputChange.bind(this)} checked={this.state.raceNativeHawaiianOrOtherPacificIslander} /></Col><Col></Col></Row>
                  </Col>
                  <Col md={2} className="align-left mt-2">
                    <Row><Col>2N. Gender</Col></Row>
                    <Row className="mt-2"><Col><Form.Check name="genderMale" type="checkbox" label="Male" onChange={this.handleInputChange.bind(this)} checked={this.state.genderMale} /></Col></Row>
                    <Row><Col><Form.Check name="genderFemale" type="checkbox" label="Female" onChange={this.handleInputChange.bind(this)} checked={this.state.genderFemale} /></Col></Row>
                  </Col>
                  <Col className="align-left mt-2">
                    <Row><Col>1O. Veteran Status</Col></Row>
                    <Row className="mt-2"><Col><Form.Check name="veteranStatusVeteran" type="checkbox" label="Veteran" onChange={this.handleInputChange.bind(this)} checked={this.state.veteranStatusVeteran} /></Col></Row>
                    <Row><Col><Form.Check name="veteranStatusNonVeteran" type="checkbox" label="Non-Veteran" onChange={this.handleInputChange.bind(this)} checked={this.state.veteranStatusNonVeteran} /></Col></Row>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}