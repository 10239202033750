import { withAITracking } from "@microsoft/applicationinsights-react-js";
import PrimaryButton from "shared/components/PrimaryButton";
import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Image } from "react-bootstrap";
import { reactPlugin } from "../../../utilities/AppInsights";
import FullLogo from '../../../assets/full_logo.png'
import "./ErrorPage.css"

type Props = {
  history: any;
}

class ErrorPage extends React.Component<Props> {
  render() {
    return (
      <Container className="d-flex align-items-stretch fill-screen">
        <Row className="justify-content-md-center">
          <Col className="align-self-center">
            <Image src={FullLogo} fluid />
          </Col>
          <Col className="align-self-center">
            <h1>Oops..</h1>
            <p> It looks like we encountered an unexpected error.
                Click the button below to go back to the homepage.
            </p>
            <PrimaryButton buttonText={'HOME'} onClick={() => this.props.history.push('/')}></PrimaryButton>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withAITracking(reactPlugin, ErrorPage);
