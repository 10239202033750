import Bank from "../models/bank/bank";
import BankEmployee from "../models/bank/bankEmployee";
import axiosRequestUtility from "../utilities/AxiosRequestUtility";

class BankService {
  public GetLoanOfficers(): Promise<BankEmployee[]>{
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `GetLoanOfficers`,
    })
    .then(response => {
      return response.data as BankEmployee[]
    })
    .catch(error => {
      console.log(error);
      alert('Error loading loan officers. Please try again.')
      return [];
    })
  }

  public GetActiveBanks(): Promise<Bank[]>{
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `GetActiveBanks`,
    })
    .then(response => {
      return response.data as Bank[]
    })
    .catch(error => {
      console.log(error);
      alert('Error loading active banks. Please try again.')
      return [];
    })
  }

  public AddNewBank(bank: Bank): Promise<Bank> {
    return axiosRequestUtility.Request({
      method: 'POST',
      url: `AddBank`,
      data: bank
    })
    .then(response => {
      return response.data as Bank;
    })
    .catch(error => {
      console.log(error);
      alert('Error adding a new bank. Please try again.')
      return {} as Bank;
    })
  }

  public UpdateBank(bank: Bank): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'PUT',
      url: `UpdateBank`,
      data: bank
    })
    .then(response => {
      return true;
    })
    .catch(error => {
      console.log(error);
      alert('Error updating bank information. Please try again.')
      return false;
    })
  }

  public GetCurrentBank(): Promise<Bank>{
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `GetCurrentBank`,
    })
    .then(response => {
      return response.data as Bank
    })
    .catch(error => {
      console.log(error);
      alert('Error loading bank information. Please try again.')
      return {} as Bank;
    })
  }
}

const bankService = new BankService();
export default bankService;