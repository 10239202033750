import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import { DocumentType, DocumentTypeMapper, LoanType } from "../../../shared/Enums";
import Button from "react-bootstrap/esm/Button";
import LoanDocument from "../../../models/loanDocument/loanDocument";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./LoanDocumentsSection.css"
import categoryUtility from "utilities/CategoryUtility";
import loanApplicationUtility from "utilities/LoanApplicationUtility";

type LoanDocumentAction = 'create' | 'download' | 'delete';
type LoanDocumentCallback = (action: LoanDocumentAction, selectedDocument?: LoanDocument, selectedFile?: File, documentType?: DocumentType) => void;
type RequestCallback = () => void;

interface LoanDocumentSectionsProps {
  loanDetails: LoanApplication,
  setDocumentModalState: LoanDocumentCallback,
  setRequestModalState: RequestCallback
}

export default class LoanDocumentsSection extends React.Component<LoanDocumentSectionsProps> {
  categoryNumbers = categoryUtility.categoryNumbers();

  renderCondition(type: number): boolean {
    return (!isNaN(Number(type)) &&
      this.props.loanDetails.customerAccount.loanDocuments !== undefined &&
      this.props.loanDetails.customerAccount.loanDocuments.filter(x => x.type === type).length > 0)
  }

  getDocumentsPerTypeInYear(type: number): string[] {
    return Array.from(
      new Set(this.props.loanDetails.customerAccount.loanDocuments?.filter(x =>
        x.type === type
      ).map(x => x.year)));
  }

  render() {
    return (
      <>
        <div>
          {this.props.loanDetails.loanType == LoanType.DealerPurchase &&
            <>
              <h3>Dealer Purchase Documents</h3>

              <Table key={`dealer-purchase-documents`} className="table-border" striped={false} size="sm">
                <thead>
                  <tr className="same-col-widths">
                    <th className="document-type-heading document-type-title">Documents</th>
                    <th className="align-right document-type-heading" >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.loanDetails.photoOfVin != undefined && this.props.loanDetails.photoOfVin.length > 0 && this.props.loanDetails.photoOfVin.map((d, i) => {
                    return (
                      <tr>
                        <td>
                          <Button className="download-link-text" variant="link" onClick={() => { this.props.setDocumentModalState('download', d) }}>{d.documentName}</Button>
                        </td>
                        <td className="align-right">
                          <Button variant="link" onClick={() => { this.props.setDocumentModalState('delete', d) }} >
                            <FontAwesomeIcon className="trashcan" icon="trash" />
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                  }

                  {(this.props.loanDetails.photoOfVin == undefined || this.props.loanDetails.photoOfVin.length === 0) &&
                    <tr>
                      <td>
                        <span className="no-file-text">No photos of vin/serial # uploaded.</span>
                      </td>
                      <td className="align-right">
                        <Button variant="link" onClick={this.props.setDocumentModalState.bind(this, 'create', undefined, undefined, DocumentType.PhotoOfVin)} >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </td>
                    </tr>
                  }

                  {this.props.loanDetails.purchaseOrderForm != undefined && this.props.loanDetails.purchaseOrderForm.length > 0 && this.props.loanDetails.purchaseOrderForm.map((d, i) => {
                    return (
                      <tr>
                        <td>
                          <Button className="download-link-text" variant="link" onClick={() => { this.props.setDocumentModalState('download', d) }}>{d.documentName}</Button>
                        </td>
                        <td className="align-right">
                          <Button variant="link" onClick={() => { this.props.setDocumentModalState('delete', d) }} >
                            <FontAwesomeIcon className="trashcan" icon="trash" />
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                  }

                  {(this.props.loanDetails.purchaseOrderForm == undefined || this.props.loanDetails.purchaseOrderForm.length === 0) &&
                    <tr>
                      <td>
                        <span className="no-file-text">No purchase order forms uploaded.</span>
                      </td>
                      <td className="align-right">
                        <Button variant="link" onClick={this.props.setDocumentModalState.bind(this, 'create', undefined, undefined, DocumentType.PurchaseOrderForm)} >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </td>
                    </tr>
                  }

                  {this.props.loanDetails.purchasePhoto != undefined && this.props.loanDetails.purchasePhoto.length > 0 && this.props.loanDetails.purchasePhoto.map((d, i) => {
                    return (
                      <tr>
                        <td>
                          <Button className="download-link-text" variant="link" onClick={() => { this.props.setDocumentModalState('download', d) }}>{d.documentName}</Button>
                        </td>
                        <td className="align-right">
                          <Button variant="link" onClick={() => { this.props.setDocumentModalState('delete', d) }} >
                            <FontAwesomeIcon className="trashcan" icon="trash" />
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                  }

                  {(this.props.loanDetails.purchasePhoto == undefined || this.props.loanDetails.purchasePhoto.length === 0) &&
                    <tr>
                      <td>
                        <span className="no-file-text">No purchase photo uploaded.</span>
                      </td>
                      <td className="align-right">
                        <Button variant="link" onClick={this.props.setDocumentModalState.bind(this, 'create', undefined, undefined, DocumentType.PurchasePhoto)} >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </td>
                    </tr>
                  }
                </tbody>
              </Table>
            </>
          }

          {this.props.loanDetails.customerAccount.loanDocuments !== undefined && this.categoryNumbers.map(
            (type: number) => {
              return (
                this.renderCondition(type) && (this.getDocumentsPerTypeInYear(type).map(
                  (year: string, i: number) => {
                    return (
                      <Table key={`applicant-document-${i}`} className="table-border" striped={false} size="sm">
                        <thead>
                          <tr>
                            <th className="document-type-heading document-type-title">{DocumentTypeMapper[type] + ' ' + year}</th>
                            <th className="align-right document-type-heading" >
                              <Button
                                className="download-link-text"
                                variant="link"
                                onClick={this.props.setDocumentModalState.bind(this, 'create', undefined, undefined, type)}>Upload</Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.loanDetails.customerAccount.loanDocuments?.filter(x => x.type === type && x.year === year).map(
                            (loanDocument: LoanDocument, j: number) => {
                              return (
                                <tr key={`loan-document-${i}${j}`}>
                                  <td><Button className="download-link-text" variant="link" onClick={() => { this.props.setDocumentModalState('download', loanDocument) }}>{`${loanDocument.documentName} (${loanApplicationUtility.BuildDisplayId(loanDocument.loanId ?? 0)})`}</Button></td>
                                  <td className="align-right">
                                    <Button variant="link" onClick={() => { this.props.setDocumentModalState('delete', loanDocument) }} >
                                      <FontAwesomeIcon className="trashcan" icon="trash" />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    )
                  }
                )
                )
              );
            }
          )}
        </div >
      </>
    );
  }
}