import React from 'react'
import { Image } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import "./DealerLandingPage.css"
import FullLogo from '../../../assets/full_logo.png'
import PrimaryButton from "shared/components/PrimaryButton";
import { Link } from "react-router-dom";
import SecondaryButton from "shared/components/SecondaryButton";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  login: any;
}

export default function DealerLandingPage() {

  const { loginWithRedirect } = useAuth0();

  return (
    <Container className="d-flex align-items-center fill-screen" fluid>
      <Row className="justify-content-md-center">
        <Col className="align-self-center">
          <Image src={FullLogo} fluid />
        </Col>
        <Col className="align-self-center">
          <h1>Dealer Portal</h1>
          <p>
            If you do not have an account, please click the button below to create one.<br /><br />If when creating an account you do not see your dealership, please notify the agGRO support team immediately to get it added. Thank you!
          </p>
          <PrimaryButton buttonText={'Login'} onClick={() => {
            loginWithRedirect({
              authorizationParams: {
                redirect_uri: window.location.hostname + '/active'
              }
            })
          }}></PrimaryButton>
          <Link to={'/create-account'}>
            <SecondaryButton buttonText={'Create Account'} onClick={() => { }}></SecondaryButton>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
