import { Subdomain } from "../../../shared/Enums";
import LoggedInAccount from "models/account/loggedInAccount";

export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_MODAL_LOADING = 'TOGGLE_MODAL_LOADING';
export const TOGGLE_API_REQUEST_SUCCESS = 'TOGGLE_API_REQUEST_SUCCESS';
export const LOGGED_IN_ACCOUNT = 'LOGGED_IN_ACCOUNT';
export const SET_SUBDOMAIN = 'SET_SUBDOMAIN';

export interface SystemState {
    isLoading: boolean;
    isModalLoading: boolean;
    // Using as a way for us to close modals when the request we have sent has been successful
    apiRequestSuccess: boolean;
    loggedInAccount: LoggedInAccount;
    subdomain: Subdomain
}

interface ToggleLoadingAction {
    type: typeof TOGGLE_LOADING;
    payload: {
        isLoading: boolean;
    }
}
 
interface ToggleModalLoadingAction {
    type: typeof TOGGLE_MODAL_LOADING;
    payload: {
        isModalLoading: boolean;
    }
}

interface ToggleAPIRequestSuccessAction {
    type: typeof TOGGLE_API_REQUEST_SUCCESS;
    payload: {
        apiRequestSuccess: boolean;
    }
}

interface GetLoggedInAccount {
    type: typeof LOGGED_IN_ACCOUNT;
    payload: {
        account: LoggedInAccount;
    }
}

interface SetSubdomain {
    type: typeof SET_SUBDOMAIN;
    payload: {
        subdomain: Subdomain;
    }
}

export type SystemActionTypes =
  | ToggleLoadingAction
  | ToggleModalLoadingAction
  | ToggleAPIRequestSuccessAction
  | GetLoggedInAccount
  | SetSubdomain;