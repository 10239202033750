import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./LoanOffersSection.css";
import { InterestType } from "../../../shared/Enums";
import LoanOffer from "../../../models/loanOffer/loanOffer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import loanApplicationUtility from "utilities/LoanApplicationUtility";

interface LoanOffersSectionProps {
  loanDetails: LoanApplication
}

export default class LoanOffersSection extends React.Component<LoanOffersSectionProps> {
  render() {
    return (
      <>
        <div>
          <h3>Offers</h3>
          {this.props.loanDetails.loanOffers !== undefined && this.props.loanDetails.loanOffers.length > 0 ?
            <>
              <Table className="table-border" striped={false} size="sm">
                <thead>
                  <tr>
                    <th className="offer-heading offer-title">Accepted</th>
                    <th className="offer-heading offer-title">Expired</th>
                    <th className="offer-heading offer-title">Offer Amount</th>
                    <th className="offer-heading offer-title">Expiration Date</th>
                    <th className="offer-heading offer-title">Loan Type</th>
                    <th className="offer-heading offer-title">Interest Rate Type</th>
                    <th className="offer-heading offer-title">Interest Rate</th>
                    <th className="offer-heading offer-title">Margin Adjustment</th>
                    <th className="offer-heading offer-title">Base Rate</th>
                    <th className="offer-heading offer-title">Term</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.loanDetails.loanOffers.map((lo: LoanOffer, i: number) => {
                    return (
                      <tr key={"loanOffer-" + i}>
                        <td className="align-middle align-items-left offer-entry-margin">{lo.selected ? <div className="text-center w-100"><FontAwesomeIcon icon="check-circle" /></div> : null}</td>
                        <td className="align-middle align-items-left offer-entry-margin">{lo.expired ? <div className="text-center w-100"><FontAwesomeIcon icon="times" /></div> : null}</td>
                        <td className="align-middle offer-entry-margin">{loanApplicationUtility.BuildCurrencyAmount(lo.amount)}</td>
                        <td className="align-middle offer-entry-margin">{loanApplicationUtility.GetDateMonthNameFullYear(lo.expirationDate)}</td>
                        <td className="align-middle offer-entry-margin">{lo.lendingLoanType.name}</td>
                        <td className="align-middle offer-entry-margin">{lo.interestRateType.name}</td>
                        <td className="align-middle offer-entry-margin">{`${lo.interestRate}%`}</td>
                        <td className="align-middle offer-entry-margin">{lo.interestRateTypeId === InterestType.Variable ? `${lo.marginAdjustment}%` : ''}</td>
                        <td className="align-middle offer-entry-margin">{lo.interestRateTypeId === InterestType.Variable ? `${this.props.loanDetails.bank.baseRate}%` : ''}</td>
                        <td className="align-middle offer-entry-margin">{`${lo.term / 12} Year(s)`}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </Table>
            </>
            :
            <div>This loan application has not been sent any loan offers.</div>
          }
        </div>
      </>
    );
  }
}