import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./LoanApplicationInformationSection.css"
import loanApplicationUtility from "../../../utilities/LoanApplicationUtility";
import Can from "auth/Can";

interface LoanApplicationInformationSectionProps {
  loanDetails: LoanApplication
}

export default class LoanApplicationInformationSection extends React.Component<LoanApplicationInformationSectionProps> {
  render() {
    return (
      <>
        <div>
          <h3>Application Information</h3>

          <Table bordered striped={false} size="sm">
            <tbody>
              <tr>
                <td width="250px" className="app-info"><strong>Date Submitted</strong></td>
                <td className="app-info">{loanApplicationUtility.GetDateMonthNameFullYear(this.props.loanDetails.dateSubmitted)}</td>
              </tr>

              <Can
                perform="Loan Officer"
                yes={() => (
                  <tr>
                    <td width="250px" className="app-info"><strong>Scorecard Level</strong></td>
                    <td className="app-info">{loanApplicationUtility.GetLoanScorecardLevel(this.props.loanDetails.scorecardLevel)}</td>
                  </tr>
                )}
                no={() => <></>}
              />

              <tr>
                <td width="250px" className="app-info"><strong>Status</strong></td>
                <td className="app-info">{loanApplicationUtility.GetLoanStatusText(this.props.loanDetails.status)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Loan Amount</strong></td>
                <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.loanAmount)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Loan Purpose</strong></td>
                <td className="app-info">{loanApplicationUtility.GetLoanTypeText(this.props.loanDetails.loanType)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Submitted By</strong></td>
                <td className="app-info">{loanApplicationUtility.JoinApplicantNames(this.props.loanDetails.applicants)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Email Address</strong></td>
                <td className="app-info">{this.props.loanDetails.customerAccount.email}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Phone Number</strong></td>
                <td className="app-info">{loanApplicationUtility.FormatPhoneNumer(this.props.loanDetails.customerAccount.phoneNumber)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Farm/Ranching Experience</strong></td>
                <td className="app-info">{loanApplicationUtility.GetYearsExperience(this.props.loanDetails.customerAccount.farmingStartYear)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Current Farm/Ranch Revenues</strong></td>
                <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.projectedGrossRevenues.farmRevenues)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Current Off-Farm/Ranch Revenues</strong></td>
                <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.projectedGrossRevenues.offFarmRevenues)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Prior Year Farm/Ranch Revenues</strong></td>
                <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.priorGrossRevenues?.farmRevenues)}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Prior Year Off-Farm/Ranch Revenues</strong></td>
                <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.priorGrossRevenues?.offFarmRevenues)}</td>
              </tr>
              <Can
                perform="Loan Officer"
                yes={() => (
                  <tr>
                    <td width="250px" className="app-info"><strong>Scorecard Score</strong></td>
                    <td className="app-info">{this.props.loanDetails.scorecardScore ?? 'N/A'}</td>
                  </tr>
                )}
                no={() => <></>}
              />
            </tbody>
          </Table>
        </div>

      </>
    );
  }
}