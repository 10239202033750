import React from "react";
import "./NotesPayableSection.css"
import loanApplicationUtility from "utilities/LoanApplicationUtility";
import NotesPayable from "models/financialDocuments/balanceSheet/liabilities/notesPayable";
import LiabilityCategory from "models/financialDocuments/balanceSheet/liabilities/liabilityCategory";
import balanceSheetUtility from "utilities/BalanceSheetUtility";

interface NotesPayableSectionProps {
  liabilityCategory: LiabilityCategory
  liabilityData: NotesPayable
}

interface NotesPayableSectionState {

}

export default class NotesPayableSection extends React.Component<NotesPayableSectionProps, NotesPayableSectionState> {
  render() {
    return (
      <>
        <div>
          <div><span style={{ fontStyle: 'italic' }}>Creditor Name:</span></div>
          <div>
            <span style={{ paddingLeft: '10px' }}>{this.props.liabilityData.creditorName}</span>
          </div>
        </div>

        <div>
          <div><span style={{ fontStyle: 'italic' }}>Loan Purpose:</span></div>
          <div>
            <span style={{ paddingLeft: '10px' }}>{balanceSheetUtility.GetNotesPayableLoanPurposeText(this.props.liabilityData.loanPurpose)}</span>
          </div>
        </div>

        <div>
          <div><span style={{ fontStyle: 'italic' }}>Interest Rate:</span></div>
          <div>
            <span style={{ paddingLeft: '10px' }}>{`${this.props.liabilityData.interestRate}%`}</span>
          </div>
        </div>

        <div>
          <div><span style={{ fontStyle: 'italic' }}>Accrued Interest:</span></div>
          <div>
            <span style={{ paddingLeft: '10px' }}>{loanApplicationUtility.BuildCurrencyAmount(this.props.liabilityData.accruedInterest)}</span>
          </div>
        </div>

        <div>
          <div><span style={{ fontStyle: 'italic' }}>Principal Balance:</span></div>
          <div>
            <span style={{ paddingLeft: '10px' }}>{loanApplicationUtility.BuildCurrencyAmount(this.props.liabilityData.principalBalance)}</span>
          </div>
        </div>

        <div>
          <div><span style={{ fontStyle: 'italic' }}>Payment Amount:</span></div>
          <div>
            <span style={{ paddingLeft: '10px' }}>{loanApplicationUtility.BuildCurrencyAmount(this.props.liabilityData.paymentAmount)}</span>
          </div>
        </div>

        <div>
          <div><span style={{ fontStyle: 'italic' }}>Next Payment Date:</span></div>
          <div>
            <span style={{ paddingLeft: '10px' }}>{this.props.liabilityData.nextPaymentDate}</span>
          </div>
        </div>
      </>
    );
  }
}