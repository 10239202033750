import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartC from "../../../../models/fsa2211Form/FSA2211PartC"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartCCallback = (formDataPartC: FSA2211PartC) => void;

interface FSA2211FormPartCProps {
  partCData?: FSA2211PartC;
  formDataChange: formDataPartCCallback;
}

export default class FSA2211FormPartC extends React.Component<FSA2211FormPartCProps, FSA2211PartC> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partCData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartCProps) {
    if (previousProps.partCData !== this.props.partCData) {
      this.setState(this.props.partCData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartC {
    return {
      applicantsFullName: "",
        applicantsAddress: "",
        applicantsBirthDate: "",
        applicantsSSN: "",
        residenceOrHeadquartersCounty: "",
        applicantsTelephoneNumber: "",
        telephoneTypeHome: false,
        telephoneTypeCell: false,
        telephoneTypeWork: false,
        maritalStatusMarried: false,
        maritalStatusUnmarried: false,
        maritalStatusDivorced: false,
        maritalStatusLegallySeparated: false,
        maritalStatusWidowed: false,
        citizenshipStatusUSCitizen: false,
        citizenshipStatusNonCitizenNational: false,
        citizenshipStatusResidentAlien: false,
        citizenshipStatusUSRefugeeOrOther: false,
    } as FSA2211PartC
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART C &nbsp;&nbsp; INDIVIDUAL APPLICANT INFORMATION <em>(LOAN APPLICANT)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={3} className="align-left">1. Applicant's Full Legal Name</Col>
                  <Col md={3} className="align-left">2. Applicant's Address</Col>
                  <Col md={3} className="align-left">3. Applicant's Birthday</Col>
                  <Col md={3} className="align-left">4. Applicant's 9 Digit Social Security or Tax ID Number</Col>
                </Row>
                <Row className="standard-text">
                  <Col md={3} className="align-left"><Form.Control name="applicantsFullName" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.applicantsFullName} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="applicantsAddress" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.applicantsAddress} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="applicantsBirthDate" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.applicantsBirthDate} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="applicantsSSN" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.applicantsSSN} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={3} className="align-left">5. Residence or Headquarters County</Col>
                  <Col md={3} className="align-left">6. Applicant's Telephone Number <em className="instruction-text">(including area code)</em></Col>
                  <Col md={3} className="align-left">7. Telephone Type</Col>
                  <Col md={3} className="align-left"></Col>
                </Row>
                <Row className="standard-text">
                  <Col md={3} className="align-left"><Form.Control name="residenceOrHeadquartersCounty" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.residenceOrHeadquartersCounty} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="applicantsTelephoneNumber" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.applicantsTelephoneNumber} /></Col>
                  <Col md={3} className="align-left">
                    <Row>
                      <Col className="align-left"><Form.Check name="telephoneTypeHome" type="checkbox" label="Home" onChange={this.handleInputChange.bind(this)} checked={this.state.telephoneTypeHome} /></Col>
                      <Col className="align-left"><Form.Check name="telephoneTypeCell" type="checkbox" label="Cell" onChange={this.handleInputChange.bind(this)} checked={this.state.telephoneTypeCell} /></Col>
                      <Col className="align-left"><Form.Check name="telephoneTypeWork" type="checkbox" label="Work" onChange={this.handleInputChange.bind(this)} checked={this.state.telephoneTypeWork} /></Col>
                    </Row>
                  </Col>
                  <Col md={3} className="align-left"></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={3} className="align-left">8. Marital Status</Col>
                </Row>
                <Row className="standard-text">
                  <Col md={6} className="align-left">
                    <Row>
                      <Col className="align-left"><Form.Check name="maritalStatusMarried" type="checkbox" label="Married" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusMarried} /></Col>
                      <Col className="align-left"><Form.Check name="maritalStatusUnmarried" type="checkbox" label="Unmaried" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusUnmarried} /></Col>
                      <Col className="align-left"><Form.Check name="maritalStatusDivorced" type="checkbox" label="Divorced" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusDivorced} /></Col>
                      <Col className="align-left"><Form.Check name="maritalStatusLegallySeparated" type="checkbox" label="Legally Seperated" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusLegallySeparated} /></Col>
                      <Col className="align-left"><Form.Check name="maritalStatusWidowed" type="checkbox" label="Widowed" onChange={this.handleInputChange.bind(this)} checked={this.state.maritalStatusWidowed} /></Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={3} className="align-left">9. Citizenship Status</Col>
                </Row>
                <Row className="standard-text">
                  <Col md={6} className="align-left">
                    <Row>
                      <Col className="align-left"><Form.Check name="citizenshipStatusUSCitizen" type="checkbox" label="U.S. Citizen" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenshipStatusUSCitizen} /></Col>
                      <Col className="align-left"><Form.Check name="citizenshipStatusNonCitizenNational" type="checkbox" label="*Non-Citizen National" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenshipStatusNonCitizenNational} /></Col>
                      <Col className="align-left"><Form.Check name="citizenshipStatusResidentAlien" type="checkbox" label="*Resident Alien" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenshipStatusResidentAlien} /></Col>
                      <Col className="align-left"><Form.Check name="citizenshipStatusUSRefugeeOrOther" type="checkbox" label="*Refugee or Other" onChange={this.handleInputChange.bind(this)} checked={this.state.citizenshipStatusUSRefugeeOrOther} /></Col>
                    </Row>
                  </Col>
                </Row>
                <Row><Col><span className="standard-bold">*NOTE:</span> <span className="instruction-text">Applicant will be asked to provide I-551 and/ or other proper documentation of immigration status as found under PRWORA (8 U.S.C. 1641)</span></Col></Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}