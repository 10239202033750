import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../../../../utilities/AppInsights";
import { AppState } from '../../../../redux/store/store';
import { toggleLoading } from '../../../../redux/actions/system/actions';
import { connect, ConnectedProps } from 'react-redux';
import LoadingSpinner from "shared//components/spinner/LoadingSpinner";
import PagedList from "../../../../models/accessors/pagedList";
import LoanApplication from "../../../../models/loan/loanApplication";
import Container from "react-bootstrap/esm/Container";
import SelectOption from "../../../../shared/models/selectOption";
import "react-datepicker/dist/react-datepicker.css";
import "./ClosedLoansListPage.css"
import loanApplicationService from "accessors/loanApplicationService";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import SecondaryButton from "shared/components/SecondaryButton";
import LoanListSection from "../../../../components/loanList/LoanListSection"
import Can from "auth/Can";

const mapState = (state: AppState, route: any) => {
  const { system, loans, bank } = state;
  const { isLoading } = system;
  const { pagedLoanApplications } = loans;
  const { loanOfficers } = bank;
  return {
    isLoading,
    pagedLoanApplications,
    loanOfficers,
    route
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

interface ClosedLoansListPageState {
  pagedLoanApplications: PagedList<LoanApplication>;
  loanTypeFilter: SelectOption[];
  scorecardLevelFilter: SelectOption[];
  loanStatusFilter: SelectOption[];
  loanOfficerOptions: SelectOption[];
  loanOfficerFilter: SelectOption[];
  submittedStartDateFilter?: Date;
  submittedEndDateFilter?: Date;
}

class ClosedLoansListPage extends React.Component<Props, ClosedLoansListPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      pagedLoanApplications: {} as PagedList<LoanApplication>,
      loanTypeFilter: [],
      scorecardLevelFilter: [],
      loanStatusFilter: [],
      loanOfficerOptions: [],
      loanOfficerFilter: []
    }
  }

  exportResults() {
    this.props.toggleLoading(true);
    loanApplicationService.ExportLoanApplications('closed', this.state.loanTypeFilter.map(f => +f.value), this.state.scorecardLevelFilter.map(f => +f.value), this.state.loanStatusFilter.map(f => +f.value), this.state.loanOfficerFilter.map(f => +f.value), this.state.submittedStartDateFilter, this.state.submittedEndDateFilter)
      .then(result => {
        this.props.toggleLoading(false);
      })
  }


  render() {
    return (
      <>
        <Container fluid>
          <div className="closed-loans-list-title-row">
            <Row className="align-items-center">
              <Col>
                <h1>Closed Loans</h1>
              </Col>
              <Can
                perform="Loan Officer"
                yes={() => (
                  <Col>
                    <div className="d-flex justify-content-end">
                      <SecondaryButton
                        buttonText={'Export Loans'}
                        onClick={this.exportResults.bind(this)}
                      />
                    </div>
                  </Col>
                )}
                no={() => <></>}
              />
            </Row>
          </div>
          <LoanListSection
            loanListType={'closed'}
            route={this.props.route} />
          {this.props.isLoading &&
            <LoadingSpinner
              variant={'dark'}
              animation={'border'}
            />
          }
        </Container>
      </>
    );
  }
}

export default withAITracking(reactPlugin, connector(ClosedLoansListPage));
