import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../../../utilities/AppInsights";
import { AppState } from '../../../redux/store/store';
import { toggleLoading, toggleModalLoading, toggleAPIRequestSuccess } from '../../../redux/actions/system/actions';
import { getLoanApplicationDetails, assignLoanOfficerToLoan, addLoanNote, updateLoanNote, deleteLoanNote, updateLoanStatus, addLoanOffer, addLoanDocument, deleteLoanDocument, addDocumentRequests, findOpenDocumentRequests, deleteDocumentRequest, uploadIdDocument } from '../../../redux/actions/loan/actions';
import { getLoanOfficers } from '../../../redux/actions/bank/actions';
import { connect, ConnectedProps } from 'react-redux';
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import SecondaryButton from "shared/components/SecondaryButton";
import LoanDocumentsSection from "../../../components/loanDetails/loanDocumentsSection/LoanDocumentsSection";
import FSAApplicationSection from "../../../components/loanDetails/FSAApplicationsSection/FSAApplicationsSection"
import OpenDocumentRequestsSection from "../../../components/loanDetails/openDocumentRequestsSection/OpenDocumentRequestsSection"
import LoanApplication from "models/loan/loanApplication";
import Container from "react-bootstrap/esm/Container";
import { isBefore } from "date-fns";
import loanApplicationUtility from "../../../utilities/LoanApplicationUtility";
import bankEmployeeUtility from "../../../utilities/BankEmployeeUtility";
import Select from "react-select";
import SelectOption from "shared/models/selectOption";
import BankEmployee from "models/bank/bankEmployee";
import { LoanAction, LoanStatus, DocumentType, IdDocumentSde, Subdomain, LoanTermType } from "../../../shared/Enums";
import "./LoanDetailsPage.css"
import LoanNote from "models/note/loanNote";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import NoteModal from "../../../components/modals/noteModal/NoteModal";
import RequestInfoModal from "../../../components/modals/requestInfoModal/RequestInfoModal";
import ConfirmModal from "../../../components/modals/confirmModal/ConfirmModal";
import OfferModal from "../../../components/modals/offerModal/OfferModal";
import AddDocumentModal from "../../../components/modals/addDocumentModal/AddDocumentModal";
import FSAApplicationModal from "../../../components/modals/fsaApplicationModal/FSAApplicationModal";
import LoanOffer from "models/loanOffer/loanOffer";
import LoanDocument from "models/loanDocument/loanDocument";
import AddDocumentsRequest from "models/addDocumentsRequest/AddDocumentsRequest";
import loanApplicationService from "accessors/loanApplicationService";
import LoanApplicationInformationSection from "../../../components/loanDetails/loanApplicationInformationSection/LoanApplicationInformationSection";
import FixedAssetSection from "../../../components/loanDetails/fixedAssetSection/FixedAssetSection";
import CollateralEquitySection from "../../../components/loanDetails/collateralEquitySection/CollateralEquitySection";
import RefinanceDebtSection from "../../../components/loanDetails/refinanceDebtSection/RefinanceDebtSection";
import OperatingLoanSection from "../../../components/loanDetails/operatingLoanSection/OperatingLoanSection";
import ApplicantSection from "../../../components/loanDetails/applicantSection/ApplicantSection";
import EntityApplicantSection from "../../../components/loanDetails/entityApplicantSection/EntityApplicantSection";
import LoanOfferSection from "../../../components/loanDetails/loanOffersSection/LoanOffersSection";
import LoanNotesSection from "../../../components/loanDetails/loanNotesSection/LoanNotesSection";
import DocumentRequest from "models/documentData/DocumentRequest";
import FSA2211 from "models/fsa2211Form/FSA2211";
import DealerPurchaseSection from "components/loanDetails/dealerPurchaseSection/DealerPurchaseSection";
import BalanceSheetSection from "components/loanDetails/balanceSheetSection/BalanceSheetSection";
import SubmittedBalanceSheet from "models/financialDocuments/balanceSheet/submittedBalanceSheet";
import BalanceSheetModal from "components/modals/balanceSheetModal/BalanceSheetModal";
import BalanceSheet from "models/financialDocuments/balanceSheet/balanceSheet";
import ScorecardBreakdown from "../../../components/loanDetails/scorecardBreakdown/ScorecardBreakdown";
import IdDocumentModal from "components/modals/idDocumentModal/IdDocumentModal";
import customerAccountService from "accessors/customerAccountService";
import DownloadDocumentResponse from "models/downloadDocumentResponse/DownloadDocumentResponse";
import Can from "auth/Can";
import IdDocumentUpload from "models/account/customer/idDocumentUpload/idDocumentUpload";
import IdDocumentsSection from "../../../components/loanDetails/idDocumentsSection/IdDocumentsSection";


const mapState = (state: AppState, route: any) => {
  const { system, loans, bank } = state;
  const { isLoading, isModalLoading, apiRequestSuccess, subdomain } = system;
  const { loanApplicationDetails, openDocumentRequests } = loans;
  const { loanOfficers } = bank;

  return {
    isLoading,
    isModalLoading,
    apiRequestSuccess,
    route,
    loanApplicationDetails,
    loanOfficers,
    openDocumentRequests,
    subdomain
  };
};


const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  toggleModalLoading: (isModalLoading: boolean) => toggleModalLoading(isModalLoading),
  getLoanApplicationDetails: (loanId: number) => getLoanApplicationDetails(loanId),
  getLoanOfficers: () => getLoanOfficers(),
  assignLoanOfficerToLoan: (loanId: number, employeeId: number) => assignLoanOfficerToLoan(loanId, employeeId),
  addLoanNote: (loanNote: LoanNote) => addLoanNote(loanNote),
  toggleAPIRequestSuccess: (apiRequestSuccess: boolean) => toggleAPIRequestSuccess(apiRequestSuccess),
  updateLoanNote: (loanNote: LoanNote) => updateLoanNote(loanNote),
  deleteLoanNote: (loanNoteId: number) => deleteLoanNote(loanNoteId),
  updateLoanStatus: (loanId: number, loanStatus: number) => updateLoanStatus(loanId, loanStatus),
  addLoanOffer: (loanOffer: LoanOffer) => addLoanOffer(loanOffer),
  addLoanDocuments: (loanDocuments: AddDocumentsRequest) => addLoanDocument(loanDocuments),
  deleteLoanDocument: (loanDocumentId: number) => deleteLoanDocument(loanDocumentId),
  addDocumentRequests: (requests: DocumentRequest[], customerAccountId: number) => addDocumentRequests(requests, customerAccountId),
  findOpenDocumentRequests: (customerAccountId: number) => findOpenDocumentRequests(customerAccountId),
  deleteDocumentRequest: (documentRequestId: number, customerAccountId: number) => deleteDocumentRequest(documentRequestId, customerAccountId),
  uploadIdDocument: (idDocumentUpload: IdDocumentUpload) => uploadIdDocument(idDocumentUpload)
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

type LoanNoteAction = 'create' | 'edit' | 'delete';
type LoanDocumentAction = 'create' | 'download' | 'delete' | 'deny loan';
type IdDocumentModalAction = 'view' | 'upload';

interface LoanDetailsPageState {
  loanDetails: LoanApplication;
  loanOfficerOptions: SelectOption[];
  assignedLoanOfficer?: SelectOption;
  loanActionOptions: SelectOption[];
  showRequestInfoModal: boolean;
  loanNoteModalAction?: LoanNoteAction;
  showNoteModal: boolean;
  showConfirmDeleteNoteModal: boolean;
  showLoanOfferModal: boolean;
  showDocumentModal: boolean;
  showConfirmModal: boolean;
  showFSAApplicationModal: boolean;
  selectedLoanNote?: LoanNote;
  selectedLoanAction?: SelectOption;
  confirmModalTitle: string;
  confirmModalMessage: string;
  confirmButtonText: string;
  confirmModalIsDanger: boolean;
  newLoanOffer: LoanOffer;
  selectedLoanDocument: LoanDocument;
  addLoanDocumentsRequest: AddDocumentsRequest;
  documentModalAction?: LoanDocumentAction;
  selectedDownloadFile?: File;
  openDocumentRequests: DocumentRequest[];
  showBalanceSheetModal: boolean;
  selectedBalanceSheet: BalanceSheet;
  showIdDocumentModal: boolean;
  idDocumentBlob: string;
  idDocumentModalAction?: IdDocumentModalAction;
}

const dealerDocuments: DocumentType[] = [DocumentType.PhotoOfVin, DocumentType.PurchasePhoto, DocumentType.PurchaseOrderForm];

class LoanDetailsPage extends React.Component<Props, LoanDetailsPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loanDetails: {} as LoanApplication,
      loanOfficerOptions: [],
      loanActionOptions: [],
      showNoteModal: false,
      showRequestInfoModal: false,
      showConfirmDeleteNoteModal: false,
      showLoanOfferModal: false,
      showConfirmModal: false,
      showFSAApplicationModal: false,
      confirmModalTitle: '',
      confirmModalMessage: '',
      confirmButtonText: '',
      confirmModalIsDanger: false,
      newLoanOffer: {} as LoanOffer,
      showDocumentModal: false,
      addLoanDocumentsRequest: {} as AddDocumentsRequest,
      openDocumentRequests: this.props.openDocumentRequests,
      selectedLoanDocument: {} as LoanDocument,
      showBalanceSheetModal: false,
      selectedBalanceSheet: {} as BalanceSheet,
      showIdDocumentModal: false,
      idDocumentBlob: ''
    }
  }

  componentDidMount() {
    this.loadLoanDetails();
    this.props.getLoanOfficers();
  }

  componentDidUpdate(previousProps: Props) {
    if (previousProps.loanApplicationDetails !== this.props.loanApplicationDetails) {
      let assignedLoanOfficer: SelectOption | undefined;

      if (this.props.loanApplicationDetails.bankEmployee !== undefined) {
        assignedLoanOfficer = {
          value: this.props.loanApplicationDetails.bankEmployee.id,
          label: bankEmployeeUtility.GetBankEmployeeName(this.props.loanApplicationDetails.bankEmployee)
        } as SelectOption;
      }

      let loanActionOptions = loanApplicationUtility.GetLoanActionOptions(this.props.loanApplicationDetails.status);

      if (this.props.loanApplicationDetails.customerAccount.balanceSheets != undefined && this.props.loanApplicationDetails.customerAccount.balanceSheets.length > 0) {
        this.props.loanApplicationDetails.customerAccount.balanceSheets.map((bs: SubmittedBalanceSheet) => bs.balanceSheet = JSON.parse(bs.balanceSheetData));
      }

      if (this.props.subdomain === Subdomain.Dealer) {
        let loanDocuments: LoanDocument[] = [...this.props.loanApplicationDetails.customerAccount.loanDocuments ?? []];
        loanDocuments = loanDocuments?.filter((ld: LoanDocument) => ld.loanId === this.props.loanApplicationDetails.id);
        this.props.loanApplicationDetails.customerAccount.loanDocuments = [...loanDocuments];
      }

      this.setState({ loanDetails: this.props.loanApplicationDetails, assignedLoanOfficer: assignedLoanOfficer, loanActionOptions: loanActionOptions });
    }

    if (previousProps.loanOfficers !== this.props.loanOfficers) {
      this.mapLoanOfficerOptions();
    }

    if (previousProps.apiRequestSuccess !== this.props.apiRequestSuccess && this.props.apiRequestSuccess === true) {
      this.resetNoteModalState();
      this.resetRequestInfoModalState();
      this.resetConfirmModalState();
      this.resetLoanOfferModalState();
      this.resetDocumentModalState();
      this.resetIdDocumentModalState();
    }

    if (previousProps.openDocumentRequests !== this.props.openDocumentRequests) {
      let documentRequests: DocumentRequest[] = [...this.props.openDocumentRequests ?? []];

      if (this.props.subdomain === Subdomain.Dealer) {
        documentRequests = documentRequests.filter((dr: DocumentRequest) => dr.loanId === this.props.loanApplicationDetails.id);
      }

      this.setState({ openDocumentRequests: documentRequests });
    }
  }

  loadLoanDetails() {
    this.props.toggleLoading(true);
    this.props.getLoanApplicationDetails(this.props.route.match.params.id);
  }

  mapLoanOfficerOptions(): void {
    var options: SelectOption[] = this.props.loanOfficers.map((o: BankEmployee) => {
      return {
        value: o.id,
        label: bankEmployeeUtility.GetBankEmployeeName(o)
      } as SelectOption;
    });

    this.setState({ loanOfficerOptions: options });
  }

  setLoanOfficerFilter(selectedOption: any): void {
    this.props.toggleLoading(true);
    this.props.assignLoanOfficerToLoan(this.state.loanDetails.id, selectedOption.value);
  }

  performLoanAction(selectedOption: any): void {
    // Because each loan action may be different and require different steps,
    // we will use this method to control the flow of where each action needs to go via the setState callback
    switch (selectedOption.value) {
      case LoanAction.Prequalify:
      case LoanAction.Approve:
      case LoanAction.DocumentsSent:
      case LoanAction.ConditionsMet:
      case LoanAction.Withdraw:
      case LoanAction.PrequalDeny:
      case LoanAction.BankDeny:
        this.setState({
          selectedLoanAction: selectedOption as SelectOption
        }, this.showConfirmModal.bind(this));
        break;

      case LoanAction.RequestInput:
        this.setRequestInfoModalState();
        break;

      default:
        break;
    }
  }

  showConfirmModal(): void {
    switch (this.state.selectedLoanAction?.value) {
      case LoanAction.Prequalify:
        this.setState({
          showConfirmModal: true,
          confirmModalTitle: 'Mark Pre-Qualified',
          confirmModalMessage: 'Are you sure you would like to mark the loan pre-qualified?',
          confirmButtonText: 'Confirm',
          confirmModalIsDanger: false
        });
        break;

      case LoanAction.Approve:
        this.setState({
          showConfirmModal: true,
          confirmModalTitle: 'Approve Loan',
          confirmModalMessage: 'Are you sure you would like to set the loan status to approved?',
          confirmButtonText: 'Confirm',
          confirmModalIsDanger: false
        });
        break;

      case LoanAction.DocumentsSent:
        this.setState({
          showConfirmModal: true,
          confirmModalTitle: 'Documents Sent',
          confirmModalMessage: 'Are you sure you would like to notify the customer that the documents have been sent?',
          confirmButtonText: 'Confirm',
          confirmModalIsDanger: false
        });
        break;

      case LoanAction.ConditionsMet:
        this.setState({
          showConfirmModal: true,
          confirmModalTitle: 'Finish Loan',
          confirmModalMessage: 'Are you sure you would like to set the loan status to conditions met?',
          confirmButtonText: 'Finish',
          confirmModalIsDanger: true
        });
        break;

      case LoanAction.Withdraw:
        this.setState({
          showConfirmModal: true,
          confirmModalTitle: 'Withdraw Loan',
          confirmModalMessage: 'Are you sure you would like to withdraw the current loan on behalf of the customer?',
          confirmButtonText: 'Withdraw',
          confirmModalIsDanger: true
        });
        break;

      case LoanAction.PrequalDeny:
      case LoanAction.BankDeny:
        if (this.state.loanDetails.customerAccount.loanDocuments?.find(ld => ld.loanId === this.state.loanDetails.id && ld.type === DocumentType.RegB) != undefined) {
          // the loan has a Reg B form uploaded already
          this.setState({
            showConfirmModal: true,
            confirmModalTitle: 'Deny Loan',
            confirmModalMessage: 'Are you sure you would like to set the loan status to officially denied?',
            confirmButtonText: 'Deny',
            confirmModalIsDanger: true
          });
        } else {
          this.setDocumentModalState('deny loan', undefined);
        }
        break;

      default:
        break;
    }
  }

  resetConfirmModalState(): void {
    this.setState({
      showConfirmModal: false,
      confirmModalTitle: '',
      confirmModalMessage: '',
      confirmButtonText: '',
      confirmModalIsDanger: false
    });
  }

  resetLoanOfferModalState(): void {
    this.setState({
      showLoanOfferModal: false,
      newLoanOffer: {} as LoanOffer
    })
  }

  confirmedCallback(): void {
    this.props.toggleLoading(true);

    switch (this.state.selectedLoanAction?.value) {
      case LoanAction.Prequalify:
        this.props.updateLoanStatus(this.state.loanDetails.id, LoanStatus.Prequalified);
        break;

      case LoanAction.Approve:
        this.props.updateLoanStatus(this.state.loanDetails.id, LoanStatus.Approved);
        break;

      case LoanAction.DocumentsSent:
        this.props.updateLoanStatus(this.state.loanDetails.id, LoanStatus.DocumentsSent);
        break;

      case LoanAction.ConditionsMet:
        this.props.updateLoanStatus(this.state.loanDetails.id, LoanStatus.ConditionsMet);
        break;

      case LoanAction.Withdraw:
        this.props.updateLoanStatus(this.state.loanDetails.id, LoanStatus.Withdrawn);
        break;

      case LoanAction.PrequalDeny:
        this.props.updateLoanStatus(this.state.loanDetails.id, LoanStatus.PrequalDenied);
        break;

      case LoanAction.BankDeny:
        this.props.updateLoanStatus(this.state.loanDetails.id, LoanStatus.BankDenied);
        break;

      default:
        this.props.toggleLoading(false);
        break;
    }
  }

  setNoteModalState(action: LoanNoteAction, selectedLoanNote?: LoanNote): void {
    if (action === 'create') {
      selectedLoanNote = {
        loanId: this.state.loanDetails.id,
        note: ''
      } as LoanNote;
    }

    this.setState({ showNoteModal: true, loanNoteModalAction: action, selectedLoanNote: selectedLoanNote });
  }


  setRequestInfoModalState(): void {
    this.setState({ showRequestInfoModal: true });
  }

  setLoanNoteValue(event: any): void {
    let loanNote: LoanNote = { ...this.state.selectedLoanNote ?? {} as LoanNote };
    loanNote.note = event.target.value;
    this.setState({ selectedLoanNote: loanNote });
  }

  getNoteModalTitle(): string {
    switch (this.state.loanNoteModalAction) {
      case 'create':
        return 'Create Note';

      case 'edit':
        return 'Edit Note';

      case 'delete':
        return 'Delete Note';

      default:
        return '';
    }
  }

  updateNote(): void {
    if (this.state.selectedLoanNote !== undefined) {
      this.props.toggleLoading(true);

      switch (this.state.loanNoteModalAction) {
        case 'create':
          this.props.addLoanNote(this.state.selectedLoanNote);
          break;

        case 'edit':
          this.props.updateLoanNote(this.state.selectedLoanNote);
          break;

        case 'delete':
          if (this.state.selectedLoanNote.id !== undefined) {
            this.props.deleteLoanNote(this.state.selectedLoanNote.id);
          }
          break;

        default:
          this.props.toggleLoading(false);
          break;
      }
    }
  }

  showLoanOfferModal(): void {
    this.setState({ showLoanOfferModal: true, newLoanOffer: { loanId: this.state.loanDetails.id, amount: this.state.loanDetails.loanAmount } as LoanOffer });
  }

  setLoanAmountValue(amount: number): void {
    let loanOffer: LoanOffer = { ...this.state.newLoanOffer };
    loanOffer.amount = amount;
    this.setState({ newLoanOffer: loanOffer });
  }

  setLendingLoanTypeValue(selectedOption: number): void {
    let loanOffer: LoanOffer = { ...this.state.newLoanOffer };
    loanOffer.lendingLoanTypeId = selectedOption;
    this.setState({ newLoanOffer: loanOffer });
  }

  setLoanOfferInterestTypeValue(selectedOption: number): void {
    let loanOffer: LoanOffer = { ...this.state.newLoanOffer };
    loanOffer.interestRateTypeId = selectedOption;
    this.setState({ newLoanOffer: loanOffer });
  }

  setFixedInterestRate(rate: number): void {
    let loanOffer: LoanOffer = { ...this.state.newLoanOffer };
    loanOffer.interestRate = rate;
    loanOffer.marginAdjustment = undefined;
    loanOffer.rateIncreasePeriodId = undefined;
    this.setState({ newLoanOffer: loanOffer });
  }

  setLoanTerms(term: number, loanTermType: LoanTermType): void {
    let loanOffer: LoanOffer = { ...this.state.newLoanOffer };
    
    if (loanTermType === LoanTermType.Years) {
      term = term * 12; // saving in months
    }

    console.log(term)
    loanOffer.term = term;

    this.setState({ newLoanOffer: loanOffer });
  }

  setVariableInterestRate(marginRate: number): void {
    let loanOffer: LoanOffer = { ...this.state.newLoanOffer };
    loanOffer.interestRate = (this.state.loanDetails.bank.baseRate ?? 0) + marginRate;
    loanOffer.marginAdjustment = marginRate;
    this.setState({ newLoanOffer: loanOffer });
  }

  setPeriodicRateIncreaseValue(selectedOption: number): void {
    let loanOffer: LoanOffer = { ...this.state.newLoanOffer };
    loanOffer.rateIncreasePeriodId = selectedOption;
    this.setState({ newLoanOffer: loanOffer });
  }

  setExpirationDate(date: Date): void {
    let loanOffer: LoanOffer = { ...this.state.newLoanOffer };
    loanOffer.expirationDate = date;
    this.setState({ newLoanOffer: loanOffer });
  }

  saveLoanOffer(): void {
    if (this.state.newLoanOffer !== undefined && this.validateLoanOfferData()) {
      this.props.toggleLoading(true);
      this.props.addLoanOffer(this.state.newLoanOffer);
    }
  }

  resetNoteModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showNoteModal: false, loanNoteModalAction: undefined, selectedLoanNote: undefined }, this.props.toggleAPIRequestSuccess.bind(this, false));
  }

  resetRequestInfoModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showRequestInfoModal: false }, this.props.toggleAPIRequestSuccess.bind(this, false));
  }

  resetBalanceSheetModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showBalanceSheetModal: false, selectedBalanceSheet: {} as BalanceSheet }, this.props.toggleAPIRequestSuccess.bind(this, false));
  }

  setBalanceSheetModalState(selectedBalanceSheet: BalanceSheet): void {
    this.setState({ showBalanceSheetModal: true, selectedBalanceSheet: selectedBalanceSheet });
  }

  validateLoanOfferData(): boolean {
    if (this.state.newLoanOffer !== undefined) {
      let validLoanAmount, validLoanType, validInterestType, validInterestRate, validRateIncreasePeriod, validExpirationDate, validTerm: boolean;

      validLoanAmount = this.state.newLoanOffer.amount > 0;

      validLoanType = this.state.newLoanOffer.lendingLoanTypeId > 0;

      validInterestType = this.state.newLoanOffer.interestRateTypeId > 0;

      validInterestRate = this.state.newLoanOffer.interestRate !== undefined && this.state.newLoanOffer.interestRate > 0;

      validRateIncreasePeriod = this.state.newLoanOffer.rateIncreasePeriodId === undefined || this.state.newLoanOffer.rateIncreasePeriodId > 0;

      validExpirationDate = this.state.newLoanOffer.expirationDate !== undefined && !isBefore(this.state.newLoanOffer.expirationDate, new Date());

      validTerm = this.state.newLoanOffer.term != undefined && this.state.newLoanOffer.term > 0;

      return validLoanAmount && validLoanType && validInterestType && validInterestRate && validRateIncreasePeriod && validExpirationDate && validTerm;
    } else {
      return false;
    }
  }

  setDocumentModalState(action: LoanDocumentAction, selectedDocument?: LoanDocument, selectedFile?: File, documentType?: DocumentType): void {
    if (action === 'download') {
      this.setState({ showDocumentModal: true, documentModalAction: action, selectedDownloadFile: selectedFile, selectedLoanDocument: selectedDocument ?? {} as LoanDocument });
    } else {
      let document: LoanDocument = {} as LoanDocument;

      if (action === 'create') {
        document = {
          customerAccountId: this.state.loanDetails.customerAccount.id,
          documentName: '',
          loanId: this.state.loanDetails.id,
          type: documentType,
          year: dealerDocuments.includes(documentType ?? -1 as DocumentType) ? new Date().getFullYear().toString() : undefined
        } as LoanDocument;
      } else if (action === 'deny loan') {
        document = {
          customerAccountId: this.state.loanDetails.customerAccount.id,
          documentName: '',
          loanId: this.state.loanDetails.id,
          type: DocumentType.RegB,
          year: new Date().getFullYear().toString(),
          note: ''
        } as LoanDocument;
      } else if (action === 'delete') {
        document = selectedDocument ?? {} as LoanDocument;
      }

      this.setState({ documentModalAction: action, selectedLoanDocument: document }, this.showDocumentModal);
    }
  }

  showDocumentModal(): void {
    this.setState({ showDocumentModal: true });
  }

  setDocumentList(documents: AddDocumentsRequest) {
    this.setState({ addLoanDocumentsRequest: documents });
  }

  async addDocument(): Promise<void> {
    if (this.state.selectedLoanDocument !== undefined) {
      this.props.toggleModalLoading(true);

      switch (this.state.documentModalAction) {
        case 'create':
        case 'deny loan':
          this.props.addLoanDocuments(this.state.addLoanDocumentsRequest);
          break;

        case 'delete':
          if (this.state.selectedLoanDocument.id !== undefined) {
            this.props.deleteLoanDocument(this.state.selectedLoanDocument.id);
          }
          break;

        case 'download':
          if (this.state.selectedLoanDocument.id !== undefined) {
            var result = await loanApplicationService.DownloadLoanDocument(this.state.selectedLoanDocument.id);
            if (result.file) {
              this.downloadFile(result.file, result.fileName);
            }
          }
          this.props.toggleModalLoading(false);
          this.resetDocumentModalState();
          break;

        default:
          this.props.toggleModalLoading(false);
          break;
      }
    }
  }

  async saveFSA2211(formData: FSA2211): Promise<void> {
    if (formData !== undefined) {
      this.props.toggleModalLoading(true);
      formData.loanId = this.state.loanDetails.id;
      if (this.state.loanDetails.fsa2211?.id) {
        formData.id = this.state.loanDetails.fsa2211.id;
      }
      await loanApplicationService.SaveFSA2211(formData).then(result => {
        if (result) {
          var updatedLoanDetails = this.state.loanDetails;

          if (updatedLoanDetails.fsa2211) {
            updatedLoanDetails.fsa2211.data = formData.data;
          } else {
            updatedLoanDetails.fsa2211 = formData;
          }

          this.setState({ loanDetails: updatedLoanDetails }, this.props.toggleAPIRequestSuccess.bind(this, false))
          this.resetFSAApplicationModalState();
        }
      });
      this.props.toggleModalLoading(false);
    }
  }

  async addDocumentRequests(requests: DocumentRequest[]): Promise<void> {
    this.props.toggleLoading(true);
    this.props.addDocumentRequests(requests, this.state.loanDetails.customerAccount.id);
    this.resetRequestInfoModalState();
  }

  async generateFSA221Pdf(formData: FSA2211): Promise<void> {
    if (formData !== undefined) {
      this.props.toggleModalLoading(true);
      formData.loanId = this.state.loanDetails.id;
      if (this.state.loanDetails.fsa2211?.id) {
        formData.id = this.state.loanDetails.fsa2211.id;
      }
      await loanApplicationService.GenerateFSA2211Pdf(formData).then(result => {
        //update state
        var updatedLoanDetails = this.state.loanDetails;

        if (updatedLoanDetails.fsa2211) {
          updatedLoanDetails.fsa2211.data = formData.data;
        } else {
          updatedLoanDetails.fsa2211 = formData;
        }

        this.setState({ loanDetails: updatedLoanDetails }, this.props.toggleAPIRequestSuccess.bind(this, false))
        this.resetFSAApplicationModalState();

        //download the file
        if (result.file) {
          this.downloadFile(result.file, result.fileName);
        }
      });
      this.props.toggleModalLoading(false);
      this.resetFSAApplicationModalState();
    }
  }

  async downloadFSA221Pdf(): Promise<void> {

    this.props.toggleModalLoading(true);

    await loanApplicationService.DownloadFSA2211Pdf(this.state.loanDetails.id).then(result => {

      //download the file
      if (result.file) {
        this.downloadFile(result.file, result.fileName);
      }
    });
    this.props.toggleModalLoading(false);
    this.resetFSAApplicationModalState();
  }

  private downloadFile(file: Blob, fileName: string) {
    var fileURL = window.URL.createObjectURL(file);
    var a: HTMLAnchorElement = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute('style', "display: none");
    a.href = fileURL;
    a.download = fileName.split('"').join(''); // Fix to remove preceding and trailing "_" in the file name when being downloaded
    a.click();
  }

  resetDocumentModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    let showDenialConfirm: boolean = (this.state.documentModalAction ?? '') === 'deny loan';

    this.setState({ showDocumentModal: false, documentModalAction: undefined, selectedLoanDocument: {} as LoanDocument }, () => {
      this.props.toggleAPIRequestSuccess.bind(this, false);

      if (showDenialConfirm) {
        let denialOption: SelectOption = this.state.selectedLoanAction ?? {} as SelectOption;
        this.performLoanAction(denialOption);
      }
    });
  }

  openFSAApplicationModal(): void {
    this.setState({ showFSAApplicationModal: true }, () => { });
  }

  resetFSAApplicationModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showFSAApplicationModal: false }, () => { });
  }

  deleteDocumentRequest(requestId: number) {
    this.props.deleteDocumentRequest(requestId, this.state.loanDetails.customerAccount.id);
  }

  showIdDocumentModal(action: IdDocumentModalAction, documentId?: number, documentSide?: IdDocumentSde) {
    if (action === 'view') {
      this.props.toggleModalLoading(true);
      this.setState({ showIdDocumentModal: true, idDocumentModalAction: 'view' });

      customerAccountService.DownloadIdDocument(documentId ?? 0, documentSide ?? IdDocumentSde.Front)
        .then((result: DownloadDocumentResponse) => {
          this.props.toggleModalLoading(false);
          if (result.file) {
            let blobUrl = URL.createObjectURL(result.file);
            this.setState({ idDocumentBlob: blobUrl });
          }
        });
    } else if (action === 'upload') {
      this.setState({ showIdDocumentModal: true, idDocumentModalAction: 'upload' });
    }
  }

  uploadIdDocument(documentUpload: IdDocumentUpload) {
    this.props.toggleModalLoading(true);
    this.props.uploadIdDocument(documentUpload);
  }

  resetIdDocumentModalState(): void {
    this.setState({ showIdDocumentModal: false, idDocumentBlob: '' });
  }

  render() {
    return (
      <>
        <Container>
          {this.state.loanDetails !== undefined && Object.keys(this.state.loanDetails).length > 0 &&
            (<>
              <div className="loans-details-title-row">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h1>Loan Details</h1>
                  </Col>
                  <Can
                    perform="Loan Officer"
                    yes={() => (
                      <>
                        {!loanApplicationUtility.GetClosedLoanStatuses().includes(this.state.loanDetails.status) &&
                          (<>
                            <Col md={3}>
                              <div className="justify-content-end">
                                <Select
                                  closeMenuOnSelect={false}
                                  placeholder={'Loan Action'}
                                  value={null}
                                  onChange={this.performLoanAction.bind(this)}
                                  options={this.state.loanActionOptions}
                                />
                              </div>
                            </Col>
                          </>)
                        }
                      </>
                    )}
                    no={() => <></>}
                  />

                </Row>
              </div>

              {/* Top Level Loan Application Information */}
              <div className="loan-application-details-section"></div>
              <LoanApplicationInformationSection
                loanDetails={this.state.loanDetails}
              />

              {/* Scorecard breakdown */}
              <Can
                perform="Loan Officer"
                yes={() => (
                  <div className="loan-application-details-section">
                    <ScorecardBreakdown
                      loanDetails={this.state.loanDetails}
                    />
                  </div>
                )}
                no={() => <></>}
              />

              {/* Fixed Asset Items */}
              <Can
                perform="Loan Officer"
                yes={() => (
                  <div className="loan-application-details-section">
                    <FixedAssetSection
                      loanDetails={this.state.loanDetails}
                    />
                  </div>
                )}
                no={() => <></>}
              />


              {/* Dealer Purchase Items */}
              <div className="loan-application-details-section">
                <DealerPurchaseSection
                  loanDetails={this.state.loanDetails}
                />
              </div>

              {/* Collateral Equity Items */}
              <div className="loan-application-details-section">
                <CollateralEquitySection
                  loanDetails={this.state.loanDetails}
                />
              </div>


              {/* Refinance Debt Items */}<Can
                perform="Loan Officer"
                yes={() => (
                  <div className="loan-application-details-section">
                    <RefinanceDebtSection
                      loanDetails={this.state.loanDetails}
                    />
                  </div>
                )}
                no={() => <></>}
              />


              {/* Operating Loan Items */}
              <Can
                perform="Loan Officer"
                yes={() => (
                  <div className="loan-application-details-section">
                    <OperatingLoanSection
                      loanDetails={this.state.loanDetails}
                    />
                  </div>
                )}
                no={() => <></>}
              />

              {/* Individual Applicants */}
              <div className="loan-application-details-section">
                <ApplicantSection
                  loanDetails={this.state.loanDetails}
                />
              </div>

              {/* Entity Applicant */}
              <div className="loan-application-details-section">
                <EntityApplicantSection
                  loanDetails={this.state.loanDetails}
                />
              </div>

              {/* Assigned Loan Officer */}
              <div className="loan-application-details-section">
                <h3>Assigned Loan Officer</h3>
                <Select
                  closeMenuOnSelect={true}
                  placeholder={'Unassigned'}
                  value={this.state.assignedLoanOfficer}
                  onChange={this.setLoanOfficerFilter.bind(this)}
                  options={this.state.loanOfficerOptions}
                  isDisabled={this.props.subdomain === Subdomain.Dealer}
                />
              </div>

              {/* Open Document Requests */}
              {this.state.openDocumentRequests.length > 0 &&
                <div className="loan-application-details-section">
                  <OpenDocumentRequestsSection
                    openRequests={this.state.openDocumentRequests}
                    deleteOpenRequest={this.deleteDocumentRequest.bind(this)}
                  />
                </div>
              }

              <Can
                perform="Loan Officer"
                yes={() => (
                  <FSAApplicationSection
                    openModal={this.openFSAApplicationModal.bind(this)} />
                )}
                no={() => <></>}
              />

              {/* Loan Document Items */}
              <div className="loan-application-details-section">
                <LoanDocumentsSection
                  loanDetails={this.state.loanDetails}
                  setDocumentModalState={this.setDocumentModalState.bind(this)}
                  setRequestModalState={this.setRequestInfoModalState.bind(this)}
                />
                <SecondaryButton
                  buttonText={'Upload File'}
                  onClick={this.setDocumentModalState.bind(this, 'create')}
                />
              </div>

              <div>
              <IdDocumentsSection
                  loanDetails={this.state.loanDetails}
                  setIdDocumentModalState={this.showIdDocumentModal.bind(this)}
                />
              </div>

              {/* Balance Sheet Items */}
              <Can
                perform="Loan Officer"
                yes={() => (
                  <div className="loan-application-details-section">
                    <BalanceSheetSection
                      loanDetails={this.state.loanDetails}
                      setBalanceSheetModalState={this.setBalanceSheetModalState.bind(this)}
                      setRequestModalState={this.setRequestInfoModalState.bind(this)}
                    />
                  </div>
                )}
                no={() => <></>}
              />

              {/* Loan Offers */}
              <div className="loan-application-details-section">
                <LoanOfferSection
                  loanDetails={this.state.loanDetails}
                />
                <Can
                  perform="Loan Officer"
                  yes={() => (
                    <div>
                      <SecondaryButton
                        buttonText={'Send Offer'}
                        onClick={this.showLoanOfferModal.bind(this)}
                      />
                    </div>
                  )}
                  no={() => <></>}
                />

              </div>

              {/* Loan Notes */}
              <div className="loan-application-details-section">
                <LoanNotesSection
                  loanDetails={this.state.loanDetails}
                  setNoteModalState={this.setNoteModalState.bind(this)}
                />
                <Can
                  perform="Loan Officer"
                  yes={() => (
                    <div>
                      <SecondaryButton
                        buttonText={'Add Note'}
                        onClick={this.setNoteModalState.bind(this, 'create')}
                      />
                    </div>
                  )}
                  no={() => <></>}
                />

              </div>

              {/* Modals */}
              <RequestInfoModal
                loanId={this.state.loanDetails.id}
                showModal={this.state.showRequestInfoModal}
                onModalCancel={this.resetRequestInfoModalState.bind(this)}
                onModalConfirm={this.addDocumentRequests.bind(this)}
              />

              <FSAApplicationModal
                showModal={this.state.showFSAApplicationModal}
                loanDetails={this.state.loanDetails}
                applicationType="FSA2211"
                modalAction="create"
                isLoading={this.props.isModalLoading}
                fsa2211Form={this.state.loanDetails.fsa2211}
                onModalCancel={this.resetFSAApplicationModalState.bind(this)}
                onModalConfirm={this.saveFSA2211.bind(this)}
                onModalGenerate={this.generateFSA221Pdf.bind(this)}
                onModalDownload={this.downloadFSA221Pdf.bind(this)}
              />

              <NoteModal
                showModal={this.state.showNoteModal}
                modalTitle={this.getNoteModalTitle()}
                loanNote={this.state.selectedLoanNote}
                noteAction={this.state.loanNoteModalAction}
                onNoteChange={this.setLoanNoteValue.bind(this)}
                onModalCancel={this.resetNoteModalState.bind(this)}
                onModalConfirm={this.updateNote.bind(this)}
              />

              <AddDocumentModal
                showModal={this.state.showDocumentModal}
                modalAction={this.state.documentModalAction}
                customerId={this.state.loanDetails.customerAccount.id}
                loanId={this.state.loanDetails.id}
                defaultType={this.state.selectedLoanDocument?.type}
                defaultYear={this.state.selectedLoanDocument?.year}
                isLoading={this.props.isModalLoading}
                documentName={this.state.selectedLoanDocument?.documentName}
                documentListChange={this.setDocumentList.bind(this)}
                onModalCancel={this.resetDocumentModalState.bind(this)}
                onModalConfirm={this.addDocument.bind(this)}
              />

              <IdDocumentModal
                showModal={this.state.showIdDocumentModal}
                image={this.state.idDocumentBlob}
                isLoading={this.props.isModalLoading}
                modalAction={this.state.idDocumentModalAction ?? 'view'}
                customerAccountId={this.state.loanDetails.customerAccount.id}
                onModalCancel={this.resetIdDocumentModalState.bind(this)}
                onModalConfirm={this.uploadIdDocument.bind(this)}
              />

              <ConfirmModal
                showModal={this.state.showConfirmModal}
                modalTitle={this.state.confirmModalTitle}
                modalMessage={this.state.confirmModalMessage}
                confirmButtonText={this.state.confirmButtonText}
                isDanger={this.state.confirmModalIsDanger}
                onModalCancel={this.resetConfirmModalState.bind(this)}
                onModalConfirm={this.confirmedCallback.bind(this)}
              />

              <OfferModal
                showModal={this.state.showLoanOfferModal}
                loanOffer={this.state.newLoanOffer}
                baseRate={this.state.loanDetails.bank.baseRate ?? 0}
                modalTitle={'Send Offer'}
                confirmButtonText={'Send Offer'}
                validData={this.validateLoanOfferData()}
                loanAmountChange={this.setLoanAmountValue.bind(this)}
                lendingLoanTypeChange={this.setLendingLoanTypeValue.bind(this)}
                interestTypeChange={this.setLoanOfferInterestTypeValue.bind(this)}
                periodicRateIncreaseChange={this.setPeriodicRateIncreaseValue.bind(this)}
                fixedInterestRateChange={this.setFixedInterestRate.bind(this)}
                loanTermChange={(termAmount) => this.setLoanTerms(termAmount, LoanTermType.Years)}
                variableInterestRateChange={this.setVariableInterestRate.bind(this)}
                expirationDateCallback={this.setExpirationDate.bind(this)}
                onModalCancel={this.resetLoanOfferModalState.bind(this)}
                onModalConfirm={this.saveLoanOffer.bind(this)}
              />

              <BalanceSheetModal
                showModal={this.state.showBalanceSheetModal}
                modalTitle={'Balance Sheet'}
                balanceSheet={this.state.selectedBalanceSheet}
                onBalanceSheetChange={() => { }}
                onModalCancel={this.resetBalanceSheetModalState.bind(this)}
                onModalConfirm={this.resetBalanceSheetModalState.bind(this)}
              />
            </>)
          }
        </Container>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'dark'}
            animation={'border'}
          />
        }
      </>
    );
  }
}

export default withAITracking(reactPlugin, connector(LoanDetailsPage));
