import React from "react";
import { AppState } from '../../../redux/store/store';
import { connect, ConnectedProps } from 'react-redux';
import { getLoggedInAccountInfo, setSubdomain } from '../../../redux/actions/system/actions';
import { Subdomain } from "../../../shared/Enums";
import globalUtility from "utilities/GlobalUtility";

const mapState = (state: AppState) => {
  const { system } = state;
  const { loggedInAccount } = system;
  return {
    loggedInAccount
  };
};

const mapDispatch = {
  setSubdomain: (subdomain: Subdomain) => setSubdomain(subdomain),
  getLoggedInEmployeeInfo: () => getLoggedInAccountInfo()
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

class ProfileTag extends React.Component<Props> {
  componentDidMount() {
    this.loadProfile();
  }

  loadProfile(): void {
    // For right now, we will not toggle the isLoading spinner
    // This can just load "silently"
    let subdomain: Subdomain = globalUtility.GetSubdomain();

    this.props.setSubdomain(subdomain)
    this.props.getLoggedInEmployeeInfo();
  }

  render() {
    return (
      <>
        {Object.keys(this.props.loggedInAccount).length > 0 ?
          <span style={{color: 'white'}}>{`Hello, ${this.props.loggedInAccount.firstName}!`}</span>
          :
          <span style={{color: 'white'}}>{'Hello!'}</span>
        }
      </>
    );
  }
}

export default connector(ProfileTag);