import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./RefinanceDebtSection.css";
import { LoanType } from "../../../shared/Enums";
import loanApplicationUtility from "../../../utilities/LoanApplicationUtility";

interface RefinanceDebtSectionProps {
  loanDetails: LoanApplication
}

export default class RefinanceDebtSection extends React.Component<RefinanceDebtSectionProps> {
  render() {
    return (
      <>
        {this.props.loanDetails.loanType === LoanType.RefinanceDebt &&
          (<>
            <div className="loan-application-details-section">
              <h3>Refinance Details</h3>
              <Table bordered striped={false} size="sm">
                <tbody>
                  {this.props.loanDetails.refinanceDebt !== undefined &&
                    (<>
                      <tr>
                        <td width="250px" className="app-info"><strong>Refinance Description</strong></td>
                        <td className="app-info">{this.props.loanDetails.refinanceDebt?.description}</td>
                      </tr>
                    </>)
                  }

                  {this.props.loanDetails.refinanceCollateralDescription !== undefined &&
                    (<>
                      <tr>
                        <td width="250px" className="app-info"><strong>Collateral Description</strong></td>
                        <td className="app-info">{this.props.loanDetails.refinanceCollateralDescription?.description}</td>
                      </tr>
                      <tr>
                        <td width="250px" className="app-info"><strong>Collateral Value</strong></td>
                        <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.refinanceCollateralDescription?.value)}</td>
                      </tr>
                    </>)
                  }
                </tbody>
              </Table>
            </div>
          </>)
        }
      </>
    );
  }
}