import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../../../utilities/AppInsights";
import { AppState } from '../../../redux/store/store';
import { toggleAPIRequestSuccess, toggleLoading } from '../../../redux/actions/system/actions';
import { addNewDealer, addNewDealerSalesman, getDealers, updateDealer, updateDealerSalesman } from '../../../redux/actions/dealer/actions';
import { connect, ConnectedProps } from 'react-redux';
import Container from "react-bootstrap/esm/Container";
import "react-datepicker/dist/react-datepicker.css";
import "./AddDealerPage.css"
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import SecondaryButton from "shared/components/SecondaryButton";
import Table from "react-bootstrap/esm/Table";
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import PrimaryButton from "shared/components/PrimaryButton";
import Button from "react-bootstrap/esm/Button";
import Dealer from "models/dealerPurchase/dealer";
import DealerSalesman from "models/dealerPurchase/dealerSalesman";

const mapState = (state: AppState, route: any) => {
  const { system, dealer } = state;
  const { isLoading, apiRequestSuccess, loggedInEmployee } = system;
  const { dealers } = dealer;
  return {
    isLoading,
    apiRequestSuccess,
    dealers,
    route,
    loggedInEmployee
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  toggleAPIRequestSuccess: (apiRequestSuccess: boolean) => toggleAPIRequestSuccess(apiRequestSuccess),
  getDealers: () => getDealers(),
  addNewDealer: (dealer: Dealer) => addNewDealer(dealer),
  updateDealer: (dealer: Dealer) => updateDealer(dealer),
  addNewDealerSalesman: (dealerSalesman: DealerSalesman) => addNewDealerSalesman(dealerSalesman),
  updateDealerSalesman: (dealerSalesman: DealerSalesman) => updateDealerSalesman(dealerSalesman)
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

type DealerAction = 'create' | 'edit';
type DealerSalesmanAction = 'create' | 'edit';

interface AddDealerPageState {
  dealers: Dealer[];
  showAddDealerModal: boolean;
  showAddDealerSalesmanModal: boolean;
  dealerModalAction?: DealerAction;
  dealerSalesmanModalAction?: DealerSalesmanAction;
  selectedDealer: Dealer;
  selectedDealerSalesman: DealerSalesman;
}

class AddDealerPage extends React.Component<Props, AddDealerPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      dealers: [],
      showAddDealerModal: false,
      showAddDealerSalesmanModal: false,
      selectedDealer: {} as Dealer,
      selectedDealerSalesman: {} as DealerSalesman
    }
  }

  componentDidMount() {
    this.loadDealers();
  }

  componentDidUpdate(previousProps: Props) {
    if (previousProps.dealers !== this.props.dealers) {
      this.setState({ dealers: this.props.dealers });
    }

    if (previousProps.apiRequestSuccess !== this.props.apiRequestSuccess && this.props.apiRequestSuccess) {
      if (this.state.dealerModalAction != undefined) this.resetAddDealerModalState();
      // Known issue: The salesman list doesn't re-render after adding a salesman
      // Will add low priority bug to fix
      if (this.state.dealerSalesmanModalAction != undefined) this.resetAddDealerSalesmanModalState();
    }
  }

  loadDealers(): void {
    this.props.toggleLoading(true);
    this.props.getDealers();
  }

  // State Setters
  setDealerNameState(event: any): void {
    let dealer: Dealer = { ...this.state.selectedDealer };
    dealer.name = event.target.value;
    this.setState({ selectedDealer: dealer });
  }

  setDealerCityState(event: any): void {
    let dealer: Dealer = { ...this.state.selectedDealer };
    dealer.city = event.target.value;
    this.setState({ selectedDealer: dealer });
  }

  setDealerStateValueState(event: any): void {
    let dealer: Dealer = { ...this.state.selectedDealer };
    dealer.state = event.target.value;
    this.setState({ selectedDealer: dealer });
  }

  setDealerZipCodeState(event: any): void {
    let dealer: Dealer = { ...this.state.selectedDealer };
    dealer.zipCode = event.target.value;
    this.setState({ selectedDealer: dealer });
  }

  setDealerSalesmanEmailState(event: any): void {
    let dealerSalesman: DealerSalesman = { ...this.state.selectedDealerSalesman };
    dealerSalesman.email = event.target.value;
    this.setState({ selectedDealerSalesman: dealerSalesman });
  }

  setDealerSalesmanFirstNameState(event: any): void {
    let dealerSalesman: DealerSalesman = { ...this.state.selectedDealerSalesman };
    dealerSalesman.firstName = event.target.value;
    this.setState({ selectedDealerSalesman: dealerSalesman });
  }

  setDealerSalesmanLastNameState(event: any): void {
    let dealerSalesman: DealerSalesman = { ...this.state.selectedDealerSalesman };
    dealerSalesman.lastName = event.target.value;
    this.setState({ selectedDealerSalesman: dealerSalesman });
  }

  setDealerSalesmanPhoneNumberState(event: any): void {
    let dealerSalesman: DealerSalesman = { ...this.state.selectedDealerSalesman };
    dealerSalesman.phoneNumber = event.target.value.replace(/\D/g, '');
    this.setState({ selectedDealerSalesman: dealerSalesman });
  }

  setDealerModalState(action: DealerAction, selectedDealer?: Dealer): void {
    if (action === 'create') {
      selectedDealer = { bankId: this.props.loggedInEmployee.bankId, active: true } as Dealer;
    }

    this.setState({ showAddDealerModal: true, dealerModalAction: action, selectedDealer: selectedDealer ?? {} as Dealer });
  }

  setSelectedDealerState(selectedDealer: Dealer): void {
    this.setState({ selectedDealer: selectedDealer });
  }

  setDealerSalesmanModalState(action: DealerSalesmanAction, selectedSalesman?: DealerSalesman): void {
    if (action === 'create') {
      selectedSalesman = { dealerId: this.state.selectedDealer.id, dealer: { ...this.state.selectedDealer } } as DealerSalesman;
    } else {
      if (selectedSalesman != undefined) {
        selectedSalesman.dealer = { ...this.state.selectedDealer };
        selectedSalesman.dealerId = this.state.selectedDealer.id;
      }
    }

    this.setState({ showAddDealerSalesmanModal: true, dealerSalesmanModalAction: action, selectedDealerSalesman: selectedSalesman ?? {} as DealerSalesman });
  }

  resetAddDealerModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showAddDealerModal: false, selectedDealer: {} as Dealer }, this.props.toggleAPIRequestSuccess.bind(this, false));
  }

  resetAddDealerSalesmanModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showAddDealerSalesmanModal: false, selectedDealerSalesman: {} as DealerSalesman }, this.props.toggleAPIRequestSuccess.bind(this, false));
  }

  getDealerModalTitle(): string {
    switch (this.state.dealerModalAction) {
      case 'create':
        return 'Add Dealer';

      case 'edit':
        return 'Edit Dealer';

      default:
        return '';
    }
  }

  getDealerModalActionButton(): JSX.Element {
    switch (this.state.dealerModalAction) {
      case 'create':
        return <PrimaryButton buttonText={'Add Dealer'} disabled={!this.validateDealerData()} onClick={this.updateDealer.bind(this)} />;

      case 'edit':
        return <PrimaryButton buttonText={'Update Dealer'} disabled={!this.validateDealerData()} onClick={this.updateDealer.bind(this)} />;

      default:
        return <></>;
    }
  }

  getDealerSalesmanModalTitle(): string {
    switch (this.state.dealerSalesmanModalAction) {
      case 'create':
        return 'Add Salesman';

      case 'edit':
        return 'Edit Salesman';

      default:
        return '';
    }
  }

  getDealerSalesmanModalActionButton(): JSX.Element {
    switch (this.state.dealerSalesmanModalAction) {
      case 'create':
        return <PrimaryButton buttonText={'Add Salesman'} disabled={!this.validateDealerSalesmanData()} onClick={this.updateDealerSalesman.bind(this)} />;

      case 'edit':
        return <PrimaryButton buttonText={'Update Salesman'} disabled={!this.validateDealerSalesmanData()} onClick={this.updateDealerSalesman.bind(this)} />;

      default:
        return <></>;
    }
  }

  updateDealer() {
    if (this.state.selectedDealer !== undefined) {
      this.props.toggleLoading(true);

      switch (this.state.dealerModalAction) {
        case 'create':
          this.props.addNewDealer(this.state.selectedDealer);
          break;

        case 'edit':
          this.props.updateDealer(this.state.selectedDealer);
          break;

        default:
          this.props.toggleLoading(false);
          break;
      }
    }
  }

  updateDealerSalesman() {
    if (this.state.selectedDealerSalesman !== undefined) {
      this.props.toggleLoading(true);
      this.state.selectedDealerSalesman.dealer = {} as Dealer;

      switch (this.state.dealerSalesmanModalAction) {
        case 'create':
          this.props.addNewDealerSalesman(this.state.selectedDealerSalesman);
          break;

        case 'edit':
          this.props.updateDealerSalesman(this.state.selectedDealerSalesman);
          break;

        default:
          this.props.toggleLoading(false);
          break;
      }


    }
  }

  noDealerSelected(): boolean {
    return (this.state.selectedDealer == undefined || Object.keys(this.state.selectedDealer).length === 0);
  }

  validateDealerData(): boolean {
    let validDealerName, validDealerCity, validDealerState, validDealerZip, nonDuplicate: boolean = false;

    if (this.state.selectedDealer != undefined) {
      validDealerName = (this.state.selectedDealer.name != undefined && this.state.selectedDealer.name.length > 0);

      validDealerCity = (this.state.selectedDealer.city != undefined && this.state.selectedDealer.city.length > 0);

      validDealerState = (this.state.selectedDealer.state != undefined && this.state.selectedDealer.state.length > 0);

      validDealerZip = (this.state.selectedDealer.zipCode != undefined && this.state.selectedDealer.zipCode.length > 0);

      nonDuplicate = ((this.state.dealers == undefined || this.state.dealers.length === 0)
        || (this.state.dealers.findIndex(ab => ab.id !== this.state.selectedDealer.id && ab.name === this.state.selectedDealer.name && ab.city === this.state.selectedDealer.city && ab.state === this.state.selectedDealer.state && ab.zipCode === this.state.selectedDealer.zipCode) === -1));

      return validDealerName
        && validDealerCity
        && validDealerState
        && validDealerZip
        && nonDuplicate
    } else {
      return false;
    }
  }

  validateDealerSalesmanData(): boolean {
    let validDealerAssignment, validEmail, validFirstName, validLastName, validPhoneNumber: boolean = false;

    if (this.state.selectedDealerSalesman != undefined) {
      validDealerAssignment = (this.state.selectedDealerSalesman.dealerId != undefined && this.state.selectedDealerSalesman.dealerId === this.state.selectedDealer.id);

      validEmail = (this.state.selectedDealerSalesman.email != undefined && this.state.selectedDealerSalesman.email.length > 0);

      validFirstName = (this.state.selectedDealerSalesman.firstName != undefined && this.state.selectedDealerSalesman.firstName.length > 0);

      validLastName = (this.state.selectedDealerSalesman.lastName != undefined && this.state.selectedDealerSalesman.lastName.length > 0);

      validPhoneNumber = (this.state.selectedDealerSalesman.phoneNumber != undefined && this.state.selectedDealerSalesman.phoneNumber.toString().length > 0);

      console.log('valid phone: ', validPhoneNumber, this.state.selectedDealerSalesman.phoneNumber)

      return validDealerAssignment
        && validEmail
        && validFirstName
        && validLastName
        && validPhoneNumber
    } else {
      return false;
    }
  }

  render() {
    return (
      <>
        <Container>
          <div>
            <Row className="add-dealer-title-row align-items-center">
              <Col>
                <h1>Dealers</h1>
              </Col>
              <Col className="d-flex justify-content-end">
                <PrimaryButton buttonText={'Add Dealer'} onClick={this.setDealerModalState.bind(this, 'create')} />
              </Col>
            </Row>
            {this.state.dealers && this.state.dealers.length > 0 ?
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Dealer Code</th>
                    <th>Dealer Name</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zip Code</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.dealers.map((d: Dealer, index: number) => {
                    return (
                      <tr key={`add-dealer-list-${index}`} onClick={this.setSelectedDealerState.bind(this, d)}>
                        <td>{d.id}</td>
                        <td>{d.name}</td>
                        <td>{d.city}</td>
                        <td>{d.state}</td>
                        <td>{d.zipCode}</td>
                        <td>{d.active ? 'Yes' : 'No'}</td>
                        <td>
                          <Button variant="link" onClick={this.setDealerModalState.bind(this, 'edit', d)} >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </Table>
              :
              <h3>No dealers.</h3>
            }
          </div>

          <div>
            <Row className="add-dealer-title-row align-items-center">
              <Col>
                <h1>Dealer Salesman</h1>
              </Col>
              <Col className="d-flex justify-content-end">
                <PrimaryButton buttonText={'Add Salesman'} disabled={this.noDealerSelected()} onClick={this.setDealerSalesmanModalState.bind(this, 'create')} />
              </Col>
            </Row>
            {(!this.noDealerSelected()) ?
              <>
                <h2>{`${this.state.selectedDealer.name} - ${this.state.selectedDealer.city}, ${this.state.selectedDealer.state} ${this.state.selectedDealer.zipCode}`}</h2>
                {this.state.selectedDealer.dealerSalesmen?.length > 0 ?
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Salesman #</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.selectedDealer.dealerSalesmen.map((ds: DealerSalesman, index: number) => {
                        return (
                          <tr key={`add-dealer-salesman-list-${index}`}>
                            <td>{ds.id}</td>
                            <td>{`${ds.firstName} ${ds.lastName}`}</td>
                            <td>{ds.email}</td>
                            <td>{ds.phoneNumber}</td>
                            <td>
                              <Button variant="link" onClick={this.setDealerSalesmanModalState.bind(this, 'edit', ds)} >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </Table>
                  :
                  <h3>No salesman.</h3>
                }
              </>
              :
              <h3>Please select a dealer.</h3>
            }
          </div>
          <Modal show={this.state.showAddDealerModal} onHide={this.resetAddDealerModalState.bind(this)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>{this.getDealerModalTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Dealer Name*</Form.Label>
                  <Form.Control value={this.state.selectedDealer.name} onChange={this.setDealerNameState.bind(this)}></Form.Control>

                  <Form.Label>City*</Form.Label>
                  <Form.Control value={this.state.selectedDealer.city} onChange={this.setDealerCityState.bind(this)}></Form.Control>

                  <Form.Label>State*</Form.Label>
                  <Form.Control value={this.state.selectedDealer.state} onChange={this.setDealerStateValueState.bind(this)}></Form.Control>

                  <Form.Label>Zip*</Form.Label>
                  <Form.Control value={this.state.selectedDealer.zipCode} onChange={this.setDealerZipCodeState.bind(this)}></Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <SecondaryButton buttonText={'Close'} onClick={this.resetAddDealerModalState.bind(this)} />
              {this.getDealerModalActionButton()}
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.showAddDealerSalesmanModal} onHide={this.resetAddDealerSalesmanModalState.bind(this)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>{this.getDealerSalesmanModalTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h2>{`${this.state.selectedDealer.name} - ${this.state.selectedDealer.city}, ${this.state.selectedDealer.state} ${this.state.selectedDealer.zipCode}`}</h2>
              <Form>
                <Form.Group>
                  <Form.Label>Email*</Form.Label>
                  <Form.Control value={this.state.selectedDealerSalesman.email} onChange={this.setDealerSalesmanEmailState.bind(this)}></Form.Control>

                  <Form.Label>First Name*</Form.Label>
                  <Form.Control value={this.state.selectedDealerSalesman.firstName} onChange={this.setDealerSalesmanFirstNameState.bind(this)}></Form.Control>

                  <Form.Label>Last Name*</Form.Label>
                  <Form.Control value={this.state.selectedDealerSalesman.lastName} onChange={this.setDealerSalesmanLastNameState.bind(this)}></Form.Control>

                  <Form.Label>Phone Number*</Form.Label>
                  <Form.Control value={this.state.selectedDealerSalesman.phoneNumber} onChange={this.setDealerSalesmanPhoneNumberState.bind(this)}></Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <SecondaryButton buttonText={'Close'} onClick={this.resetAddDealerSalesmanModalState.bind(this)} />
              {this.getDealerSalesmanModalActionButton()}
            </Modal.Footer>
          </Modal>
        </Container>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'dark'}
            animation={'border'}
          />
        }
      </>
    );
  }
}

export default withAITracking(reactPlugin, connector(AddDealerPage));