import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import Container from "react-bootstrap/esm/Container";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "redux/store/store";
import { toggleAPIRequestSuccess, toggleLoading } from '../../../redux/actions/system/actions';
import { reactPlugin } from "utilities/AppInsights";
import "./UsersPage.css"
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import BankEmployee from "models/bank/bankEmployee";
import { getEmployeeBank, getLoanOfficers } from "redux/actions/bank/actions";
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import Table from "react-bootstrap/esm/Table";
import EditProfileModal from "components/modals/profileModal/EditProfileModal";
import PrimaryButton from "shared/components/PrimaryButton";

const mapState = (state: AppState, route: any) => {
  const { system, bank } = state;
  const { isLoading, apiRequestSuccess } = system;
  const { employeeBank, loanOfficers } = bank;
  return {
    isLoading,
    apiRequestSuccess,
    employeeBank,
    loanOfficers,
    route
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  toggleAPIRequestSuccess: (apiRequestSuccess: boolean) => toggleAPIRequestSuccess(apiRequestSuccess),
  getEmployeeBank: () => getEmployeeBank(),
  getLoanOfficers: () => getLoanOfficers()
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

type UserAction = 'create' | 'edit';

interface UsersPageState {
  loanOfficers: BankEmployee[];
  showUserEditModal: boolean;
  modalAction?: UserAction;
  selectedLoanOfficer: BankEmployee;
}

class UsersPage extends React.Component<Props, UsersPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loanOfficers: [],
      showUserEditModal: false,
      selectedLoanOfficer: {} as BankEmployee
    }
  }

  componentDidMount() {
    this.loadLoanOfficers();
  }

  componentDidUpdate(previousProps: Props) {
    if (previousProps.loanOfficers !== this.props.loanOfficers) {
      this.setState({ loanOfficers: this.props.loanOfficers });
    }

    if (previousProps.apiRequestSuccess !== this.props.apiRequestSuccess && this.props.apiRequestSuccess) {
      this.resetBankEmployeeModalState();
    }
  }

  loadLoanOfficers(): void {
    this.props.toggleLoading(true);
    if (Object.keys(this.props.employeeBank).length === 0) this.props.getEmployeeBank();
    this.props.getLoanOfficers();
  }

  setBankEmployeeModalState(action: UserAction, selectedLoanOfficer: BankEmployee): void {
    this.setState({ showUserEditModal: true, modalAction: action, selectedLoanOfficer: selectedLoanOfficer });
  }

  resetBankEmployeeModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showUserEditModal: false, selectedLoanOfficer: {} as BankEmployee, modalAction: undefined});
    
  }

  render() {
    return (
      <>
        <Container>
          <div>
            <Row className="users-title-row align-items-center">
              <Col>
                <h1>{`${this.props.employeeBank.name ?? ''} Users`}</h1>
              </Col>
              <Col className="d-flex justify-content-end">
                <PrimaryButton buttonText={'Create Loan Officer'} onClick={this.setBankEmployeeModalState.bind(this, 'create', { bankId: this.props.employeeBank.id } as BankEmployee)} />
              </Col>
            </Row>
            {this.state.loanOfficers && this.state.loanOfficers.length > 0 ?
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>User Role</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.loanOfficers.map((be: BankEmployee, index: number) => {
                    return (
                      <tr className="loan-officer-row" key={`loan-officer-list-${index}`} onClick={this.setBankEmployeeModalState.bind(this, 'edit', be)}>
                        <td>{`${be.firstName} ${be.lastName}`}</td>
                        <td>{be.email}</td>
                        <td>{be.securityRole.name}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </Table>
              :
              <h3>No loan officers.</h3>
            }
          </div>
          <EditProfileModal
            showModal={this.state.showUserEditModal}
            sourcePage={'users'}
            action={this.state.modalAction ?? 'edit'}
            showSecurityRole={true}
            selectedBankEmployee={this.state.selectedLoanOfficer}
            onModalCancel={this.resetBankEmployeeModalState.bind(this)}
          />
        </Container>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'dark'}
            animation={'border'}
          />
        }
      </>
    )
  }
}

export default withAITracking(reactPlugin, connector(UsersPage));
