import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./ScorecardBreakdown.css"

interface ScorecardBreakdownProps {
  loanDetails: LoanApplication
}

export default class ScorecardBreakdown extends React.Component<ScorecardBreakdownProps> {
  render() {
    return (
      <>
        <div>
          <h3>Scorecard Breakdown</h3>

          <Table bordered striped={false} size="sm">
            <tbody>
              <tr>
                <td width="250px" className="app-info"><strong>Total Loan-To-Value Points</strong></td>
                <td className="app-info">{this.props.loanDetails.scorecardBreakdown?.loanToValuePoints}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Total Credit Score Points</strong></td>
                <td className="app-info">{this.props.loanDetails.scorecardBreakdown?.creditScorePoints}</td>
              </tr>
              <tr>
                <td width="250px" className="app-info"><strong>Total Experience Points</strong></td>
                <td className="app-info">{this.props.loanDetails.scorecardBreakdown?.yearsFarmingPoints}</td>
              </tr>
            </tbody>
          </Table>
        </div>

      </>
    );
  }
}