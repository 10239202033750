import Dealer from "models/dealerPurchase/dealer";
import DealerSalesman from "models/dealerPurchase/dealerSalesman";

export const GET_DEALERS = 'GET_DEALERS';
export const ADD_DEALER = 'ADD_NEW_DEALER';
export const UPDATE_DEALER = 'UPDATE_DEALER';
export const ADD_DEALER_SALESMAN = 'ADD_DEALER_SALESMAN';
export const UPDATE_DEALER_SALESMAN = 'UPDATE_DEALER_SALESMAN';

export interface DealerState {
  dealers: Dealer[];
}
interface GetDealersAction {
  type: typeof GET_DEALERS;
  payload: {
    dealers: Dealer[];
  }
}

interface AddNewDealerAction {
  type: typeof ADD_DEALER;
  payload: {
    dealer: Dealer;
  }
}

interface UpdateDealerAction {
  type: typeof UPDATE_DEALER;
  payload: {
    dealer: Dealer;
  }
}

interface AddNewDealerSalesmanAction {
  type: typeof ADD_DEALER_SALESMAN;
  payload: {
    dealerId: number;
    dealerSalesman: DealerSalesman;
  }
}

interface UpdateDealerSalesmanAction {
  type: typeof UPDATE_DEALER_SALESMAN;
  payload: {
    dealerId: number;
    dealerSalesman: DealerSalesman;
  }
}

export type DealerActionTypes = GetDealersAction
  | AddNewDealerAction
  | UpdateDealerAction
  | AddNewDealerSalesmanAction
  | UpdateDealerSalesmanAction;