import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../../../utilities/AppInsights";
import { AppState } from '../../../redux/store/store';
import { toggleAPIRequestSuccess, toggleLoading } from '../../../redux/actions/system/actions';
import { addNewBank, getActiveBanks, adminUpdateBank } from '../../../redux/actions/bank/actions';
import { connect, ConnectedProps } from 'react-redux';
import Container from "react-bootstrap/esm/Container";
import "react-datepicker/dist/react-datepicker.css";
import "./AddBankPage.css"
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import SecondaryButton from "shared/components/SecondaryButton";
import Bank from "../../../models/bank/bank";
import Table from "react-bootstrap/esm/Table";
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import PrimaryButton from "shared/components/PrimaryButton";
import InputGroup from "react-bootstrap/esm/InputGroup";
import BankEmployee from "models/bank/bankEmployee";
import EditProfileModal from "components/modals/profileModal/EditProfileModal";
import Button from "react-bootstrap/esm/Button";
import { SecurityRoleEnum } from "shared/Enums";

const mapState = (state: AppState, route: any) => {
  const { system, bank } = state;
  const { isLoading, apiRequestSuccess } = system;
  const { activeBanks } = bank;
  return {
    isLoading,
    apiRequestSuccess,
    activeBanks,
    route
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  toggleAPIRequestSuccess: (apiRequestSuccess: boolean) => toggleAPIRequestSuccess(apiRequestSuccess),
  getActiveBanks: () => getActiveBanks(),
  addNewBank: (bank: Bank) => addNewBank(bank),
  updateBank: (bank: Bank) => adminUpdateBank(bank)
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

type BankAction = 'create' | 'edit';

interface AddBankPagePageState {
  activeBanks: Bank[];
  showAddBankModal: boolean;
  modalAction?: BankAction;
  selectedBank: Bank;
  showUserEditModal: boolean;
}

class AddBankPage extends React.Component<Props, AddBankPagePageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      activeBanks: [],
      showAddBankModal: false,
      selectedBank: {} as Bank,
      showUserEditModal: false
    }
  }

  componentDidMount() {
    this.loadActiveBanks();
  }

  componentDidUpdate(previousProps: Props) {
    if (previousProps.activeBanks !== this.props.activeBanks) {
      this.setState({ activeBanks: this.props.activeBanks });
    }

    if (previousProps.apiRequestSuccess !== this.props.apiRequestSuccess && this.props.apiRequestSuccess) {
      this.resetAddBankModalState();
    }
  }

  loadActiveBanks(): void {
    this.props.toggleLoading(true);
    this.props.getActiveBanks();
  }

  // ** Utilize this later whenever we decide to export list of banks
  // exportResults() {
  //   this.props.toggleLoading(true);
  //   loanApplicationService.ExportLoanApplications('active', this.state.loanTypeFilter.map(f => +f.value), this.state.scorecardLevelFilter.map(f => +f.value), this.state.loanStatusFilter.map(f => +f.value), this.state.loanOfficerFilter.map(f => +f.value), this.state.submittedStartDateFilter, this.state.submittedEndDateFilter)
  //     .then(result => {
  //       this.props.toggleLoading(false);
  //     })
  // }

  // State Setters
  setBankNameState(event: any): void {
    let bank: Bank = { ...this.state.selectedBank };
    bank.name = event.target.value;
    this.setState({ selectedBank: bank });
  }

  setBankAddressState(event: any): void {
    let bank: Bank = { ...this.state.selectedBank };
    bank.streetAddress = event.target.value;
    this.setState({ selectedBank: bank });
  }

  setBankAddress2State(event: any): void {
    let bank: Bank = { ...this.state.selectedBank };
    bank.streetAddress2 = event.target.value.length > 0 ? event.target.value : undefined;
    this.setState({ selectedBank: bank });
  }

  setBankCityState(event: any): void {
    let bank: Bank = { ...this.state.selectedBank };
    bank.city = event.target.value;
    this.setState({ selectedBank: bank });
  }

  setBankStateValueState(event: any): void {
    let bank: Bank = { ...this.state.selectedBank };
    bank.state = event.target.value;
    this.setState({ selectedBank: bank });
  }

  setBankZipCodeState(event: any): void {
    let bank: Bank = { ...this.state.selectedBank };
    bank.zipCode = event.target.value;
    this.setState({ selectedBank: bank });
  }

  setBankBaseRateState(event: any): void {
    let bank: Bank = { ...this.state.selectedBank };
    bank.baseRate = event.target.value.length > 0 ? +event.target.value : undefined;
    this.setState({ selectedBank: bank });
  }

  setBankModalState(action: BankAction, selectedBank?: Bank): void {
    if (action === 'create') {
      selectedBank = {} as Bank;
    }

    this.setState({ showAddBankModal: true, modalAction: action, selectedBank: selectedBank ?? {} as Bank });
  }

  resetAddBankModalState(): void {
    // Resetting the modal values and setting the request success value back to false
    this.setState({ showAddBankModal: false, selectedBank: {} as Bank }, this.props.toggleAPIRequestSuccess.bind(this, false));
  }

  setInitialBankEmployee(bankEmployee: BankEmployee): void {
    let bank: Bank = { ...this.state.selectedBank };
    bank.manager = bankEmployee;
    this.setState({ selectedBank: bank, showUserEditModal: false })
  }

  getModalTitle(): string {
    switch (this.state.modalAction) {
      case 'create':
        return 'Add Bank';

      case 'edit':
        return 'Edit Bank';

      default:
        return '';
    }
  }

  getModalActionButton(): JSX.Element {
    switch (this.state.modalAction) {
      case 'create':
        return <PrimaryButton buttonText={'Add Bank'} disabled={!this.validateData()} onClick={this.updateBank.bind(this)} />;

      case 'edit':
        return <PrimaryButton buttonText={'Update Bank'} disabled={!this.validateData()} onClick={this.updateBank.bind(this)} />;

      default:
        return <></>;
    }
  }

  updateBank() {
    if (this.state.selectedBank !== undefined && this.validateData()) {
      this.props.toggleLoading(true);

      switch (this.state.modalAction) {
        case 'create':
          this.props.addNewBank(this.state.selectedBank);
          break;

        case 'edit':
          this.props.updateBank(this.state.selectedBank);
          break;

        default:
          this.props.toggleLoading(false);
          break;
      }


    }
  }

  validateData(): boolean {
    let validBankName, validBankAddress, validBankAddress2, validBankCity, validBankState, validBankZip, validBaseRate, nonDuplicate, validManager: boolean = false;

    if (this.state.selectedBank !== undefined) {
      validBankName = (this.state.selectedBank.name !== undefined && this.state.selectedBank.name.length > 0);

      validBankAddress = (this.state.selectedBank.streetAddress !== undefined && this.state.selectedBank.streetAddress.length > 0);

      validBankAddress2 = this.state.selectedBank.streetAddress2 === undefined || (this.state.selectedBank.streetAddress2 !== undefined && this.state.selectedBank.streetAddress2.length > 0);

      validBankCity = (this.state.selectedBank.city !== undefined && this.state.selectedBank.city.length > 0);

      validBankState = (this.state.selectedBank.state !== undefined && this.state.selectedBank.state.length > 0);

      validBankZip = (this.state.selectedBank.zipCode !== undefined && this.state.selectedBank.zipCode.length > 0);

      validBaseRate = this.state.selectedBank.baseRate !== undefined && !isNaN(+this.state.selectedBank.baseRate);

      nonDuplicate = ((this.state.activeBanks === undefined || this.state.activeBanks.length === 0)
        || (this.state.activeBanks.findIndex(ab => ab.id !== this.state.selectedBank.id && ab.name === this.state.selectedBank.name && ab.city === this.state.selectedBank.city && ab.state === this.state.selectedBank.state && ab.zipCode === this.state.selectedBank.zipCode) === -1));

      validManager = this.state.selectedBank.manager != undefined && Object.keys(this.state.selectedBank.manager).length > 0 && this.state.selectedBank.manager.securityRole.id === SecurityRoleEnum.Manager;

      return validBankName
        && validBankAddress
        && validBankAddress2
        && validBankCity
        && validBankState
        && validBankZip
        && validBaseRate
        && nonDuplicate
        && validManager;
    } else {
      return false;
    }
  }

  render() {
    return (
      <>
        <Container>
          <div>
            <Row className="add-bank-title-row align-items-center">
              <Col>
                <h1>Current Banks</h1>
              </Col>
              <Col className="d-flex justify-content-end">
                <PrimaryButton buttonText={'Add Bank'} onClick={this.setBankModalState.bind(this, 'create')} />
              </Col>
              {/* <Col> ** Leaving this here as we may want to export a list of current banks at some point
                <div className="d-flex justify-content-end">
                  <SecondaryButton
                    buttonText={'Export Loans'}
                    onClick={this.exportResults.bind(this)}
                  />
                  </div>
              </Col> */}
            </Row>
            {this.state.activeBanks && this.state.activeBanks.length > 0 ?
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Bank Name</th>
                    <th>Address Line 1</th>
                    <th>Address Line 2</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zip Code</th>
                    <th>Base Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.activeBanks.map((b: Bank, index: number) => {
                    return (
                      <tr key={`add-bank-list-${index}`} onClick={this.setBankModalState.bind(this, 'edit', b)}>
                        <td>{b.name}</td>
                        <td>{b.streetAddress}</td>
                        <td>{b.streetAddress2 ?? ''}</td>
                        <td>{b.city}</td>
                        <td>{b.state}</td>
                        <td>{b.zipCode}</td>
                        <td>{`${b.baseRate}%`}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </Table>
              :
              <h3>No active banks.</h3>
            }
          </div>
          <Modal show={this.state.showAddBankModal} onHide={this.resetAddBankModalState.bind(this)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>{this.getModalTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Bank Name*</Form.Label>
                  <Form.Control value={this.state.selectedBank.name} onChange={this.setBankNameState.bind(this)}></Form.Control>

                  <Form.Label>Address Line 1*</Form.Label>
                  <Form.Control value={this.state.selectedBank.streetAddress} onChange={this.setBankAddressState.bind(this)}></Form.Control>

                  <Form.Label>Address Line 2</Form.Label>
                  <Form.Control value={this.state.selectedBank.streetAddress2} onChange={this.setBankAddress2State.bind(this)}></Form.Control>

                  <Form.Label>City*</Form.Label>
                  <Form.Control value={this.state.selectedBank.city} onChange={this.setBankCityState.bind(this)}></Form.Control>

                  <Form.Label>State*</Form.Label>
                  <Form.Control value={this.state.selectedBank.state} onChange={this.setBankStateValueState.bind(this)}></Form.Control>

                  <Form.Label>Zip*</Form.Label>
                  <Form.Control value={this.state.selectedBank.zipCode} onChange={this.setBankZipCodeState.bind(this)}></Form.Control>

                  <Form.Label>Interest Rate*</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Interest Rate"
                      aria-label="Interest Rate"
                      type="number"
                      value={this.state.selectedBank.baseRate}
                      onChange={this.setBankBaseRateState.bind(this)}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>

                  {this.state.modalAction === 'create' &&
                    <>
                      <Form.Label>Manager*</Form.Label>
                      {this.state.selectedBank?.manager !== undefined ?
                        <div>{`${this.state.selectedBank.manager.firstName} ${this.state.selectedBank.manager.lastName}`}</div>
                        :
                        <InputGroup className="mb-3">
                          <Button variant="link" onClick={() => this.setState({ showUserEditModal: true })}>Add Manager</Button>
                        </InputGroup>
                      }
                    </>
                  }
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <SecondaryButton buttonText={'Close'} onClick={this.resetAddBankModalState.bind(this)} />
              {this.getModalActionButton()}
            </Modal.Footer>
          </Modal>
          <EditProfileModal
            showModal={this.state.showUserEditModal}
            sourcePage={'bank'}
            action={'create'}
            showSecurityRole={true}
            selectedBankEmployee={{} as BankEmployee}
            onModalCancel={() => this.setState({ showUserEditModal: false })}
            newUserCallback={this.setInitialBankEmployee.bind(this)}
          />
        </Container>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'dark'}
            animation={'border'}
          />
        }
      </>
    );
  }
}

export default withAITracking(reactPlugin, connector(AddBankPage));
