import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartL from "../../../../models/fsa2211Form/FSA2211PartL"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartLCallback = (formDataPartL: FSA2211PartL) => void;

interface FSA2211FormPartLProps {
  partLData?: FSA2211PartL;
  formDataChange: formDataPartLCallback;
}

export default class FSA2211FormPartL extends React.Component<FSA2211FormPartLProps, FSA2211PartL> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partLData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartLProps) {
    if (previousProps.partLData !== this.props.partLData) {
      this.setState(this.props.partLData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartL {
    return {
      helwlComplianceYes: false,
      helwlComplianceNo: false,
      landUseYes: false,
      landUseNo: false,
      floodplainsYes: false,
      floodplainsNo: false,
      historicalArchaeologicalSitesYes: false,
      historicalArchaeologicalSitesNo: false,
      hazardousSubstancesYes: false,
      hazardousSubstancesNo: false,
      endangeredSpeciesYes: false,
      endangeredSpeciesNo: false,
      environmentalComplianceYes: false,
      environmentalComplianceNo: false,
      stateWaterQualityStandardsYes: false,
      stateWaterQualityStandardsNo: false,
      stateWaterQualityStandardsNoNumberLivestock: 0,
      stateWaterQualityStandardsNoTypeLivestock: "",
    } as FSA2211PartL
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART L &nbsp;&nbsp;    ENVIRONMENTAL INFORMATION <em>(ALL LENDER TYPES)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>Based on a site visit to the loan applicant's operation and discussion of the operating plan, answer the following: </Col>
                  <Col><Row><Col>Yes</Col><Col>No</Col></Row></Col>
                </Row>
                <Row className="standard-text">
                  <Col md={10}></Col>
                  <Col><Row><Col>(True)</Col><Col>(False)</Col></Row></Col>
                </Row>
                <Row className="standard-text">
                  <Col md={10}>1. HEL/WL Compliance: Applicant has certified compliance on AD-1026 covering the period of the loan and filed AD-1026 with
                    the applicable Farm Service Agency Service Center.</Col>
                  <Col><Row>
                    <Col><Form.Check name="hELWLComplianceYes" onChange={this.handleInputChange.bind(this)} checked={this.state.helwlComplianceYes} /></Col>
                    <Col><Form.Check name="hELWLComplianceNo" onChange={this.handleInputChange.bind(this)} checked={this.state.helwlComplianceNo} /></Col>
                  </Row></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>2. Land Use: Proceeds from this request or project will not accommodate any shifts in land use, ground disturbance, clearing of
                    woody vegetation or stumps or for drilling of a well.</Col>
                  <Col><Row>
                    <Col><Form.Check name="landUseYes" onChange={this.handleInputChange.bind(this)} checked={this.state.landUseYes} /></Col>
                    <Col><Form.Check name="landUseNo" onChange={this.handleInputChange.bind(this)} checked={this.state.landUseNo} /></Col>
                  </Row></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>3. Floodplains: Property on which farming activities are taking place is not located near or within a floodplain.</Col>
                  <Col><Row>
                    <Col><Form.Check name="floodplainsYes" onChange={this.handleInputChange.bind(this)} checked={this.state.floodplainsYes} /></Col>
                    <Col><Form.Check name="floodplainsNo" onChange={this.handleInputChange.bind(this)} checked={this.state.floodplainsNo} /></Col>
                  </Row></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>4. Historical and Archaeological Sites: Property on which farming activities take place is not known to be of historical
                    significance or contain any known archaeological sites.</Col>
                  <Col><Row>
                    <Col><Form.Check name="historicalArchaeologicalSitesYes" onChange={this.handleInputChange.bind(this)} checked={this.state.historicalArchaeologicalSitesYes} /></Col>
                    <Col><Form.Check name="historicalArchaeologicalSitesNo" onChange={this.handleInputChange.bind(this)} checked={this.state.historicalArchaeologicalSitesNo} /></Col>
                  </Row></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>5. Hazardous Substances: Property on which the farming activities take place is not contaminated with hazardous substances or
                    waste and does not contain underground storage tanks.</Col>
                  <Col><Row>
                    <Col><Form.Check name="hazardousSubstancesYes" onChange={this.handleInputChange.bind(this)} checked={this.state.hazardousSubstancesYes} /></Col>
                    <Col><Form.Check name="hazardousSubstancesNo" onChange={this.handleInputChange.bind(this)} checked={this.state.hazardousSubstancesNo} /></Col>
                  </Row></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>6. Endangered Species: There are no known endangered or proposed endangered species or habitats that will be disturbed by
                    the operation.</Col>
                  <Col><Row>
                    <Col><Form.Check name="endangeredSpeciesYes" onChange={this.handleInputChange.bind(this)} checked={this.state.endangeredSpeciesYes} /></Col>
                    <Col><Form.Check name="endangeredSpeciesNo" onChange={this.handleInputChange.bind(this)} checked={this.state.endangeredSpeciesNo} /></Col>
                  </Row></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>7. Environmental Compliance: There are no pending or active lawsuits regarding environmental compliance against the operator
                    or property and there are no environmental liens or judgements filed against the property as a result of not complying with
                    Federal or State environmental laws.</Col>
                  <Col><Row>
                    <Col><Form.Check name="environmentalComplianceYes" onChange={this.handleInputChange.bind(this)} checked={this.state.environmentalComplianceYes} /></Col>
                    <Col><Form.Check name="environmentalComplianceNo" onChange={this.handleInputChange.bind(this)} checked={this.state.environmentalComplianceNo} /></Col>
                  </Row></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>
                    <Row><Col>8. State Water Quality Standards: This is not a livestock operation.</Col></Row>
                    <Row>
                      <Col>*If “NO”, this is a livestock operation consisting of</Col><Col><Form.Control name="stateWaterQualityStandardsNoNumberLivestock" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.stateWaterQualityStandardsNoNumberLivestock} /></Col>
                      <Col><Form.Control name="stateWaterQualityStandardsNoTypeLivestock" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.stateWaterQualityStandardsNoTypeLivestock} /></Col>
                    </Row>
                    <Row><Col></Col><Col><em className="instruction-text">(number of livestock)</em></Col><Col><em className="instruction-text">(type of livestock)</em></Col></Row>
                  </Col>
                  <Col><Row>
                    <Col><Form.Check name="stateWaterQualityStandardsYes" onChange={this.handleInputChange.bind(this)} checked={this.state.stateWaterQualityStandardsYes} /></Col>
                    <Col><Form.Check name="stateWaterQualityStandardsNo" onChange={this.handleInputChange.bind(this)} checked={this.state.stateWaterQualityStandardsNo} /></Col>
                  </Row></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}