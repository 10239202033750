import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./ApplicantSection.css";
import Applicant from "../../../models/applicantForm/applicant";
import loanApplicationUtility from "utilities/LoanApplicationUtility";

interface ApplicantSectionProps {
  loanDetails: LoanApplication
}

export default class ApplicantSection extends React.Component<ApplicantSectionProps> {
  render() {
    return (
      <>
        <div>
          <h3>Applicants</h3>
          {this.props.loanDetails.applicants.map((a: Applicant, i: number) => {
            return (
              <Table key={`applicant-${i}`} bordered striped={false} size="sm">
                <tbody>
                  <tr>
                    <td width="250px" className="app-info"><strong>Applicant Name</strong></td>
                    <td className="app-info">{loanApplicationUtility.GetApplicantName(a)}</td>
                  </tr>
                  <tr>
                    <td width="250px" className="app-info"><strong>ID Verified</strong></td>
                    <td className="app-info">{a.identityVerified ? 'Yes' : 'No'}</td>
                  </tr>
                  <tr>
                    <td width="250px" className="app-info"><strong>Date Of Birth</strong></td>
                    <td className="app-info">{loanApplicationUtility.GetFullYearDateText(a.dateOfBirth)}</td>
                  </tr>
                  <tr>
                    <td width="250px" className="app-info"><strong>SSN</strong></td>
                    <td className="app-info">{a.ssn}</td>
                  </tr>
                </tbody>
              </Table>
            )
          })
          }
        </div>
      </>
    );
  }
}