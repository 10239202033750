import BankEmployee from "models/bank/bankEmployee";
import { AppThunk } from "../../store/store";
import { LOGGED_IN_ACCOUNT, SET_SUBDOMAIN, TOGGLE_API_REQUEST_SUCCESS, TOGGLE_LOADING, TOGGLE_MODAL_LOADING } from "../../store/system/types";
import { Subdomain } from "shared/Enums";
import accountService from "accessors/accountService";
import LoggedInAccount from "models/account/loggedInAccount";

export const toggleLoading = (
    isLoading: boolean
): AppThunk => async (dispatch) => {
    dispatch({
        type: TOGGLE_LOADING,
        payload: { isLoading: isLoading }
    });
};

export const toggleModalLoading = (
    isModalLoading: boolean
): AppThunk => async (dispatch) => {
    dispatch({
        type: TOGGLE_MODAL_LOADING,
        payload: { isModalLoading: isModalLoading }
    });
};

export const toggleAPIRequestSuccess = (
    apiRequestSuccess: boolean
): AppThunk => async (dispatch) => {
    dispatch({
        type: TOGGLE_API_REQUEST_SUCCESS,
        payload: { apiRequestSuccess: apiRequestSuccess }
    });
};

export const getLoggedInAccountInfo = (): AppThunk => async (dispatch) => {
    accountService.GetLoggedInProfile()
        .then((account: LoggedInAccount) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (Object.keys(account).length > 0) {
                dispatch({
                    type: LOGGED_IN_ACCOUNT,
                    payload: {
                        account: account
                    },
                });
            }
        })
};

export const setSubdomain = (
    subdomain: Subdomain
): AppThunk => async (dispatch) => {
    dispatch({
        type: SET_SUBDOMAIN,
        payload: {
            subdomain: subdomain
        },
    });
}

export const updateLoggedInEmployeeInfo = (
    bankEmployee: BankEmployee
): AppThunk => async (dispatch) => {
    accountService.UpdateLoggedInProfile(bankEmployee)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (success) {
                dispatch({
                    type: LOGGED_IN_ACCOUNT,
                    payload: {
                        bankEmployee: bankEmployee
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};