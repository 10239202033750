import SecurityRole from "models/account/securityRole";
import BankEmployee from "../models/bank/bankEmployee";
import axiosRequestUtility from "../utilities/AxiosRequestUtility";

class BankEmployeeService {
  
  public GetBankSecurityRoles(): Promise<SecurityRole[]>{
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `GetBankSecurityRoles`,
    })
    .then(response => {
      return response.data as SecurityRole[];
    })
    .catch(error => {
      console.log(error);
      alert('Error loading security roles. Please try again.')
      return [];
    })
  }

  public UpdateBankEmployee(bankEmployee: BankEmployee): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'PUT',
      url: `UpdateBankEmployee`,
      data: bankEmployee
    })
    .then(response => {
      return true;
    })
    .catch(error => {
      console.log(error);
      alert('Error updating profile information. Please try again.')
      return false;
    })
  }

  public CreateBankEmployee(bankEmployee: BankEmployee): Promise<BankEmployee> {
    return axiosRequestUtility.Request({
      method: 'POST',
      url: `CreateBankEmployee`,
      data: bankEmployee
    })
    .then(response => {
      return response.data as BankEmployee;
    })
    .catch(error => {
      console.log(error);
      alert('Error updating profile information. Please try again.')
      return {} as BankEmployee;
    })
  }
}

const bankEmployeeService = new BankEmployeeService();
export default bankEmployeeService;