import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Form, Image } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { reactPlugin } from "../../../utilities/AppInsights";
import "./CreateAccountPage.css"
import FullLogo from '../../../assets/full_logo.png'
import PrimaryButton from "shared/components/PrimaryButton";
import { AppState } from "redux/store/store";
import { toggleAPIRequestSuccess, toggleLoading } from "redux/actions/system/actions";
import { addNewDealer, getDealers, updateDealer } from "redux/actions/dealer/actions";
import Dealer from "models/dealerPurchase/dealer";
import { ConnectedProps, connect } from "react-redux";
import dealerService from "accessors/dealerService";
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import CreateDealerSalesmanAccount from "models/account/dealer/createDealerAccount";
import { isValidPhoneNumber } from 'react-phone-number-input/input'
import PhoneInput from "react-phone-input-2";
import globalUtility from "utilities/GlobalUtility";


const mapState = (state: AppState, route: any) => {
  const { system, dealer } = state;
  const { isLoading, apiRequestSuccess } = system;
  const { dealers } = dealer;
  return {
    isLoading,
    apiRequestSuccess,
    dealers,
    route,
  };
};

const mapDispatch = {
  toggleLoading: (isLoading: boolean) => toggleLoading(isLoading),
  toggleAPIRequestSuccess: (apiRequestSuccess: boolean) => toggleAPIRequestSuccess(apiRequestSuccess),
  getDealers: () => getDealers(),
  addNewDealer: (dealer: Dealer) => addNewDealer(dealer),
  updateDealer: (dealer: Dealer) => updateDealer(dealer),
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

interface CreateAccountPageState {
  dealers: Dealer[];
  selectedDealer: Dealer,
  createDealerAccount: CreateDealerSalesmanAccount,
  validPhoneNumber: boolean;
  validEmailAddress: boolean;
}

class CreateAccountPage extends React.Component<Props, CreateAccountPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      dealers: [],
      selectedDealer: {} as Dealer,
      createDealerAccount: {} as CreateDealerSalesmanAccount,
      validPhoneNumber: false,
      validEmailAddress: false
    }
  }

  componentDidMount() {
    this.loadDealers();
  }

  loadDealers(): void {
    this.props.toggleLoading(true);

    dealerService.GetDealersPublic()
      .then(dealers => {
        this.props.toggleLoading(false);

        if (dealers.length > 0) this.setState({ dealers: dealers })
      })
  }

  setEmailState(value: string): void {
    let createDealer: CreateDealerSalesmanAccount = { ...this.state.createDealerAccount };

    createDealer.email = value;

    let validEmail = globalUtility.ValidateEmailAddress(value);

    this.setState({ createDealerAccount: createDealer, validEmailAddress: validEmail });
  }

  setPasswordState(value: string): void {
    let createDealer: CreateDealerSalesmanAccount = { ...this.state.createDealerAccount };

    createDealer.password = value;

    this.setState({ createDealerAccount: createDealer });
  }

  setSelectedDealerState(selectedValue: number): void {
    let selectedDealer: Dealer = {} as Dealer;
    let createDealer: CreateDealerSalesmanAccount = { ...this.state.createDealerAccount };

    if (selectedValue !== -1) {
      selectedDealer = { ...this.state.dealers.find(d => d.id === selectedValue) ?? {} as Dealer };
      createDealer.dealerId = selectedValue;
    } else {
      selectedDealer = {} as Dealer;
      createDealer.dealerId = -1;
    }

    this.setState({ selectedDealer: selectedDealer, createDealerAccount: createDealer });
  }

  setFirstNameState(value: string): void {
    let createDealer: CreateDealerSalesmanAccount = { ...this.state.createDealerAccount };

    createDealer.firstName = value;

    this.setState({ createDealerAccount: createDealer });
  }

  setLastNameState(value: string): void {
    let createDealer: CreateDealerSalesmanAccount = { ...this.state.createDealerAccount };

    createDealer.lastName = value;

    this.setState({ createDealerAccount: createDealer });
  }

  setPhoneNumberState(value: string, data: any, event: any, formattedValue: string): void {
    let createDealer: CreateDealerSalesmanAccount = { ...this.state.createDealerAccount };
    let validNumber = false;

    validNumber = isValidPhoneNumber(formattedValue);
    createDealer.phoneNumber = value;


    this.setState({ createDealerAccount: createDealer, validPhoneNumber: validNumber });
  }

  validateData(): boolean {
    let validEmail = true, validPassword = true, validDealership = true, validFirstName = true, validLastName = true, validPhoneNumber = true;

    validEmail = this.state.createDealerAccount != undefined && this.state.createDealerAccount.email != undefined && this.state.createDealerAccount.email.length > 0 && this.state.validEmailAddress;

    validPassword = this.state.createDealerAccount != undefined && this.state.createDealerAccount.password != undefined && this.state.createDealerAccount.password.length > 0;

    validDealership = this.state.createDealerAccount != undefined && this.state.createDealerAccount.dealerId != undefined && this.state.createDealerAccount.dealerId > 0;

    validFirstName = this.state.createDealerAccount != undefined && this.state.createDealerAccount.firstName != undefined && this.state.createDealerAccount.firstName.length > 0;

    validLastName = this.state.createDealerAccount != undefined && this.state.createDealerAccount.lastName != undefined && this.state.createDealerAccount.lastName.length > 0;

    validPhoneNumber = this.state.createDealerAccount != undefined && this.state.createDealerAccount.phoneNumber != undefined && this.state.createDealerAccount.phoneNumber.length > 0 && this.state.validPhoneNumber;

    return validEmail && validPassword && validDealership && validFirstName && validLastName && validPhoneNumber;
  }

  createAccount(): void {
    this.props.toggleLoading(true);

    if (this.state.validPhoneNumber) {
      let createDealer: CreateDealerSalesmanAccount = { ...this.state.createDealerAccount };
      createDealer.phoneNumber = createDealer.phoneNumber.substring(1, 11) // Removing 1 and only allowing 10 characters max

      dealerService.CreateDealerSalesmanAccount(createDealer)
        .then((newId: number) => {
          this.props.toggleLoading(false);

          if (newId > 0) {
            if (window.confirm('Account successfully created! Please check your email to verify your email address before logging in. You will not be able to login until you have verified your email address. Please contact support@agcreditbank.com if do not receive your confirmation email. Thank you!')) {
              window.location.replace(window.location.origin)
            } else {
              window.location.replace(window.location.origin)
            }
          } else {
            alert('We were unable to create your account. Please try again, or contact Support to report an issue. Thank you!')
          }
        })
    }
  }

  render() {
    return (
      <>
        <Container className="d-flex align-items-center fill-screen" fluid>
          <Row className="justify-content-md-center">
            <Col className="align-self-center">
              <Image src={FullLogo} fluid />
            </Col>
            <Col className="align-self-center">
              <h1>Create Account</h1>

              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={this.state.createDealerAccount.email} onChange={(event) => this.setEmailState(event.target.value)} />
                  {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" value={this.state.createDealerAccount.password} onChange={(event) => this.setPasswordState(event.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDealerSelect">
                  <Form.Label>Dealership</Form.Label>
                  <Form.Control as="select" onChange={(event) => this.setSelectedDealerState(+event.target.value)} value={this.state.selectedDealer?.id}>
                    <option value={-1}>Please select the dealership you are employed at...</option>
                    {this.state.dealers.map(d => {
                      return (
                        <option value={d.id}>{`${d.name} - ${d.city}, ${d.state}`}</option>
                      )
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="first-name" placeholder="First Name" value={this.state.createDealerAccount.firstName} onChange={(event) => this.setFirstNameState(event.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="last-name" placeholder="Last Name" value={this.state.createDealerAccount.lastName} onChange={(event) => this.setLastNameState(event.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                  <PhoneInput
                    inputProps={{ name: 'Phone Number' }}
                    inputClass="form-control"
                    country={'us'}
                    onlyCountries={['us']}
                    value={this.state.createDealerAccount.phoneNumber}
                    onChange={this.setPhoneNumberState.bind(this)}
                  />
                </Form.Group>
              </Form>

              <PrimaryButton buttonText={'Create'} onClick={this.createAccount.bind(this)} disabled={!this.validateData()}></PrimaryButton>
            </Col>
          </Row>
        </Container>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'dark'}
            animation={'border'}
          />
        }
      </>
    );
  }
}

export default withAITracking(reactPlugin, connector(CreateAccountPage));
