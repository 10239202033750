import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import SecondaryButton from "shared/components/SecondaryButton";
import PrimaryButton from "shared/components/PrimaryButton";
import "./OfferModal.css"
import FormLabel from "react-bootstrap/esm/FormLabel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InterestType, LendingLoanType, PeriodicRateIncrease } from "shared/Enums";
import LoanOffer from "models/loanOffer/loanOffer";
import loanOfferUtility from "../../../utilities/LoanOfferUtility";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from "date-fns";

type callback = () => void;
type selectCallback = (selectedOption: number) => void;
type numberCallback = (value: number) => void;
type dateCallback = (date: Date) => void;

interface OfferModalProps {
  showModal: boolean;
  loanOffer: LoanOffer;
  baseRate: number;
  modalTitle: string;
  confirmButtonText?: string;
  validData: boolean;
  loanAmountChange: numberCallback;
  lendingLoanTypeChange: selectCallback;
  interestTypeChange: selectCallback;
  periodicRateIncreaseChange: selectCallback;
  fixedInterestRateChange: numberCallback;
  loanTermChange: numberCallback;
  variableInterestRateChange: numberCallback;
  expirationDateCallback: dateCallback;
  onModalCancel: callback;
  onModalConfirm: callback;
}

interface OfferModalState {
  showCalendar: boolean
}

export default class OfferModal extends React.Component<OfferModalProps, OfferModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showCalendar: false
    }
  }

  setCalendarState(show: boolean): void {
    this.setState({ showCalendar: show });
  }

  getConfirmButtonText(): string {
    return this.props.confirmButtonText ?? 'Send Offer';
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.onModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormLabel>Loan Amount</FormLabel>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  $
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Loan Amount"
                aria-label="Loan Amount"
                type="number"
                value={this.props.loanOffer.amount}
                onChange={(event => this.props.loanAmountChange(+event.target.value))}
              />
            </InputGroup>

            <FormLabel>Loan Type</FormLabel>
            <InputGroup className="mb-3">
              <Form.Control
                as="select"
                className="mr-sm-2"
                custom
                onChange={(event) => this.props.lendingLoanTypeChange(+event.target.value)}
              >
                <option value={0}>Choose...</option>
                <option value={LendingLoanType.InstallmentLoanSingleAdvance}>Installment Loan/Single Advance</option>
                <option value={LendingLoanType.SinglePaySingleAdvance}>Single Pay/Single Advance</option>
                <option value={LendingLoanType.RevolvingLOCMultipleAdvance}>Revolving LOC/Multiple Advance</option>
                <option value={LendingLoanType.ClosedEndLOCMultipleAdvance}>Closed-End LOC/Multiple Advance</option>
              </Form.Control>
            </InputGroup>

            <FormLabel>Interest Type</FormLabel>
            <InputGroup className="mb-3">
              <Form.Control
                as="select"
                className="mr-sm-2"
                custom
                onChange={(event) => this.props.interestTypeChange(+event.target.value)}
              >
                <option value={0}>Choose...</option>
                <option value={InterestType.Variable}>Variable Rate</option>
                <option value={InterestType.FixedRate}>Fixed Rate</option>
              </Form.Control>
            </InputGroup>

            {this.props.loanOffer.interestRateTypeId > 0 &&
              (<>
                {loanOfferUtility.GetVariableRateInterestTypes().includes(this.props.loanOffer.interestRateTypeId) ?
                  <>
                    <FormLabel>Interest Rate</FormLabel>
                    <Form.Text>{`Base Rate: ${this.props.baseRate}%`}</Form.Text>
                    <Form.Text>{`Adjusted Customer Rate: ${this.props.loanOffer.interestRate ?? '--'}%`}</Form.Text>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Rate Above/Below Base Rate"
                        aria-label="Interest Rate"
                        type="number"
                        value={this.props.loanOffer.marginAdjustment}
                        onChange={(event) => this.props.variableInterestRateChange(+event.target.value)}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>
                          %
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>

                    <FormLabel>Rate Increase Period</FormLabel>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="select"
                        className="mr-sm-2"
                        custom
                        onChange={(event) => this.props.periodicRateIncreaseChange(+event.target.value)}
                      >
                        <option value={0}>Choose...</option>
                        <option value={PeriodicRateIncrease.Immediate}>Immediately Repriceable</option>
                        <option value={PeriodicRateIncrease.ThirtyDays}>30 Days</option>
                        <option value={PeriodicRateIncrease.SixtyDays}>60 Days</option>
                        <option value={PeriodicRateIncrease.NinetyDays}>90 Days</option>
                        <option value={PeriodicRateIncrease.OneHundredTwentyDays}>120 Days</option>
                        <option value={PeriodicRateIncrease.OneHundredEightyDays}>180 Days</option>
                        <option value={PeriodicRateIncrease.OneMonth}>1 Month</option>
                        <option value={PeriodicRateIncrease.ThreeMonths}>3 Months</option>
                        <option value={PeriodicRateIncrease.SixMonths}>6 Months</option>
                        <option value={PeriodicRateIncrease.TwelveMonths}>12 Months</option>
                        <option value={PeriodicRateIncrease.EighteenMonths}>18 Months</option>
                        <option value={PeriodicRateIncrease.TwentyFourMonths}>24 Months</option>
                        <option value={PeriodicRateIncrease.ThirtySixMonths}>36 Months</option>
                        <option value={PeriodicRateIncrease.FortyEightMonths}>48 Months</option>
                        <option value={PeriodicRateIncrease.SixtyMonths}>60 Months</option>
                      </Form.Control>
                    </InputGroup>
                  </>
                  :
                  <>
                    <FormLabel>Interest Rate</FormLabel>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Interest Rate"
                        aria-label="Interest Rate"
                        type="number"
                        value={this.props.loanOffer.interestRate}
                        onChange={(event) => this.props.fixedInterestRateChange(+event.target.value)}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>
                          %
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </>
                }
              </>
              )}

            <FormLabel>Loan Terms</FormLabel>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Term Length"
                aria-label="Loan Term Length"
                type="number"
                value={this.props.loanOffer.term == 0 ? '' : this.props.loanOffer.term / 12}
                onChange={(event) => this.props.loanTermChange(+event.target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  Year(s)
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>

            <FormLabel>Expiration Date</FormLabel>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Expiration Date"
                aria-label="Expiration Date"
                value={this.props.loanOffer.expirationDate !== undefined ? format(new Date(this.props.loanOffer.expirationDate), 'MMMM d, yyyy') : undefined}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon="calendar-day" onClick={() => this.setCalendarState(true)} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {this.state.showCalendar && (
              <Calendar
                onChange={(value: Date) => { this.props.expirationDateCallback(value); this.setCalendarState(false); }}
                showWeekNumbers
                minDate={new Date()}
                value={this.props.loanOffer.expirationDate}
              />
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton buttonText={'Cancel'} onClick={this.props.onModalCancel} />
          <PrimaryButton
            buttonText={this.getConfirmButtonText()}
            onClick={this.props.onModalConfirm}
            disabled={!this.props.validData}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}