import DownloadDocumentResponse from "models/downloadDocumentResponse/DownloadDocumentResponse";
import { Subdomain } from "shared/Enums";

class GlobalUtility {
  public CreateFileDownloadResponse(response: any): DownloadDocumentResponse {

    var contentFileName: string = response.headers['content-disposition'].split('filename=')[1].slice(0);
    var fileName = contentFileName.slice(0, contentFileName.indexOf(';'));
    var file = new Blob([response.data], { type: 'octet/stream' });

    return { file: file, fileName: fileName } as DownloadDocumentResponse;
  }

  public GetSubdomain(): Subdomain {
    let subdomainText = window.location.hostname.split('.')
    if (subdomainText.findIndex(item => item.endsWith('dealer')) >= 0) {
      return Subdomain.Dealer;
    } else {
      return Subdomain.Admin;
    }
  }

  public ValidateEmailAddress(email: string): boolean {
    // Expecting __@__.__
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  public ValidateSSN(ssn: string): boolean {
    // Expecting a string in the format 000-00-0000
    let validSSN: boolean = true;
    let ssnSegments: string[] = ssn.split('-');

    validSSN = ssnSegments.length === 3 && ssnSegments[0].length === 3 && ssnSegments[1].length === 2 && ssnSegments[2].length === 4
    return validSSN;
  }

  public ValidateEIN(ein: string): boolean {
    // Expecting a string in the format 00-0000000
    let validEIN: boolean = true;
    let einSegments: string[] = ein.split('-');

    validEIN = einSegments.length === 2 && einSegments[0].length === 2 && einSegments[1].length === 7
    return validEIN;
}
}

const globalUtility = new GlobalUtility();
export default globalUtility;