import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartB from "../../../../models/fsa2211Form/FSA2211PartB";
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartBCallback = (formDataPartB: FSA2211PartB) => void;

interface FSA2211FormPartBProps {
  partBData?: FSA2211PartB;
  formDataChange: formDataPartBCallback;
}

export default class FSA2211FormPartB extends React.Component<FSA2211FormPartBProps, FSA2211PartB> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partBData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartBProps) {
    if (previousProps.partBData !== this.props.partBData) {
      this.setState(this.props.partBData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartB {
    return {
      entityName: "",
      entityAddress: "",
      numberOfEntityMembers: "",
      entityTaxIdNumber: "",
      entityHeadquartersCounty: "",
      entityTelephoneNumber: "",
      telephoneTypeHome: false,
      telephoneTypeCell: false,
      telephoneTypeWork: false
    } as FSA2211PartB
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART B &nbsp;&nbsp; ENTITY APPLICANT INFORMATION <em>(LOAN APPLICANT)</em></Col>
                </Row>
                <Row className="mt-2 standard-text">
                  <Col md={3} className="align-left">1. Entity Name</Col>
                  <Col md={3} className="align-left">2. Entity Address</Col>
                  <Col md={3} className="align-left">3. Number of Entity Members</Col>
                  <Col md={3} className="align-left">4. Entity Tax ID Number</Col>
                </Row>
                <Row className="standard-text">
                  <Col md={3} className="align-left"><Form.Control name="entityName" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.entityName} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="entityAddress" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.entityAddress} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="numberOfEntityMembers" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.numberOfEntityMembers} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="entityTaxIdNumber" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.entityTaxIdNumber} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={3} className="align-left">5. Entity Headquarters County</Col>
                  <Col md={3} className="align-left">6. Entity Telephone Number <em className="instruction-text">(including area code)</em></Col>
                  <Col md={3} className="align-left">7. Telephone Type</Col>
                  <Col md={3} className="align-left"></Col>
                </Row>
                <Row className="standard-text">
                  <Col md={3} className="align-left"><Form.Control name="entityHeadquartersCounty" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.entityHeadquartersCounty} /></Col>
                  <Col md={3} className="align-left"><Form.Control name="entityTelephoneNumber" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.entityTelephoneNumber} /></Col>
                  <Col md={3} className="align-left">
                    <Row>
                      <Col className="align-left"><Form.Check name="telephoneTypeHome" type="checkbox" label="Home" onChange={this.handleInputChange.bind(this)} checked={this.state.telephoneTypeHome}></Form.Check></Col>
                      <Col className="align-left"><Form.Check name="telephoneTypeCell" type="checkbox" label="Cell" onChange={this.handleInputChange.bind(this)} checked={this.state.telephoneTypeCell}></Form.Check></Col>
                      <Col className="align-left"><Form.Check name="telephoneTypeWork" type="checkbox" label="Work" onChange={this.handleInputChange.bind(this)} checked={this.state.telephoneTypeWork}></Form.Check></Col>
                    </Row>
                  </Col>
                  <Col md={3} className="align-left"></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}