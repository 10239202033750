import axiosRequestUtility from "../utilities/AxiosRequestUtility";
import DownloadDocumentResponse from "models/downloadDocumentResponse/DownloadDocumentResponse"
import { IdDocumentSde } from "shared/Enums";
import globalUtility from "utilities/GlobalUtility";

class CustomerAccountService {
  public DownloadIdDocument(documentId: number, documentSide: IdDocumentSde ): Promise<DownloadDocumentResponse> {
    return axiosRequestUtility.Request({
      method: 'POST',
      responseType: 'arraybuffer',
      url: `DownloadIdDocument`,
      params:
      {
        'documentId': documentId,
        'documentSide': documentSide
      }
    })
      .then(response => {
        return globalUtility.CreateFileDownloadResponse(response);
      })
      .catch(error => {
        console.log(error)
        alert('Error downloading document from customer account. Please try again.')
        return {} as DownloadDocumentResponse;
      })
  }
}

const customerAccountService = new CustomerAccountService();
export default customerAccountService;