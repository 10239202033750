import axios from "axios";
import auth from "./AuthUtility";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

client.interceptors.request.use(
  (config) => {
    return new Promise(async (resolve, reject) => {
      const token = await auth.getToken();
      if (!token) return reject("No token found");
      config.headers["Authorization"] = `Bearer ${token}`;
      return resolve(config);
    });
  },
  (error) => {
    return Promise.reject(error);
  }
);

class AxiosRequestUtility {
  public Request(options: any) {
    return client(options).then(this.OnSuccess).catch(this.OnError);
  }

  // TODO: Do we have a specific response class that we will use?
  private OnSuccess(response: any) {
    return response;
  }

  private OnError(error: any) {
    let errorMessage: string = "";

    // Ignore cancels
    if (!axios.isCancel(error)) {
      if (error.response) {
        // Server responded with something other than 2xx code
        // We also have the ability to handle any specific logic that we want for specific response codes
        errorMessage = `Response Status: ${error.response.status}; Headers: ${error.response.headers}; Response Data: ${error.response.data};`;

        if (error.response.status === 401) {
          auth.logout();
        }
      } else {
        // There was an issue setting up the requests
        errorMessage = `There was an unexpected error when setting up the request. Error Message: ${error.message}`;
      }

      // By throwing the error, AppInsights will grab it, log it, and redirect to our error page
      throw new Error(errorMessage);
    }
  }
}

/*
Example calls:

AxiosRequestUtility.Request({
  method: 'GET',
  url: '/path/'
}).then((resp) => {
  console.log(resp);
})

AxiosRequestUtility.Request({
    method: 'POST',
    url:    '/loan/create',
    data:   {
      loanData
    }
  });
*/

const axiosRequestUtility = new AxiosRequestUtility();
export default axiosRequestUtility;
