import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./LoanNotesSection.css";
import LoanNote from "../../../models/note/loanNote";
import Button from "react-bootstrap/esm/Button";
import bankEmployeeUtility from "utilities/BankEmployeeUtility";
import loanApplicationUtility from "utilities/LoanApplicationUtility";

type LoanNoteAction = 'create' | 'edit' | 'delete';
type callback = (action: LoanNoteAction, selectedLoanNote?: LoanNote) => void;

interface LoanNotesSectionProps {
  loanDetails: LoanApplication;
  setNoteModalState: callback;
}

export default class LoanNotesSection extends React.Component<LoanNotesSectionProps> {
  render() {
    return (
      <>
        <h3>Notes</h3>
        {this.props.loanDetails.loanNotes !== undefined && this.props.loanDetails.loanNotes.length > 0 ?
          <>
            <Table striped={false} className="table-border" size="sm">
              <thead>
                <tr>
                  <th className="note-heading note-title">Note</th>
                  <th className="note-heading note-title">Date Posted</th>
                  <th className="note-heading note-title">Loan Officer</th>
                  <th className="note-heading note-title"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.loanDetails.loanNotes.map((ln: LoanNote) => {
                  return (
                    <>
                      <tr >
                        <td className="align-middle note-entry-margin">{ln.note}</td>
                        <td className="align-middle note-entry-margin">{loanApplicationUtility.GetDateMonthNameFullYear(ln.dateSubmitted)}</td>
                        <td className="align-middle note-entry-margin">{bankEmployeeUtility.GetBankEmployeeName(ln.author)}</td>
                        <td className="align-right">
                          <Button variant="link" onClick={this.props.setNoteModalState.bind(this, 'edit', ln)}>Edit</Button>
                          <Button variant="link" onClick={this.props.setNoteModalState.bind(this, 'delete', ln)}>Delete</Button>
                        </td>
                      </tr>
                    </>
                  )
                })
                }
              </tbody>
            </Table>
          </>
          :
          <div>This loan application does not have any notes.</div>
        }
      </>
    );
  }
}