import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ComponentType, FunctionComponent } from "react";
import { Route } from "react-router-dom";
import LoadingSpinner from "../shared/components/spinner/LoadingSpinner";

interface ProtectedRouteProps {
  component: ComponentType;
  exact?: boolean;
  path: string;
}
export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  component,
  ...args
}) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <LoadingSpinner
        variant={'dark'}
        animation={'border'}
      />,
    })}
    {...args}
  />
);
