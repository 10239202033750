import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "utilities/AppInsights";

class OffersPage extends React.Component {
  render() {
    return <h1>Offers Page</h1>;
  }
}

export default withAITracking(reactPlugin, OffersPage);
