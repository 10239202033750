import CustomerAccount from "models/account/customer/customerAccount";
import BankEmployee from "../models/bank/bankEmployee";
import axiosRequestUtility from "../utilities/AxiosRequestUtility";
import LoggedInAccount from "models/account/loggedInAccount";
import axios from "axios";

var CancelToken = axios.CancelToken;
var cancel: any;

class AccountService {
  public GetLoggedInProfile(): Promise<LoggedInAccount> {
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `GetLoggedInProfile`,
    })
      .then(response => {
        return response.data as LoggedInAccount
      })
      .catch(error => {
        console.log(error);
        alert('Error loading account profile. Please try again.')
        return {} as LoggedInAccount;
      })
  }

  public UpdateLoggedInProfile(bankEmployee: BankEmployee): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'PUT',
      url: `UpdateLoggedInProfile`,
      data: bankEmployee
    })
      .then(response => {
        return true;
      })
      .catch(error => {
        console.log(error);
        alert('Error updating profile information. Please try again.')
        return false;
      })
  }

  public LookupCustomerAccounts(searchText: string): Promise<CustomerAccount[]> {
    if (cancel != undefined) {
      cancel();
    }

    return axiosRequestUtility.Request({
      method: 'GET',
      url: `LookupCustomerAccounts`,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      params: {
        'query': searchText
      }
    })
      .then(response => {
        return response?.data ?? []; // Cancelled requests return undefined
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          console.log(error);
          alert('Error searching customer account information. Please try again.')
          return [];
        }
      })
  }

  public CheckIfAccountExists(email: string): Promise<boolean> {
    return axiosRequestUtility.Request({
      method: 'GET',
      url: `CheckCustomerAccountExists`,
      params: {
        'email': email
      }
    })
      .then(response => {
        return response?.data ?? true;
      })
      .catch(error => {
        console.log(error);
        alert('Error checking if account exists. Please try again.')
        return true;
      })
  }
}

const accountService = new AccountService();
export default accountService;