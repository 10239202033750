import React from "react";
import { Col, Row } from "react-bootstrap";
import FSA2211FormPartA from "./FSA2211PartA";
import FSA2211FormPartB from "./FSA2211PartB";
import FSA2211FormPartC from "./FSA2211PartC";
import FSA2211FormPartD from "./FSA2211PartD";
import FSA2211FormPartE from "./FSA2211PartE";
import FSA2211FormPartF from "./FSA2211PartF";
import FSA2211FormPartG from "./FSA2211PartG";
import FSA2211FormPartH from "./FSA2211PartH";
import FSA2211FormPartI from "./FSA2211PartI";
import FSA2211FormPartJ from "./FSA2211PartJ";
import FSA2211FormPartK from "./FSA2211PartK";
import FSA2211FormPartL from "./FSA2211PartL";
import FSA2211FormPartM from "./FSA2211PartM";
import FSA2211FormPartN from "./FSA2211PartN";
import FSA2211LegalStatementSection from "./FSA2211LegalStatementSection";
import FSA2211FormPartOSection1 from "./FSA2211PartOSection1";
import FSA2211FormPartOSection2 from "./FSA2211PartOSection2";
import FSA2211FormPartP from "./FSA2211PartP";
import FSA2211FormPartQSel from "./FSA2211PartQSel";
import FSA2211FormPartQClp from "./FSA2211PartQClp";
import FSA2211Data from "models/fsa2211Form/FSA2211Data";
import FSA2211PartA from "../../../../models/fsa2211Form/FSA2211PartA"
import "./FSA2211Form.css"
import FSA2211PartB from "models/fsa2211Form/FSA2211PartB";
import FSA2211PartC from "models/fsa2211Form/FSA2211PartC";
import FSA2211PartD from "models/fsa2211Form/FSA2211PartD";
import FSA2211PartE from "models/fsa2211Form/FSA2211PartE";
import FSA2211PartF from "models/fsa2211Form/FSA2211PartF";
import FSA2211PartG from "models/fsa2211Form/FSA2211PartG";
import FSA2211PartH from "models/fsa2211Form/FSA2211PartH";
import FSA2211PartI from "models/fsa2211Form/FSA2211PartI";
import FSA2211PartJ from "models/fsa2211Form/FSA2211PartJ";
import FSA2211PartK from "models/fsa2211Form/FSA2211PartK";
import FSA2211PartL from "models/fsa2211Form/FSA2211PartL";
import FSA2211PartM from "models/fsa2211Form/FSA2211PartM";
import FSA2211PartN from "models/fsa2211Form/FSA2211PartN";
import FSA2211PartOCoApplicant from "models/fsa2211Form/FSA2211PartOCoApplicant";
import FSA2211PartP from "models/fsa2211Form/FSA2211PartP";
import FSA2211PartQSel from "models/fsa2211Form/FSA2211PartQSel";
import FSA2211PartQClp from "models/fsa2211Form/FSA2211PartQClp";
import FSA2211 from "models/fsa2211Form/FSA2211";

type formDataCallback = (formData: FSA2211Data) => void;

interface FSA2211FormProps {
  formData: FSA2211Data;
  fsa2211RevisionVersion: string;
  onFSA2211FormUpdated: formDataCallback;
}

interface FSA2211FormState {
  formData: FSA2211Data
}

export default class FSA2211Form extends React.Component<FSA2211FormProps, FSA2211FormState> {
  constructor(props: any) {
    super(props);

    this.state = {
      formData: this.props.formData
    }
  }

  componentDidUpdate(previousProps: FSA2211FormProps) {
    if (previousProps.formData !== this.props.formData) {
      this.setState({formData: this.props.formData});
    }
  }

  setFormDataPartAState(formAData: FSA2211PartA): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partA = formAData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartBState(formBData: FSA2211PartB): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partB = formBData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartCState(formCData: FSA2211PartC): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partC = formCData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartDState(formDData: FSA2211PartD): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partD = formDData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartEState(formEData: FSA2211PartE): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partE = formEData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartFState(formFData: FSA2211PartF): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partF = formFData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartGState(formBData: FSA2211PartG): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partG = formBData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartHState(formHData: FSA2211PartH): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partH = formHData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartIState(formIData: FSA2211PartI): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partI = formIData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartJState(formJData: FSA2211PartJ): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partJ = formJData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartKState(formKData: FSA2211PartK): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partK = formKData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartLState(formLData: FSA2211PartL): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partL = formLData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartMState(formMData: FSA2211PartM): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partM = formMData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartNState(formNData: FSA2211PartN): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partN = formNData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartOOneState(formOOneData: FSA2211PartOCoApplicant): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partOOne = formOOneData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartOTwoState(formOTwoData: FSA2211PartOCoApplicant): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partOTwo = formOTwoData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartPState(formPData: FSA2211PartP): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partP = formPData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartQSelState(formQSelData: FSA2211PartQSel): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partQSel = formQSelData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  setFormDataPartQClpState(formQClpData: FSA2211PartQClp): void {
    let formData: FSA2211Data = { ...this.state.formData };
    formData.partQClp = formQClpData;
    this.setState({ formData: formData }, () => this.props.onFSA2211FormUpdated(this.state.formData));
  }

  render() {
    return (
      <>
        <div>
          <div className="standard-text align-right">OMB Control No. 0560-0155</div>
          <div className="standard-text align-right mt-2">Expiration Date 09/30/2023</div>
          <div className="standard-text align-right mt-2 mb-2">(See Page 4 for Privacy Act and Paperwork Reduction Act Statements)</div>
          <Row>
            <Col className="section-border">
              <Row className="mt-2">
                <Col><div className="standard-bold align-left">FSA-2211</div></Col>
                <Col><div className="standard-bold align-center">U.S. DEPARTMENT OF AGRICULTURE</div></Col>
                <Col><div className="standard-text align-right">Position 3</div></Col>
              </Row>
              <Row>
                <Col><div className="standard-text align-left">({this.props.fsa2211RevisionVersion})</div></Col>
                <Col><div className="standard-text align-center">Farm Service Agency</div></Col>
                <Col><div className="align-right"></div></Col>
              </Row>
              <Row><Col></Col></Row>
              <Row className="mt-3">
                <Col><div className="align-center"><h4><strong>APPLICATION FOR GUARANTEE</strong></h4></div></Col>
              </Row>
              <Row className="mb-3">
                <Col><div className="align-center"><h6><strong><em>LENDERS RETURN THIS COMPLETED FORM TO YOUR COUNTY FSA OFFICE</em></strong></h6></div></Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="section-border">
              <Row className="mt-2">
                <Col xs={2}><div className="align-left standard-bold">INSTRUCTIONS TO APPLICANT AND LENDER:</div></Col>
                <Col>
                  <Row><Col><div className="align-left standard-text">
                    All Loan Applicants will complete Part A. If the Loan Applicant is an entity, they will complete Part B. If the Applicant is
                    an individual, they will complete Part C. All Loan Applicants will complete Parts D, E, and F. Lender will complete Parts
                    G through M. Co-Applicants, entity members, and cosigners will complete Parts O and P
                  </div></Col></Row>
                  <Row className="mt-3 mb-2"><Col><div className="align-left standard-text">
                    <span className="standard-bold">Note:</span> When both spouses are involved in the operation, and both are applying for the loan, they should be considered a
                    Joint Operation. One spouse completes Part C, the other completes Parts O and P.
                  </div></Col></Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <FSA2211FormPartA partAData={this.state.formData.partA} formDataChange={this.setFormDataPartAState.bind(this)} />
          <FSA2211FormPartB partBData={this.state.formData.partB} formDataChange={this.setFormDataPartBState.bind(this)} />
          <FSA2211FormPartC partCData={this.state.formData.partC} formDataChange={this.setFormDataPartCState.bind(this)} />
          <FSA2211FormPartD partDData={this.state.formData.partD} formDataChange={this.setFormDataPartDState.bind(this)} />
          <FSA2211FormPartE partEData={this.state.formData.partE} formDataChange={this.setFormDataPartEState.bind(this)} />
          <FSA2211FormPartF partFData={this.state.formData.partF} formDataChange={this.setFormDataPartFState.bind(this)} />
          <FSA2211FormPartG partGData={this.state.formData.partG} formDataChange={this.setFormDataPartGState.bind(this)} />
          <FSA2211FormPartH partHData={this.state.formData.partH} formDataChange={this.setFormDataPartHState.bind(this)} />
          <FSA2211FormPartI partIData={this.state.formData.partI} formDataChange={this.setFormDataPartIState.bind(this)} />
          <FSA2211FormPartJ partJData={this.state.formData.partJ} formDataChange={this.setFormDataPartJState.bind(this)} />
          <FSA2211FormPartK partKData={this.state.formData.partK} formDataChange={this.setFormDataPartKState.bind(this)} />
          <FSA2211FormPartL partLData={this.state.formData.partL} formDataChange={this.setFormDataPartLState.bind(this)} />
          <FSA2211FormPartM partMData={this.state.formData.partM} formDataChange={this.setFormDataPartMState.bind(this)} />
          <FSA2211FormPartN partNData={this.state.formData.partN} formDataChange={this.setFormDataPartNState.bind(this)} />
          <FSA2211LegalStatementSection />
          <FSA2211FormPartOSection1 partOSectionOneData={this.state.formData.partOOne} formDataChange={this.setFormDataPartOOneState.bind(this)} />
          <FSA2211FormPartOSection2 partOSectionTwoData={this.state.formData.partOTwo} formDataChange={this.setFormDataPartOTwoState.bind(this)} />
          <FSA2211FormPartP partPData={this.state.formData.partP} formDataChange={this.setFormDataPartPState.bind(this)} />
          <FSA2211FormPartQSel partQSelData={this.state.formData.partQSel} formDataChange={this.setFormDataPartQSelState.bind(this)} />
          <FSA2211FormPartQClp partQClpData={this.state.formData.partQClp} formDataChange={this.setFormDataPartQClpState.bind(this)} />
        </div>
      </>
    );
  }
}