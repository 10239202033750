import numberUtility from "./NumberUtility"
import { DocumentType} from "../shared/Enums";

class CategoryUtility{
    categoryNumbers(): number[] {
        return Object.keys(DocumentType).filter(x => numberUtility.isNumber(x)).map((i) => Number(i));
    }
}


const categoryUtility = new CategoryUtility();
export default categoryUtility;