import React from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import loanApplicationUtility from "../../../utilities/LoanApplicationUtility";
import Select from "react-select";
import SelectOption from "../../../shared/models/selectOption";
import DatePicker from "react-datepicker";
import "./LoansListFilters.css"
import { Form } from "react-bootstrap";
import Can from "auth/Can";

type SelectFilterCallback = (selectedOptions: any) => void;
type ReferenceCallback = (reference: any) => void;
type DateFilterCallback = (selectedDate: Date) => void;
type ResetFilterCallback = () => void;

interface LoansListFiltersProps {
    loanTypeFilter: SelectOption[];
    loanTypeFilterCallback: SelectFilterCallback;
    scorecardLevelFilter: SelectOption[];
    scorecardLevelFilterCallback: SelectFilterCallback;
    loanStatusOptions: SelectOption[];
    loanStatusFilter: SelectOption[];
    loanStatusFilterCallback: SelectFilterCallback;
    loanOfficerOptions: SelectOption[];
    loanOfficerFilter: SelectOption[];
    loanOfficerFilterCallback: SelectFilterCallback;
    submittedStartDateFilter?: Date;
    submittedStartDateFilterCallback: DateFilterCallback;
    submittedEndDateFilter?: Date;
    submittedEndDateFilterCallback: DateFilterCallback;
    referenceNumber?: string;
    referenceNumberFilterCallback: ReferenceCallback;
    resetFilterCallback: ResetFilterCallback;
}

export default class LoansListFilters extends React.Component<LoansListFiltersProps> {
    onlyNumbers(event: React.KeyboardEvent<HTMLInputElement>) {
        if (!/\d/.test(event.key)) {
            event.preventDefault();
        }
    }
    render() {
        return (
            <div>
                <Row className="loans-list-filter-row align-items-center">
                    <Col>
                        <label>Submit Start Date</label>
                        <DatePicker
                            selected={this.props.submittedStartDateFilter}
                            onChange={this.props.submittedStartDateFilterCallback}
                        />
                    </Col>
                    <Col>
                        <label>Submit End Date</label>
                        <DatePicker
                            selected={this.props.submittedEndDateFilter}
                            onChange={this.props.submittedEndDateFilterCallback}
                        />
                    </Col>
                    <Col>
                        <label>Reference #</label>
                        <Form.Control
                            type="text"
                            value={this.props.referenceNumber}
                            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => this.onlyNumbers(event)}
                            onChange={(event) => this.props.referenceNumberFilterCallback(event)} />
                    </Col>
                    <Can
                        perform="Loan Officer"
                        yes={() => (
                            <Col>
                                <label>Loan Purpose</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    placeholder={'All Types'}
                                    value={this.props.loanTypeFilter}
                                    onChange={this.props.loanTypeFilterCallback}
                                    options={loanApplicationUtility.GetLoanTypeOptions()}
                                />
                            </Col>
                        )}
                        no={() => <></>}
                    />

                    <Can
                        perform="Loan Officer"
                        yes={() => (
                            <Col>
                                <label>Loan Officer</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    placeholder={'All Officers'}
                                    value={this.props.loanOfficerFilter}
                                    onChange={this.props.loanOfficerFilterCallback}
                                    options={this.props.loanOfficerOptions}
                                />
                            </Col>
                        )}
                        no={() => <></>}
                    />
                    <Can
                        perform="Loan Officer"
                        yes={() => (

                            <Col>
                                <label>Scorecard Level</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    placeholder={'All Levels'}
                                    value={this.props.scorecardLevelFilter}
                                    onChange={this.props.scorecardLevelFilterCallback}
                                    options={loanApplicationUtility.GetLoanScorecardLevelOptions()}
                                />
                            </Col>
                        )}
                        no={() => <></>}
                    />
                    <Col>
                        <label>Status</label>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder={'All Statuses'}
                            value={this.props.loanStatusFilter}
                            onChange={this.props.loanStatusFilterCallback}
                            options={this.props.loanStatusOptions}
                        />
                    </Col>
                </Row>
            </div >
        )
    }
}