import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartF from "../../../../models/fsa2211Form/FSA2211PartF"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartFCallback = (formDataPartF: FSA2211PartF) => void;

interface FSA2211FormPartFProps {
  partFData?: FSA2211PartF;
  formDataChange: formDataPartFCallback;
}

export default class FSA2211FormPartF extends React.Component<FSA2211FormPartFProps, FSA2211PartF> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partFData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartFProps) {
    if (previousProps.partFData !== this.props.partFData) {
      this.setState(this.props.partFData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartF {
    return {
      capacitySelf: false,
      capacityEntityRepresentative: false,
      dateSigned: "",
    } as FSA2211PartF
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART F &nbsp;&nbsp; APPLICANT ELIGIBILITY INFORMATION <em>(LOAN APPLICANT)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-center"><strong>RIGHT TO FINANCIAL PRIVACY ACT OF 1978</strong></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-left">
                    FSA has a right of access to financial records held by financial institutions in connection with providing assistance to you, as well as collecting on loans made to you or
                    guaranteed by the government. Financial records involving your transaction will be available to FSA without further notice or authorization but will not be disclosed or
                    released by this institution to another government Agency or Department without your consent except as required by law.
                  </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-left">
                    <strong>THE FEDERAL EQUAL CREDIT OPPORTUNITY ACT</strong> prohibits creditors from discriminating against applicants on the basis of race, color, religion, sex, national
                    origin, marital status, or age (provided the applicant has the capacity to enter into a binding contract), because all or a part of the applicant's income derives from any public
                    assistance program, or because the applicant has in good faith exercised any right under the Consumer Credit Protection Act.
                  </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-center"><strong>CERTIFICATIONS, RESTRICTIONS, AND DISCLOSURE OF LOBBYING ACTIVITIES</strong></Col>
                </Row>
                <Row className="standard-text">
                  <Col>
                    <Row className="mt-2"><Col md={1} className="align-right">1.</Col><Col>
                      The loan applicant certifies that: if any funds, by or on behalf of the loan applicant, have been or will be paid to any person for influencing or attempting to influence
                      an officer or employee of any agency, a Member, an officer or employee of Congress, or an employee of a Member of Congress in connection with the awarding of
                      any Federal contract, the making of any Federal grant or Federal loan, and the extension, continuation, renewal, amendment, or modification of any Federal contract,
                      grant, or loan, the loan applicant shall complete and submit Standard Form - LLL, ''Disclosure of Lobbying Activities,'' in accordance with its instructions.
                    </Col></Row>
                    <Row className="mt-2"><Col md={1} className="align-right">2.</Col><Col>
                      The loan applicant shall require that the language of this certification be included in the award documents for all sub-awards at all tiers (including contracts,
                      subcontracts, and subgrants, under grants and loans) and that all subrecipients shall certify and disclose accordingly.
                    </Col></Row>
                    <Row className="mt-2"><Col md={1} className="align-right">3.</Col><Col>
                      This certification is a material representation of fact upon which reliance was placed when this transaction was made or entered into. Submission of this statement is a
                      prerequisite for making or entering into this transaction and is imposed by 31 U.S.C. 1352. Any person who fails to file the required statement shall be subject to a
                      civil penalty of not less than $10,000 and not more than $100,000 for each failure.
                    </Col></Row>
                  </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-center"><strong>ABUSE OF CONTROLLED SUBSTANCES</strong></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-left">
                    The loan applicant certifies that he or she as an individual, or any member of an entity applicant, has not been convicted under Federal or State law of planting, cultivating,
                    growing, producing, harvesting, or storing a controlled substance within the last 5 crop years, in accordance with 21 U.S.C. 889. The loan applicant also certifies that he/she
                    as an individual, or any member of an entity applicant, is not ineligible for Federal benefits based on a conviction for the distribution of controlled substances or any offense
                    involving the possession of a controlled substance under 21 U.S.C. 862.
                  </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-center"><strong>FEDERAL DEBT</strong></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-left">
                    The loan applicant certifies and acknowledges that any amounts paid by FSA on account of the liabilities of the guaranteed loan borrower will constitute a Federal debt
                    owing to FSA by the guaranteed loan borrower. In such case, FSA may use all remedies available to it, including offset under the Debt Collection Improvement Act, to
                    collect the debt from the borrower. The Agency's right to collect is independent of the lender's right to collect under the guaranteed note and will not be affected by any
                    release by the lender of my (our) obligation to repay the loan. Any Agency collection under this paragraph will not be shared with the lender.
                  </Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-center"><strong>ACKNOWLEDGMENT</strong></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col className="align-left">
                    <em>I certify that I accept and comply with the conditions stated hereon. I certify that the statements made by me in this application are true, complete, and correct to the best of
                      my knowledge and belief and are made in good faith to obtain a loan. I understand that the approval period will not begin until a complete application has been filed.
                      (Warning: section 1001 of Title 18, United States Code provides for criminal penalties to those who provide false statements on loans. If any information on this application
                      is found to be false or incomplete, such finding may be grounds for denial of the requested credit and civil and criminal prosecution.)
                    </em>
                  </Col>
                </Row>
                <Row className="standard-text mt-4">
                  <Col md={5}>1A. Signature of Applicant</Col>
                  <Col md={3}>1B. Capacity</Col>
                  <Col md={4}>1C. Date Signed <em>(MM-DD-YYYY)</em></Col>
                </Row>
                <Row className="mt-2">
                  <Col md={5}><Form.Control type="text" disabled={true} /></Col>
                  <Col md={3}>
                    <Row>
                      <Col><Form.Check name="capacitySelf" inline label="Self" onChange={this.handleInputChange.bind(this)} checked={this.state.capacityEntityRepresentative} /><Form.Check name="capacityEntityRepresentative" inline className="ml-2" label="Entity Representative" onChange={this.handleInputChange.bind(this)} checked={this.state.capacitySelf} /></Col>
                    </Row>
                  </Col>
                  <Col md={4}><Form.Control name="dateSigned" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.dateSigned} /></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}