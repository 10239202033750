import { AppThunk } from '../../../redux/store/store';
import { TOGGLE_API_REQUEST_SUCCESS, TOGGLE_LOADING } from '../../store/system/types';
import dealerService from 'accessors/dealerService';
import Dealer from 'models/dealerPurchase/dealer';
import DealerSalesman from 'models/dealerPurchase/dealerSalesman';
import { ADD_DEALER, ADD_DEALER_SALESMAN, GET_DEALERS, UPDATE_DEALER, UPDATE_DEALER_SALESMAN } from 'redux/store/dealer/types';

export const getDealers = (): AppThunk => async (dispatch) => {
    dealerService.GetDealers()
        .then((dealers: Dealer[]) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            dispatch({
                type: GET_DEALERS,
                payload: {
                    dealers: dealers
                },
            });
        })
};

export const addNewDealer = (
    dealer: Dealer
): AppThunk => async (dispatch) => {
    dealerService.AddNewDealer(dealer)
        .then((newDealer: Dealer) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (Object.keys(newDealer).length > 0) {
                dispatch({
                    type: ADD_DEALER,
                    payload: {
                        dealer: newDealer
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const updateDealer = (
    dealer: Dealer
): AppThunk => async (dispatch) => {
    dealerService.UpdateDealer(dealer)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            if (success) {
                dispatch({
                    type: UPDATE_DEALER,
                    payload: {
                        dealer: dealer
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const addNewDealerSalesman = (
    dealerSalesman: DealerSalesman
): AppThunk => async (dispatch) => {
    dealerService.AddNewDealerSalesman(dealerSalesman)
        .then((newDealerSalesman: DealerSalesman) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (Object.keys(newDealerSalesman).length > 0) {
                dispatch({
                    type: ADD_DEALER_SALESMAN,
                    payload: {
                        dealerId: newDealerSalesman.dealerId,
                        dealerSalesman: newDealerSalesman
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const updateDealerSalesman = (
    dealerSalesman: DealerSalesman
): AppThunk => async (dispatch) => {
    dealerService.UpdateDealerSalesman(dealerSalesman)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            if (success) {
                dispatch({
                    type: UPDATE_DEALER_SALESMAN,
                    payload: {
                        dealerId: dealerSalesman.dealerId,
                        dealerSalesman: dealerSalesman
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};