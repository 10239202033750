import React from "react";
import Table from "react-bootstrap/esm/Table";
import { DocumentType, DocumentTypeMapper } from "../../../shared/Enums";
import Button from "react-bootstrap/esm/Button";
import DocumentRequest from "../../../models/documentData/DocumentRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OpenDocumentRequestsSection.css"
import categoryUtility from "../../../utilities/CategoryUtility";
import ConfirmModal from "components/modals/confirmModal/ConfirmModal";
import loanApplicationUtility from "utilities/LoanApplicationUtility";
import Can from "auth/Can";

interface OpenDocumentRequestsSectionProps {
  openRequests: DocumentRequest[],
  deleteOpenRequest(requestId: number): void;
}

interface OpenDocumentRequestsSectionState {
  showConfirmModal: boolean;
  selectedOpenRequest: DocumentRequest;
}

export default class OpenDocumentRequestsSection extends React.Component<OpenDocumentRequestsSectionProps, OpenDocumentRequestsSectionState> {
  categoryNumbers = categoryUtility.categoryNumbers();

  constructor(props: any) {
    super(props);

    this.state = {
      showConfirmModal: false,
      selectedOpenRequest: {} as DocumentRequest,
    };
  }

  renderCondition(type: number): boolean {
    return (!isNaN(Number(type)) &&
      this.props.openRequests !== undefined &&
      this.props.openRequests.filter(x => x.type.toString() === DocumentType[type]).length > 0)
  }

  getDocumentsPerTypeInYear(type: number): string[] {
    return Array.from(
      new Set(this.props.openRequests.filter(x =>
        x.type.toString() === DocumentType[type]
      ).map(x => x.year)));
  }

  confirmDelete(request: DocumentRequest) {
    this.setState({
      selectedOpenRequest: request,
      showConfirmModal: true
    });
  }

  deleteOpenRequest() {
    this.props.deleteOpenRequest(this.state.selectedOpenRequest?.id);
    this.resetConfirmationModal();
  }

  resetConfirmationModal() {
    this.setState({
      showConfirmModal: false,
      selectedOpenRequest: {} as DocumentRequest
    });
  }

  render() {
    return (
      <>
        <div>
          <h3>Applicant Open Document Requests</h3>

          {this.props.openRequests !== undefined &&
            <Table className="table-border" striped={false} size="sm">
              <thead>
                <tr>
                  <th className="document-type-heading document-type-title">Type</th>
                  <th className="document-type-heading">Year</th>
                  <th className="document-type-heading">Loan</th>
                  <th className="document-type-heading">Note</th>
                  <th className="align-right document-type-heading"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.openRequests.map(
                  (request: DocumentRequest, j: number) => {
                    return (
                      <tr key={`applicant-open-document-request-${j}`}>
                        <td>{DocumentTypeMapper[Number(DocumentType[request.type])]}</td>
                        <td>{request.year}</td>
                        <td>{loanApplicationUtility.BuildDisplayId(request.loanId)}</td>
                        <td>{request.note}</td>
                        <Can
                          perform="Loan Officer"
                          yes={() => (
                            <td className="align-right">
                              <Button variant="link">
                                <FontAwesomeIcon className="trashcan" icon="trash" onClick={() => this.confirmDelete(request)} />
                              </Button>
                            </td>
                          )}
                          no={() => <td></td>}
                        />
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          }
        </div>

        <ConfirmModal
          showModal={this.state.showConfirmModal}
          modalTitle="Delete Document Request?"
          modalMessage="Are you sure you want to remove this request?"
          confirmButtonText="Delete"
          isDanger={true}
          onModalCancel={() => this.resetConfirmationModal()}
          onModalConfirm={() => this.deleteOpenRequest()}
        />
      </>
    );
  }
}