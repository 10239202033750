import React from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import "./LoadingSpinner.css"

interface SpinnerProps {
  animation: "border" | "grow",
  variant: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark"
  size?: "sm"
}

export default class LoadingSpinner extends React.Component<SpinnerProps> {
  render() {
    return (
      <div className={'spinner-overlay'}>
        <div className={'center-spinner'}>
          <Spinner className={''} animation={this.props.animation} variant={this.props.variant} size={this.props.size} role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }
}