import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./FixedAssetSection.css";
import { FixedAssetType } from "../../../shared/Enums";
import FixedAssetLivestock from "../../../models/fixedAsset/livestock";
import loanApplicationUtility from "../../../utilities/LoanApplicationUtility";

interface FixedAssetSectionProps {
  loanDetails: LoanApplication
}

export default class FixedAssetSection extends React.Component<FixedAssetSectionProps> {
  render() {
    return (
      <>
        {this.props.loanDetails.fixedAssetTypes !== undefined && this.props.loanDetails.fixedAssetTypes.length > 0 &&
          (<>
            <div>
              <h3>Fixed Asset Details</h3>
              <Table bordered striped={false} size="sm">
                <tbody>
                  {this.props.loanDetails.fixedAssetTypes.includes(FixedAssetType.Equipment) &&
                    (
                      <>
                        <tr>
                          <td width="250px" className="app-info"><strong>Equipment Purchase</strong></td>
                          <td className="app-info">{this.props.loanDetails.fixedAssetEquipment?.description}</td>
                        </tr>
                        <tr>
                          <td width="250px" className="app-info"><strong>Equipment Cost</strong></td>
                          <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.fixedAssetEquipment?.cost)}</td>
                        </tr>
                      </>
                    )
                  }

                  {this.props.loanDetails.fixedAssetTypes.includes(FixedAssetType.Livestock) &&
                    (<>
                      {this.props.loanDetails.fixedAssetLivestock !== undefined &&
                        (<>
                          {this.props.loanDetails.fixedAssetLivestock.map((l: FixedAssetLivestock, i: number) => {
                            return (
                              <>
                                <tr>
                                  <td colSpan={2}>{`Livestock #${i+1}`}</td>
                                </tr>
                                <tr>
                                  <td width="250px" className="app-info"><strong>Description</strong></td>
                                  <td className="app-info">{l.description}</td>
                                </tr>
                                <tr>
                                  <td width="250px" className="app-info"><strong>Number of Units</strong></td>
                                  <td className="app-info">{l.units}</td>
                                </tr>
                                <tr>
                                  <td width="250px" className="app-info"><strong>Cost Per Unit</strong></td>
                                  <td className="app-info">{l.costPerUnit}</td>
                                </tr>
                              </>
                            )
                          })
                          }
                        </>)
                      }
                    </>)
                  }

                  {this.props.loanDetails.fixedAssetTypes.includes(FixedAssetType.RealEstate) &&
                    (<>
                      <tr>
                        <td width="250px" className="app-info"><strong>Describe Real Estate</strong></td>
                        <td className="app-info">{this.props.loanDetails.fixedAssetRealEstate?.description}</td>
                      </tr>
                      <tr>
                        <td width="250px" className="app-info"><strong>Number of Acres</strong></td>
                        <td className="app-info">{this.props.loanDetails.fixedAssetRealEstate?.numberOfAcres}</td>
                      </tr>
                      <tr>
                        <td width="250px" className="app-info"><strong>Cost Per Acre</strong></td>
                        <td className="app-info">{this.props.loanDetails.fixedAssetRealEstate?.costPerAcre}</td>
                      </tr>
                      <tr>
                        <td width="250px" className="app-info"><strong>Describe Improvements</strong></td>
                        <td className="app-info">{this.props.loanDetails.fixedAssetRealEstate?.improvementsDescription}</td>
                      </tr>
                      <tr>
                        <td width="250px" className="app-info"><strong>Improvements Value</strong></td>
                        <td className="app-info">{this.props.loanDetails.fixedAssetRealEstate?.improvementsValue}</td>
                      </tr>
                    </>)
                  }
                </tbody>
              </Table>
            </div>
          </>)
        }
      </>
    );
  }
}