import React from "react";
import "./MachineryEquipmentVehicleSection.css"
import loanApplicationUtility from "utilities/LoanApplicationUtility";
import AssetCategory from "models/financialDocuments/balanceSheet/assets/assetCategory";
import MachineryEquipmentVehicle from "models/financialDocuments/balanceSheet/assets/machineryEquipmentVehicle";
import balanceSheetUtility from "utilities/BalanceSheetUtility";

interface MachineryEquipmentVehicleSectionProps {
  assetCategory: AssetCategory
  assetData: MachineryEquipmentVehicle
}

interface MachineryEquipmentVehicleSectionState {

}

export default class MachineryEquipmentVehicleSection extends React.Component<MachineryEquipmentVehicleSectionProps, MachineryEquipmentVehicleSectionState> {
  render() {
    return (
      <>
        <div>
          <div><span style={{fontStyle: 'italic'}}>Quantity:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{loanApplicationUtility.BuildAmount(this.props.assetData.quantity ?? 0)}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Manufacturer:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{this.props.assetData.manufacturer}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Size/Type:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{this.props.assetData.sizeType}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Condition:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{balanceSheetUtility.GetEquipmentConditionText(this.props.assetData.condition)}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Year:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{this.props.assetData.year}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Serial Number/VIN:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{this.props.assetData.serialVin}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Loan Against This Piece Of Equipment:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{this.props.assetData.isCurrentLoan ? 'Yes' : 'No'}</span>
          </div>
        </div>

        <div>
          <div><span style={{fontStyle: 'italic'}}>Customer Planning To Sell/Trade This Piece Of Equipment During Production Cycle:</span></div>
          <div>
            <span style={{paddingLeft: '10px'}}>{this.props.assetData.forSellTrade ? 'Yes' : 'No'}</span>
          </div>
        </div>
      </>
    );
  }
}