import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import { LoanType } from "../../../shared/Enums";
import Button from "react-bootstrap/esm/Button";
import "./BalanceSheetSection.css"
import SubmittedBalanceSheet from "models/financialDocuments/balanceSheet/submittedBalanceSheet";
import BalanceSheet from "models/financialDocuments/balanceSheet/balanceSheet";

type BalanceSheetCallback = (selectedBalanceSheet: BalanceSheet) => void;
type RequestCallback = () => void;

interface BalanceSheetSectionsProps {
  loanDetails: LoanApplication,
  setBalanceSheetModalState: BalanceSheetCallback,
  setRequestModalState: RequestCallback
}

export default class BalanceSheetSection extends React.Component<BalanceSheetSectionsProps> {


  render() {
    return (
      <>
        <div>
          <h3>Customer Balance Sheets</h3>

          <Table key={`dealer-purchase-documents`} className="table-border" striped={false} size="sm">
            <thead>
              <tr className="same-col-widths">
                <th className="document-type-heading document-type-title">Balance Sheets (As of Dates)</th>
                <th className="align-right document-type-heading" >
                  {/* <Button
                        className="download-link-text"
                        variant="link"
                        onClick={this.props.setDocumentModalState.bind(this, 'create', undefined)}>Upload</Button> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {(this.props.loanDetails.customerAccount.balanceSheets == undefined || this.props.loanDetails.customerAccount.balanceSheets.length === 0) &&
                <tr>
                  <td><span className="no-file-text">No balance sheets submitted.</span></td>
                </tr>
              }
              {this.props.loanDetails.customerAccount.balanceSheets?.map((bs: SubmittedBalanceSheet, index) => {
                return (
                  <tr>
                    <td>
                      <Button className="download-link-text" variant="link" onClick={() => { this.props.setBalanceSheetModalState(bs.balanceSheet) }}>{bs.balanceSheet.asOfDate}</Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}