import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import SecondaryButton from "shared/components/SecondaryButton";
import PrimaryButton from "shared/components/PrimaryButton";
import "./RequestInfoModal.css"
import numberUtility from "utilities/NumberUtility";
import guidUtility from "utilities/GuidUtility";
import DocumentRequestSection from "shared/components/documentRequestSection";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentRequestWithGuid from 'models/documentData/DocumentRequestWithGuid';
import DocumentRequest from "models/documentData/DocumentRequest";
import { DocumentRequestStatus } from "shared/Enums";

interface RequestInfoModalProps {
  showModal: boolean;
  loanId: number;
  onModalCancel(): void;
  onModalConfirm(requests: DocumentRequest[]): void;
}

interface RequestInfoModalState {
  documentRequestDatas: DocumentRequestWithGuid[];
  isValid: boolean;
}

export default class RequestInfoModal extends React.Component<RequestInfoModalProps, RequestInfoModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      documentRequestDatas: [this.emptyDocumentDataItem()],
      isValid: false
    };
  }

  componentDidMount(): void {
    this.setState({
      documentRequestDatas: [this.emptyDocumentDataItem()],
      isValid: false
    });
  }

  reset(): void {
    this.setState({
      documentRequestDatas: [this.emptyDocumentDataItem()],
      isValid: false
    });
  }

  updateValidation(): void {
    let isValid = this.state.documentRequestDatas.every((data: DocumentRequestWithGuid) => {
      return (
        data !== undefined &&
        data.type !== undefined && numberUtility.isNumber(Number(data.type)) &&
        data.year !== undefined && numberUtility.isNumber(Number(data.year))
      )
    });

    this.setState({ isValid: isValid });
  }

  updateRequest(data: DocumentRequestWithGuid, index: number): void {
    const updatedList = [...this.state.documentRequestDatas];
    updatedList[index] = data;

    this.setState({ documentRequestDatas: updatedList }, () => {
      this.updateValidation();
    });
  }

  addRequest(): void {
    this.setState({ documentRequestDatas: [...this.state.documentRequestDatas, this.emptyDocumentDataItem()] }, () => this.updateValidation());
  }

  removeRequest(index: number): void {
    this.setState({ documentRequestDatas: this.state.documentRequestDatas.filter((_, j) => index !== j) }, () => this.updateValidation());
  }

  emptyDocumentDataItem(): DocumentRequestWithGuid {
    let data = {} as DocumentRequestWithGuid;
    data.guid = guidUtility.uuidv4();
    data.loanId = this.props.loanId;
    data.documentRequestStatus = DocumentRequestStatus.Unfulfilled;
    return data;
  }

  submitNewRequest(): void {
    this.props.onModalConfirm(this.state.documentRequestDatas);
    this.reset();
  }

  close(): void {
    this.reset();
    this.props.onModalCancel();
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={() => this.close()} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Request Input</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            {this.state.documentRequestDatas.map((data: DocumentRequestWithGuid, i: number) =>
              <div key={`document-data-${data.guid}`} ref={React.createRef<HTMLDivElement>()}>
                <Form.Group>
                  {i > 0 &&
                    <Form.Row>
                      <Col>
                        <Button size="sm" variant="link" className="text-danger fa-pull-right" onClick={() => this.removeRequest(i)}><FontAwesomeIcon icon="minus-circle" /> Remove</Button>
                      </Col>
                    </Form.Row>
                  }
                  <Form.Row>
                    <Col>
                      <Form.Text className="mb-1">Please specify what documents you need from the applicant.</Form.Text>
                    </Col>
                  </Form.Row>
                  <DocumentRequestSection
                    documentsRequestData={data}
                    index={i}
                    updateRequest={this.updateRequest.bind(this)} />
                </Form.Group>
              </div>
            )}
            <Button size="sm" variant="link" onClick={() => this.addRequest()}><FontAwesomeIcon icon="plus-circle" /> Add Request</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton buttonText={'Cancel'} onClick={() => this.close()} />
          <PrimaryButton buttonText={'Submit Request'} onClick={() => this.submitNewRequest()} disabled={!this.state.isValid} />
        </Modal.Footer>
      </Modal>
    );
  }
}