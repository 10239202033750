import LoanApplication from "../models/loan/loanApplication";
import FSA2211Data from "../models/fsa2211Form/FSA2211Data";
import loanApplicationUtility from "./LoanApplicationUtility";

class FormPoppulationUtility {
  populateNewFSA2211FormData(loanDetails: LoanApplication): FSA2211Data {
    let currentDate = new Date();
    let operationDescription = "";

    if (loanDetails.operationNAICSCodes) {
      operationDescription = loanDetails.operationNAICSCodes.map(nc => loanApplicationUtility.BuildOtherTitle(nc)).join(', ');
    }

    let newFormData: FSA2211Data = {
      partA: {
        individual: loanDetails.applicants.length === 1, 
        jointOperation: loanDetails.applicants.length > 1, 
        trust: false,  
        partnership: false,  
        corporation: false,  
        cooperative: false,  
        llc: false,  
        otherCheckbox: false,  
        otherExplainTextbox: "" 
      },
      partB: {
        entityName: loanDetails.entityApplicant?.organizationName ?? "",
        entityAddress: loanDetails.entityApplicant ? `${loanDetails.entityApplicant?.address} ${loanDetails.entityApplicant?.city} ${loanDetails.entityApplicant?.state}` : "",
        numberOfEntityMembers: "",
        entityTaxIdNumber: loanDetails.entityApplicant?.ein ?? "",
        entityHeadquartersCounty: "",
        entityTelephoneNumber: "",
        telephoneTypeHome: false,
        telephoneTypeCell: false,
        telephoneTypeWork: false
      },
      partC: {
        applicantsFullName: loanDetails.applicants[0].displayName,
        applicantsAddress: `${loanDetails.applicants[0].address}
                                  ${loanDetails.applicants[0].city}, ${loanDetails.applicants[0].state} ${loanDetails.applicants[0].zipCode}`,
        applicantsBirthDate: loanApplicationUtility.GetDateMonthNameFullYear(loanDetails.applicants[0].dateOfBirth),
        applicantsSSN: loanDetails.applicants[0].ssn,
        residenceOrHeadquartersCounty: "",
        applicantsTelephoneNumber: loanDetails.customerAccount.phoneNumber,
        telephoneTypeHome: false,
        telephoneTypeCell: false,
        telephoneTypeWork: false,
        maritalStatusMarried: false,
        maritalStatusUnmarried: false,
        maritalStatusDivorced: false,
        maritalStatusLegallySeparated: false,
        maritalStatusWidowed: false,
        citizenshipStatusUSCitizen: false,
        citizenshipStatusNonCitizenNational: false,
        citizenshipStatusResidentAlien: false,
        citizenshipStatusUSRefugeeOrOther: false,
      },
      partE: {
        descriptionOfOperation: operationDescription,
        operatorOfAFamilyFarmYes: false,
        operatorOfAFamilyFarmNo: false,
        yearsOperatingAFarm: currentDate.getFullYear() - loanDetails.beganFarming,
        acresOwned: 0,
        acresRented: 0,
        notCausedFarmServiceAgencyALossYes: false,
        notCausedFarmServiceAgencyALossNo: false,
        notDelinquentOnDebtToUSGovernmentYes: false,
        notDelinquentOnDebtToUSGovernmentNo: false,
        notHaveOutstandingRecordedJudgmentYes: false,
        notHaveOutstandingRecordedJudgmentNo: false,
        citizenOfUSYes: false,
        citizenOfUSNo: false,
        capacityToIncurObligationsOfLoanYes: false,
        capacityToIncurObligationsOfLoanNo: false,
        convictedOfControlledSubstanceYes: false,
        convictedOfControlledSubstanceNo: false,
        notEmployeeOfLenderOrAgencyYes: false,
        notEmployeeOfLenderOrAgencyNo: false,
        unableToObtainCreditWithoutGuaranteeYes: false,
        unableToObtainCreditWithoutGuaranteeNo: false,
        notProvidedFalseDocumentsOrStatementsInPastYes: false,
        notProvidedFalseDocumentsOrStatementsInPastNo: false,
      },
      partG: {
        requestNumber: 0,
        requestNumberOf: 0,
        loanTypeFO: false,
        loanTypeOL: false,
        loanTypeOLLOC: false,
        loanTypeCL: false,
        eZGuaranteeYes: false,
        eZGuaranteeNo: false,
        loanAmount: loanDetails.loanAmount,
        interestRate: 0,
        variable: false,
        fixed: false,
        maximum: false,
        other: false,
        otherEntry: "",
        repaymentPeriod: "",
        principalRepaymentTerms: "",
        interestRepaymentTerms: "",
      },
      partM: {
        lenderStatusPLP: false,
        lenderStatusCLP: false,
        lenderStatusSEL: false,
        lenderStatusMLP: false,
        lenderCertifiesDate: "",
        lendingInstitutionNameAndAddress: `${loanDetails.bank.name.trim()} ${loanDetails.bank.streetAddress.trim()} ${loanDetails.bank.streetAddress2 ?? ""} ${loanDetails.bank.city}, ${loanDetails.bank.state} ${loanDetails.bank.zipCode}`,
        telephoneNumber: "",
        lenderTaxIDNumber: "",
        regulatoryCertifyingAgency: "",
        emailAddress: "",
        nameOfLendersRepresentative: "",
        titleOfLenderRepresentative: "",
        date: "",
      },
      partOOne: loanDetails.applicants.length > 1 && loanDetails.applicants[0] ? {
        coApplicantFullName: loanDetails.applicants[0].displayName,
        coApplicantSSNOrTaxId: loanDetails.applicants[0].ssn,
        coApplicantBirthDate: loanApplicationUtility.GetFullYearDateText(loanDetails.applicants[0].dateOfBirth),
        coApplicantAddress: `${loanDetails.applicants[0].address} ${loanDetails.applicants[0].city} ${loanDetails.applicants[0].state} ${loanDetails.applicants[0].zipCode}`,
        coApplicantCounty: "",
        coApplicantTelephoneNumber: "",
        coApplicantTelephoneTypeHome: false,
        coApplicantTelephoneTypeCell: false,
        coApplicantTelephoneTypeWork: false,
        percentOwnership: 0,
        coSignerOnly: false,
        maritalStatusMarried: false,
        maritalStatusUnmarried: false,
        maritalStatusDivorced: false,
        maritalStatusLegallySeparated: false,
        maritalStatusWidowed: false,
        citizenshipStatusUSCitizen: false,
        citizenshipStatusNonCitizenNational: false,
        citizenshipStatusResidentAlien: false,
        citizenshipStatusRefugeeOther: false,
        ethnicityHispanicOrLatino: false,
        ethnicityNonHispanicOrLatino: false,
        raceAmericanIndianOrAlaskanNative: false,
        raceBlackOrAfricanAmerican: false,
        raceNativeHawaiianOrOtherPacificIslander: false,
        raceAsian: false,
        raceWhite: false,
        genderMale: false,
        genderFemale: false,
        veteranStatusVeteran: false,
        veteranStatusNonVeteran: false,
      } : undefined,
      partOTwo: loanDetails.applicants.length > 1 && loanDetails.applicants[1] ? {
        coApplicantFullName: loanDetails.applicants[1].displayName,
        coApplicantSSNOrTaxId: loanDetails.applicants[1].ssn,
        coApplicantBirthDate: loanApplicationUtility.GetFullYearDateText(loanDetails.applicants[1].dateOfBirth),
        coApplicantAddress: `${loanDetails.applicants[1].address} ${loanDetails.applicants[1].city} ${loanDetails.applicants[1].state} ${loanDetails.applicants[1].zipCode}`,
        coApplicantCounty: "",
        coApplicantTelephoneNumber: "",
        coApplicantTelephoneTypeHome: false,
        coApplicantTelephoneTypeCell: false,
        coApplicantTelephoneTypeWork: false,
        percentOwnership: 0,
        coSignerOnly: false,
        maritalStatusMarried: false,
        maritalStatusUnmarried: false,
        maritalStatusDivorced: false,
        maritalStatusLegallySeparated: false,
        maritalStatusWidowed: false,
        citizenshipStatusUSCitizen: false,
        citizenshipStatusNonCitizenNational: false,
        citizenshipStatusResidentAlien: false,
        citizenshipStatusRefugeeOther: false,
        ethnicityHispanicOrLatino: false,
        ethnicityNonHispanicOrLatino: false,
        raceAmericanIndianOrAlaskanNative: false,
        raceBlackOrAfricanAmerican: false,
        raceNativeHawaiianOrOtherPacificIslander: false,
        raceAsian: false,
        raceWhite: false,
        genderMale: false,
        genderFemale: false,
        veteranStatusVeteran: false,
        veteranStatusNonVeteran: false,
      } : undefined
    }

    return newFormData
  }
}


const categoryUtility = new FormPoppulationUtility();
export default categoryUtility;