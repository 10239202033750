
import Bank from "models/bank/bank";
import { ACTIVE_BANKS, ADD_BANK, BankActionTypes, BankState, EMPLOYEE_BANK, LOAN_OFFICERS, ADMIN_UPDATE_BANK, EMPLOYEE_UPDATE_BANK, BANK_SECURITY_ROLES, UPDATE_BANK_EMPLOYEE, CREATE_BANK_EMPLOYEE } from "../../store/bank/types";

const initialState: BankState = {
    loanOfficers: [],
    activeBanks: [],
    employeeBank: {} as Bank,
    bankSecurityRoles: []
};

export default function bank(
    state = initialState,
    action?: BankActionTypes
): BankState {
    switch (action?.type) {
        case LOAN_OFFICERS: {
            return {
                ...state,
                loanOfficers: action.payload.loanOfficers
            };
        }

        case ACTIVE_BANKS: {
            return {
                ...state,
                activeBanks: action.payload.activeBanks
            };
        }

        case ADD_BANK: {
            return {
                ...state,
                activeBanks: [...state.activeBanks ?? [], action.payload.bank]
            };
        }

        case ADMIN_UPDATE_BANK: {
            let activeBanks = [...state.activeBanks ?? []];
            let updatedIndex = activeBanks.findIndex(ab => ab.id === action.payload.bank.id);
            activeBanks[updatedIndex] = action.payload.bank;

            return {
                ...state,
                activeBanks: activeBanks
            };
        }

        case EMPLOYEE_BANK: {
            return {
                ...state,
                employeeBank: {...action.payload.bank}
            };
        }

        case EMPLOYEE_UPDATE_BANK: {
            return {
                ...state,
                employeeBank: {...action.payload.bank}
            };
        }

        case BANK_SECURITY_ROLES: {
            return {
                ...state,
                bankSecurityRoles: action.payload.bankSecurityRoles
            };
        }

        case UPDATE_BANK_EMPLOYEE: {
            let loanOfficers = [...state.loanOfficers ?? []];
            let updatedIndex = loanOfficers.findIndex(lo => lo.id === action.payload.updatedBankEmployee.id);
            loanOfficers[updatedIndex] = action.payload.updatedBankEmployee;

            return {
                ...state,
                loanOfficers: loanOfficers
            };
        }

        case CREATE_BANK_EMPLOYEE: {
            let loanOfficers = [...state.loanOfficers ?? []];
            loanOfficers.push(action.payload.newBankEmployee);

            return {
                ...state,
                loanOfficers: loanOfficers
            };
        }

        default:
            return state;
    }
}
