import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartQClp from "../../../../models/fsa2211Form/FSA2211PartQClp"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartQClpCallback = (formDataPartQClp: FSA2211PartQClp) => void;

interface FSA2211FormPartQClpProps {
  partQClpData?: FSA2211PartQClp;
  formDataChange: formDataPartQClpCallback;
}

export default class FSA2211FormPartQClp extends React.Component<FSA2211FormPartQClpProps, FSA2211PartQClp> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partQClpData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartQClpProps) {
    if (previousProps.partQClpData !== this.props.partQClpData) {
      this.setState(this.props.partQClpData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartQClp {
    return {
      clpLoanNarrativeLess: false,
      clpLoanNarrativeMore: false,
      clpBalanceSheetLess: false,
      clpBalanceSheetMore: false,
      clpCashFlowBudgetLess: false,
      clpCashFlowBudgetMore: false,
      clpLocationOfFarmedLandLess: false,
      clpLocationOfFarmedLandMore: false,
      clpEntityInformationLess: false,
      clpEntityInformationMore: false,
      clpEnvironmentalInformationLess: false,
      clpEnvironmentalInformationMore: false,
      clpTransitionPlanLess: false,
      clpTransitionPlanMore: false,
      clpConservationForestStewardshipManagementPlanLess: false,
      clpConservationForestStewardshipManagementPlanMore: false,
    } as FSA2211PartQClp
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="clp-border">
            <Row className="align-center subsection-heading standard-text">
              <Col className="white-border" md={8}>
                <Row className="mt-2"><Col>CLP</Col></Row>
                <Row><Col><em>(Certified Lender)</em></Col></Row>
                <Row className="mb-2"><Col>Submission Requirements</Col></Row>
              </Col>
              <Col md={2}>
                <Row><Col>&nbsp;</Col></Row>
                <Row><Col>For Loans</Col></Row>
                <Row><Col>$125,000 or Less</Col></Row>
              </Col>
              <Col className="white-border" md={2}>
                <Row><Col>&nbsp;</Col></Row>
                <Row><Col>For Loans</Col></Row>
                <Row><Col>More Than $125,000</Col></Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>

                <Row className="standard-text mt-2">
                  <Col md={8}>Loan Narrative</Col>
                  <Col md={2} className="align-center"><Form.Check name="clpLoanNarrativeLess" onChange={this.handleInputChange.bind(this)} checked={this.state.clpLoanNarrativeLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="clpLoanNarrativeMore" onChange={this.handleInputChange.bind(this)} checked={this.state.clpLoanNarrativeMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Balance Sheet</Col>
                  <Col md={2} className="align-center"><Form.Check name="clpBalanceSheetLess" onChange={this.handleInputChange.bind(this)} checked={this.state.clpBalanceSheetLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="clpBalanceSheetMore" onChange={this.handleInputChange.bind(this)} checked={this.state.clpBalanceSheetMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Cash Flow Budge</Col>
                  <Col md={2} className="align-center"><Form.Check name="clpCashFlowBudgetLess" onChange={this.handleInputChange.bind(this)} checked={this.state.clpCashFlowBudgetLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="clpCashFlowBudgetMore" onChange={this.handleInputChange.bind(this)} checked={this.state.clpCashFlowBudgetMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Location of Farmed Land</Col>
                  <Col md={2} className="align-center"><Form.Check name="clpLocationOfFarmedLandLess" onChange={this.handleInputChange.bind(this)} checked={this.state.clpLocationOfFarmedLandLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="clpLocationOfFarmedLandMore" onChange={this.handleInputChange.bind(this)} checked={this.state.clpLocationOfFarmedLandMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Credit Report</Col>
                  <Col md={2} className="align-center">In File</Col>
                  <Col md={2} className="align-center">In File</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Proposed Loan Agreement</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center">In File</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Verification of Debts over $5,000</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center">In File</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Verification of Non-Farm & Other Income</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center">In File</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>3 Years of Financial History</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center">In File</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>3 Years of Production History</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center">In File</Col>
                </Row>
                <Row><Col className="standard-text instruction-heading">If Applicable, include the information below:</Col></Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Entity Information (including a balance sheet for <span className="standard-bold">each</span> member)</Col>
                  <Col md={2} className="align-center"><Form.Check name="clpEntityInformationLess" onChange={this.handleInputChange.bind(this)} checked={this.state.clpEntityInformationLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="clpEntityInformationMore" onChange={this.handleInputChange.bind(this)} checked={this.state.clpEntityInformationMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Environmental Information</Col>
                  <Col md={2} className="align-center"><Form.Check name="clpEnvironmentalInformationLess" onChange={this.handleInputChange.bind(this)} checked={this.state.clpEnvironmentalInformationLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="clpEnvironmentalInformationMore" onChange={this.handleInputChange.bind(this)} checked={this.state.clpEnvironmentalInformationMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}>Construction/Development Plans</Col>
                  <Col md={2} className="align-center"></Col>
                  <Col md={2} className="align-center">In File</Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}><span className="standard-bold">FOR CL Loans:</span> Transition Plan</Col>
                  <Col md={2} className="align-center"><Form.Check name="clpTransitionPlanLess" onChange={this.handleInputChange.bind(this)} checked={this.state.clpTransitionPlanLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="clpTransitionPlanMore" onChange={this.handleInputChange.bind(this)} checked={this.state.clpTransitionPlanMore} /></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={8}><span className="standard-bold">FOR CL Loans:</span> Conservation or Forest Stewardship Management Plan</Col>
                  <Col md={2} className="align-center"><Form.Check name="clpConservationForestStewardshipManagementPlanLess" onChange={this.handleInputChange.bind(this)} checked={this.state.clpConservationForestStewardshipManagementPlanLess} /></Col>
                  <Col md={2} className="align-center"><Form.Check name="clpConservationForestStewardshipManagementPlanMore" onChange={this.handleInputChange.bind(this)} checked={this.state.clpConservationForestStewardshipManagementPlanMore} /></Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}