import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./DealerPurchaseSection.css";
import loanApplicationUtility from "../../../utilities/LoanApplicationUtility";
import EquipmentInformation from "models/dealerPurchase/equipmentInformation";

interface DealerPurchaseSectionProps {
  loanDetails: LoanApplication
}

export default class DealerPurchaseSection extends React.Component<DealerPurchaseSectionProps> {
  render() {
    return (
      <>
        {this.props.loanDetails.loanDealerPurchase !== undefined &&
          (<>
            <div>
              <h3>Dealer Purchase Details</h3>
              <Table bordered striped={false} size="sm">
                <tbody>
                  <>
                    <tr>
                      <td width="250px" className="app-info"><strong>Purchase Description</strong></td>
                      <td className="app-info">{this.props.loanDetails.loanDealerPurchase.description}</td>
                    </tr>
                    <tr>
                      <td width="250px" className="app-info"><strong>Purchase Amount</strong></td>
                      <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.loanDealerPurchase.purchaseAmount)}</td>
                    </tr>
                    {this.props.loanDetails.loanEquipmentInformation?.map((le: EquipmentInformation, i: number) => {
                      return (
                        <tr>
                          <td width="250px" className="app-info"><strong>{`Equipment Item #${i + 1} Information`}</strong></td>
                          <td className="app-info">{`${loanApplicationUtility.BuildCurrencyAmount(le.price)} | ${le.year ?? 'N/A'} ${le.make ?? 'N/A'} ${le.model ?? 'N/A'} | Vin/Serial: ${le.vin} | ${le.new ? 'New' : 'Used'}`}</td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td width="250px" className="app-info"><strong>Equipment Insurance Information</strong></td>
                      <td className="app-info">{`${this.props.loanDetails.loanEquipmentInsuranceInformation?.companyName ?? 'N/A'}, ${this.props.loanDetails.loanEquipmentInsuranceInformation?.agentName ?? 'N/A'}, ${loanApplicationUtility.FormatPhoneNumer(this.props.loanDetails.loanEquipmentInsuranceInformation?.phoneNumber ?? '')}`}</td>
                    </tr>
                    <tr>
                      <td width="250px" className="app-info"><strong>Custom Hire Use</strong></td>
                      <td className="app-info">{this.props.loanDetails.loanDealerPurchase.customHire ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                      <td width="250px" className="app-info"><strong>Total Assets</strong></td>
                      <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.loanDealerPurchase.totalAssets)}</td>
                    </tr>
                    <tr>
                      <td width="250px" className="app-info"><strong>Total Liabilities</strong></td>
                      <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.loanDealerPurchase.totalLiabilities)}</td>
                    </tr>
                    {this.props.loanDetails.loanDealerPurchase.dealerOther != undefined &&
                      <>
                        <tr>
                          <td width="250px" className="app-info"><strong>Dealer/Salesman Information</strong></td>
                          <td className="app-info">{`${this.props.loanDetails.loanDealerPurchase.dealerOther.location}, ${this.props.loanDetails.loanDealerPurchase.dealerOther.name}, ${loanApplicationUtility.FormatPhoneNumer(this.props.loanDetails.loanDealerPurchase.dealerOther.phoneNumber ?? '')}`}</td>
                        </tr>
                      </>
                    }
                    {this.props.loanDetails.loanDealerPurchase.dealerOther == undefined &&
                      <>
                        <tr>
                          <td width="250px" className="app-info"><strong>Dealer Information</strong></td>
                          <td className="app-info">{`#${this.props.loanDetails.loanDealerPurchase.dealer?.id}, ${this.props.loanDetails.loanDealerPurchase.dealer?.name} - ${this.props.loanDetails.loanDealerPurchase.dealer?.city}, ${this.props.loanDetails.loanDealerPurchase.dealer?.state}`}</td>
                        </tr>
                        <tr>
                          <td width="250px" className="app-info"><strong>Salesman Information</strong></td>
                          <td className="app-info">{`#${this.props.loanDetails.loanDealerPurchase.dealerSalesman?.id}, ${this.props.loanDetails.loanDealerPurchase.dealerSalesman?.firstName} ${this.props.loanDetails.loanDealerPurchase.dealerSalesman?.lastName}, ${this.props.loanDetails.loanDealerPurchase.dealerSalesman?.email}, ${loanApplicationUtility.FormatPhoneNumer(this.props.loanDetails.loanDealerPurchase.dealerSalesman?.phoneNumber ?? '')}`}</td>
                        </tr>
                      </>
                    }

                    <tr>
                      <td width="250px" className="app-info"><strong>Main Lending Reference</strong></td>
                      <td className="app-info">{`${this.props.loanDetails.loanLendingReference?.bankName ?? 'N/A'}, ${this.props.loanDetails.loanLendingReference?.contactName ?? 'N/A'}, ${loanApplicationUtility.FormatPhoneNumer(this.props.loanDetails.loanLendingReference?.phoneNumber ?? '')}`}</td>
                    </tr>
                  </>
                </tbody>
              </Table>
            </div>
          </>)
        }
      </>
    );
  }
}