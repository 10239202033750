import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import SecondaryButton from "shared/components/SecondaryButton";
import "./BalanceSheetModal.css"
import BalanceSheet from "models/financialDocuments/balanceSheet/balanceSheet";
import loanApplicationUtility from "utilities/LoanApplicationUtility";
import AssetCategory, { AssetData } from "models/financialDocuments/balanceSheet/assets/assetCategory";
import LiabilityCategory, { LiabilityData } from "models/financialDocuments/balanceSheet/liabilities/liabilityCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Button, Card } from "react-bootstrap";
import { BalanceSheetAssetCategories, BalanceSheetLiabilityCategories } from "shared/Enums";
import BalanceSheetNAICSSection from "./assetSections/balanceSheetNAICSSection/BalanceSheetNAICSSection";
import CashAndEquivalents from "models/financialDocuments/balanceSheet/assets/cashAndEquivalents";
import RealEstateLandSection from "./assetSections/realEstateLandSection/RealEstateLandSection";
import RealEstateLand from "models/financialDocuments/balanceSheet/assets/realEstateLand";
import MachineryEquipmentVehicleSection from "./assetSections/machineryEquipmentVehicleSection/MachineryEquipmentVehicleSection";
import MachineryEquipmentVehicle from "models/financialDocuments/balanceSheet/assets/machineryEquipmentVehicle";
import NotesPayableSection from "./liabilitySections/notesPayableSection/NotesPayableSection";
import NotesPayable from "models/financialDocuments/balanceSheet/liabilities/notesPayable";
import BalanceSheetNAICS from "models/financialDocuments/balanceSheet/assets/naics";

type callback = () => void;
type onBalanceSheetChangeCallback = (event: any) => void;

interface BalanceSheetModalProps {
  showModal: boolean;
  modalTitle: string;
  balanceSheet: BalanceSheet;
  onBalanceSheetChange: onBalanceSheetChangeCallback;
  onModalCancel: callback;
  onModalConfirm: callback;
}

interface BalanceSheetModalState {

}

const AssetTypesWithExtraData: BalanceSheetAssetCategories[] = [BalanceSheetAssetCategories.CropInventory, BalanceSheetAssetCategories.GrowingCrops,
BalanceSheetAssetCategories.BreedingStock, BalanceSheetAssetCategories.MarketLivestockOrPoultry, BalanceSheetAssetCategories.SpecialtyLivestockOrPoultry,
BalanceSheetAssetCategories.RealEstateLand, BalanceSheetAssetCategories.MachineryAndEquipment, BalanceSheetAssetCategories.PersonalVehicles, BalanceSheetAssetCategories.FarmVehicles];

const NAICSAssetTypes: BalanceSheetAssetCategories[] = [BalanceSheetAssetCategories.CropInventory, BalanceSheetAssetCategories.GrowingCrops,
BalanceSheetAssetCategories.BreedingStock, BalanceSheetAssetCategories.MarketLivestockOrPoultry, BalanceSheetAssetCategories.SpecialtyLivestockOrPoultry];

const MachineVehicleAssetTypes: BalanceSheetAssetCategories[] = [BalanceSheetAssetCategories.MachineryAndEquipment, BalanceSheetAssetCategories.FarmVehicles, BalanceSheetAssetCategories.PersonalVehicles];

const LiabilityTypesWithExtraData: BalanceSheetLiabilityCategories[] = [BalanceSheetLiabilityCategories.NotesPayable];

export default class BalanceSheetModal extends React.Component<BalanceSheetModalProps, BalanceSheetModalState> {
  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.onModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h3>{`As of Date: ${this.props.balanceSheet.asOfDate}`}</h3>
            <span>{`Submitted: ${loanApplicationUtility.GetDateMonthNameFullYear(this.props.balanceSheet.submittedDate ?? new Date())}`}</span>
          </div>

          <div className='data-section'>
            <h3>Personal Information</h3>
            <div>
              <h5>Applicants</h5>
              {this.props.balanceSheet.applicants?.map((a, i) => {
                return (
                  <ul>
                    <li>
                      {`${a.firstName} ${a.lastName}`}
                    </li>
                  </ul>
                )
              })}
            </div>


            <div className='data-section'>
              <h5>Entity Information</h5>
              {this.props.balanceSheet.entity != undefined && this.props.balanceSheet.entity.name != undefined && this.props.balanceSheet.entity.name.length > 0 &&
                <div>
                  <ul>
                    <li>
                      {`${this.props.balanceSheet.entity.name}`}
                    </li>
                  </ul>
                </div>
              }
              {(this.props.balanceSheet.entity == undefined || this.props.balanceSheet.entity.name == undefined || this.props.balanceSheet.entity.name?.length > 0) &&
                <div>
                  <ul>
                    <li>
                      No entity information added.
                    </li>
                  </ul>
                </div>
              }
            </div>

            <div className='data-section'>
              <h3>Assets</h3>
              <ul>
                {this.props.balanceSheet.selectedAssetCategories?.map((ac: AssetCategory, index: number) => {
                  return (
                    <>
                      <li>
                        {ac.name}
                        <ul>
                          {ac.data.map((d: AssetData, i: number) => {
                            return (
                              <li>
                                {!AssetTypesWithExtraData.includes(ac.type) &&
                                  <span>{`${d.description} - ${loanApplicationUtility.BuildCurrencyAmount(d.value)}`}</span>
                                }
                                {AssetTypesWithExtraData.includes(ac.type) &&
                                  <Accordion>
                                    <Card style={{ border: 'none' }}>
                                      <Card.Header style={{ background: 'transparent', padding: 0 }}>
                                        <Accordion.Toggle eventKey={index.toString()} as={Button} variant="link" style={{ color: 'black' }}>
                                          <span>{`${d.description} - ${loanApplicationUtility.BuildCurrencyAmount(d.value)}`}</span>
                                          <span style={{ marginLeft: "15px" }}>
                                            <FontAwesomeIcon icon="plus" color="black" size="sm" />
                                          </span>
                                        </Accordion.Toggle>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey={index.toString()}>
                                        <Card.Body>
                                          <>
                                            {NAICSAssetTypes.includes(ac.type) &&
                                              <BalanceSheetNAICSSection assetCategory={ac} assetData={d as BalanceSheetNAICS} />
                                            }

                                            {ac.type === BalanceSheetAssetCategories.RealEstateLand &&
                                              <RealEstateLandSection assetCategory={ac} assetData={d as RealEstateLand} />
                                            }

                                            {MachineVehicleAssetTypes.includes(ac.type) &&
                                              <MachineryEquipmentVehicleSection assetCategory={ac} assetData={d as MachineryEquipmentVehicle} />
                                            }
                                          </>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                }
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                    </>
                  )
                })}
              </ul>
            </div>

            <div className='data-section'>
              <h3>Liabilities</h3>
              <ul>
                {this.props.balanceSheet.selectedLiabilityCategories?.map((lc: LiabilityCategory, index: number) => {
                  return (
                    <li>
                      {lc.name}
                      <ul>
                        {lc.data.map((d: LiabilityData, i: number) => {
                          return (
                            <li>
                              {!LiabilityTypesWithExtraData.includes(lc.type) &&
                                <span>{`${d.description} - ${loanApplicationUtility.BuildCurrencyAmount(d.value)}`}</span>
                              }
                              {LiabilityTypesWithExtraData.includes(lc.type) &&
                                <Accordion>
                                  <Card style={{ border: 'none' }}>
                                    <Card.Header style={{ background: 'transparent', padding: 0 }}>
                                      <Accordion.Toggle eventKey={index.toString()} as={Button} variant="link" style={{ color: 'black' }}>
                                        <span>{`${d.description} - ${loanApplicationUtility.BuildCurrencyAmount(d.value)}`}</span>
                                        <span style={{ marginLeft: "15px" }}>
                                          <FontAwesomeIcon icon="plus" color="black" size="sm" />
                                        </span>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={index.toString()}>
                                      <Card.Body>
                                        <>
                                          {lc.type === BalanceSheetLiabilityCategories.NotesPayable &&
                                            <NotesPayableSection liabilityCategory={lc} liabilityData={d as NotesPayable} />
                                          }
                                        </>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              }
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          {/* <Form>
            <Form.Group>
              <Form.Label>Note</Form.Label>
              <Form.Control as="textarea" disabled={this.props.noteAction === 'delete'} value={this.props.loanNote?.note} onChange={this.props.onBalanceSheetChange.bind(this)} maxLength={500}></Form.Control>
            </Form.Group>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton buttonText={'Close'} onClick={this.props.onModalCancel} />
        </Modal.Footer>
      </Modal>
    );
  }
}