import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import Table from "react-bootstrap/esm/Table";
import "./EntityApplicantSection.css";

interface EntityApplicantSectionProps {
  loanDetails: LoanApplication
}

export default class EntityApplicantSection extends React.Component<EntityApplicantSectionProps> {
  render() {
    return (
      <>
        <div>
          <h3>Entity Applicant</h3>
          <Table bordered striped={false} size="sm">
            <tbody>
              <tr>
                <td width="250px" className="app-info"><strong>Entity Name</strong></td>
                <td className="app-info">{this.props.loanDetails.entityApplicant !== undefined ? this.props.loanDetails.entityApplicant.organizationName : 'N/A'}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}