import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartK from "../../../../models/fsa2211Form/FSA2211PartK"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartKCallback = (formDataPartK: FSA2211PartK) => void;

interface FSA2211FormPartKProps {
  partKData?: FSA2211PartK;
  formDataChange: formDataPartKCallback;
}

export default class FSA2211FormPartK extends React.Component<FSA2211FormPartKProps, FSA2211PartK> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partKData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartKProps) {
    if (previousProps.partKData !== this.props.partKData) {
      this.setState(this.props.partKData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartK {
    return {
      abilityToRepayAsDemonstratedBy: "",
      abilityToRepayYes: false,
      abilityToRepayNo: false,
      acceptableCreditHistoryYes: false,
      acceptableCreditHistoryNo: false,
    } as FSA2211PartK
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART K &nbsp;&nbsp;   EZ GUARANTEE DOCUMENTATION <em>(APPLICABLE ONLY FOR EZ GUARANTEE APPLICATIONS)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}></Col>
                  <Col><Row><Col>Yes</Col><Col>No</Col></Row></Col>
                </Row>
                <Row className="standard-text">
                  <Col md={10}>1. Applicant shows the ability to repay requested loan as demonstrated by:</Col>
                  <Col><Row><Col>(True)</Col><Col>(False)</Col></Row></Col>
                </Row>
                <Row className="standard-text">
                  <Col md={10}><Form.Control name="abilityToRepayAsDemonstratedBy" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.abilityToRepayAsDemonstratedBy} /></Col>
                  <Col><Row>
                    <Col><Form.Check name="abilityToRepayYes" onChange={this.handleInputChange.bind(this)} checked={this.state.abilityToRepayYes} /></Col>
                    <Col><Form.Check name="abilityToRepayNo" onChange={this.handleInputChange.bind(this)} checked={this.state.abilityToRepayNo} /></Col>
                  </Row></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={10}>2. Applicant has acceptable credit history.</Col>
                  <Col>
                    <Row>
                      <Col><Form.Check name="acceptableCreditHistoryYes" onChange={this.handleInputChange.bind(this)} checked={this.state.acceptableCreditHistoryYes} /></Col>
                      <Col><Form.Check name="acceptableCreditHistoryNo" onChange={this.handleInputChange.bind(this)} checked={this.state.acceptableCreditHistoryNo} /></Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}