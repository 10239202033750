import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import React, { ReactNode } from "react";

export type Roles = "Admin" | "Manager" | "Loan Officer" | "Dealer Salesman";

type CanProps = WithAuth0Props & {
  perform: Roles;
  yes?: () => ReactNode;
  no?: () => ReactNode;
};

class Can extends React.Component<CanProps> {
  rules: any = {
    "Admin": ["Admin", "Manager", "Loan Officer"],
    "Manager": ["Manager", "Loan Officer"],
    "Loan Officer": ["Loan Officer"],
    "Dealer Salesman": ["Dealer Salesman"],
  };

  hasRole = (role: string, perform: Roles) => {
    const rule = this.rules[role];
    return rule.includes(perform);
  };

  render() {
    let { user } = this.props.auth0;

    // User not populated yet, return null
    if (!user) return null;

    return this.hasRole(
      user["https://agcreditbank.com/role"],
      this.props.perform
    )
      ? this.props.yes && this.props.yes()
      : this.props.no && this.props.no();
  }
}

export default withAuth0(Can);
