import React from "react";
import LoanApplication from "../../../models/loan/loanApplication";
import "./OperatingLoanSection.css";
import { LoanType } from "../../../shared/Enums";
import Table from "react-bootstrap/esm/Table";
import NAICSCode from "models/operatingLoan/naicsCode";
import loanApplicationUtility from "utilities/LoanApplicationUtility";

interface OperatingLoanSectionProps {
  loanDetails: LoanApplication
}

export default class OperatingLoanSection extends React.Component<OperatingLoanSectionProps> {
  buildQuantityText(naicsCode: NAICSCode): string {
    return `${loanApplicationUtility.BuildAmount(naicsCode.quantity ?? 0)}`;
  }

  buildPriceText(naicsCode: NAICSCode): string {
    return `${loanApplicationUtility.BuildCurrencyAmount(naicsCode.price)}/${loanApplicationUtility.GetAgriculturalProductMeasurers(naicsCode.measurement ?? -1).toLowerCase()}`.trim();
  }

  render() {
    return (
      <>
        {this.props.loanDetails.loanType === LoanType.OperatingLoan &&
          (<>
            <div className="loan-application-details-section">
              <h3>Operating Commodities</h3>
              <Table bordered striped={false} size="sm">
                <tbody>
                  <tr>
                    <th>NAICS Title</th>
                    <th>NAICS Code</th>
                    <th>NAICS Quantity</th>
                    <th>NAICS Amount</th>
                  </tr>
                  {this.props.loanDetails.operationNAICSCodes?.map(nc => {
                    return (
                      <tr>
                        <td width="250px" className="app-info"><strong>{loanApplicationUtility.BuildOtherTitle(nc)}</strong></td>
                        <td className="app-info">{nc.code}</td>
                        <td className="app-info">{this.buildQuantityText(nc)}</td>
                        <td className="app-info">{this.buildPriceText(nc)}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </Table>
            </div>

            <div className="loan-application-details-section">
              <h3>Other Income(s)</h3>
              <Table bordered striped={false} size="sm">
                <tbody>
                  <tr>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                  {this.props.loanDetails.otherIncomes?.map(oi => {
                    return (
                      <tr>
                        <td width="250px" className="app-info"><strong>{oi.name}</strong></td>
                        <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(oi.value)}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </Table>
            </div>

            <div className="loan-application-details-section">
              <h3>Inventories Held</h3>
              <Table bordered striped={false} size="sm">
                <tbody>
                  <tr>
                    <td width="250px" className="app-info"><strong>{'Amount'}</strong></td>
                    <td className="app-info">{loanApplicationUtility.BuildCurrencyAmount(this.props.loanDetails.inventoryHeld?.value)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </>)
        }
      </>
    );
  }
}