import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import SecondaryButton from "shared/components/SecondaryButton";
import PrimaryButton from "shared/components/PrimaryButton";
import DangerButton from "shared/components/DangerButton";
import "./NoteModal.css"
import LoanNote from "../../../models/note/loanNote";

type callback = () => void;
type onNoteChangeCallback = (event: any) => void;
type LoanNoteAction = 'create' | 'edit' | 'delete';

interface OfferModalProps {
  showModal: boolean;
  modalTitle: string;
  loanNote?: LoanNote;
  noteAction?: LoanNoteAction;
  onNoteChange: onNoteChangeCallback;
  onModalCancel: callback;
  onModalConfirm: callback;
}

interface OfferModalState {
  showCalendar: boolean
}

export default class OfferModal extends React.Component<OfferModalProps, OfferModalState> {
  getModalActionButton(): JSX.Element {
    switch (this.props.noteAction) {
      case 'create':
        return <PrimaryButton buttonText={'Add Note'} onClick={this.props.onModalConfirm.bind(this)} />;

      case 'edit':
        return <PrimaryButton buttonText={'Update Note'} onClick={this.props.onModalConfirm.bind(this)} />;

      case 'delete':
        return <DangerButton buttonText={'Delete Note'} onClick={this.props.onModalConfirm.bind(this)} />

      default:
        return <></>;
    }
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.onModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              {this.props.noteAction === 'delete' &&
                (<Form.Text>Are you sure you would like to delete the following?</Form.Text>)
              }
              <Form.Label>Note</Form.Label>
              <Form.Control as="textarea" disabled={this.props.noteAction === 'delete'} value={this.props.loanNote?.note} onChange={this.props.onNoteChange.bind(this)} maxLength={500}></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton buttonText={'Close'} onClick={this.props.onModalCancel} />
          {this.getModalActionButton()}
        </Modal.Footer>
      </Modal>
    );
  }
}