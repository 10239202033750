import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import PrimaryButton from "shared/components/PrimaryButton";
import "./FSAApplicationModal.css"
import 'react-calendar/dist/Calendar.css';
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";
import FSA2211Form from "../../loanDetails/FSAApplicationsSection/FSA2211Form/FSA2211Form";
import { Button, Col, Row } from "react-bootstrap";
import FSA2211 from "models/fsa2211Form/FSA2211";
import loanApplicationService from "accessors/loanApplicationService";
import LoanApplication from "models/loan/loanApplication";
import FSA2211Data from "models/fsa2211Form/FSA2211Data";
import FormPopulationUtility from "../../../utilities/FormPopulationUtility"

type callback = () => void;
type formCallback = (formData: FSA2211) => void

interface FSAApplicationModalProps {
  showModal: boolean;
  loanDetails: LoanApplication;
  applicationType?: string;
  modalAction?: string;
  isLoading: boolean;
  fsa2211Form?: FSA2211;
  onModalCancel: callback;
  onModalConfirm: formCallback;
  onModalGenerate: formCallback;
  onModalDownload: callback;
}

interface FSAApplicationModalState {
  currentRevisionVersion: string;
  currentDate: Date;
  fsa2211Form: FSA2211;
  fsa2211FormDirty: FSA2211;
}

export default class FSAApplicationModal extends React.Component<FSAApplicationModalProps, FSAApplicationModalState> {
  FSA2211: React.RefObject<FSA2211Form>;

  constructor(props: any) {
    super(props);

    this.FSA2211 = React.createRef();
    this.state = {
      currentRevisionVersion: "",
      currentDate: new Date(),
      fsa2211Form: this.props.fsa2211Form ?? {},
      fsa2211FormDirty: this.props.fsa2211Form ?? {}
    }
  }

  async componentDidMount() {
    // getcurrent revison version
    await loanApplicationService.GetCurrentFSA2211RevisionVersion().then(response => {
      this.setState({ currentRevisionVersion: response });
    });
  }

  getModalTitle(): string {
    switch (this.props.applicationType) {
      case 'FSA2211':
        return 'FSA2211';

      case 'FSA2037':
        return 'FSA2037';

      case 'FSA2038':
        return 'FSA2038';

      default:
        return '';
    }
  }

  populateFSA2211FormData(): void {
    let formData: FSA2211Data = this.props.fsa2211Form?.data ?? FormPopulationUtility.populateNewFSA2211FormData(this.props.loanDetails);
    let form: FSA2211 = { ...this.state.fsa2211Form ?? {loanId: this.props.loanDetails.id, data: {} as FSA2211Data} as FSA2211 }
    form.data = formData;
    this.setState({ fsa2211Form: form, fsa2211FormDirty: form });
  }

  getModalActionButton(): JSX.Element {
    switch (this.props.modalAction) {
      case 'create':
        return <PrimaryButton
          buttonText={'Save'}
          onClick={this.onModalConfirmAction.bind(this)} />;
      case 'delete':
        return <PrimaryButton
          buttonText={'Delete'}
          onClick={this.onModalConfirmAction.bind(this)} />;
      default:
        return <></>;
    }
  }

  onFSA2211FormUpdated(formData: FSA2211Data): void {
    let fsa2211Form: FSA2211 = { ...this.state.fsa2211Form };
    fsa2211Form.data = formData;
    this.setState({ fsa2211FormDirty: fsa2211Form });
  }

  resetModalState(): void {
    this.setState({});
  }

  onModalConfirmAction(): void {
    let form: FSA2211 = { ...this.state.fsa2211FormDirty };
    form.revisionVersion = this.state.currentRevisionVersion;
    this.setState({ fsa2211Form: form }, () => this.props.onModalConfirm(form));
  }

  onModalGenerateAction(): void {
    if (!this.props.fsa2211Form || this.state.currentRevisionVersion === this.props.fsa2211Form?.revisionVersion) {
      var formData = this.state.fsa2211Form;
      if (formData) {
        this.props.onModalGenerate(formData);
      }
    } else {
      this.props.onModalDownload();
    }
  }

  onModalCancelAction(): void {
    this.props.onModalCancel();
  }

  render() {
    return (
      <Modal show={this.props.showModal} dialogClassName="custom-modal" size="xl" onShow={this.populateFSA2211FormData.bind(this)} onHide={this.onModalCancelAction.bind(this)} backdrop="static" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{this.getModalTitle()} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              {this.props.applicationType === 'FSA2211' &&
                (<>
                  {(this.props.fsa2211Form?.revisionVersion === this.state.currentRevisionVersion || this.props.fsa2211Form === undefined) &&
                    <FSA2211Form
                      formData={this.state.fsa2211Form.data ?? {}}
                      fsa2211RevisionVersion={this.state.currentRevisionVersion}
                      onFSA2211FormUpdated={this.onFSA2211FormUpdated.bind(this)} />
                  }
                  {(this.props.fsa2211Form?.revisionVersion !== this.state.currentRevisionVersion && this.props.fsa2211Form !== undefined) &&
                    <>
                      This form is a previous revision, it can be generated but not edited.
                    </>
                  }
                </>)
              }
              {this.props.applicationType === 'FSA2037' &&
                (<>
                  <Form.Text>FSA2037</Form.Text>
                </>)
              }
              {this.props.applicationType === 'FSA2038' &&
                (<>
                  <Form.Text>FSA2038</Form.Text>
                </>)
              }
            </Form.Group>
          </Form>
        </Modal.Body>
        <div className="application-footer">
          <Modal.Footer>
            <Row>
              <Col md={2}>
                <Button variant="primary"
                  className="w-100"
                  onClick={this.onModalGenerateAction.bind(this)}>
                  Generate PDF
                </Button>
              </Col>
              <Col>
              </Col>
              <Col md={2}>
                <Button variant="secondary"
                  className="w-100"
                  onClick={this.onModalCancelAction.bind(this)}>
                  Cancel
                </Button>
              </Col>
              <Col md={2}>
                <Button variant="primary"
                  className="w-100"
                  onClick={this.onModalConfirmAction.bind(this)}>
                  Save
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </div>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'light'}
            animation={'border'}
          />
        }
      </Modal>
    );
  }
}