import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FSA2211PartJ from "../../../../models/fsa2211Form/FSA2211PartJ"
import HandleInputChangeUtility from "../../../../utilities/HandleInputChangeUtility";
import "./FSA2211Form.css"

type formDataPartJCallback = (formDataPartJ: FSA2211PartJ) => void;

interface FSA2211FormPartJProps {
  partJData?: FSA2211PartJ;
  formDataChange: formDataPartJCallback;
}

export default class FSA2211FormPartJ extends React.Component<FSA2211FormPartJProps, FSA2211PartJ> {
  constructor(props: any) {
    super(props);

    this.state = this.props.partJData ?? this.setDefaultValues();
  }

  componentDidUpdate(previousProps: FSA2211FormPartJProps) {
    if (previousProps.partJData !== this.props.partJData) {
      this.setState(this.props.partJData ?? this.setDefaultValues());
    }
  }

  setDefaultValues(): FSA2211PartJ {
    return {
      netCashFlowWithoutInterestAssistance: "",
      netCashFlowWithInterestAssistance: "",
    } as FSA2211PartJ
  }

  handleInputChange(event: any) {
    var result = HandleInputChangeUtility.GetNameAndValue(event);

    this.setState({
      ...this.state,
      [result.name]: result.value
    }, () => this.props.formDataChange(this.state));
  }

  render() {
    return (
      <>
        <Row>
          <Col className="section-border">
            <Form>
              <Form.Group>
                <Row>
                  <Col className="section-heading">PART J &nbsp;&nbsp;  INTEREST ASSISTANCE DOCUMENTATION <em>(APPLICABLE ONLY FOR INTEREST ASSISTANCE APPLICATIONS)</em></Col>
                </Row>
                <Row className="standard-text mt-2">
                  <Col md={6}>1. NET CASH FLOW <em>(inflows - outflows)</em> WITHOUT INTEREST ASSISTANCE:</Col>
                  <Col><Form.Control name="netCashFlowWithoutInterestAssistance" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.netCashFlowWithoutInterestAssistance} /></Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col>If a feasible plan cannot be developed (net cash flow is negative) without interest assistance, the applicant should be considered for interest assistance.
                    The applicant must project a feasible plan with interest assistance, or the request will be denied.</Col>
                </Row>
                <Row className="standard-text mt-1">
                  <Col md={6}>2. NET CASH FLOW <em>(inflows - outflows)</em> WITH INTEREST ASSISTANCE:</Col>
                  <Col>
                    <Form.Control name="netCashFlowWithInterestAssistance" type="text" onChange={this.handleInputChange.bind(this)} value={this.state.netCashFlowWithInterestAssistance} />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}